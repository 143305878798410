import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { CHATS_BOTS_MODERATION_STATUSES } from 'const';

import './style.scss';

const StatusCellRenderer = ({ valueFormatted }) => {
  const { t } = useTranslation();

  const currentStatus = useMemo(
    () =>
      CHATS_BOTS_MODERATION_STATUSES.find(I => I.id === valueFormatted),
    [valueFormatted],
  );

  const className = classNames('sender-status-cell-renderer', {
    [currentStatus?.className]: currentStatus?.className,
  });

  return (
    <div className={className}>
      <img
        src={currentStatus?.icon}
        alt=""
      />
      {t(currentStatus?.name)}
    </div>
  );
};

export default StatusCellRenderer;
