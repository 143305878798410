import {
  AbstractDisplacementState,
  Action,
  InputType,
} from '@projectstorm/react-canvas-core';
/*eslint-disable */
export default class SelectLinkState extends AbstractDisplacementState {
  constructor() {
    super({ name: 'select-link' });

    this.registerAction(
      new Action({
        type: InputType.MOUSE_DOWN,
        fire: (event) => {
          const link = this.engine.getMouseElement(event.event);

          if (link) {
            if (link.isLocked()) {
              this.eject();
            }

            this.engine.getModel().clearSelection();
            link.setSelected(true);
          }
        },
      }),
    );
  }

  fireMouseMoved() {
    console.log('fireMouseMoved');
    // do nothing
  }
}
