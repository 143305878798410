import React from 'react';
import { useKey } from 'react-use';

import Modal from 'components/Modal';

import './style.scss';

const QueryErrorModal = ({
  closeModal,
  text,
}) => {
  useKey('Escape', closeModal);

  return (
    <Modal
      onClose={closeModal}
      withWarning
    >
      <div className="query-error-modal__description">
        {text}
      </div>
    </Modal>
  );
};

export default QueryErrorModal;
