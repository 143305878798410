import React, { useEffect, useState, useMemo } from 'react';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { useBoolean } from 'hooks';
import { useFlowResourceApi, useFlowsNewApi } from 'hooks/api';
import { DropdownFieldNew, ReportField, BasicField } from 'components/fields';
import { BasicButton } from 'components/buttons';
import { required } from 'helpers/validators';
import SelectScenario from 'components/blocks/SelectScenario';
import { uikit } from 'components';
import urlIcon from 'assets/icons/rcs/url.svg';
import urlActiveIcon from 'assets/icons/rcs/url-active.svg';
import callIcon from 'assets/icons/rcs/call.svg';
import callActiveIcon from 'assets/icons/rcs/call-active.svg';
import locationIcon from 'assets/icons/rcs/location.svg';
import locationActiveIcon from 'assets/icons/rcs/location-active.svg';
import pushActiveIcon from 'assets/icons/rcs/push-active.svg';
import calendarIcon from 'assets/icons/calendar-white.svg';
import calendarActiveIcon from 'assets/icons/calendar-active.svg';
import robotIcon from 'assets/icons/side-menu/robot.svg';
import crossIcon from 'assets/icons/cross.svg';

import DisplayedFields from './DisplayedFields';

import './style.scss';

const CreateActionForm = ({
  onSubmit,
  initialValues = {
    suggestionType: 'reply',
    url: 'http://',
    category: 'urlAction',
    viewMode: 'full',
    application: 'browser',
  },
  reportFields,
  onClose,
  readOnly,
  withPostback,
  maxLength = 25,
  replyOnly = false,
}) => {
  const basicButtonStyle = {
    alignSelf: 'center',
    justifyContent: 'center',
  };
  const commonFieldStyle = {
    marginBottom: 12,
  };
  const dropdownFieldStyles = {
    container: {
      marginBottom: 12,
    },
    label: {
      display: 'flex',
      flex: 3,
      paddingRight: 11,
    },
    content: {
      flex: 7,
    },
  };

  const { t } = useTranslation();

  const {
    getFlowResource,
  } = useFlowResourceApi();
  const { flowsAll, getFlowsAll } = useFlowsNewApi();

  const [
    isOpenDrawer,
    openDrawer,
    closeDrawer,
  ] = useBoolean(false);

  const suggestionTypes = useMemo(() => {
    if (replyOnly) {
      return [
        {
          value: 'reply',
          label: t('MESSAGE_CONSTRUCTOR.INSTANCES.REPLY'),
        },
      ];
    }
    return [
      {
        value: 'reply',
        label: t('MESSAGE_CONSTRUCTOR.INSTANCES.REPLY'),
      },
      {
        value: 'action',
        label: t('MESSAGE_CONSTRUCTOR.INSTANCES.ACTION'),
      },
    ];
  }, [replyOnly]);

  const modifyHandleSubmit = ({ postback, ...values }) => {
    let icon;

    if (values.suggestionType === 'action') {
      switch (values.category) {
        case 'urlAction':
          icon = urlActiveIcon;
          break;
        case 'dialerAction':
          icon = callActiveIcon;
          break;
        case 'mapAction':
          icon = values.type === 'requestLocationPush' ? pushActiveIcon : locationActiveIcon;
          break;
        case 'calendarAction':
          icon = calendarActiveIcon;
          break;
        default: break;
      }
    } else {
      icon = null;
    }

    onSubmit({
      ...values,
      postback: {
        actionType: values.suggestionType === 'action' ? values.category : 'reply',
        scenarioId: postback?.scenarioId,
        nodeId: postback?.nodeId,
      },
      icon,
      isEdit: !!initialValues,
    });
  };

  useEffect(() => {
    if (!flowsAll.length) {
      getFlowsAll();
    }
  }, []);

  return (
    <Form
      onSubmit={modifyHandleSubmit}
      initialValues={initialValues}
      render={({
        handleSubmit,
        values,
        form,
        invalid,
      }) => {
        const [stepName, setStepName] = useState('');
        const [scenarioName, setScenarioName] = useState('');

        const buttonText = useMemo(() => {
          if (stepName) {
            return `${stepName} (${scenarioName})`;
          }

          return scenarioName || t('MESSAGE_CONSTRUCTOR.INSTANCES.STEP_NAME');
        }, [stepName, scenarioName]);

        useEffect(() => {
          if (values?.postback) {
            const { scenarioId, nodeId } = values.postback;

            if (scenarioId) {
              getFlowResource({
                id: scenarioId,
                successCallback: (response) => {
                  const foundFlow = flowsAll.find(item => item.id === scenarioId);
                  if (foundFlow) {
                    setScenarioName(foundFlow.name);
                  }

                  if (!nodeId && stepName) {
                    setStepName('');
                  }

                  const foundNode = response.nodes.find(item => item.id === nodeId);
                  if (foundNode) {
                    setStepName(foundNode.label);
                  }
                },
              });
            }
          } else if (scenarioName || stepName) {
            setStepName('');
            setScenarioName('');
          }
        }, [values?.postback]);
        return (
          <form onSubmit={handleSubmit} className="mkit-create-action-form">
            <h2 className="mkit-create-action-form__title">
              {t('MESSAGE_CONSTRUCTOR.INSTANCES.SUGGESTION_SETTINGS')}
            </h2>
            <Field
              name="text"
              component={BasicField}
              label={t('INSTANCES.TEXT')}
              styleWrapper={commonFieldStyle}
              type="with-border"
              isRequired
              isDisabled={readOnly}
              validate={value => required(value, t)}
              maxLength={maxLength}
            />
            <Field
              name="suggestionType"
              component={DropdownFieldNew}
              options={suggestionTypes}
              label={t('MESSAGE_CONSTRUCTOR.INSTANCES.SUGGESTION_TYPE')}
              styles={dropdownFieldStyles}
              type="border"
              disabled={readOnly}
            />
            {
              values.suggestionType === 'action' && (
                <>
                  <Field
                    name="category"
                    component={DropdownFieldNew}
                    className="mkit-create-action-form__row__dropdown"
                    options={[
                      {
                        value: 'urlAction',
                        icon: urlIcon,
                        label: t('MESSAGE_CONSTRUCTOR.INSTANCES.URL_ACTION'),
                      },
                      {
                        value: 'dialerAction',
                        icon: callIcon,
                        label: t('MESSAGE_CONSTRUCTOR.INSTANCES.DIALER_ACTION'),
                      },
                      {
                        value: 'mapAction',
                        icon: locationIcon,
                        label: t('MESSAGE_CONSTRUCTOR.INSTANCES.MAP_ACTION'),
                      },
                      {
                        value: 'calendarAction',
                        icon: calendarIcon,
                        label: t('MESSAGE_CONSTRUCTOR.INSTANCES.CALENDAR_ACTION'),
                      },
                    ]}
                    label={t('MESSAGE_CONSTRUCTOR.INSTANCES.ACTION_CATEGORY')}
                    styles={dropdownFieldStyles}
                    type="border"
                    disabled={readOnly}
                  />
                  <DisplayedFields values={values} form={form} readOnly={readOnly} />
                </>
              )
            }
            {
              (!!values.suggestionType && withPostback) && (
                <>
                  <h3 className="mkit-create-action-form__subtitle">
                    {t('MESSAGE_CONSTRUCTOR.INSTANCES.SCRIPT_SUBTITLE')}
                  </h3>
                  <div className="mkit-create-action-form__row">
                    <span className="mkit-create-action-form__row__label" style={readOnly && { opacity: 0.6 }}>
                      {t('MESSAGE_CONSTRUCTOR.INSTANCES.START_SCRIPT')}
                    </span>
                    <button
                      onClick={openDrawer}
                      disabled={readOnly}
                      type="button"
                      className="mkit-create-action-form__row__button"
                    >
                      {buttonText}
                      <img
                        src={values.postback ? crossIcon : robotIcon}
                        className="mkit-create-action-form__row__button__icon"
                        role="presentation"
                        onClick={(e) => {
                          if (values.postback && !readOnly) {
                            e.stopPropagation();
                            form.change('postback', undefined);
                          }
                        }}
                        alt=""
                      />
                    </button>
                  </div>
                </>
              )
            }
            {reportFields && (
              <Field
                name="reportField"
                type="border"
                label={t('SCENARIOS_CONSTRUCTOR.INSTANCES.REPORTING')}
                component={ReportField}
                options={reportFields}
                styles={{
                  label: {
                    marginTop: 12,
                  },
                }}
              />
            )}
            <div className="mkit-create-action-form__buttons">
              <BasicButton
                text={t('CONTROLS.CANCEL')}
                type={BasicButton.types.PRIMARY}
                onClick={onClose}
                style={basicButtonStyle}
              />
              {
                !readOnly && (
                  <BasicButton
                    text={t('CONTROLS.APPLY')}
                    type={BasicButton.types.ACCENT}
                    onClick={handleSubmit}
                    style={basicButtonStyle}
                    disabled={!values.suggestionType || invalid}
                  />
                )
              }
            </div>
            {withPostback && (
              <uikit.Drawer
                visible={isOpenDrawer}
                onClose={closeDrawer}
                width={800}
                destroyOnClose
              >
                <Field name="postback">
                  {({ input: { onChange } }) => (
                    <SelectScenario
                      onClose={closeDrawer}
                      onSelect={({ scenario, nodeId }) => onChange({
                        scenarioId: scenario?.id,
                        nodeId,
                      })}
                      isPostback
                    />
                  )}
                </Field>
              </uikit.Drawer>
            )}
          </form>
        );
      }}
    />
  );
};

export default CreateActionForm;
