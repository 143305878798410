import endpoints from '../../const/endpoints';

export default ({ errorCallback } = {}) => ({
  url: endpoints.getGroupsUrl(),
  queryKey: endpoints.getGroupsUrl(),
  meta: {
    errorCallback,
    authToken: true,
  },
  options: {
    method: 'GET',
  },
  transform: response => ({ groups: response }),
  update: {
    groups: (oldValue, newValue) => newValue,
  },
});
