/*eslint-disable */
import React, { useState } from 'react';
import AutocompleteSource from 'react-autocomplete';
import { useTheme } from 'hooks';
import closeIcon from 'assets/icons/cross.svg';

import './style.scss';

const Autocomplete = ({
  defaultInputValue,
  inputIcon,
  values, // values is an array of objects {text: "", img: "" | undefined}
  wrapperStyle = {},
  // menuStyle,
  onChange,
  onSelect = Function.prototype,
}) => {
  const { isDarkTheme } = useTheme();

  // todo: remove but save _id mapping
  const defaultValues = values ? values.map((el, i) => ({ id: i, text: el.text || 'value' })) : [
    { id: 1, text: 'birthdaytoday' },
    { id: 2, text: 'loyalcustomer' },
    { id: 3, text: 'eltech' },
    { id: 4, text: 'sometag' },
    { id: 5, text: 'sometag2' },
  ];

  const [value, setValue] = useState(defaultInputValue || '');
  const onInputChange = (newValue) => {
    onChange(newValue);
    setValue(newValue);
  };
  const onSelectHandler = (selectedValue) => {
    onInputChange(selectedValue);
    onSelect(selectedValue);
  };

  const innerWrapperStyle = {
    width: '100%',
    position: 'relative',
    zIndex: '5',
  };

  const defaultMenuStyle = {
    width: wrapperStyle.width || '200px',
    borderRadius: '0',
    background: isDarkTheme ? '#222430' : '#eef1f4',
    overflow: 'auto',
    position: 'absolute',
    left: '0',
    top: '24px',
    maxHeight: 200,
  };

  return (
    <div style={wrapperStyle} className="mkit-autocomplete__wrapper">
      <AutocompleteSource
        wrapperStyle={innerWrapperStyle}
        getItemValue={item => item.text}
        items={defaultValues}
        renderInput={props => (
          <div className="mkit-autocomplete__input-wrapper">
            {inputIcon && <img src={inputIcon} alt="" className="mkit-autocomplete__input-wrapper__prefix-icon" />}
            <input {...props} />
            {props.value && <img src={closeIcon} alt="" className="mkit-autocomplete__input-wrapper__suffix-icon" onClick={() => onInputChange('')} />}
          </div>
        )}
        renderItem={(item, isHighlighted) => (
          <div key={item.id} className={isHighlighted ? 'mkit-autocomplete__value-wrapper autocomplete__value-wrapper_highlighted' : 'mkit-autocomplete__value-wrapper'}>
            {item.img && <img src={item.img} alt="" />}
            {item.text}
          </div>
        )}
        shouldItemRender={(item, val) => item.text.toLowerCase().indexOf(val.toLowerCase()) >= 0}
        value={value}
        onChange={e => onInputChange(e.currentTarget.value)}
        onSelect={selectedValue => onSelectHandler(selectedValue)}
        menuStyle={defaultMenuStyle}
      />
    </div>
  );
};

export default Autocomplete;
