import endpoints from 'const/endpoints';

export default ({
  limit = 10,
  offset = 0,
  errorCallback = Function.prototype,
  successCallback = Function.prototype,
  ...queries
}) => ({
  url: endpoints.getApprovedTemplatesUrl(),
  queryKey: endpoints.getApprovedTemplatesUrl(),
  body: {
    limit,
    offset: offset ? ((offset - 1) * limit) : offset,
    ...queries,
  },
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
    errorCallback,
    successCallback,
  },
  transform: response => ({
    approvedTemplates: response?.resourceList?.filter(I => I.status === 2),
  }),
  update: {
    approvedTemplates: (oldValue, newValue) => newValue,
  },
});
