import React, { useCallback, useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import { isEmpty, throttle } from 'lodash';
import { Icon } from '@blueprintjs/core';
import toastRef from 'mkit-front-ds/dist/helpers/toast';
import { getMaxFileSizeByChannels } from 'mkit-front-ds/dist/helpers';

import { blocks } from 'components';
import { BasicButton } from 'components/buttons';
import { SearchField } from 'components/fields';
import { copyInClipboard, toast } from 'helpers';
import crossIcon from 'assets/icons/cross-grey.svg';
import okIcon from 'assets/icons/ok.svg';
import { useAssetsApi } from 'hooks/api';
import Drawer from 'components/UIKit/Drawer/Drawer';

import LoadingSpinner from '../LoadingSpinner';

import './style.scss';

const initialLimit = 20;

const SelectMedia = ({
  open,
  onClose,
  onSelected,
  width = 380,
  buttonText,
  mediaFormat,
  format,
  channel,
  withUploadButton,
  // in MB
  fileMaxSize,
}) => {
  const fieldStyle = { marginRight: 10, width: '100%' };

  const { t } = useTranslation();

  const {
    getAssets,
    assets,
    deleteAsset,
    isPendingGetAssets,
    isPendingAddAsset,
    postAddAsset,
  } = useAssetsApi();

  // выбранный контект хранится в этой переменной
  const [selectedContent, setSelectedContent] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [limit, setLimit] = useState(initialLimit);

  const onCancel = useCallback(() => {
    setSelectedContent({});
    onClose();
  }, [setSelectedContent, onClose]);

  const handleSelected = (selected) => {
    if (channel === 4) {
      const sizeInMB = selectedContent.fileSize * 9.537 * (10 ** -7);
      const type = selectedContent.mediaType;
      if (sizeInMB >= 5 && type === 'Image') {
        toastRef.current.showMessage({
          message: {
            key: 'NEW_TRANSLATES.TOASTS.SELECTED_FILE_SIZE_MORE_THAN',
            values: { fileName: selectedContent.fileName, maxSize: 5 },
          },
        });
        return;
      }
      if (sizeInMB >= 16 && type === 'Video') {
        toastRef.current.showMessage({
          message: {
            key: 'NEW_TRANSLATES.TOASTS.SELECTED_FILE_SIZE_MORE_THAN',
            values: { fileName: selectedContent.fileName, maxSize: 16 },
          },
        });
        return;
      }
      if (sizeInMB >= 100 && type === 'Application') {
        toastRef.current.showMessage({
          message: {
            key: 'NEW_TRANSLATES.TOASTS.SELECTED_FILE_SIZE_MORE_THAN',
            values: { fileName: selectedContent.fileName, maxSize: 100 },
          },
        });
        return;
      }
    }

    onSelected(selected);
    setSelectedContent({});
    onClose();
  };

  const handleChooseClick = useCallback(() => {
    handleSelected(selectedContent);
  }, [selectedContent, onSelected]);

  const handleScroll = ({ target }) => {
    const bottom = target.scrollHeight - target.scrollTop === target.clientHeight;

    if (bottom && !isPendingGetAssets && !searchValue) {
      setLimit(limit + initialLimit);
    }
  };

  const onDoubleClick = (item) => {
    handleSelected(item);
  };

  const searchQuery = useCallback(throttle((value) => {
    if (!isPendingGetAssets) {
      if (value) {
        getAssets({
          offset: 0,
          limit: 50,
          'fileName[of]': `*${value}*`,
          'mediaType[of]': mediaFormat,
          'format[of]': format?.join(),
          'weight[gt]': 0,
          'weight[lt]': getMaxFileSizeByChannels(channel, 'byte'),
        });
      } else if (limit !== initialLimit) {
        setLimit(initialLimit);
      } else {
        getAssets({
          offset: 0,
          limit: initialLimit,
          'mediaType[of]': mediaFormat,
          'format[of]': format?.join(),
          'weight[gt]': 0,
          'weight[lt]': getMaxFileSizeByChannels(channel, 'byte'),
        });
      }
    }
  }, 1500, { leading: false }), [mediaFormat, isPendingGetAssets]);

  const handleSearch = (value) => {
    setSearchValue(value);
    searchQuery(value);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (fileMaxSize && file.size * 9.537 * (10 ** -7) >= fileMaxSize) {
      toast.error(t('TOAST.FILE_SIZE_MORE_THAN', { fileName: file.name, maxSize: fileMaxSize }));
      return;
    }
    if (format.length && !format.some(ext => file.type.toLowerCase().includes(ext))) {
      toast.error(t(
        'TOAST.INCORRECT_FILE_FORMAT',
        {
          fileName: file.name,
          format: `.${file.name.split('.').pop().toLowerCase()}`,
        },
      ));
      return;
    }

    const form = new FormData();

    form.append('multipartFiles', e.target.files[0]);

    postAddAsset({
      body: form,
      successCallback: () => {
        setLimit(initialLimit);
        setSearchValue('');
        getAssets({
          offset: 0,
          limit: 50,
          'mediaType[of]': mediaFormat,
          'format[of]': format?.join(),
          'weight[gt]': 0,
          'weight[lt]': getMaxFileSizeByChannels(channel, 'byte'),
        });
      },
    });
  };

  useEffect(() => {
    if (open) {
      if (limit !== initialLimit) {
        setLimit(initialLimit);
      } else {
        getAssets({
          offset: 0,
          limit,
          'mediaType[of]': mediaFormat,
          'format[of]': format?.join(),
          'weight[gt]': 0,
          'weight[lt]': getMaxFileSizeByChannels(channel, 'byte'),
        });
      }
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      getAssets({
        offset: 0,
        limit,
        'mediaType[of]': mediaFormat,
        'format[of]': format?.join(),
        'weight[gt]': 0,
        'weight[lt]': getMaxFileSizeByChannels(channel, 'byte'),
      });
    }
  }, [limit, mediaFormat, open]);

  return (
    <Drawer
      visible={open}
      onClose={onCancel}
      width={width}
    >
      <div className="mkit-content-selector">
        <h2 className="mkit-content-selector__title">
          {t('MESSAGE_CONSTRUCTOR.INSTANCES.SELECT_MEDIA')}
        </h2>
        <SearchField
          input={{
            value: searchValue,
            onChange: handleSearch,
          }}
          isDisabled={isPendingGetAssets}
          type="full-border"
          placeholder={t('PLACEHOLDERS.SEARCH_SOMETHING')}
          styleWrapper={fieldStyle}
        />
        <blocks.MediaMiniatureBlock
          assets={assets}
          copyInClipboard={copyInClipboard}
          saveAs={saveAs}
          deleteAsset={deleteAsset}
          onSelectItem={setSelectedContent}
          selectedId={selectedContent?.fileLinkId}
          onScroll={handleScroll}
          onDoubleClick={onDoubleClick}
          type="small"
          firstElement={withUploadButton ? (
            <label htmlFor="file-uploader" className="mkit-content-selector__upload-button">
              {isPendingAddAsset ? (
                <LoadingSpinner fullSize />
              ) : (
                <Icon
                  icon="export"
                  size={30}
                />
              )}
              <input type="file" id="file-uploader" onChange={handleFileChange} accept={`${mediaFormat}/*`} />
            </label>
          ) : null}
        />
        <div className="mkit-content-selector__buttons-wrapper">
          <BasicButton
            icon={crossIcon}
            onClick={onCancel}
            type={BasicButton.types.CANCEL}
            text={t('CONTROLS.CANCEL')}
            isNewStyle
          />
          <BasicButton
            icon={okIcon}
            onClick={handleChooseClick}
            text={buttonText || t('MESSAGE_CONSTRUCTOR.CONTROLS.CHOOSE')}
            type={BasicButton.types.ACCENT}
            disabled={isEmpty(selectedContent)}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default SelectMedia;
