import endpoints from 'const/endpoints';

export default ({ params = {}, errorCallback }) => ({
  url: endpoints.getStatsByFiltersUrl(params),
  queryKey: 'getStatsByFilters',
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
    errorCallback,
  },
  transform: response => ({
    statsByFilters: response.totalCount,
  }),
  update: {
    statsByFilters: (oldValue, newValue) => newValue,
  },
});
