import CampaignEditGeneral from './CampaignEditGeneral';
import CampaignEditTargets from './CampaignEditTargets';
import CampaignEditLaunchTime from './CampaignEditLaunchTime';
import CampaignEditFallback from './CampaignEditFallback';

export default {
  CampaignEditGeneral,
  CampaignEditTargets,
  CampaignEditLaunchTime,
  CampaignEditFallback,
};
