import React, { useMemo } from 'react';
import FormContext from 'antd/es/form/context';

/**
 * @see https://ant.design/components/form/?locale=en-US#Form
 * @param labelAlign
 * @param labelCol
 * @param wrapperCol
 * @param layout
 * @param colon
 * @param children
 * @returns {*}
 */
const FormLayout = ({
  labelAlign,
  labelCol,
  wrapperCol,
  layout = 'vertical',
  colon,
  children,
}) => {
  const value = useMemo(() => ({
    name: 'name',
    labelAlign,
    labelCol,
    wrapperCol,
    vertical: layout === 'vertical',
    colon,
    children,
  }), [labelAlign, labelCol, wrapperCol, layout, colon, children]);

  return (
    <FormContext.Provider value={value}>
      {children}
    </FormContext.Provider>
  );
};

export default FormLayout;
