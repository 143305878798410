import endpoints from 'const/endpoints';

export default ({
  successCallback,
} = {}) => ({
  url: endpoints.getOwnCompanyUrl(),
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
    successCallback,
  },
  transform: response => ({
    ownCompany: response,
  }),
  update: {
    ownCompany: (oldValue, newValue) => newValue,
  },
});
