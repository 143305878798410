import endpoints from 'const/endpoints';

export default id => ({
  url: endpoints.getDeleteTemplateUrl(id),
  queryKey: endpoints.getDeleteTemplateUrl(),
  options: {
    method: 'DELETE',
  },
  meta: {
    authToken: true,
  },
});
