import React, { useMemo } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import {
  BasicField,
  DropdownFieldNew,
  CodeEditorField,
  ConstructorField,
  CheckboxField,
} from 'components/fields';
// import { useFlowsNewApi } from 'hooks/api';
import { useBoolean } from 'hooks';
import SelectScenario from 'components/blocks/SelectScenario';
import { uikit } from 'components';
import { STEP_TYPES } from 'const/scenario';
import { useFlowsNewApi, useFlowResourceApi } from 'hooks/api';
import { required } from 'helpers/validators';
import crossIcon from 'assets/icons/cross.svg';
import robotIcon from 'assets/icons/side-menu/robot.svg';

const initialValueCode = { language: 'javascript' };

const StepTypeField = ({
  form,
  values,
  // isPendingGetValidators,
  // lastUpdatedGetValidators,
  validators,
  flowId,
  reportFields,
  isFirstNode,
  // initialValues,
  readOnly = false,
}) => {
  // const {
  //   flowsAll,
  //   getFlowsAll,
  //   isPendingGetFlowsAll,
  // } = useFlowsNewApi();

  const stylesDropdownField = {
    label: { flex: 3 },
    content: { flex: 7 },
    container: { marginBottom: 12 },
  };
  const stylesBasicField = {
    container: { marginBottom: 12 },
    label: { flex: 3 },
    inputContainer: { flex: 7 },
  };
  const stylesCodeEditor = {
    container: { marginBottom: 12, flex: 'none' },
  };
  const stylesConstructField = {
    container: { marginBottom: 12 },
  };

  const { t } = useTranslation();
  const { flowsAll } = useFlowsNewApi();
  const { flowResource, getFlowResource } = useFlowResourceApi();

  const [
    isOpenDrawer,
    openDrawer,
    closeDrawer,
  ] = useBoolean(false);

  const optionsStepType = [
    {
      label: t('SCENARIOS_CONSTRUCTOR.INSTANCES.OPT_OUT_USER'),
      value: STEP_TYPES.OPT_OUT_USER,
    },
    {
      label: t('SCENARIOS_CONSTRUCTOR.INSTANCES.STATIC_MESSAGE'),
      value: STEP_TYPES.STATIC_MESSAGE,
    },
    {
      label: t('SCENARIOS_CONSTRUCTOR.INSTANCES.DYNAMIC_MESSAGE'),
      value: STEP_TYPES.DYNAMIC_MESSAGE,
    },
    {
      label: t('SCENARIOS_CONSTRUCTOR.INSTANCES.ASK_QUESTION'),
      value: STEP_TYPES.ASK_QUESTION,
    },
    // {
    //   label: 'Set variable',
    //   value: STEP_TYPES.SET_VARIABLE,
    // },
    // {
    //   label: 'Set contact attribute',
    //   value: stepTypes.SET_CONTACT_ATTRIBUTE,
    // },
    // {
    //   label: 'Make Webhook call',
    //   value: STEP_TYPES.MAKE_WEBHOOK_CALL',
    // },
    {
      label: t('SCENARIOS_CONSTRUCTOR.EXECUTE_CODE'),
      value: STEP_TYPES.EXECUTE_CODE,
    },
    {
      label: t('SCENARIOS_CONSTRUCTOR.INSTANCES.JUMP_TO'),
      value: STEP_TYPES.JUMP_TO,
    },
    {
      label: t('SCENARIOS_CONSTRUCTOR.INSTANCES.RESET_CHAT'),
      value: STEP_TYPES.RESET_CHAT_AND_START_OVER,
    },
    {
      label: t('SCENARIOS_CONSTRUCTOR.INSTANCES.HANDOVER'),
      value: STEP_TYPES.HANDOVER_TO_HUMAN,
    },
    // {
    //   label: t('SCENARIOS_CONSTRUCTOR.INSTANCES.END_CHAT'),
    //   value: STEP_TYPES.END_CHAT,
    // },
  ];
  const retryOptions = [
    {
      label: t('SCENARIOS_CONSTRUCTOR.INSTANCES.NOT_RETRY'),
      value: 0,
    },
    {
      label: t('SCENARIOS_CONSTRUCTOR.INSTANCES.ONCE'),
      value: 1,
    },
    {
      label: t('SCENARIOS_CONSTRUCTOR.INSTANCES.NUMBER_TIMES', { value: '2' }),
      value: 2,
    },
    {
      label: t('SCENARIOS_CONSTRUCTOR.INSTANCES.NUMBER_TIMES', { value: '3' }),
      value: 3,
    },
    {
      label: t('SCENARIOS_CONSTRUCTOR.INSTANCES.NUMBER_TIMES', { value: '4' }),
      value: 4,
    },
    {
      label: t('SCENARIOS_CONSTRUCTOR.INSTANCES.NUMBER_TIMES', { value: '5' }),
      value: 5,
    },
    {
      label: t('SCENARIOS_CONSTRUCTOR.INSTANCES.UNTIL'),
      value: 'null',
    },
  ];
  const messageTypeOptions = [
    {
      label: t('INSTANCES.TEXT'),
      value: 1,
    },
    {
      label: t('INSTANCES.FILE'),
      value: 2,
    },
    {
      label: t('INSTANCES.GEOLOCATION'),
      value: 4,
    },
    {
      label: t('SCENARIOS_CONSTRUCTOR.INSTANCES.RICH_CARD'),
      value: 5,
    },
    {
      label: t('SCENARIOS_CONSTRUCTOR.INSTANCES.CAROUSEL'),
      value: 6,
    },
  ];
  const initialStaticParams = {
    selectedType: '',
    axis: 'VERTICAL',
    titleFontStyle: 'bold',
    imageHeight: 'small',
    suggestionsInside: [],
    suggestionsOutside: [],
  };

  const optionsValidators = useMemo(() => validators
    .map(validator => ({ value: validator.id, label: validator.translateKey ? `NEW_TRANSLATES.SCENARIOS_CONSTRUCTOR.VALIDATION.${validator.translateKey}` : validator.name })), [validators]);
  const currentFlow = useMemo(() => flowsAll.find(({ id }) => id === flowId), [flowsAll]);

  const scenarioButtonText = useMemo(() => {
    if (values.selectedFlowId) {
      const flowName = flowsAll.find(I => I.id === values.selectedFlowId)?.name;
      let nodeName;

      if (values.selectedNodeId) {
        if (flowResource[values.selectedFlowId]) {
          nodeName = flowResource[values.selectedFlowId].nodes
            .find(I => I.id === values.selectedNodeId)?.label;
        } else {
          getFlowResource({
            id: values.selectedFlowId,
            successCallback: (response) => {
              nodeName = response.nodes.find(I => I.id === values.selectedNodeId)?.label;
            },
          });
        }
      }

      return values.selectedNodeId
        ? `${nodeName || `Node id: ${values.selectedNodeId}`} (${flowName})`
        : flowName;
    }

    return t('MESSAGE_CONSTRUCTOR.INSTANCES.STEP_NAME');
  }, [values.selectedFlowId, values.selectedNodeId, flowsAll]);

  const onSelectScenario = ({ scenario, nodeId }) => {
    if (scenario?.id) {
      form.change('selectedFlowId', scenario.id);

      form.change('selectedNodeId', nodeId);
    }
  };

  const onScenarioCrossClick = (e) => {
    if (values.selectedFlowId && !readOnly) {
      e.stopPropagation();
      form.change('selectedFlowId', undefined);
      form.change('selectedNodeId', undefined);
    }
  };

  // STEP_TYPES.JUMP_TO
  // useEffect(() => {
  //   if (values.flowId) {
  //     if (!initialValues.stepId) {
  //       if (values.flowId && values.flowId !== 'null') {
  //         form.change('stepId', 'null');
  //       } else {
  //         form.change('stepId', undefined);
  //       }
  //     }
  //   }
  // }, [values.flowId]);

  return (
    <>
      <Field
        name="type"
        placeholder={t('SCENARIOS_CONSTRUCTOR.PLACEHOLDERS.SELECT_STEP_TYPE')}
        type="border"
        label={t('SCENARIOS_CONSTRUCTOR.INSTANCES.STEP_TYPE')}
        component={DropdownFieldNew}
        styles={stylesDropdownField}
        options={optionsStepType}
        readOnly={readOnly}
        isRequired
      />
      {
        values.type === STEP_TYPES.OPT_OUT_USER && (
          <>
            <h2 className="edit-node-action-form__sub-title edit-node-action-form__sub-title_settings">
              {t('SCENARIOS_CONSTRUCTOR.INSTANCES.OPT_OUT_USER_SETTINGS')}
            </h2>
            <Field
              name="wholeChannel"
              component={CheckboxField}
              label={t('SCENARIOS_CONSTRUCTOR.PLACEHOLDERS.WHOLE_CHANNEL')}
              styles={stylesBasicField}
              readOnly={readOnly}
            />
          </>
        )
      }
      {
        values.type === STEP_TYPES.DYNAMIC_MESSAGE && (
          <>
            <h2 className="edit-node-action-form__sub-title edit-node-action-form__sub-title_settings">
              {t('SCENARIOS_CONSTRUCTOR.INSTANCES.DYNAMIC_MESSAGE_SETTINGS')}
            </h2>
            <Field
              name="isTypingDelay"
              type="border"
              label={t('SCENARIOS_CONSTRUCTOR.INSTANCES.IS_DELAY')}
              component={BasicField}
              styles={stylesBasicField}
              readOnly={readOnly}
            />
            <Field
              name="messageType"
              placeholder={t('SCENARIOS_CONSTRUCTOR.PLACEHOLDERS.SELECT_MESSAGE_TYPE')}
              type="border"
              label={t('INSTANCES.MESSAGE_TYPE')}
              component={DropdownFieldNew}
              styles={stylesDropdownField}
              options={messageTypeOptions}
              readOnly={readOnly}
            />
            {/* eslint-disable-next-line max-len */}
            {/* <h2 className="edit-node-action-form__sub-title edit-node-action-form__sub-title_settings"> */}
            {/*  Accepts */}
            {/* </h2> */}
            {/* <FieldArray */}
            {/*  name="accepts" */}
            {/*  component={DropdownFieldArray} */}
            {/*  buttonText="ADD INPUT VARIABLE" */}
            {/*  options={[]} */}
            {/*  replaceDropdownOnField */}
            {/*  dropdownPlaceholder="Select accept type" */}
            {/*  type="equivalent" */}
            {/* /> */}
            <h2 className="edit-node-action-form__sub-title">
              {t('SCENARIOS_CONSTRUCTOR.INSTANCES.CODE')}
            </h2>
            <Field
              name="executeCodeDynamicMessage"
              component={CodeEditorField}
              styles={stylesCodeEditor}
              readOnly={readOnly}
              initialValue={values.executeCodeDynamicMessage || initialValueCode}
            />
          </>
        )
      }
      {
        values.type === STEP_TYPES.EXECUTE_CODE && (
          <>
            {/* eslint-disable-next-line max-len */}
            {/* <h2 className="edit-node-action-form__sub-title edit-node-action-form__sub-title_settings"> */}
            {/*  Accepts */}
            {/* </h2> */}
            {/* <FieldArray */}
            {/*  name="inParameters" */}
            {/*  component={DropdownFieldArray} */}
            {/*  buttonText="ADD INPUT VARIABLE" */}
            {/*  options={[]} */}
            {/*  dropdownPlaceholder="Select accept type" */}
            {/*  type="equivalent" */}
            {/*  styles={{ wrapper: { marginBottom: 12 } }} */}
            {/*  replaceDropdownOnField */}
            {/*  dropdownName="parameter" */}
            {/*  fieldName="bindName"  */}
            {/* /> */}
            <h2 className="edit-node-action-form__sub-title edit-node-action-form__sub-title_settings">
              {t('SCENARIOS_CONSTRUCTOR.INSTANCES.CODE')}
            </h2>
            <Field
              name="executeCode"
              component={CodeEditorField}
              styles={stylesCodeEditor}
              readOnly={readOnly}
              initialValue={values.executeCode || initialValueCode}
            />
            {/* eslint-disable-next-line max-len */}
            {/* <h2 className="edit-node-action-form__sub-title edit-node-action-form__sub-title_settings"> */}
            {/*  Returns to */}
            {/* </h2>  */}
            {/* <FieldArray */}
            {/*  name="outParameters" */}
            {/*  component={BasicFieldArray} */}
            {/*  buttonText="ADD OUTPUT VARIABLE"  */}
            {/*  options={[]} */}
            {/*  dropdownPlaceholder="Accept type" */}
            {/*  type="equivalent" */}
            {/*  styles={{ wrapper: { marginBottom: 12 } }} */}
            {/* /> */}
          </>
        )
      }
      {
        values.type === STEP_TYPES.STATIC_MESSAGE && (
          <>
            <h2 className="edit-node-action-form__sub-title edit-node-action-form__sub-title_settings">
              {t('SCENARIOS_CONSTRUCTOR.INSTANCES.STATIC_MESSAGE_SETTINGS')}
            </h2>
            <Field
              name="isTypingDelay"
              type="border"
              label={t('SCENARIOS_CONSTRUCTOR.INSTANCES.IS_DELAY')}
              component={BasicField}
              styles={stylesBasicField}
              readOnly={readOnly}
            />
            <Field
              name="content"
              readOnly={readOnly}
              component={ConstructorField}
              initialParams={initialStaticParams}
              label={t('SCENARIOS_CONSTRUCTOR.MESSAGE_CONSTRUCTOR')}
              styles={stylesConstructField}
              currentFlow={currentFlow}
              reportFields={reportFields}
              isFirstNode={isFirstNode}
            />
          </>
        )
      }
      {
        values.type === STEP_TYPES.ASK_QUESTION && (
          <>
            <h2 className="edit-node-action-form__sub-title">
              {t('SCENARIOS_CONSTRUCTOR.INSTANCES.ASK_QUESTION_SETTINGS')}
            </h2>
            <Field
              name="delayQuestion"
              type="border"
              label={t('SCENARIOS_CONSTRUCTOR.INSTANCES.IS_DELAY')}
              component={BasicField}
              styles={stylesBasicField}
              readOnly={readOnly}
            />
            <Field
              name="questionContent"
              readOnly={readOnly}
              component={ConstructorField}
              reportFields={reportFields}
              label={t('INSTANCES.MESSAGE')}
              initialParams={initialStaticParams}
              styles={stylesConstructField}
              currentFlow={currentFlow}
              isFirstNode={isFirstNode}
            />
            <Field
              name="threadVariable"
              type="border"
              label={t('SCENARIOS_CONSTRUCTOR.INSTANCES.SAVE_RESPONSE')}
              component={BasicField}
              styles={stylesBasicField}
              placeholder="contentVarName"
              readOnly={readOnly}
              validate={value => required(value, t)}
              isRequired
            />
            <Field
              name="showInReport"
              component={CheckboxField}
              label={t('SCENARIOS_CONSTRUCTOR.INSTANCES.SHOW_IN_REPORT')}
              styles={stylesBasicField}
              readOnly={readOnly}
              initialValue
            />
            <Field
              name="validatorId"
              placeholder={t('SCENARIOS_CONSTRUCTOR.PLACEHOLDERS.SELECT_AVAILABLE')}
              type="border"
              label={t('SCENARIOS_CONSTRUCTOR.INSTANCES.VALIDATION')}
              component={DropdownFieldNew}
              styles={stylesDropdownField}
              options={optionsValidators}
              readOnly={readOnly}
              // todo: redo the logic for finding the ANY VALUE item,
              //  will not work with multilanguage on the back
              // initialValue={1}
              validate={value => required(value, t)}
              isRequired
            />
            <Field
              name="retryCount"
              placeholder={t('SCENARIOS_CONSTRUCTOR.PLACEHOLDERS.SELECT_RETRY')}
              type="border"
              label={t('SCENARIOS_CONSTRUCTOR.INSTANCES.RETRY_QUESTION')}
              component={DropdownFieldNew}
              styles={stylesDropdownField}
              options={retryOptions}
              initialValue={0}
              readOnly={readOnly}
            />
            {values.retryCount !== 0 && (
              <>
                <h3 className="edit-node-action-form__sub-sub-title">
                  {t('SCENARIOS_CONSTRUCTOR.INSTANCES.RETRY_QUESTION_SETTINGS')}
                </h3>
                <Field
                  name="delayRetry"
                  type="border"
                  label={t('SCENARIOS_CONSTRUCTOR.INSTANCES.IS_DELAY')}
                  component={BasicField}
                  styles={stylesBasicField}
                  readOnly={readOnly}
                />
                <Field
                  name="retryContent"
                  readOnly={readOnly}
                  component={ConstructorField}
                  reportFields={reportFields}
                  label={t('INSTANCES.MESSAGE')}
                  initialParams={initialStaticParams}
                  styles={stylesConstructField}
                  currentFlow={currentFlow}
                  isFirstNode={isFirstNode}
                />
              </>
            )}
          </>
        )
      }
      {
        values.type === STEP_TYPES.JUMP_TO && (
          <>
            <h2 className="edit-node-action-form__sub-title">
              {t('SCENARIOS_CONSTRUCTOR.INSTANCES.JUMP_TO_SETTINGS')}
            </h2>
            <div className="edit-node-action-form__row">
              <span className="edit-node-action-form__row__label">
                {t('MESSAGE_CONSTRUCTOR.INSTANCES.START_SCRIPT')}
              </span>
              <button
                onClick={openDrawer}
                type="button"
                className="edit-node-action-form__row__button"
                disabled={readOnly}
              >
                {scenarioButtonText}
                <img
                  src={values.selectedFlowId ? crossIcon : robotIcon}
                  className="edit-node-action-form__row__button__icon"
                  role="presentation"
                  onClick={onScenarioCrossClick}
                  alt=""
                />
              </button>
            </div>
            <Field
              name="passContext"
              component={CheckboxField}
              label={t('SCENARIOS_CONSTRUCTOR.INSTANCES.PASS_CONTEXT')}
              styles={stylesBasicField}
              readOnly={readOnly}
              initialValue
            />
            <uikit.Drawer
              visible={isOpenDrawer}
              onClose={closeDrawer}
              width={800}
              destroyOnClose
            >
              <SelectScenario
                onClose={closeDrawer}
                onSelect={onSelectScenario}
                initialFlowId={values.selectedFlowId}
                initialNodeId={values.selectedNodeId}
                channelToFilter={currentFlow.channel}
                isPostback
              />
            </uikit.Drawer>
          </>
        )
      }
    </>
  );
};

export default StepTypeField;
