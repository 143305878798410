import { useMutation } from 'redux-query-react';
import { useCallback } from 'react';

import {
  deleteFilter as deleteFilterApi,
  getFilters as getFiltersApi,
  postFilter as postFilterApi,
  putFilter as putFilterApi,
} from 'queries/recipients';

import { getArray, useISESelector } from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetFilters,
      lastUpdated: lastUpdatedGetFilters,
    },
    getFilters,
  ] = useMutation(getFiltersApi);
  const [, deleteFilter] = useMutation(deleteFilterApi);
  const [, postFilter] = useMutation(postFilterApi);
  const [, putFilter] = useMutation(putFilterApi);

  const selector = useCallback(state => ({
    filters: getArray(state, 'filters'),
  }), []);

  const data = useISESelector(selector);

  return {
    isPendingGetFilters,
    lastUpdatedGetFilters,
    getFilters,
    putFilter,
    deleteFilter,
    postFilter,
    ...data,
  };
};
