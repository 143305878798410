import endpoints from 'const/endpoints';

export default id => ({
  url: endpoints.getDeleteGroupUrl(id),
  queryKey: endpoints.getDeleteGroupUrl(),
  options: {
    method: 'DELETE',
  },
  meta: {
    authToken: true,
  },
  update: {
    senderGroups: oldValue => oldValue.filter(item => item.id !== id),
  },
});
