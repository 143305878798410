import React, {
  useState,
  useReducer, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ConstructorEditor as ConstructorEditorNew } from 'mkit-front-ds/dist/components/constructor';
import classNames from 'classnames';
import { useToggle } from 'react-use';

import { DropdownField, TimePickerField } from 'components/fields';
import { BasicButton } from 'components/buttons';
import { convertObjectTimeToSeconds, toast } from 'helpers';
import crossIcon from 'assets/icons/cross-grey.svg';
import disketteIcon from 'assets/icons/diskette.svg';
import { WECHAT_IMAGE_MIME_TYPES } from 'const';
import SelectMedia from 'components/SelectMedia/SelectMedia';

import './style.scss';

const initialSmsParams = {
  selectedType: '',
};

const WeChatBlock = ({
  channel,
  onChange,
  onCancel,
  index,
  withTools = true,
  readOnly = false,
  deliveryCondition = 'DELIVERY_SUCCESS',
  timeout = { hours: '00', minutes: '10', seconds: '00' },
  channelNumber,
}) => {
  const timeFieldStyle = {
    container: {
      width: '100%',
    },
    overlay: {
      top: 'unset',
      bottom: 36,
    },
  };

  const wrapperClass = classNames('wechat-block__content-wrapper', {
    'wechat-block__content-wrapper_new-design': true,
    alwaysLightTheme: true,
  });

  const wrapperHeaderClass = classNames('wechat-block__content-wrapper__header', {
    'wechat-block__content-wrapper__header_new-design': true,
  });

  const { t } = useTranslation();
  const [waitFor, setWaitFor] = useState(deliveryCondition);
  const [waitingTime, setWaitingTime] = useState(timeout);

  const [selectMediaOpen, toggleSelectOpenMedia] = useToggle(false);

  const reducer = (state, { isReset, ...newValue }) => {
    if (isReset) {
      return newValue;
    }

    return ({
      ...state,
      ...newValue,
    });
  };
  const [rcsParams, updateRcsParams] = useReducer(reducer, initialSmsParams);

  const selectMedia = (image) => {
    updateRcsParams({
      image,
    });
  };

  const onApply = () => {
    let contentPattern = {};
    let saveAllowed = true;

    switch (rcsParams.selectedType) {
      case 'weChatText':
        if (!rcsParams?.message) {
          saveAllowed = false;
          toast.error(t('NEW_TRANSLATES.TOAST_ERRORS.WHATSAPP_TEXT_REQUIREMENTS'));
        }
        contentPattern = {
          MsgType: 'text',
          Content: rcsParams.message,
        };
        break;
      case 'weChatFile':
        if (!rcsParams?.image?.fileUrl) {
          saveAllowed = false;
          toast.error(t('NEW_TRANSLATES.TOAST_ERRORS.WHATSAPP_FILE_REQUIREMENTS'));
        }
        contentPattern = {
          ...rcsParams.image,
          MsgType: 'image',
          PicUrl: rcsParams.image.fileUrl,
        };
        break;
      default:
        break;
    }

    if (saveAllowed) {
      onChange({
        index,
        channelType: 6,
        contentPattern,
        deliveryCondition: waitFor,
        order: 0,
        timeout: convertObjectTimeToSeconds(waitingTime),
      });
    }
  };

  useEffect(() => {
    if (channel && !channel.name) {
      updateRcsParams({ isReset: true, ...channel });
    }
  }, [channel]);

  return (
    <div className="wechat-block">
      <h2 className="wechat-block__header">
        {t('SCREENS.BROADCASTS.WECHAT_MESSAGE')}
      </h2>
      <div className={wrapperClass}>
        <div className={wrapperHeaderClass} />
        <ConstructorEditorNew
          rcsParams={rcsParams}
          updateRcsParams={updateRcsParams}
          readOnly={readOnly}
          channel={channelNumber}
          openMediaSelect={toggleSelectOpenMedia}
          withoutSuggestions
        />
      </div>
      {
        withTools && (
          <>
            <div className="wechat-block__time-tools" style={{ marginTop: 24 }}>
              <span className="wechat-block__label">
                {t('SCREENS.BROADCASTS.WAIT_FOR')}
              </span>
              <DropdownField
                input={{
                  value: waitFor,
                  onChange: setWaitFor,
                }}
                type="grey-border"
                values={[
                  {
                    id: 'DELIVERY_SUCCESS',
                    title: t('SCREENS.BROADCASTS.DELIVERY_SUCCESS'),
                  },
                  {
                    id: 'SUBMIT_SUCCESS',
                    title: t('SCREENS.BROADCASTS.SUBMIT_SUCCESS'),
                  },
                ]}
                isDisabled={readOnly}
                withoutCallbackDestroy
              />
            </div>
            <div className="wechat-block__time-tools">
              <span className="wechat-block__label">
                {t('SCREENS.BROADCASTS.WAITING_TIME')}
              </span>
              <TimePickerField
                input={{
                  value: waitingTime,
                  onChange: setWaitingTime,
                }}
                styles={timeFieldStyle}
                typeOverlay="primary-color"
                withTimezone={false}
                disabled={readOnly}
              />
            </div>
          </>
        )
      }
      <div className="wechat-block__buttons">
        <BasicButton
          text={t('CONTROLS.CANCEL')}
          type={BasicButton.types.CANCEL}
          icon={crossIcon}
          onClick={onCancel}
          isNewStyle
        />
        <BasicButton
          text={t('CONTROLS.SAVE')}
          type={BasicButton.types.ACCENT}
          icon={disketteIcon}
          onClick={onApply}
          disabled={readOnly}
        />
      </div>
      <SelectMedia
        open={selectMediaOpen}
        onClose={toggleSelectOpenMedia}
        onSelected={selectMedia}
        width={555}
        format={WECHAT_IMAGE_MIME_TYPES}
        fileMaxSize={10}
        withUploadButton
      />
    </div>
  );
};

export default WeChatBlock;
