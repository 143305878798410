import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

import { ListFilterForm } from 'components/forms';
import { useTheme } from 'hooks';
import { useTagsApi } from 'hooks/api';
import tagIcon from 'assets/icons/tag.svg';
import tagBlueIcon from 'assets/icons/tag-big-blue.svg';

const TagsFilterFormWrapper = forwardRef(({
  onSubmit,
  initialValues,
  column,
  ...props
}, ref) => {
  const { getTags, tags } = useTagsApi();
  const tagsFilterRef = useRef();
  const { isDarkTheme } = useTheme();

  useImperativeHandle(ref, () => ({
    getModel() {
      return tagsFilterRef.current.getModel();
    },
    setModel(values = { value: '' }) {
      tagsFilterRef.current.setModel(values);
    },
    afterGuiAttached() {
      getTags();
    },
    isFilterActive() {
      return tagsFilterRef.current.isFilterActive();
    },
    doesFilterPass() {
      return true;
    },
  }));

  const items = useMemo(
    () => tags.map(item => ({
      title: item,
      id: item,
      icon: isDarkTheme ? tagIcon : tagBlueIcon,
    })),
    [tags, isDarkTheme],
  );
  // const processedInitialValues = useMemo(
  //   () => {
  //     if (initialValues) {
  //       return Object.keys(initialValues).reduce((acc, key) => {
  //         acc[`_${key}`] = initialValues[key];
  //         return acc;
  //       }, {});
  //     }
  //     return null;
  //   },
  //   [initialValues],
  // );

  // const handleSubmit = (values, actions, complete) => {
  //   const processedValues = Object.keys(values).reduce((acc, key) => {
  //     acc[key.replace('_', '')] = values[key];
  //     return acc;
  //   }, {});
  //   onSubmit(processedValues, actions, complete);
  // };

  // useEffect(() => {
  //   if (column.menuVisible) {
  //     getTags();
  //   }
  // }, [column.menuVisible]);

  // if (!tags.length || isPendingGetTags) {
  //   return <Spin />;
  // }

  return (
    <ListFilterForm
      {...props}
      column={column}
      ref={tagsFilterRef}
      items={items}
      // initialValues={processedInitialValues}
      onSubmit={onSubmit}
      withSearch
      withFilter
    />
  );
});

export default TagsFilterFormWrapper;
