import React, { useRef, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { RcsTypeButton } from 'components/buttons';
import {
  CarouselRichCard,
  RichCard,
  TextMessage,
  FileMessage,
  ViberPicture,
  ViberPictureCard,
  ViberTextCard,
  ViberTextMessage,
  WhatsAppText,
  WhatsAppFile,
  WhatsAppGeolocation,
  WhatsAppTextTemplate,
  WhatsAppTemplateCard,
} from 'components/constructor';
import { DEFAULT_CAROUSEL_CARD } from 'const';
import richCardIcon from 'assets/icons/rich-card.svg';
import richCardCarouselIcon from 'assets/icons/rich-card-carousel.svg';
import textCardIcon from 'assets/icons/text-card.svg';
import fileCardIcon from 'assets/icons/file.svg';
import textViberIcon from 'assets/icons/viber/text.svg';
import textCardViberIcon from 'assets/icons/viber/text-card.svg';
import imageViberIcon from 'assets/icons/viber/picture.svg';
import imageCardViberIcon from 'assets/icons/viber/picture-card.svg';
import textWhatsAppIcon from 'assets/icons/whatsapp/text.svg';
import fileWhatsAppIcon from 'assets/icons/whatsapp/file.svg';
// import contactWhatsAppIcon from 'assets/icons/whatsapp/contact.svg';
import locationWhatsAppIcon from 'assets/icons/whatsapp/location.svg';
import documentIcon from 'assets/icons/document-dark.svg';

import './style.scss';

const ConstructorEditor = ({
  rcsParams: {
    selectedType,
  },
  rcsParams,
  updateRcsParams,
  openMediaSelect,
  openCreateSuggestion,
  selectedCard,
  setSelectedCard,
  withoutSuggestions,
  constructorType = 'rcs',
  openUrlButtonConfig,
  readOnly,
  openGeolocation,
  isOpenInTab,
  constructorStyle,
  chooseTypeStyle,
  openUploadMedia,
  selectedTemplate,
}) => {
  const editorRef = useRef();
  const { t } = useTranslation();
  const constructorClass = classNames('mkit-constructor-editor', {
    'mkit-constructor-editor_milk-bg': constructorType === 'whatsApp' || constructorType === 'whatsAppTemplate',
  });

  const typeButtons = [
    {
      icon: richCardIcon,
      onClick: () => updateRcsParams({ selectedType: 'richcardMessage' }),
      text: t('NEW_TRANSLATES.INSTANCES.MESSAGE_CONSTRUCTOR.RICH_CARD'),
    },
    {
      icon: richCardCarouselIcon,
      onClick: () => updateRcsParams({
        isReset: true,
        selectedType: 'carousel',
        cardWidth: 'medium',
        titleFontStyle: 'bold',
        suggestionsOutside: [],
        cards: [DEFAULT_CAROUSEL_CARD, DEFAULT_CAROUSEL_CARD],
      }),
      text: t('NEW_TRANSLATES.INSTANCES.MESSAGE_CONSTRUCTOR.CAROUSEL'),
    },
    {
      icon: textCardIcon,
      onClick: () => updateRcsParams({ selectedType: 'textMessage' }),
      text: t('NEW_TRANSLATES.INSTANCES.MESSAGE_CONSTRUCTOR.TEXT'),
    },
    {
      icon: fileCardIcon,
      onClick: () => updateRcsParams({ selectedType: 'fileMessage' }),
      text: t('NEW_TRANSLATES.INSTANCES.MESSAGE_CONSTRUCTOR.FILE'),
    },
  ];

  const viberTypeButtons = [
    {
      icon: textViberIcon,
      onClick: () => updateRcsParams({ selectedType: 'viberText' }),
      text: 'Text',
    },
    {
      icon: imageViberIcon,
      onClick: () => updateRcsParams({ selectedType: 'viberImage' }),
      text: 'Pic',
    },
    {
      icon: textCardViberIcon,
      onClick: () => updateRcsParams({ selectedType: 'viberTextCard' }),
      text: 'Text Card',
    },
    {
      icon: imageCardViberIcon,
      onClick: () => updateRcsParams({ selectedType: 'viberImageCard' }),
      text: 'Pic Card',
    },
  ];

  const whatsAppTypeButtons = [
    {
      icon: textWhatsAppIcon,
      onClick: () => updateRcsParams({ selectedType: 'whatsAppText' }),
      text: 'Text',
    },
    {
      icon: fileWhatsAppIcon,
      onClick: () => updateRcsParams({ selectedType: 'whatsAppFile' }),
      text: 'File',
    },
    // {
    //   icon: contactWhatsAppIcon,
    //   onClick: () => updateRcsParams({ selectedType: 'whatsAppContact' }),
    //   text: 'Contact',
    //   disabled: true,
    // },
    {
      icon: locationWhatsAppIcon,
      onClick: () => updateRcsParams({ selectedType: 'whatsAppGeolocation' }),
      text: 'Location',
    },
  ];

  const whatsAppTemplateTypeButtons = [
    {
      icon: textWhatsAppIcon,
      onClick: () => updateRcsParams({ selectedType: 'whatsAppTemplateText' }),
      text: 'Text',
    },
    {
      icon: documentIcon,
      onClick: () => updateRcsParams({ selectedType: 'whatsAppTemplateMedia' }),
      text: 'With media',
    },
  ];

  const defaultProps = {
    rcsParams,
    updateRcsParams,
    openCreateSuggestion,
    editorRef,
    readOnly,
  };

  const defaultWhatsAppProps = {
    rcsParams,
    updateRcsParams,
    editorRef,
    readOnly,
  };

  const buttonsToDisplay = useMemo(() => {
    switch (constructorType) {
      case 'viber': return viberTypeButtons;
      case 'whatsApp': return whatsAppTypeButtons;
      case 'whatsAppTemplate': return whatsAppTemplateTypeButtons;
      default: return typeButtons;
    }
  }, [constructorType]);

  if (!selectedTemplate) {
    return (
      <div className={constructorClass} ref={editorRef}>
        <div className="mkit-constructor-editor__notice-message">
          {t('NEW_TRANSLATES.INSTANCES.MESSAGE_CONSTRUCTOR.SELECT_TEMPLATE')}
        </div>
      </div>
    );
  }

  switch (selectedType) {
    case 'richcardMessage':
      return (
        <div className={constructorClass} ref={editorRef}>
          <RichCard
            {...defaultProps}
            openMediaSelect={openMediaSelect}
            openUploadMedia={openUploadMedia}
            isOpenInTab={isOpenInTab}
          />
        </div>
      );
    case 'textMessage':
      return (
        <div className={constructorClass} ref={editorRef}>
          <TextMessage
            {...defaultProps}
            withoutSuggestions={withoutSuggestions}
          />
        </div>
      );
    case 'fileMessage':
      return (
        <div className={constructorClass} ref={editorRef}>
          <FileMessage
            {...defaultProps}
            openMediaSelect={openMediaSelect}
            openUploadMedia={openUploadMedia}
            isOpenInTab={isOpenInTab}
          />
        </div>
      );
    case 'carousel':
      return (
        <div className={constructorClass} ref={editorRef}>
          <CarouselRichCard
            {...defaultProps}
            openMediaSelect={openMediaSelect}
            openUploadMedia={openUploadMedia}
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
            isOpenInTab={isOpenInTab}
          />
        </div>
      );
    case 'viberText':
      return (
        <div className={constructorClass} ref={editorRef}>
          <ViberTextMessage
            rcsParams={rcsParams}
            updateRcsParams={updateRcsParams}
            readOnly={readOnly}
          />
        </div>
      );
    case 'viberImage':
      return (
        <div className={constructorClass} ref={editorRef}>
          <ViberPicture
            rcsParams={rcsParams}
            updateRcsParams={updateRcsParams}
            openMediaSelect={openMediaSelect}
            openUploadMedia={openUploadMedia}
          />
        </div>
      );
    case 'viberTextCard':
      return (
        <div className={constructorClass} ref={editorRef}>
          <ViberTextCard
            rcsParams={rcsParams}
            updateRcsParams={updateRcsParams}
            openUrlButtonConfig={openUrlButtonConfig}
          />
        </div>
      );
    case 'viberImageCard':
      return (
        <div className={constructorClass} ref={editorRef}>
          <ViberPictureCard
            rcsParams={rcsParams}
            updateRcsParams={updateRcsParams}
            openUrlButtonConfig={openUrlButtonConfig}
            openMediaSelect={openMediaSelect}
            openUploadMedia={openUploadMedia}
          />
        </div>
      );
    case 'whatsAppText':
      return (
        <div className={constructorClass} ref={editorRef}>
          <WhatsAppText
            {...defaultWhatsAppProps}
          />
        </div>
      );
    case 'whatsAppFile':
      return (
        <div className={constructorClass} ref={editorRef}>
          <WhatsAppFile
            {...defaultWhatsAppProps}
            openMediaSelect={openMediaSelect}
            openUploadMedia={openUploadMedia}
            isOpenInTab={isOpenInTab}
          />
        </div>
      );
    case 'whatsAppGeolocation':
      return (
        <div className={constructorClass} ref={editorRef}>
          <WhatsAppGeolocation
            {...defaultWhatsAppProps}
            openGeolocation={openGeolocation}
          />
        </div>
      );
    case 'whatsAppTemplateText':
      return (
        <div className={constructorClass} ref={editorRef}>
          <WhatsAppTextTemplate
            {...defaultWhatsAppProps}
          />
        </div>
      );
    case 'whatsAppTemplate':
      return (
        <div className={constructorClass} ref={editorRef}>
          <WhatsAppTemplateCard
            {...defaultWhatsAppProps}
            openMediaSelect={openMediaSelect}
            openGeolocation={openGeolocation}
          />
        </div>
      );
    default:
      return (
        <div className={constructorClass} ref={editorRef} style={constructorStyle}>
          <div className="mkit-constructor-editor__choose-type" style={chooseTypeStyle}>
            {
              buttonsToDisplay.map(I => (
                <RcsTypeButton
                  key={I}
                  {...I}
                />
              ))
            }
          </div>
        </div>
      );
  }
};

export default ConstructorEditor;
