import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';

import {
  getOwnCompany as getOwnCompanyApi,
} from 'queries/companies';

import {
  getObject,
  useISESelector,
} from './selectors';

export default () => {
  const [{
    isPending: isPendingGetOwnCompany,
    lastUpdated: lastUpdatedGetOwnCompany,
  }, getOwnCompany] = useMutation(getOwnCompanyApi);

  const selector = useCallback(state => ({
    ownCompany: getObject(state, 'ownCompany'),
  }), []);
  const data = useISESelector(selector);

  return {
    getOwnCompany,
    isPendingGetOwnCompany,
    lastUpdatedGetOwnCompany,
    ...data,
  };
};
