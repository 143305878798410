import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import arrowsHide from 'assets/icons/arrows-hide.svg';
import arrowsShow from 'assets/icons/arrows-show.svg';
import { RATES } from 'const';

import './style.scss';

const RatesCellRenderer = ({
  getValue,
  api,
  node,
  eGridCell,
}) => {
  const [isExpand, setIsExpand] = useState(false);

  const { t } = useTranslation();

  const rates = useMemo(() => {
    const items = getValue();
    return Object.keys(items).map(key => [Number(key), items[key]]);
  }, []);

  const onDisplayAllTags = () => {
    setTimeout(() => {
      node.setRowHeight(eGridCell.offsetHeight + 15);
      api.onRowHeightChanged();
    }, 50);
  };

  const handleClickRow = () => {
    setIsExpand(!isExpand);
    onDisplayAllTags();
  };

  return (
    <div className="rates-group">
      <div role="presentation" className="rates-group__button" onClick={handleClickRow}>
        {isExpand ? (
          <span>
            {t('SCREENS.RATE_PLANS.HIDE_RATES')}
          </span>
        ) : (
          <span>
            {t(`SCREENS.RATE_PLANS.SHOW_RATE${rates.length > 1 ? 'S' : ''}`, { what: rates.length })}
          </span>
        )}
        <img
          alt="arrowHide"
          className="rates-group__button__right-icon"
          src={isExpand ? arrowsHide : arrowsShow}
        />
      </div>
      {isExpand && (
        <div className="rates-group__items">
          {RATES.map((rate) => {
            const currentRate = rates.find(I => I[0] === rate.id);
            return (
              <div className="rates-group__items__item">
                <span className="rates-group__items__item__left-text">{`${rate.name}: `}</span>
                <span className="rates-group__items__item__right-text">
                  {currentRate && currentRate[1]}
                </span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default RatesCellRenderer;
