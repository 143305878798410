import { DefaultLinkModel } from '@projectstorm/react-diagrams';

/*eslint-disable */
export default class LinkModel extends DefaultLinkModel {
  constructor(options) {
    super({ width: 3, ...options });
  }

  addPoint() {
    return undefined;
  }

  getSVGPath() {
    // todo пока без this.getSourcePort().getPositionCircle?.() !== 'left'
    if (this.getTargetPort() && this.getSourcePort().setPositionCircle) {
      if (this.getSourcePort().getOptions().withBottomCircle) {
        if (this.getSourcePort().getBoundingBox().getRightMiddle().x < this.getTargetPort().getPosition().x) {
          const position = this.getSourcePort().getBoundingBox().getRightMiddle();
          this.getFirstPoint().setPosition(position.x, position.y);

          this.getSourcePort().setPositionCircle('right');
        } else if (this.getSourcePort().getBoundingBox().getBottomMiddle().x < this.getTargetPort().getPosition().x) {
          const position = this.getSourcePort().getBoundingBox().getBottomMiddle();
          this.getFirstPoint().setPosition(position.x, position.y);

          this.getSourcePort().setPositionCircle('bottom');
        } else if (this.getSourcePort().getBoundingBox().getLeftMiddle().x < this.getTargetPort().getPosition().x) {
          const position = this.getSourcePort().getBoundingBox().getLeftMiddle();
          this.getFirstPoint().setPosition(position.x, position.y);

          this.getSourcePort().setPositionCircle('left');
        }
      } else {
        if (this.getSourcePort().getPosition().x > this.getTargetPort().getPosition().x) {
          const position = this.getSourcePort().getBoundingBox().getLeftMiddle();
          this.getFirstPoint().setPosition(position.x, position.y);

          this.getSourcePort().setPositionCircle('left');
        } else if (this.getSourcePort().getPosition().x < this.getTargetPort().getPosition().x) {
          const position = this.getSourcePort().getBoundingBox().getRightMiddle();
          this.getFirstPoint().setPosition(position.x, position.y);

          this.getSourcePort().setPositionCircle('right');
        }
      }
    }

    // if (this.getID() === 470) {
    //   console.log('this.getSourcePort().getPositionCircle?.()', this.getSourcePort().getPositionCircle?.());
    //   console.log('x = ', this.getFirstPoint().getX());
    // }

    return `M${this.getFirstPoint().getX()},${this.getFirstPoint().getY()} L ${this.getLastPoint().getX()},${this.getLastPoint().getY()}`;
  }

  setTargetPort(port) {
    if (port !== null) {
      port.addLink(this);
    }
    if (this.targetPort !== null) {
      this.targetPort.removeLink(this);
    }
    this.targetPort = port;

    const parentCanvasModel = this.getParentCanvasModel();
    if (parentCanvasModel) {
      parentCanvasModel.fireEvent({ port: this.getSourcePort() }, 'portUpdated');
    }
  }
}
