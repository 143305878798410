import endpoints from 'const/endpoints';

export default ({
  limit = 10,
  offset = 0,
  errorCallback,
  ...queries
} = {}) => ({
  url: endpoints.getFlowsNewUrl(),
  queryKey: endpoints.getFlowsNewUrl(),
  body: {
    limit,
    offset: offset ? ((offset - 1) * limit) : offset,
    ...queries,
  },
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
    errorCallback,
  },
  transform: (response) => {
    const {
      flows,
      ...other
    } = response;

    return {
      flows,
      flowsMeta: other,
    };
  },
  update: {
    flows: (oldValue, newValue) => newValue,
    flowsMeta: (oldValue, newValue) => newValue,
  },
});
