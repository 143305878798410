import React from 'react';
import classNames from 'classnames';

import './style.scss';

const TreeDropdownField = ({
  id,
  title,
  subItems,
  isSelected,
  onSelectSection,
  onSelectItem,
  selectedSubsection,
}) => {
  const dropdownClass = classNames({
    'tree-dropdown-field': true,
    'tree-dropdown-field_selected': isSelected,
  });
  const subItemClass = itemId => classNames({
    'tree-dropdown-field__sub-item': true,
    'tree-dropdown-field__sub-item_selected': selectedSubsection === itemId,
  });

  const onSectionClick = () => {
    if (isSelected) {
      onSelectSection({});
    } else {
      onSelectSection({ id, title });
    }
  };

  return (
    <div className={dropdownClass}>
      <div
        className="tree-dropdown-field__button"
        onClick={onSectionClick}
        role="presentation"
        data-testid={`${window.location.pathname}/dropdown-field/${id}`}
      >
        {title}
      </div>
      {
        subItems.map(item => (
          <button
            type="button"
            onClick={() => onSelectItem(item)}
            className={subItemClass(item.id)}
            data-testid={`${window.location.pathname}/dropdown-field/${id}/button/${item.id}`}
            key={item.id}
          >
            {item.title}
          </button>
        ))
      }
    </div>
  );
};

export default TreeDropdownField;
