import endpoints from 'const/endpoints';

export default ({ body, successCallback }) => ({
  url: endpoints.getRecipientsUrl(),
  options: {
    method: 'DELETE',
  },
  body,
  meta: {
    successCallback,
    authToken: true,
  },
  update: {
    recipients: oldValue => (
      body.filter
        ? oldValue
        : oldValue.filter(item => !body.ids.includes(item.id))
    ),
  },
});
