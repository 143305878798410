// layout
const TOGGLE_MENU_EXPAND = 'TOGGLE_MENU_EXPAND';
const SET_DASHBOARD_HINT = 'SET_DASHBOARD_HINT';
// Campaign
const TOGGLE_CAMPAIGN_EXTERNAL_TRIGGER = 'TOGGLE_CAMPAIGN_EXTERNAL_TRIGGER';
const ADD_CHANNEL_TO_CAMPAIGN = 'ADD_CHANNEL_TO_CAMPAIGN';
const STOP_CAMPAIGN_CHANNELS = 'STOP_CAMPAIGN_CHANNELS';

export default {
  TOGGLE_MENU_EXPAND,
  TOGGLE_CAMPAIGN_EXTERNAL_TRIGGER,
  ADD_CHANNEL_TO_CAMPAIGN,
  STOP_CAMPAIGN_CHANNELS,
  SET_DASHBOARD_HINT,
};
