/*eslint-disable */
import actions from 'const/actions';
import { isBoolean } from 'lodash';

const initialState = {
  create: {
    externalTrigger: false,
     channels: [],
     fallbackStopped: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.TOGGLE_CAMPAIGN_EXTERNAL_TRIGGER:
      return {
        ...state,
        create: {
          ...state.create,
          externalTrigger: isBoolean(action.state) ? action.state : !state.create.externalTrigger,
        },
      };
     case actions.ADD_CHANNEL_TO_CAMPAIGN:
        return {
           ...state,
           create: {
              ...state.create,
              channels: [...state.create.channels, {type: action.channelType, text: action.text}],
           },
        };
     case actions.STOP_CAMPAIGN_CHANNELS:
        return {
           ...state,
           create: {
              ...state.create,
              fallbackStopped: true,
           },
        };
    default:
      return state;
  }
};
