import { useContext } from 'react';

import { TokenContext } from '../context/TokenProvider';

export default () => {
  const {
    token,
    setToken,
    tokenExpiresIn,
    setTokenExpiresIn,
    refreshTokenExpiresIn,
    setRefreshTokenExpiresIn,
    idToken,
    setIdToken,
  } = useContext(TokenContext);

  const resetAuth = () => {
    setToken(null);
    setTokenExpiresIn(null);
    setRefreshTokenExpiresIn(null);
    setIdToken(null);
  };

  return {
    token,
    setToken,
    tokenExpiresIn,
    setTokenExpiresIn,
    refreshTokenExpiresIn,
    setRefreshTokenExpiresIn,
    idToken,
    setIdToken,
    resetAuth,
  };
};
