import viberIcon from 'assets/icons/viber.svg';
import weChatIcon from 'assets/socials/wechat.svg';
import emailIcon from 'assets/socials/email.svg';
import smsIcon from 'assets/icons/sms.svg';
import rcsIcon from 'assets/icons/rcs.svg';
import whatsAppIcon from 'assets/socials/whatsapp.svg';
import rcsDarkIcon from 'assets/socials/rcs-dark.svg';
import smsDarkIcon from 'assets/socials/sms-dark.svg';
import approvedIcon from 'assets/icons/moderation/approved.svg';
import draftIcon from 'assets/icons/moderation/draft.svg';
import moderationIcon from 'assets/icons/moderation/moderation.svg';
import rejectIcon from 'assets/icons/moderation/reject.svg';
import pendingIcon from 'assets/icons/moderation/pending.svg';

import { MAIN_MENU, SUB_MENU } from './menu';
import {
  CAMPAIGN_CHANNEL_TYPE,
  CAMPAIGN_CHANNEL_ACTION_TYPE,
  CAMPAIGN_CHANNEL_IMAGES,
  CAMPAIGN_CHANNEL_ACTION_IMAGES,
} from './campaignChannel';
import { API_URL } from './env';
import {
  VALIDATION_MESSAGE_REQUIRED,
  VALIDATION_MESSAGE_TAG_LIST_REQUIRED,
  VALIDATION_MESSAGE_DATE,
  VALIDATION_MESSAGE_ARRAY,
} from './validationMessages';
import * as columns from './columns';
import * as channels from './channels';
import * as hints from './hints';
import * as scenario from './scenario';
import { BILLING_SCHEMA, BILLING_MODS } from './billing';
import {
  RICH_CARD_ORIENTATION_VERTICAL,
  RICH_CARD_ORIENTATION_HORIZONTAL,
  RICH_CARD_MEDIA_ALIGNMENT_LEFT,
  RICH_CARD_MEDIA_ALIGNMENT_RIGHT,
  RICH_CARD_MEDIA_HEIGHT_SHORT,
  RICH_CARD_MEDIA_HEIGHT_MEDIUM,
  RICH_CARD_MEDIA_HEIGHT_TALL,
  RICH_CARD_TEXT_STYLE_BOLD,
  RICH_CARD_TEXT_STYLE_ITALIC,
  RICH_CARD_TEXT_STYLE_UNDERLINE,
  RICH_CARD_ACTION_CATEGORIES,
  RICH_CARD_ACTIONS,
  RICH_CARD_ACTION_CATEGORIES_LIST,
  RICH_CARD_ACTION_URL_OPEN_APPLICATION,
  RICH_CARD_ACTION_URL_OPEN_VIEW_MODE,
  RICH_CARD_DEFAULT_ACTION,
  RICH_CARD_DEFAULT_ACTION_CONFIG,
  RICH_CARD_ACTION_CATEGORIES_ICONS,
  RICH_CARD_DEFAULT_ACTION_CATEGORY,
  DEFAULT_CAROUSEL_CARD,
} from './richCard';
import { RCS_TYPE } from './rcs';
import MUURI_GRID_ID from './muuriGridId';
import { RATES } from './rates';
import {
  WHATSAPP_IMAGE_EXTENSIONS,
  WHATSAPP_AUDIO_EXTENSIONS,
  WHATSAPP_VIDEO_EXTENSIONS,
  WHATSAPP_DOCUMENT_EXTENSIONS,
  WHATSAPP_ALL_EXTENSIONS,
  RCS_ALL_EXTENSIONS,
  VIBER_ALL_EXTENSIONS,
  VIBER_ALL_MIME_TYPES,
  RCS_ALL_MIME_TYPES,
  WHATSAPP_ALL_MIME_TYPES,
  VIBER_IMAGE_MIME_TYPES,
  VIBER_VIDEO_MIME_TYPES,
  VIBER_DOCUMENT_MIME_TYPES,
  WHATSAPP_IMAGE_MIME_TYPES,
  WHATSAPP_AUDIO_MIME_TYPES,
  WHATSAPP_VIDEO_MIME_TYPES,
  WHATSAPP_DOCUMENT_MIME_TYPES,
  WECHAT_IMAGE_MIME_TYPES,
  WECHAT_IMAGE_EXTENSIONS,
} from './allowedFileTypes';

const DELIVERY_CONDITIONS_TITLE = {
  DISPLAY_SUCCESS: 'DISPLAY SUCCESS',
  SUBMIT_SUCCESS: 'SUBMIT SUCCESS',
  DELIVERY_SUCCESS: 'DELIVERY SUCCESS',
};
const CHANNELS_DICTIONARY = {
  1: {
    label: 'SMS',
    icon: smsIcon,
    lightThemeIcon: smsDarkIcon,
  },
  2: {
    label: 'RCS',
    icon: rcsIcon,
    lightThemeIcon: rcsDarkIcon,
  },
  3: {
    label: 'Viber',
    icon: viberIcon,
  },
  4: {
    label: 'WhatsApp',
    icon: whatsAppIcon,
  },
  6: {
    label: 'WeChat',
    icon: weChatIcon,
  },
  7: {
    label: 'Email',
    icon: emailIcon,
  },
};

const CHATS_BOTS_MODERATION_STATUSES = [
  {
    id: 0,
    className: 'draft',
    icon: draftIcon,
    name: 'NEW_TRANSLATES.INSTANCES.CHATBOTS.DRAFT',
  },
  {
    id: 1,
    className: 'approved',
    icon: approvedIcon,
    name: 'NEW_TRANSLATES.INSTANCES.CHATBOTS.APPROVED',
  },
  {
    id: 2,
    className: 'moderation',
    icon: moderationIcon,
    name: 'NEW_TRANSLATES.INSTANCES.CHATBOTS.MODERATION',
  },
  {
    id: 3,
    className: 'reject',
    icon: rejectIcon,
    name: 'NEW_TRANSLATES.INSTANCES.CHATBOTS.REJECTED',
  },
  {
    id: 4,
    className: 'pending',
    icon: pendingIcon,
    name: 'NEW_TRANSLATES.INSTANCES.CHATBOTS.PENDING',
  },
];

const MIME_TYPES = {
  abs: 'audio/x-mpeg',
  ai: 'application/postscript',
  aif: 'audio/x-aiff',
  aifc: 'audio/x-aiff',
  aiff: 'audio/x-aiff',
  aim: 'application/x-aim',
  art: 'image/x-jg',
  asf: 'video/x-ms-asf',
  asx: 'video/x-ms-asf',
  au: 'audio/basic',
  avi: 'video/x-msvideo',
  avx: 'video/x-rad-screenplay',
  bcpio: 'application/x-bcpio',
  bin: 'application/octet-stream',
  bmp: 'image/bmp',
  body: 'text/html',
  cdf: 'application/x-cdf',
  cer: 'application/pkix-cert',
  class: 'application/java',
  cpio: 'application/x-cpio',
  csh: 'application/x-csh',
  css: 'text/css',
  dib: 'image/bmp',
  doc: 'application/msword',
  dtd: 'application/xml-dtd',
  dv: 'video/x-dv',
  dvi: 'application/x-dvi',
  eot: 'application/vnd.ms-fontobject',
  eps: 'application/postscript',
  etx: 'text/x-setext',
  exe: 'application/octet-stream',
  gif: 'image/gif',
  gtar: 'application/x-gtar',
  gz: 'application/x-gzip',
  hdf: 'application/x-hdf',
  hqx: 'application/mac-binhex40',
  htc: 'text/x-component',
  htm: 'text/html',
  html: 'text/html',
  ief: 'image/ief',
  jad: 'text/vnd.sun.j2me.app-descriptor',
  jar: 'application/java-archive',
  java: 'text/x-java-source',
  jnlp: 'application/x-java-jnlp-file',
  jpe: 'image/jpeg',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  js: 'application/javascript',
  jsf: 'text/plain',
  json: 'application/json',
  jspf: 'text/plain',
  kar: 'audio/midi',
  latex: 'application/x-latex',
  m3u: 'audio/x-mpegurl',
  mac: 'image/x-macpaint',
  man: 'text/troff',
  mathml: 'application/mathml+xml',
  me: 'text/troff',
  mid: 'audio/midi',
  midi: 'audio/midi',
  mif: 'application/x-mif',
  mov: 'video/quicktime',
  movie: 'video/x-sgi-movie',
  mp1: 'audio/mpeg',
  mp2: 'audio/mpeg',
  mp3: 'audio/mpeg',
  mp4: 'video/mp4',
  mpa: 'audio/mpeg',
  mpe: 'video/mpeg',
  mpeg: 'video/mpeg',
  mpega: 'audio/x-mpeg',
  mpg: 'video/mpeg',
  mpv2: 'video/mpeg2',
  ms: 'application/x-wais-source',
  nc: 'application/x-netcdf',
  oda: 'application/oda',
  odb: 'application/vnd.oasis.opendocument.database',
  odc: 'application/vnd.oasis.opendocument.chart',
  odf: 'application/vnd.oasis.opendocument.formula',
  odg: 'application/vnd.oasis.opendocument.graphics',
  odi: 'application/vnd.oasis.opendocument.image',
  odm: 'application/vnd.oasis.opendocument.text-master',
  odp: 'application/vnd.oasis.opendocument.presentation',
  ods: 'application/vnd.oasis.opendocument.spreadsheet',
  odt: 'application/vnd.oasis.opendocument.text',
  otg: 'application/vnd.oasis.opendocument.graphics-template',
  oth: 'application/vnd.oasis.opendocument.text-web',
  otp: 'application/vnd.oasis.opendocument.presentation-template',
  ots: 'application/vnd.oasis.opendocument.spreadsheet-template',
  ott: 'application/vnd.oasis.opendocument.text-template',
  ogx: 'application/ogg',
  ogv: 'video/ogg',
  oga: 'audio/ogg',
  ogg: 'audio/ogg',
  otf: 'application/x-font-opentype',
  spx: 'audio/ogg',
  flac: 'audio/flac',
  anx: 'application/annodex',
  axa: 'audio/annodex',
  axv: 'video/annodex',
  xspf: 'application/xspf+xml',
  pbm: 'image/x-portable-bitmap',
  pct: 'image/pict',
  pdf: 'application/pdf',
  pgm: 'image/x-portable-graymap',
  pic: 'image/pict',
  pict: 'image/pict',
  pls: 'audio/x-scpls',
  png: 'image/png',
  pnm: 'image/x-portable-anymap',
  pnt: 'image/x-macpaint',
  ppm: 'image/x-portable-pixmap',
  ppt: 'application/vnd.ms-powerpoint',
  pps: 'application/vnd.ms-powerpoint',
  ps: 'application/postscript',
  psd: 'image/vnd.adobe.photoshop',
  qt: 'video/quicktime',
  qti: 'image/x-quicktime',
  qtif: 'image/x-quicktime',
  ras: 'image/x-cmu-raster',
  rdf: 'application/rdf+xml',
  rgb: 'image/x-rgb',
  rm: 'application/vnd.rn-realmedia',
  roff: 'text/troff',
  rtf: 'application/rtf',
  rtx: 'text/richtext',
  sfnt: 'application/font-sfnt',
  sh: 'application/x-sh',
  shar: 'application/x-shar',
  sit: 'application/x-stuffit',
  snd: 'audio/basic',
  src: 'application/x-wais-source',
  sv4cpio: 'application/x-sv4cpio',
  sv4crc: 'application/x-sv4crc',
  svg: 'image/svg+xml',
  svgz: 'image/svg+xml',
  swf: 'application/x-shockwave-flash',
  t: 'text/troff',
  tar: 'application/x-tar',
  tcl: 'application/x-tcl',
  tex: 'application/x-tex',
  texi: 'application/x-texinfo',
  texinfo: 'application/x-texinfo',
  tif: 'image/tiff',
  tiff: 'image/tiff',
  tr: 'text/troff',
  tsv: 'text/tab-separated-values',
  ttf: 'application/x-font-ttf',
  txt: 'text/plain',
  ulw: 'audio/basic',
  ustar: 'application/x-ustar',
  vxml: 'application/voicexml+xml',
  xbm: 'image/x-xbitmap',
  xht: 'application/xhtml+xml',
  xhtml: 'application/xhtml+xml',
  xls: 'application/vnd.ms-excel',
  xml: 'application/xml',
  xpm: 'image/x-xpixmap',
  xsl: 'application/xml',
  xslt: 'application/xslt+xml',
  xul: 'application/vnd.mozilla.xul+xml',
  xwd: 'image/x-xwindowdump',
  vsd: 'application/vnd.visio',
  wav: 'audio/x-wav',
  wbmp: 'image/vnd.wap.wbmp',
  wml: 'text/vnd.wap.wml',
  wmlc: 'application/vnd.wap.wmlc',
  wmls: 'text/vnd.wap.wmlsc',
  wmlscriptc: 'application/vnd.wap.wmlscriptc',
  wmv: 'video/x-ms-wmv',
  woff: 'application/font-woff',
  woff2: 'application/font-woff2',
  wrl: 'model/vrml',
  wspolicy: 'application/wspolicy+xml',
  z: 'application/x-compress',
  zip: 'application/zip',
};

const {
  ASSETS_COLUMNS,
  RATE_PLANS_COLUMNS,
  EDR_COLUMNS,
  SENDERS_COLUMNS,
  CAMPAIGNS_COLUMNS,
  SENDER_GROUPS_COLUMNS,
  TEMPLATES_COLUMNS,
  RCS_RATE_PLANS_COLUMNS,
  SCENARIOS_COLUMNS,
  USERS_COLUMNS,
  AUDIT_COLUMNS,
  WHATS_APP_TEMPLATES_COLUMNS,
} = columns;

const {
  DASHBOARD_HINTS,
  CAMPAIGN_HINTS,
  RECIPIENTS_HINTS,
  MENU_HINTS,
  DASHBOARD_KEY,
  CAMPAIGN_KEY,
  RECIPIENTS_KEY,
  MENU_KEY,
  HINTS_KEY,
} = hints;

export {
  MAIN_MENU,
  SUB_MENU,
  CAMPAIGN_CHANNEL_TYPE,
  CAMPAIGN_CHANNEL_ACTION_TYPE,
  CAMPAIGN_CHANNEL_IMAGES,
  CAMPAIGN_CHANNEL_ACTION_IMAGES,
  API_URL,
  VALIDATION_MESSAGE_REQUIRED,
  VALIDATION_MESSAGE_TAG_LIST_REQUIRED,
  VALIDATION_MESSAGE_DATE,
  VALIDATION_MESSAGE_ARRAY,
  ASSETS_COLUMNS,
  RICH_CARD_ORIENTATION_VERTICAL,
  RICH_CARD_ORIENTATION_HORIZONTAL,
  RICH_CARD_MEDIA_ALIGNMENT_LEFT,
  RICH_CARD_MEDIA_ALIGNMENT_RIGHT,
  RICH_CARD_MEDIA_HEIGHT_SHORT,
  RICH_CARD_MEDIA_HEIGHT_MEDIUM,
  RICH_CARD_MEDIA_HEIGHT_TALL,
  RCS_TYPE,
  RICH_CARD_TEXT_STYLE_BOLD,
  RICH_CARD_TEXT_STYLE_ITALIC,
  RICH_CARD_TEXT_STYLE_UNDERLINE,
  RATE_PLANS_COLUMNS,
  RICH_CARD_ACTION_CATEGORIES,
  RICH_CARD_ACTIONS,
  RICH_CARD_ACTION_CATEGORIES_LIST,
  RICH_CARD_ACTION_URL_OPEN_APPLICATION,
  RICH_CARD_ACTION_URL_OPEN_VIEW_MODE,
  EDR_COLUMNS,
  RICH_CARD_DEFAULT_ACTION,
  RICH_CARD_DEFAULT_ACTION_CONFIG,
  RICH_CARD_ACTION_CATEGORIES_ICONS,
  RICH_CARD_DEFAULT_ACTION_CATEGORY,
  DELIVERY_CONDITIONS_TITLE,
  SENDERS_COLUMNS,
  CAMPAIGNS_COLUMNS,
  SENDER_GROUPS_COLUMNS,
  channels,
  columns,
  MUURI_GRID_ID,
  scenario,
  DEFAULT_CAROUSEL_CARD,
  TEMPLATES_COLUMNS,
  DASHBOARD_HINTS,
  CAMPAIGN_HINTS,
  RECIPIENTS_HINTS,
  MENU_HINTS,
  DASHBOARD_KEY,
  CAMPAIGN_KEY,
  RECIPIENTS_KEY,
  MENU_KEY,
  HINTS_KEY,
  RCS_RATE_PLANS_COLUMNS,
  SCENARIOS_COLUMNS,
  USERS_COLUMNS,
  AUDIT_COLUMNS,
  WHATS_APP_TEMPLATES_COLUMNS,
  RATES,
  BILLING_SCHEMA,
  BILLING_MODS,
  WHATSAPP_IMAGE_EXTENSIONS,
  WHATSAPP_AUDIO_EXTENSIONS,
  WHATSAPP_VIDEO_EXTENSIONS,
  WHATSAPP_DOCUMENT_EXTENSIONS,
  WHATSAPP_ALL_EXTENSIONS,
  RCS_ALL_EXTENSIONS,
  VIBER_ALL_EXTENSIONS,
  VIBER_ALL_MIME_TYPES,
  RCS_ALL_MIME_TYPES,
  WHATSAPP_ALL_MIME_TYPES,
  VIBER_IMAGE_MIME_TYPES,
  VIBER_VIDEO_MIME_TYPES,
  VIBER_DOCUMENT_MIME_TYPES,
  WHATSAPP_IMAGE_MIME_TYPES,
  WHATSAPP_AUDIO_MIME_TYPES,
  WHATSAPP_VIDEO_MIME_TYPES,
  WHATSAPP_DOCUMENT_MIME_TYPES,
  CHANNELS_DICTIONARY,
  CHATS_BOTS_MODERATION_STATUSES,
  MIME_TYPES,
  WECHAT_IMAGE_MIME_TYPES,
  WECHAT_IMAGE_EXTENSIONS,
};
