import React from 'react';
import { useKey } from 'react-use';
import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal';
import { BasicButton } from 'components/buttons';

import './style.scss';

const InfoModal = ({
  closeModal,
  description,
}) => {
  const { t } = useTranslation();

  useKey('Escape', closeModal);

  const basicButtonStyle = {
    alignSelf: 'center',
    justifyContent: 'center',
  };

  return (
    <Modal
      onClose={closeModal}
      contentStyle={{
        minHeight: '10vh',
        minWidth: '30vw',
      }}
      withWarning
      title={t('TOAST.WARNING')}
    >
      <div className="info-modal__description">
        {description}
      </div>
      <div className="info-modal__buttons">
        <BasicButton
          text="OK"
          type={BasicButton.types.PRIMARY}
          onClick={closeModal}
          style={basicButtonStyle}
        />
      </div>
    </Modal>
  );
};

export default InfoModal;
