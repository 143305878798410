import {
  State,
  Action,
  InputType,
  DragCanvasState,
  // MoveItemsState,
  SelectingState,
} from '@projectstorm/react-canvas-core';
import {
  PointModel,
  DefaultPortModel,
} from '@projectstorm/react-diagrams';

// import DragCanvasState from './DragCanvasState';
// import DragNewLinkState from './DragNewLinkState';
// import SelectingState from './SelectingState';
import SelectLinkState from './SelectLinkState';
import DragNewLinkState from './DragNewLinkState';
import MoveItemsState from './MoveItemsState';

/**
 * This class defines custom handlers (called states) to respond to
 * clicking events on certain elements.
 */
export default class States extends State {
  constructor() {
    super({
      name: 'diagram-states',
    });

    // You can grab the default state from `react-diagrams` for every one of these...
    this.childStates = [new SelectingState()];
    this.dragCanvas = new DragCanvasState();
    this.dragNewLink = new DragNewLinkState({ allowLooseLinks: false });
    this.dragItems = new MoveItemsState();

    // But this is a custom one!
    this.selectLink = new SelectLinkState();

    // Determine what was clicked on
    this.registerAction(
      new Action({
        type: InputType.MOUSE_DOWN,
        fire: (event) => {
          const element = this.engine
            .getActionEventBus()
            .getModelForEvent(event);

          // The canvas was clicked on, transition to the dragging canvas state
          if (!element) {
            this.transitionWithEvent(this.dragCanvas, event);
          } else if (element instanceof DefaultPortModel) {
            // if (event.event.target.dataset.id === 'circle') {
            //
            // }
            // Initiate dragging a new link
            this.transitionWithEvent(this.dragNewLink, event);
          } else if (element instanceof PointModel) {
            // Link selection <============================================
            this.transitionWithEvent(this.selectLink, event);
          } else {
            // Move items
            this.transitionWithEvent(this.dragItems, event);
          }
        },
      }),
    );
  }
}
