import endpoints from 'const/endpoints';

export default ({
  limit = 10,
  offset = 0,
} = {}) => ({
  url: endpoints.getSenderGroupsUrl(),
  queryKey: endpoints.getSenderGroupsUrl(),
  body: {
    limit,
    offset: offset ? ((offset - 1) * limit) : offset,
  },
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
  },
  transform: (response) => {
    const {
      content,
      ...other
    } = response;

    return {
      senderGroups: content,
      senderGroupsPagination: other,
    };
  },
  update: {
    senderGroups: (oldValue, newValue) => newValue,
    senderGroupsPagination: (oldValue, newValue) => newValue,
  },
});
