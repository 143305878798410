/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Hint } from 'components';
import {
  MAIN_MENU,
  SUB_MENU,
  MENU_HINTS,
  MENU_KEY,
} from 'const';
import actions from 'actions';
import { useTheme, useHint, useToken } from 'hooks';
import { useDictionariesApi, useUsersApi } from 'hooks/api';
import endpoints from 'const/endpoints';
import { ReactComponent as NoneIcon } from 'assets/icons/side-menu/none.svg';
import { ReactComponent as ExitIcon } from 'assets/icons/side-menu/exit.svg';
import { ReactComponent as ChevronIcon } from 'assets/icons/chevron-white.svg';

import MenuItem from './MenuItem';
import './style.scss';

const SideMenu = ({
  menuExpand,
  toggleMenuExpandAction,
  dashboardHint,
}) => {
  const sideMenuClass = classNames('side-menu', {
    'side-menu_open': menuExpand,
  });

  const { t } = useTranslation();
  const { isDarkTheme, toggleTheme } = useTheme();
  const { availableChannelsOptions } = useDictionariesApi();
  const { currentUser } = useUsersApi();
  const {
    resetAuth,
  } = useToken();
  const {
    activeHint,
    setNextHint,
    skipAll,
  } = useHint({ hints: MENU_HINTS, hintsKey: MENU_KEY });
  const history = useHistory();

  const {
    logoSquareLight,
    logoSquareDark,
    logoExpandedLight,
    logoExpandedDark,
  } = useMemo(() => {
    try {
      const parsedPortal = JSON.parse(localStorage.getItem('mkitPortal'));

      return {
        logoSquareLight: endpoints.getPortalAssetsUrl(parsedPortal.logoSquareLight),
        logoSquareDark: endpoints.getPortalAssetsUrl(parsedPortal.logoSquareDark),
        logoExpandedLight: endpoints.getPortalAssetsUrl(parsedPortal.logoExpandedLight),
        logoExpandedDark: endpoints.getPortalAssetsUrl(parsedPortal.logoExpandedDark),
      };
    } catch (error) {
      return {};
    }
  }, []);
  const logoToDisplay = useMemo(() => {
    if (menuExpand) {
      return isDarkTheme ? logoExpandedDark : logoExpandedLight;
    }
    return isDarkTheme ? logoSquareDark : logoSquareLight;
  }, [
    menuExpand,
    isDarkTheme,
    logoSquareLight,
    logoSquareDark,
    logoExpandedLight,
    logoExpandedDark,
  ]);
  const menuItems = useMemo(() => {
    const userRolesList = currentUser?.roles || [];

    return MAIN_MENU.reduce((acc, {
      disabled, permissionKey, subLinks, ...menuItem
    }) => {
      if (menuItem.id === 'assets'
        && availableChannelsOptions.length === 1 && availableChannelsOptions[0].value === 1) {
        acc.push({
          ...menuItem,
          href: '/media/sender-ids',
          menuExpand,
          disabled: disabled || (permissionKey && !userRolesList.includes(permissionKey)),
          subLinks: subLinks?.reduce((subAcc, subMenuItem) => {
            if (subMenuItem.id === 'media-assets' || (subMenuItem.id === 'whats-app-templates'
              && !availableChannelsOptions.find(channel => channel.value === 4))) {
              return subAcc;
            }
            subAcc.push({
              ...subMenuItem,
              disabled: subMenuItem.disabled
                || (subMenuItem.permissionKey && !userRolesList.includes(subMenuItem.permissionKey)),
            });
            return subAcc;
          }, []),
        });
      } else {
        acc.push({
          ...menuItem,
          menuExpand,
          disabled: disabled || (permissionKey && !userRolesList.includes(permissionKey)),
          subLinks: subLinks?.reduce((subAcc, subMenuItem) => {
            if (subMenuItem.id === 'whats-app-templates'
              && !availableChannelsOptions.find(channel => channel.value === 4)) {
              return subAcc;
            }
            subAcc.push({
              ...subMenuItem,
              disabled: subMenuItem.disabled
                || (subMenuItem.permissionKey && !userRolesList.includes(subMenuItem.permissionKey)),
            });
            return subAcc;
          }, []),
        });
      }

      return acc;
    }, []);
  }, [menuExpand, availableChannelsOptions, currentUser]);
  const subMenuItems = useMemo(() => {
    const userRolesList = currentUser?.roles || [];

    return SUB_MENU.map(({
      disabled, permissionKey, subLinks, ...menuItem
    }) => ({
      ...menuItem,
      menuExpand,
      disabled: disabled
        || (permissionKey && !userRolesList.includes(permissionKey)),
      subLinks: subLinks?.map(subMenuItem => ({
        ...subMenuItem,
        disabled: subMenuItem.disabled
          || (subMenuItem.permissionKey && !userRolesList.includes(subMenuItem.permissionKey)),
      })),
    }));
  }, [menuExpand, currentUser]);

  const onLogout = () => {
    fetch(endpoints.getLogoutUrl(), {
      method: 'POST',
      credentials: process.env.NODE_ENV === 'production' ? undefined : 'include',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        } else {
          resetAuth();
        }
        return response.json();
      })
      .catch((error) => {
        console.error('ERROR:', error);
        resetAuth();
      });
  };

  return (
    <div className={sideMenuClass}>
      <Link to="/" className="side-menu__logo">
        <img
          src={logoToDisplay}
          alt="side-menu__logo__icon"
        />
      </Link>
      <button
        type="button"
        className="side-menu__button-open"
        onClick={toggleMenuExpandAction}
      >
        <ChevronIcon className="side-menu__button-open__icon" />
      </button>
      {menuItems.map(item => (
        <MenuItem
          key={item.href}
          {...item}
        />
      ))}
      <div className="side-menu__separation" />
      {
        process.env.NODE_ENV !== 'production' && (
          <MenuItem
            label="Theme"
            href={location => location.pathname}
            onClick={toggleTheme}
            icon={NoneIcon}
          />
        )
      }
      {subMenuItems.map(item => (
        <MenuItem
          key={item.href}
          menuExpand={menuExpand}
          {...item}
        />
      ))}
      <div
        className="menu-item"
        role="presentation"
        onClick={onLogout}
        data-testid="menu/logout"
      >
        <ExitIcon className="menu-item__icon" />
        {
          menuExpand && (
            <span className="menu-item__label">
              {t('MENU.EXIT')}
            </span>
          )
        }
      </div>
      {
        (history.location.pathname === '/dashboard' || history.location.pathname === '/')
        && activeHint !== 'complete' && dashboardHint === 'complete' && (
          <Hint
            setNextHint={setNextHint}
            onSkip={skipAll}
            {...MENU_HINTS[activeHint]}
          />
        )
      }
    </div>
  );
};

const mapStateToProps = state => ({
  menuExpand: state.layout.menuExpand,
  dashboardHint: state.dashboard.dashboardHint,
});

const mapDispatchToProps = {
  toggleMenuExpandAction: actions.toggleMenuExpandAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
