import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import EmailConstructor from 'mkit-front-ds/dist/components/EmailConstructor';
import { JsonToMjml } from 'easy-email-core';
import mjml from 'mjml-browser';
import toastRef from 'mkit-front-ds/dist/helpers/toast';

import { useSendersApi, useRecipientsApi, useTemplatesApi } from 'hooks/api';
import {
  DropdownField,
  DropdownSpecifyField,
  TimePickerField,
} from 'components/fields';
import { BasicButton, IconButton } from 'components/buttons';
import LoadingSpinner from 'components/LoadingSpinner';
import { convertObjectTimeToSeconds } from 'helpers';
import crossIcon from 'assets/icons/cross-grey.svg';
import trashIcon from 'assets/icons/trash.svg';
import disketteIcon from 'assets/icons/diskette.svg';
import disketteDarkIcon from 'assets/icons/diskette-dark.svg';

import './style.scss';

const EmailBlock = ({
  channel,
  onChange,
  onCancel,
  index,
  withTemplates,
  senderId,
  withTools = true,
  readOnly = false,
  deliveryCondition = 'DELIVERY_SUCCESS',
  timeout = { hours: '00', minutes: '10', seconds: '00' },
}) => {
  const timeFieldStyle = {
    container: {
      width: '100%',
    },
    overlay: {
      top: 'unset',
      bottom: 36,
    },
  };

  const { t } = useTranslation();
  const {
    getSenders,
    senders,
    isPendingGetSenders,
  } = useSendersApi();
  const {
    getTemplates,
    templates,
    postCreateTemplate,
    deleteTemplate,
    patchUpdateTemplate,
    isPendingGetTemplates,
  } = useTemplatesApi();
  const { getAttributes, isPendingGetAttributes } = useRecipientsApi();
  const [sender, setSender] = useState(senderId);
  const [template, setTemplate] = useState({});
  const [waitFor, setWaitFor] = useState(deliveryCondition);
  const [waitingTime, setWaitingTime] = useState(timeout);
  const [initialValue, setInitialValue] = useState();
  const emailConstructorValuesRef = useRef();

  const emailTemplates = useMemo(() => templates.filter(I => I.channel === 7).map(I => ({
    id: I.id,
    title: I.name,
  })), [templates]);
  const sendersOptions = useMemo(() => senders.filter(I => I.channel === 7 && I.status === 2)
    .map(I => ({
      id: I.senderId,
      title: I.displayName,
    })), [senders]);

  const onApply = () => {
    if (!sender && withTools) {
      toastRef?.current?.showMessage({
        message: 'CONVERSATIONS.INSTANCES.SELECT_SENDER',
      });
    } else {
      const content = emailConstructorValuesRef.current?.content;

      if (!content || !content.children?.length
        || (content.children.length === 1
          && (content.children[0]?.type === 'wrapper' || content.children[0]?.type === 'advanced_wrapper')
          && !content.children[0]?.children?.length)) {
        toastRef?.current?.showMessage({
          message: 'NEW_TRANSLATES.TOASTS.CONSTRUCTOR.CANNOT_SAVE_EMAIL',
        });
      } else {
        const mjmlBody = JsonToMjml({
          data: emailConstructorValuesRef.current?.content,
          mode: 'production',
          context: emailConstructorValuesRef.current?.content,
        });
        const { html } = mjml(
          mjmlBody,
          {
            beautify: false,
            validationLevel: 'soft',
            minify: true,
          },
        );

        onChange({
          index,
          channelType: 7,
          contentPattern: {
            subject: emailConstructorValuesRef.current?.subject,
            subtitle: emailConstructorValuesRef.current?.subTitle,
            body: {
              json: content,
              rendered: [
                {
                  contentType: 'mjml',
                  content: mjmlBody,
                },
                {
                  contentType: 'html',
                  content: html,
                },
              ],
            },
          },
          deliveryCondition: waitFor,
          order: 0,
          senderId: sender,
          timeout: convertObjectTimeToSeconds(waitingTime),
        });
      }
    }
  };
  const onSaveTemplate = () => {
    const content = emailConstructorValuesRef.current?.content;

    const mjmlBody = JsonToMjml({
      data: emailConstructorValuesRef.current?.content,
      mode: 'production',
      context: emailConstructorValuesRef.current?.content,
    });
    const { html } = mjml(
      mjmlBody,
      {
        beautify: false,
        validationLevel: 'soft',
        minify: true,
      },
    );

    const templateBody = {
      name: template.value,
      channel: 7,
      contentNative: html,
      type: 'e-mail',
      content: JSON.stringify({
        subject: emailConstructorValuesRef.current?.subject,
        subtitle: emailConstructorValuesRef.current?.subTitle,
        body: {
          json: content,
          rendered: [
            {
              contentType: 'mjml',
              content: mjmlBody,
            },
            {
              contentType: 'html',
              content: html,
            },
          ],
        },
      }),
    };

    if (template.isCustom) {
      postCreateTemplate(templateBody)
        .then(() => {
          getTemplates({ limit: 100, offset: 0 });
        });
    } else {
      patchUpdateTemplate({
        ...templateBody,
        name: emailTemplates.find(I => I.id === template.value).title,
        id: template.value,
      })
        .then(() => {
          getTemplates({ limit: 100, offset: 0 });
        });
    }
  };
  const onDeleteTemplate = () => {
    deleteTemplate(template.value)
      .then(() => {
        getTemplates({ limit: 100, offset: 0 });
      });
  };

  useEffect(() => {
    const selectedItem = templates.find(I => I.id === template.value);

    if (selectedItem) {
      const parsedContent = JSON.parse(selectedItem.content);

      setInitialValue({
        subject: parsedContent.subject,
        subTitle: parsedContent.subtitle,
        content: parsedContent.body.json,
      });
    }
  }, [template]);
  useEffect(() => {
    if (channel?.contentPattern) {
      const parsedContent = channel.contentPattern;

      setInitialValue({
        subject: parsedContent.subject,
        subTitle: parsedContent.subtitle,
        content: parsedContent.body.json,
      });
    }
  }, [channel?.contentPattern]);
  useEffect(() => {
    if (!isEmpty(template)) {
      const foundItem = emailTemplates.find(I => I.title === template.value
        || I.id === template.value);

      if (foundItem) {
        setTemplate({ value: foundItem.id, isCustom: false });
      } else {
        setTemplate({});
      }
    }
  }, [emailTemplates]);
  useEffect(() => {
    getSenders({ limit: 1000, onlyActiveSenders: false });
    getTemplates({ limit: 100, offset: 0 });
    getAttributes({ variablesOnly: true });
  }, []);

  if (isPendingGetTemplates || isPendingGetSenders || isPendingGetAttributes) {
    return <LoadingSpinner fullSize />;
  }

  return (
    <div className="email-block">
      <h2 className="email-block__header">
        {t('SCREENS.BROADCASTS.EMAIL_MESSAGE_SETTINGS')}
      </h2>
      {
        (withTools || withTemplates) && (
          <>
            <div className="email-block__tools">
              <span className="email-block__label" style={{ minWidth: 'unset' }}>
                {t('SCREENS.BROADCASTS.TEMPLATE')}
              </span>
              <DropdownSpecifyField
                input={{
                  value: template,
                  onChange: setTemplate,
                }}
                placeholder={t('PLACEHOLDERS.ENTER_NAME')}
                items={emailTemplates}
                style={{ width: 265, marginLeft: 'auto' }}
                disabled={readOnly}
              />
              <IconButton
                icon={disketteDarkIcon}
                onClick={onSaveTemplate}
                isDisabled={isEmpty(template) || readOnly}
                filterType="white"
              />
              <IconButton
                icon={trashIcon}
                onClick={onDeleteTemplate}
                isDisabled={isEmpty(template) || template.isCustom || readOnly}
                filterType="white"
              />
            </div>
            <DropdownField
              values={sendersOptions}
              input={{
                value: sender,
                onChange: setSender,
              }}
              label={t('NEW_TRANSLATES.INSTANCES.SENDER_ID')}
              disabled={readOnly}
              type="grey-border"
              styleInput={{ minHeight: 36, maxWidth: 330, marginLeft: 'auto' }}
              withoutCallbackDestroy
            />
          </>
        )
      }
      <EmailConstructor valuesRef={emailConstructorValuesRef} initialValues={initialValue} />
      {
        withTools && (
          <>
            <div className="email-block__time-tools" style={{ marginTop: 24 }}>
              <span className="email-block__label">
                {t('SCREENS.BROADCASTS.WAIT_FOR')}
              </span>
              <DropdownField
                input={{
                  value: waitFor,
                  onChange: setWaitFor,
                }}
                type="grey-border"
                values={[
                  {
                    id: 'DELIVERY_SUCCESS',
                    title: t('SCREENS.BROADCASTS.DELIVERY_SUCCESS'),
                  },
                  {
                    id: 'SUBMIT_SUCCESS',
                    title: t('SCREENS.BROADCASTS.SUBMIT_SUCCESS'),
                  },
                ]}
                isDisabled={readOnly}
                withoutCallbackDestroy
              />
            </div>
            <div className="email-block__time-tools">
              <span className="email-block__label">
                {t('SCREENS.BROADCASTS.WAITING_TIME')}
              </span>
              <TimePickerField
                input={{
                  value: waitingTime,
                  onChange: setWaitingTime,
                }}
                styles={timeFieldStyle}
                typeOverlay="primary-color"
                withTimezone={false}
                disabled={readOnly}
              />
            </div>
          </>
        )
      }
      <div className="email-block__buttons">
        <BasicButton
          text={t('CONTROLS.CANCEL')}
          type={BasicButton.types.CANCEL}
          icon={crossIcon}
          onClick={onCancel}
          isNewStyle
        />
        <BasicButton
          text={t('CONTROLS.SAVE')}
          type={BasicButton.types.ACCENT}
          icon={disketteIcon}
          onClick={onApply}
          disabled={readOnly}
        />
      </div>
    </div>
  );
};

export default EmailBlock;
