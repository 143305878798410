import React from 'react';
import { Input as InputSource } from 'antd';
import classNames from 'classnames';
import './Input.scss';

const Input = ({ className, ...props }) => (
  <InputSource className={classNames(className, 'input', 'input-field')} {...props} />
);

export default Input;
