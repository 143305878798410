import { useMutation } from 'redux-query-react';
import { useCallback } from 'react';

import {
  getDeliveryConditions as getDeliveryConditionsApi,
  getAvailableChannels as getAvailableChannelsApi,
} from 'queries/dictionaries';
import {
  getDictionariesDbActions as getDictionariesDbActionsApi,
  getDictionariesFileActions as getDictionariesFileActionsApi,
} from 'queries/importRecipients';
import { CHANNELS_DICTIONARY } from 'const';

import { getArray, useISESelector } from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetDeliveryConditions,
      lastUpdated: lastUpdatedGetDeliveryConditions,
    },
    getDeliveryConditions,
  ] = useMutation(getDeliveryConditionsApi);
  const [
    {
      isPending: isPendingGetDictionariesDbActions,
    },
    getDictionariesDbActions,
  ] = useMutation(getDictionariesDbActionsApi);
  const [
    {
      isPending: isPendingGetDictionariesFileActions,
    },
    getDictionariesFileActions,
  ] = useMutation(getDictionariesFileActionsApi);
  const [
    {
      lastUpdated: lastUpdatedGetAvailableChannels,
    },
    getAvailableChannels,
  ] = useMutation(getAvailableChannelsApi);

  const selector = useCallback(state => ({
    deliveryConditions: getArray(state, 'deliveryConditions'),
    fileActions: getArray(state, 'fileActions'),
    dbActions: getArray(state, 'dbActions'),
    availableChannelsOptions: getArray(state, 'mkitAvailableChannelsOptions')?.length
      ? getArray(state, 'mkitAvailableChannelsOptions')
      : [{ value: 1, ...CHANNELS_DICTIONARY[1] }],
  }), []);

  const data = useISESelector(selector);
  return {
    getDeliveryConditions,
    getDictionariesDbActions,
    getDictionariesFileActions,
    getAvailableChannels,
    isPendingGetDeliveryConditions,
    isPendingGetDictionariesDbActions,
    isPendingGetDictionariesFileActions,
    lastUpdatedGetDeliveryConditions,
    lastUpdatedGetAvailableChannels,
    ...data,
  };
};
