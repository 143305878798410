/* eslint-disable */
import classNames from 'classnames';
import React from 'react';

import LoadingSpinner from 'components/LoadingSpinner';

import './InfographicsBase.scss';

export default ({ size, title, text, numbers, img, filterType, isLoading }) => (
  <div
    className={classNames('infographics', {
      'infographics--big': size === 'big',
    })}
  >
    <div
      className={classNames('infographics__image', {
        [`infographics__image_filter-${filterType}`]: filterType,
      })}
      style={{ backgroundImage: `url(${img})` }}
    />
    {title && <div className="infographics__title">{title}</div>}
    {text && <div className="infographics__text">{text}</div>}
    <div className="infographics__numbers">
      {isLoading ? (
        <LoadingSpinner style={{ marginTop: 20 }} />
      ) : numbers}
    </div>
  </div>
);
