import endpoints from 'const/endpoints';

export default ({ body, successCallback, errorCallback }) => ({
  url: endpoints.getRecipientsUrl(),
  body,
  options: {
    method: 'PATCH',
  },
  meta: {
    authToken: true,
    successCallback,
    errorCallback,
  },
});
