import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';

import { ListFilterForm } from 'components/forms';
import { useAccountApi } from 'hooks/api';

const OperationsFilterFormWrapper = forwardRef(({
  onSubmit,
  initialValues,
  column,
  ...props
}, ref) => {
  const { t } = useTranslation();

  const { getOperations, operations } = useAccountApi();
  const tagsFilterRef = useRef();

  useImperativeHandle(ref, () => ({
    getModel() {
      return tagsFilterRef.current.getModel();
    },
    setModel(values = { value: '' }) {
      tagsFilterRef.current.setModel(values);
    },
    afterGuiAttached() {
      getOperations();
    },
    isFilterActive() {
      return tagsFilterRef.current.isFilterActive();
    },
    doesFilterPass() {
      return true;
    },
  }));

  const items = useMemo(
    () => operations.map(item => ({
      title: t(`SCREENS.AUDIT.${item}`),
      id: item,
    })),
    [operations],
  );

  return (
    <ListFilterForm
      {...props}
      column={column}
      ref={tagsFilterRef}
      items={items}
      onSubmit={onSubmit}
      withSearch
      withFilter
    />
  );
});

export default OperationsFilterFormWrapper;
