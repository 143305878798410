import { fromByteArray, toByteArray } from 'base64-js';

import i18n from '../i18n';

export const encode = (str, encoding = 'utf-8') => {
  try {
    const bytes = new TextEncoder(encoding).encode(str);
    return fromByteArray(bytes);
  } catch (err) {
    console.error(i18n.t('SCENARIOS_CONSTRUCTOR.INSTANCES.PARSE_ERROR'));
    return '';
  }
};

export const decode = (str, encoding = 'utf-8') => {
  try {
    const bytes = toByteArray(str);
    return new TextDecoder(encoding).decode(bytes);
  } catch (err) {
    console.error(i18n.t('SCENARIOS_CONSTRUCTOR.INSTANCES.PARSE_ERROR'));
    return '';
  }
};
