import React from 'react';
import SecondStepSignUpSource from 'mkit-front-ds/dist/screens/SecondStepSignUp';

import { useToken, useTheme } from 'hooks';

const SecondStepSignUpScreen = () => (
  <SecondStepSignUpSource useTokenHook={useToken} useThemeHook={useTheme} />
);

export default SecondStepSignUpScreen;
