import React from 'react';
import ImportRecipientsScreen from 'mkit-front-ds/dist/screens/ImportRecipientsScreen';

import { useToken } from 'hooks';

import './style.scss';

const ImportScreen = () => {
  const { token } = useToken();

  return (
    <ImportRecipientsScreen
      token={token}
    />
  );
};

export default ImportScreen;
