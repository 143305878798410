import React from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { IconButton } from 'components/buttons';
import { BasicField, DropdownFieldNew } from 'components/fields';
import plusIcon from 'assets/icons/plus.svg';
import trashIcon from 'assets/icons/trash.svg';

import './style.scss';
import ReportField from '../ReportField';

const optionsReplyType = [
  {
    label: 'Regexp',
    value: 1,
  },
  // {
  //   label: 'Keyphrase',
  //   value: 2,
  // },
  // {
  //   label: 'Intent',
  //   value: 3,
  // },
];

const ResponseFieldArray = ({
  fields,
  styles = {},
  label,
  removeGroup,
  readOnly = false,
  reportFields,
}) => {
  const onAddField = () => {
    fields.push({ conditionType: 1, key: `_${Math.random().toString(36).substr(2, 9)}` });
  };

  const handleRemoveField = (index) => {
    fields.remove(index);

    if (fields.length === 1) {
      setTimeout(() => removeGroup());
    }
  };

  const { t } = useTranslation();
  return (
    <div className="response-field-array" style={styles.wrapper}>
      <div className="response-field-array__label">
        {label}
      </div>
      {
        fields.map((name, index) => (
          <>
            {index === 0
            && (
            <>
              <Field
                name={`${name}.label`}
                type="border"
                label={t('SCENARIOS_CONSTRUCTOR.INSTANCES.LABEL')}
                component={BasicField}
                styles={{
                  label: { flex: 3 },
                  inputContainer: { flex: 7 },
                  container: { marginBottom: 12 },
                }}
                readOnly={readOnly}
                defaultValue=""
              />
              <Field
                name={`${name}.reportField`}
                type="border"
                label=""
                component={ReportField}
                readOnly={readOnly}
                showCustomReportField={false}
                options={reportFields}
              />
            </>
            )}
            <div className="response-field-array__field" key={name}>

              <Field
                name={`${name}.conditionType`}
                component={DropdownFieldNew}
                options={optionsReplyType}
                type="border"
                styles={{
                  container: {
                    width: '30%',
                    marginRight: 26,
                  },
                }}
                readOnly={readOnly}
              />
              <Field
                name={`${name}.condition`}
                component={BasicField}
                type="border"
                styles={{
                  container: {
                    width: `calc(70% - ${(fields.length !== index + 1) ? 48 : 80}px)`,
                  },
                }}
                readOnly={readOnly}
                withoutCallbackDestroy
              />
              <div className="response-field-array__field__buttons">
                {
                fields.length === index + 1 && (
                  <IconButton
                    filterType="white"
                    icon={plusIcon}
                    onClick={onAddField}
                    isDisabled={readOnly}
                    title={t('NEW_TRANSLATES.TOOLTIPS.ADD')}
                  />
                )
              }
                <IconButton
                  filterType="white"
                  icon={trashIcon}
                  onClick={() => handleRemoveField(index)}
                  isDisabled={readOnly}
                  title={t('NEW_TRANSLATES.TOOLTIPS.DELETE')}
                />
              </div>
            </div>
          </>
        ))
      }
    </div>
  );
};

export default ResponseFieldArray;
