import { useMemo, useEffect } from 'react';
import { usePrevious } from 'react-use';

import {
  transformMessage,
  // setCaretAtEnd,
} from 'helpers';

import useDropdownLogic from './useDropdownLogic';
import useRecipientsApi from './api/useRecipientsApi';

export default ({
  element,
  value,
  onChange,
  cardIndex,
  isSms,
  removeUnbreakableSpaces = true,
  // highlightingVariables,
}) => {
  const prevCardIndex = usePrevious(cardIndex);

  const { attributes } = useRecipientsApi();

  const variables = useMemo(() => attributes.map(I => I.name), [attributes]);

  const {
    buttonRef,
    dropdownRef,
    isDropdownOpen,
    setDropdownOpen,
  } = useDropdownLogic();

  const setInitialValueInControl = (initElement, initValue) => {
    if (initValue && initElement && !initElement.innerHTML) {
      initElement.focus();

      document.execCommand(
        'insertHtml',
        false,
        initValue,
      );

      initElement.blur();
    }
  };

  const setEditorContent = () => {
    if (value && element.current && !element.current.innerHTML) {
      element.current.focus();

      document.execCommand(
        'insertHtml',
        false,
        transformMessage(value),
      );

      element.current.blur();
    }
  };

  useEffect(() => {
    setEditorContent();
  }, []);

  if (isSms) {
    useEffect(() => {
      setEditorContent();
    }, [value]);
  }

  useEffect(() => {
    if (!value) {
      element.current.innerText = '';
    }
    if (value && value !== element.current.innerText) {
      element.current.focus();

      document.execCommand(
        'selectAll',
        false,
      );

      document.execCommand(
        'insertHtml',
        false,
        transformMessage(value),
      );

      element.current.blur();
    }
  }, [value]);

  useEffect(() => {
    if (cardIndex !== undefined && prevCardIndex !== cardIndex) {
      if (!value && element.current.innerHTML) {
        element.current.focus();

        document.execCommand(
          'selectAll',
          false,
        );

        document.execCommand(
          'insertHtml',
          false,
          '',
        );

        element.current.blur();
      }

      if (value) {
        element.current.focus();

        document.execCommand(
          'selectAll',
          false,
        );

        document.execCommand(
          'insertHtml',
          false,
          transformMessage(value),
        );

        element.current.blur();
      }
    }
  }, [cardIndex]);

  const onItemClick = (val) => {
    setDropdownOpen(!isDropdownOpen);
    document.execCommand(
      'insertHtml',
      false,
      `<span class=editor-textarea__tag>{{attribute.${val}}}</span> `,
    );
    element.current.focus();
    // setCaretAtEnd(element.current);

    // const range = window.getSelection().getRangeAt(0);
    // const tagNode = document.createElement('span');
    // tagNode.className = 'editor-textarea__tag';
    // // // tagNode.contentEditable = 'false';
    // tagNode.textContent = `{{attribute.${val}}}`;
    // // // tagNode.innerText = `{{attribute.${val}}}`;
    // range.insertNode(tagNode);
    // setCaretAtEnd(element.current);
  };

  const onButtonClick = (e) => {
    e.stopPropagation();
    setDropdownOpen(!isDropdownOpen);
  };

  const handleChange = () => {
    if (element.current) {
      // if (highlightingVariables && /{{\d}}/.test(element.current.innerHTML)) {
      //   if (/{{\d}}/.test(element.current.innerHTML)) {
      //     document.execCommand(
      //       'selectAll',
      //       false,
      //     );
      //     document.execCommand(
      //       'insertHtml',
      //       false,
      //       element.current.innerHTML
      // eslint-disable-next-line max-len
      //         .replace(/{{\d}}/, stringValue => `<div class="editor-textarea__variable" contenteditable="false"><span>${stringValue.replace(/({{(?=[1-9])|(?<=[1-9])}})/g, '')}</span></div>`),
      //     );
      //     setCaretAtEnd(element.current);
      //   }
      // }

      if (removeUnbreakableSpaces) {
        onChange(
          element.current.innerText
            .replace(/&nbsp;/g, ' ')
            .replace(/\u00A0/g, ' ')
            .replace(/ /g, ' ')
            .trim(),
        );
      } else {
        onChange(element.current.innerText.trim());
      }

      // onChange(
      //   element.current.innerHTML
      // eslint-disable-next-line max-len
      //     .replace(/<div class="editor-textarea__variable" contenteditable="false"><span>/g, '{{')
      //     .replace(/<div class="editor-textarea__tag" contenteditable="false"><span>/g, '{{')
      //     .replace(/<\/span><\/div>/g, '}}')
      //     .replace(/&nbsp;/g, ' ')
      //     .replace('<div><br></div>', '\n')
      //     .replace('/<br/>/g', '\n')
      //     .replace(/<br>/g, '\n')
      //     .replace('/</br>/g', '\n')
      //     .replace(/(<([^>]+)>)/gi, ''),
      // );
    }
  };

  return {
    setInitialValueInControl,
    onItemClick,
    onButtonClick,
    handleChange,
    isDropdownOpen,
    buttonRef,
    dropdownRef,
    variables,
  };
};
