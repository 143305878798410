import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { DatePicker as DatePickerSource } from 'antd';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import localeRU from 'antd/es/date-picker/locale/ru_RU';
import localeEs from 'antd/es/date-picker/locale/es_ES';

import { locale } from 'helpers';

import './DatePicker.scss';

const DatePicker = ({
  className,
  dropdownClassName,
  fieldType,
  input: {
    value,
    onChange,
    ...input
  },
  mode,
  label,
  labelStyle,
  styleWrapper,
  closeOnSelect = true,
  dateFormat = 'YYYY-MM-DD HH:mm:ss',
  ...props
}) => {
  const localLang = locale.getLanguage();
  const [open, setOpen] = useState(false);

  const pickerClass = classNames({
    datepicker: true,
    [`datepicker_${fieldType}`]: fieldType,
    [className]: className,
  });

  const modifyValue = useMemo(() => {
    if (value) {
      const parseValue = moment(value, dateFormat);
      if (parseValue.isValid()) {
        return parseValue;
      }
    }

    return null;
  }, [value]);

  const localLangPack = useMemo(() => {
    if (localLang === 'ru') {
      return localeRU;
    }
    if (localLang === 'es') {
      return localeEs;
    }
    return locale;
  }, [localLang]);

  const handleChange = (nextValue) => {
    if (!isEqual(value, nextValue) && closeOnSelect) {
      setOpen(false);
    }
    onChange(nextValue);
  };

  const datePickerProps = useMemo(() => {
    if (mode) {
      return {
        mode,
        onPanelChange: handleChange,
      };
    }

    return {};
  }, [mode]);

  return (
    <div className="mkit-date-picker-field" style={styleWrapper}>
      {
        label && (
          <span className="mkit-date-picker-field__label" style={labelStyle}>
            {label}
          </span>
        )
      }
      <DatePickerSource
        locale={localLangPack}
        className={pickerClass}
        dropdownClassName={classNames(dropdownClassName, 'datepicker-dropdown')}
        value={modifyValue}
        getCalendarContainer={trigger => trigger}
        {...input}
        {...props}
        format={dateFormat}
        onChange={handleChange}
        open={open}
        onOpenChange={setOpen}
        {...datePickerProps}
        data-testid={`${window.location.pathname}/datepicker-dropdown/${label?.toLowerCase().replace(/\s+/g, '') || props.fieldId}`}
      />
    </div>
  );
};

export default DatePicker;
