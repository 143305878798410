import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import './style.scss';

const MenuItem = ({
  icon: Icon,
  label,
  href = '',
  onClick,
  subLinks = [],
  menuExpand,
  isChild,
  disabled = false,
}) => {
  const { t } = useTranslation();

  const navClass = classnames('menu-item', {
    'menu-item_disabled': disabled,
  });

  const isActive = (match, location) => {
    if (subLinks.length) {
      return subLinks.some(item => !item.disabled && item.href === location.pathname);
    }

    return !disabled && location.pathname === href;
  };

  return (
    <NavLink
      className={navClass}
      activeClassName="menu-item_selected"
      to={!disabled && href}
      onClick={onClick}
      isActive={isActive}
      title={t(label)}
      data-testid={`menu${href}${isChild ? '/expanded' : ''}`}
    >
      <Icon className="menu-item__icon" />
      {
        menuExpand && (
          <span className="menu-item__label">
            {t(label)}
          </span>
        )
      }
      {
        !!subLinks.length && (
          <div className="menu-item__overlay">
            {
              subLinks.map(item => (
                <MenuItem
                  key={item.id}
                  {...item}
                  label={t(item.label)}
                  isChild
                  menuExpand
                />
              ))
            }
          </div>
        )
      }
    </NavLink>
  );
};

export default MenuItem;
