import {
  AbstractDisplacementState,
  Action,
  InputType,
  BasePositionModel,
} from '@projectstorm/react-canvas-core';
import { DefaultNodeModel } from '@projectstorm/react-diagrams';
/*eslint-disable */
export default class MoveItemsState extends AbstractDisplacementState {
  constructor() {
    super({
      name: 'move-items',
    });
    this.registerAction(
      new Action({
        type: InputType.MOUSE_DOWN,
        fire: (event) => {
          this.moved = false;
          const element = this.engine.getActionEventBus().getModelForEvent(event);

          if (!element) {
            return;
          }

          if (!element.isSelected()) {
            this.engine.getModel().clearSelection();
          }
          element.setSelected(true);
          this.engine.repaintCanvas();
        },
      }),
    );
    this.registerAction(
      new Action({
        type: InputType.MOUSE_MOVE,
        fire: () => {
          this.moved = true;
        },
      }),
    );
    this.registerAction(
      new Action({
        type: InputType.MOUSE_UP,
        fire: (event) => {
          const element = this.engine.getActionEventBus().getModelForEvent(event);
          if (!this.moved && event.event.target.dataset.id !== 'delete-button' && event.event.target.dataset.id !== 'clone-button') {
            if (element instanceof DefaultNodeModel) {
              this.engine.fireEvent({ node: element }, 'onClickNode');
            }
            this.engine.getModel().clearSelection();
          } else if (!this.engine.getModel().isLocked()) {
            this.engine.getModel().fireEvent({ node: element }, 'nodeMoved');
            this.engine.getModel().clearSelection();
          }
        },
      }),
    );
  }

  activated(previous) {
    super.activated(previous);
    this.initialPositions = {};
  }

  fireMouseMoved(event) {
    const items = this.engine.getModel().getSelectedEntities();
    const model = this.engine.getModel();

    items.forEach((item) => {
      if (item instanceof BasePositionModel) {
        if (item.isLocked()) {
          return;
        }
        if (!this.initialPositions[item.getID()]) {
          this.initialPositions[item.getID()] = {
            point: item.getPosition(),
            item,
          };
        }

        const pos = this.initialPositions[item.getID()].point;
        item.setPosition(
          model.getGridPosition(pos.x + event.virtualDisplacementX),
          model.getGridPosition(pos.y + event.virtualDisplacementY),
        );
      }
    });

    this.engine.repaintCanvas();
  }
}
