import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';

import {
  getFlowsNewAll as getFlowsAllApi,
  getFlowsNew as getFlowsApi,
  getFlowById as getFlowByIdApi,
  postFlowNew as createFlowApi,
  deleteFlowNew as deleteFlowApi,
  putFlow as putFlowApi,
  postCloneFlow as postCloneFlowApi,
  getFlowToggleModerate as getFlowToggleModerateApi,
  postImportFlow as postImportFlowApi,
} from 'queries/flows';

import {
  getArray,
  getObject,
  useISESelector,
} from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetFlows,
      lastUpdated: lastUpdatedGetFlows,
    },
    getFlows,
  ] = useMutation(getFlowsApi);
  const [
    {
      isPending: isPendingGetFlowsAll,
    },
    getFlowsAll,
  ] = useMutation(getFlowsAllApi);
  const [
    {
      isPending: isPendingDeleteFlow,
    },
    deleteFlow,
  ] = useMutation(deleteFlowApi);
  const [
    {
      isPending: isPendingCreateFlow,
    },
    createFlow,
  ] = useMutation(createFlowApi);
  const [
    {
      isPending: isPendingPutFlow,
    },
    putFlow,
  ] = useMutation(putFlowApi);
  const [
    {
      isPending: isPendingPostCloneFlow,
    },
    postCloneFlow,
  ] = useMutation(postCloneFlowApi);
  const [
    {
      isPending: isPendingGetFlowById,
    },
    getFlowById,
  ] = useMutation(getFlowByIdApi);
  const [
    {
      isPending: isPendingGetFlowToggleModerate,
    },
    getFlowToggleModerate,
  ] = useMutation(getFlowToggleModerateApi);
  const [
    {
      isPending: isPendingPostImportFlow,
    },
    postImportFlow,
  ] = useMutation(postImportFlowApi);

  const selector = useCallback(state => ({
    flows: getArray(state, 'flows'),
    flowsMeta: getObject(state, 'flowsMeta'),
    flowsAll: getArray(state, 'flowsAll'),
    flowById: getObject(state, 'flowById'),
  }), []);
  const data = useISESelector(selector);

  return {
    getFlows,
    deleteFlow,
    createFlow,
    getFlowById,
    getFlowToggleModerate,
    putFlow,
    postCloneFlow,
    postImportFlow,
    isPendingGetFlows,
    isPendingDeleteFlow,
    isPendingCreateFlow,
    isPendingPutFlow,
    isPendingGetFlowById,
    isPendingGetFlowToggleModerate,
    lastUpdatedGetFlows,
    getFlowsAll,
    isPendingGetFlowsAll,
    isPendingPostCloneFlow,
    isPendingPostImportFlow,
    ...data,
  };
};
