import { isBoolean } from 'lodash';

import actions from 'const/actions';

const initialState = {
  open: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.TOGGLE_SELECT_MEDIA_DIALOG:
      return {
        ...state,
        open: isBoolean(action.state) ? action.state : !state.open,
      };
    default:
      return state;
  }
};
