import React from 'react';
import { Form } from 'antd';
import classNames from 'classnames';
import './FormItem.scss';

const FormItem = ({ className, ...props }) => (
  <Form.Item
    className={classNames(className, 'form-item')}
    {...props}
  />
);

export default FormItem;
