import { useRef, useState, useEffect } from 'react';

export default (checkCloseDropdown = false) => {
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const openDropdown = () => setDropdownOpen(true);
  const closeDropdown = () => setDropdownOpen(false);
  const toggleDropdown = () => setDropdownOpen(!isDropdownOpen);

  useEffect(() => {
    const handleOuterDropdownClick = (e) => {
      if (dropdownRef && dropdownRef.current
        && ((dropdownRef.current.contains(e.target)
            || buttonRef.current && buttonRef.current.contains(e.target))
            || (checkCloseDropdown && checkCloseDropdown())
        )) {
        return;
      }
      setDropdownOpen(false);
    };

    document.addEventListener('mousedown', handleOuterDropdownClick, false);

    return () => {
      document.removeEventListener('mousedown', handleOuterDropdownClick, false);
    };
  }, [checkCloseDropdown && checkCloseDropdown()]);

  return {
    dropdownRef,
    buttonRef,
    isDropdownOpen,
    setDropdownOpen,
    toggleDropdown,
    closeDropdown,
    openDropdown,
  };
};
