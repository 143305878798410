import endpoints from 'const/endpoints';

export default ({
  errorCallback = Function.prototype,
  successCallback = Function.prototype,
} = {}) => ({
  url: endpoints.getFlowsNewAllUrl(),
  queryKey: endpoints.getFlowsNewAllUrl(),
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
    successCallback,
    errorCallback,
  },
  transform: response => ({ flowsAll: response }),
  update: {
    flowsAll: (oldValue, newValue) => newValue,
  },
});
