import React, { useMemo, useState, useEffect } from 'react';
import classNames from 'classnames';

import { useDropdownLogic } from 'hooks';
import chevronWhiteIcon from 'assets/icons/chevron-white.svg';

import './style.scss';

const TimeDropdown = ({
  value,
  label,
  options,
  onChange,
  withSearch,
}) => {
  const {
    buttonRef,
    dropdownRef,
    isDropdownOpen,
    openDropdown,
    closeDropdown,
    toggleDropdown,
  } = useDropdownLogic();

  const [isAll, setIsAll] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const containerClassName = classNames('time-dropdown', {
    'time-dropdown_open': isDropdownOpen,
  });

  const valueDisplayed = useMemo(() => {
    const foundOption = options.find(item => item.value === value);
    return foundOption ? foundOption.label : '';
  }, [value, options]);
  const optionsDisplayed = useMemo(() => {
    if (isAll || !withSearch) {
      return options;
    }

    if (searchValue) {
      const searchValueLowerCase = searchValue.toLowerCase();
      return options.filter(item => item.label.toLowerCase().includes(searchValueLowerCase));
    }
    return options;
  }, [searchValue, options, isAll]);

  const onChangeSearchInput = (event) => {
    setSearchValue(event.target.value);

    if (isAll) {
      setIsAll(false);
    }
  };
  const onFocusSearchInput = () => {
    if (!isDropdownOpen) {
      if (isAll) {
        setIsAll(false);
      }

      openDropdown();
    }
  };
  const handleOnChange = (id) => {
    onChange(id);
    closeDropdown();
  };
  const onButtonClick = () => {
    toggleDropdown();
    setIsAll(true);
  };

  useEffect(() => {
    if (value && options.length) {
      const foundOption = options.find(item => item.value === value);

      if (foundOption) {
        setSearchValue(foundOption.label);
      }
    }
  }, [value, options]);

  return (
    <div className={containerClassName}>
      <div className="time-dropdown__label">
        {label}
      </div>
      {
        withSearch ? (
          <div className="time-dropdown__input" ref={buttonRef}>
            <input
              className="time-dropdown__input__search-input"
              value={searchValue}
              onChange={onChangeSearchInput}
              onFocus={onFocusSearchInput}
            />
            <img
              role="presentation"
              className="time-dropdown__input__icon"
              alt="clock"
              src={chevronWhiteIcon}
              onClick={onButtonClick}
            />
            <div
              role="presentation"
              className="time-dropdown__input__overlay"
              ref={dropdownRef}
            >
              {
                optionsDisplayed.map(item => (
                  <button
                    type="button"
                    className="time-dropdown__input__overlay__item"
                    onClick={() => handleOnChange(item.value)}
                  >
                    <div className="time-dropdown__input__overlay__item-text">
                      {item.label}
                    </div>
                  </button>
                ))
              }
            </div>
          </div>
        ) : (
          <button
            type="button"
            ref={buttonRef}
            className="time-dropdown__input"
            onClick={toggleDropdown}
          >
            <div className="time-dropdown__input__value">
              {valueDisplayed}
            </div>
            <img
              className="time-dropdown__input__icon"
              alt="clock"
              src={chevronWhiteIcon}
            />
            <div
              role="presentation"
              className="time-dropdown__input__overlay"
              ref={dropdownRef}
            >
              {
                optionsDisplayed.map(item => (
                  <button
                    type="button"
                    className="time-dropdown__input__overlay__item"
                    onClick={() => handleOnChange(item.value)}
                  >
                    <div className="time-dropdown__input__overlay__item-text">
                      {item.label}
                    </div>
                  </button>
                ))
              }
            </div>
          </button>
        )
      }
    </div>
  );
};

export default TimeDropdown;
