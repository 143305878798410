import React, { useState, useMemo } from 'react';
import { Form, Field } from 'react-final-form';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import { BasicButton } from 'components/buttons';
import { SearchField, CheckboxField } from 'components/fields';
import saveIcon from 'assets/icons/save.svg';
import crossIcon from 'assets/icons/cross-grey.svg';

import './style.scss';

const CustomColumnsView = ({
  columnsNames = [],
  onSubmit = () => {},
  onCancel,
  initialValues: externalInitialValues,
}) => {
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState('');

  const initialValues = useMemo(
    () => {
      if (isEmpty(externalInitialValues)) {
        return columnsNames.reduce((acc, { field }) => ({ ...acc, [field]: true }), {});
      }

      return columnsNames.reduce((acc, { field }) =>
        ({
          ...acc,
          [field]: externalInitialValues[field] ?? true,
        }), {});
    },
    [externalInitialValues, columnsNames],
  );
  const filteredColumns = useMemo(
    () => columnsNames.filter(el => el.headerName
      .toLowerCase()
      .includes(searchValue.toLowerCase())),
    [searchValue, columnsNames],
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={
        ({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className="mkit-custom-view-wrapper">
            <div className="mkit-custom-view-wrapper__header">
              <h1>
                {t('TABLE.COLUMNS_TO_SHOW')}
              </h1>
              <span className="mkit-custom-view-wrapper__header__subheader">
                {t('TABLE.CAN_CUSTOMIZE')}
              </span>
            </div>
            <div className="mkit-custom-view-wrapper__content-wrapper">
              <SearchField
                input={{
                  onChange: setSearchValue,
                  name: 'search',
                }}
                placeholder={t('NEW_TRANSLATES.PLACEHOLDERS.SEARCH_SOMETHING')}
              />
              <div className="mkit-custom-view-wrapper__content-wrapper__values-list">
                {filteredColumns.map(item => (
                  <Field
                    key={item.field}
                    name={item.field}
                    component={CheckboxField}
                    label={item.headerName}
                    wrapperStyle={{ marginBottom: '10px' }}
                  />
                ))}
              </div>
            </div>
            <div className="mkit-custom-view-wrapper__buttons-wrapper">
              <BasicButton
                text={t('CONTROLS.CANCEL')}
                type={BasicButton.types.CANCEL}
                icon={crossIcon}
                isNewStyle
                onClick={onCancel}
              />
              <BasicButton
                text={t('CONTROLS.SAVE')}
                type={BasicButton.types.ACCENT}
                icon={saveIcon}
                onClick={handleSubmit}
                isNewStyle
              />
            </div>
          </form>
        )
      }
    />
  );
};

export default CustomColumnsView;
