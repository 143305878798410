import endpoints from 'const/endpoints';

export default ({
  limit = 10,
  offset = 0,
  errorCallback = Function.prototype,
  ...queries
} = {}) => ({
  url: endpoints.getTemplatesUrl(),
  queryKey: endpoints.getTemplatesUrl(),
  body: {
    limit,
    offset: offset ? ((offset - 1) * limit) : offset,
    ...queries,
  },
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
    errorCallback,
  },
  transform: (response) => {
    const {
      content,
      ...other
    } = response;

    return {
      templates: content,
      templatesPagination: other,
    };
  },
  update: {
    templates: (oldValue, newValue) => newValue,
    templatesPagination: (oldValue, newValue) => newValue,
  },
});
