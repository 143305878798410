import React, {
  useState,
  useMemo,
  useReducer,
  useEffect,
} from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import ConstructorEditor from 'mkit-front-ds/dist/components/constructor/ConstructorEditor';
import convertTemplateToConstructorFormat from 'mkit-front-ds/dist/helpers/converterTemplateToConstructorFormat';
import ConstructorErrorBoundary from 'mkit-front-ds/dist/components/constructor/ConstructorErrorBoundary';
import validateConstructorParams from 'mkit-front-ds/dist/helpers/validateConstructorParams';
import ElementConfigDrawer from 'mkit-front-ds/dist/components/ElementConfigDrawer';
import convertConstructorContentToContentPattern from 'mkit-front-ds/dist/helpers/convertConstructorContentToContentPattern';
import { toast as toastClass } from 'react-toastify';

import {
  useSendersApi,
  useRecipientsApi,
  useWhatsAppTemplatesApi,
  useTemplatesApi,
} from 'hooks/api';
import {
  DropdownField,
  TimePickerField,
  DropdownSpecifyField,
} from 'components/fields';
import { BasicButton, IconButton } from 'components/buttons';
import { SendersDropdown } from 'components/constructor';
import LoadingSpinner from 'components/LoadingSpinner';
import {
  convertObjectTimeToSeconds,
  toast,
} from 'helpers';
import crossIcon from 'assets/icons/cross-grey.svg';
import disketteIcon from 'assets/icons/diskette.svg';
import arrowLeftIcon from 'assets/icons/left-arrow.svg';
import disketteDarkIcon from 'assets/icons/diskette-dark.svg';
import trashIcon from 'assets/icons/trash.svg';
import { error } from 'helpers/toast';
import './style.scss';

const initialWhatsAppParams = {
  selectedType: '',
  message: '',
};

const WhatsAppBlock = ({
  channel,
  onChange,
  onCancel,
  index,
  senderId,
  initialParams,
  withTools = true,
  isNewDesign = false,
  readOnly,
  deliveryCondition = 'DELIVERY_SUCCESS',
  constructorType = 'freeMessage',
  timeout = { hours: '00', minutes: '10', seconds: '00' },
  withShortLink,
  setShortLinks,
  channelNumber,
}) => {
  const { t } = useTranslation();
  const timeFieldStyle = {
    container: {
      width: '100%',
    },
    overlay: {
      top: 'unset',
      bottom: 36,
    },
  };
  const messageTypes = [
    {
      id: 1,
      value: 'freeMessage',
      title: t('MESSAGE_CONSTRUCTOR.FREE_MESSAGE'),
    },
    {
      id: 2,
      value: 'template',
      title: t('MESSAGE_CONSTRUCTOR.TEMPLATE'),
    },
  ];

  const headerButtonStyle = { minWidth: 20 };

  const {
    getSenders,
    senders,
    isPendingGetSenders,
  } = useSendersApi();
  const {
    getTemplates,
    templates,
    isPendingGetTemplates,
    postCreateTemplate,
    patchUpdateTemplate,
    deleteTemplate,
  } = useTemplatesApi();

  const { getApprovedTemplates, approvedTemplates } = useWhatsAppTemplatesApi();
  const { getAttributes, isPendingGetAttributes } = useRecipientsApi();

  const [sender, setSender] = useState(senderId || '');
  const [waitFor, setWaitFor] = useState(deliveryCondition);
  const [waitingTime, setWaitingTime] = useState(timeout);
  const [showError, setShowError] = useState(false);
  const [messageType, setMessageType] = useState(constructorType === 'template' ? { value: 2, isCustom: false } : { value: 1, isCustom: false });
  const [freeMessage, setFreeMessage] = useState('');
  const [approvedTemplate, setApprovedTemplate] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [openedDrawer, setOpenedDrawer] = useState('');
  const [selectedCard, setSelectedCard] = useState(0);
  const [editableSuggestion, setEditableSuggestion] = useState(null);

  const wrapperClass = classNames('whats-app-block__content-wrapper', {
    'whats-app-block__content-wrapper_new-design': isNewDesign,
    alwaysLightTheme: true,
  });

  const wrapperHeaderClass = classNames('whats-app-block__content-wrapper__header', {
    'whats-app-block__content-wrapper__header_new-design': isNewDesign,
  });

  const reducer = (state, { isReset, ...newValue }) => {
    if (isReset) {
      return newValue;
    }

    return ({
      ...state,
      ...newValue,
    });
  };
  const [rcsParams, updateRcsParams] = useReducer(reducer, initialParams || {
    suggestionsInside: [],
  });

  const sendersOptions = useMemo(() => senders.filter(I => I.channel === 4 && I.status === 2)
    .map(I => ({
      id: I.senderId,
      title: I.displayName,
    })), [senders]);
  const approvedTemplatesOptions = useMemo(() => approvedTemplates
    .filter(I => I.category !== 'AUTHENTICATION')
    .map(I => ({
      title: I.name,
      id: I.id,
      senderId: I.senderId,
    })), [approvedTemplates]);
  const templatesOptions = useMemo(() => templates.filter(I => I.channel === 4).map(I =>
    ({
      title: I.name,
      id: I.id,
    })), [templates]);
  const isWhatsAppTemplate = useMemo(() => messageType.value === 2, [messageType]);

  const resetType = () => {
    if (initialParams) {
      updateRcsParams({ isReset: true, ...initialParams });
    } else {
      updateRcsParams({ isReset: true, ...initialWhatsAppParams });
    }
  };
  const openCreateSuggestion = ({ place, initialValues, isReply }) => {
    if (initialValues?.category === 'mapAction' && initialValues?.query) {
      initialValues.by = true;
    }

    setOpenedDrawer(isWhatsAppTemplate
      ? 'suggestion-whatsapp'
      : 'suggestion');

    setEditableSuggestion({
      open: true,
      place,
      initialValues,
      isReply,
    });
  };
  const onApply = () => {
    if (!sender && withTools) {
      setShowError(true);
    } else {
      let saveAllowed = true;
      setShowError(false);

      let contentPattern = {};

      switch (rcsParams.selectedType) {
        case 'whatsAppMenu':
          if (!rcsParams?.body?.text || !rcsParams?.action?.sections.length) {
            saveAllowed = false;
            toast.error(t('NEW_TRANSLATES.TOAST_ERRORS.WHATSAPP_LIST_REQUIREMENTS'));
          }
          contentPattern = {
            recipient_type: 'individual',
            type: 'interactive',
            interactive: {
              type: 'list',
              ...rcsParams,
            },
          };
          break;
        case 'whatsAppText':
          if (messageType.value === 1) {
            if (rcsParams?.text?.body) {
              contentPattern = {
                preview_url: true,
                recipient_type: 'individual',
                type: 'text',
                text: {
                  body: rcsParams?.text?.body,
                },
              };
            } else {
              saveAllowed = false;
              toast.error(t('NEW_TRANSLATES.TOAST_ERRORS.WHATSAPP_TEXT_REQUIREMENTS'));
            }
          } else {
            contentPattern = {
              preview_url: true,
              recipient_type: 'individual',
              type: 'text',
              text: {
                body: rcsParams.message,
              },
            };
          }
          break;
        case 'whatsAppFile': {
          if (messageType.value === 1) {
            if (!rcsParams?.image
                && !rcsParams?.document
                && !rcsParams?.video
                && !rcsParams?.audio
            ) {
              saveAllowed = false;
              toast.error(t('NEW_TRANSLATES.TOAST_ERRORS.WHATSAPP_FILE_REQUIREMENTS'));
            } else {
              let type = 'document';
              if (rcsParams?.image) {
                type = 'image';
              }
              if (rcsParams?.video) {
                type = 'video';
              }
              if (rcsParams?.audio) {
                type = 'audio';
              }
              contentPattern = {
                type,
                ...rcsParams,
              };
            }
          } else {
            const mediaType = rcsParams.image.mediaType === 'Image' || rcsParams.image.mediaType === 'Video'
              ? rcsParams.image.mediaType.toLowerCase()
              : 'document';

            contentPattern = {
              recipient_type: 'individual',
              type: mediaType,
              [mediaType]: {
                link: rcsParams.image.fileUrl,
              },
            };
          }
          break;
        }
        case 'whatsAppCard': {
          if (rcsParams?.body?.text && (rcsParams?.suggestionsInside && rcsParams?.suggestionsInside.length)) {
            const rcsParamsCopy = { ...rcsParams };
            const copyOfSuggestions = rcsParamsCopy.suggestionsInside;
            const buttons = copyOfSuggestions.map(I => ({
              type: 'reply',
              reply: {
                id: `ID: ${I.id}`,
                title: I.text,
              },
            }));
            rcsParamsCopy.action = { buttons };
            delete rcsParamsCopy.suggestionsInside;

            contentPattern = {
              recipient_type: 'individual',
              type: 'interactive',
              interactive: {
                type: 'button',
                ...rcsParamsCopy,
              },
            };
          } else {
            saveAllowed = false;
            toast.error(t('NEW_TRANSLATES.TOAST_ERRORS.WHATSAPP_CARD_REQUIREMENTS'));
          }
          break;
        }
        case 'whatsAppSendGeolocation':
          if (rcsParams?.body?.text) {
            contentPattern = {
              recipient_type: 'individual',
              type: 'interactive',
              interactive: {
                ...rcsParams,
              },
            };
          } else {
            saveAllowed = false;
            toast.error(t('NEW_TRANSLATES.TOAST_ERRORS.WHATSAPP_TEXT_REQUIREMENTS'));
          }
          break;
        case 'whatsAppGetGeolocation':
          if (rcsParams?.location?.latitude && rcsParams?.location?.longitude) {
            contentPattern = {
              recipient_type: 'individual',
              type: 'location',
              location: { ...rcsParams.location },
            };
          } else {
            saveAllowed = false;
            toast.error(t('NEW_TRANSLATES.TOAST_ERRORS.WHATSAPP_GEOLOCATION_REQUIREMENTS'));
          }
          break;
        case 'whatsAppRichCard':
        case 'whatsAppCarouselCard':
        case 'whatsAppCouponCodeCard':
        case 'whatsAppLimitedTimeOfferCard': {
          const errorMessage = validateConstructorParams(rcsParams);
          if (errorMessage) {
            saveAllowed = false;
            toast.error(t(errorMessage));
          } else {
            contentPattern = {
              ...convertConstructorContentToContentPattern({
                channel: 4,
                content: rcsParams,
                isForBroadcast: true,
              }),
            };
          }
          break;
        }
        default:
          saveAllowed = false;
          toast?.error(t('NEW_TRANSLATES.TOAST_ERRORS.SELECT_MESSAGE_TYPE'));
          break;
      }
      if (saveAllowed) {
        onChange({
          index,
          channelType: 4,
          contentPattern,
          deliveryCondition: waitFor,
          order: 0,
          senderId: sender,
          timeout: convertObjectTimeToSeconds(waitingTime),
        });
      }
    }
  };
  const onChangeMessageType = (value) => {
    updateRcsParams({ isReset: true });
    setApprovedTemplate('');
    setFreeMessage('');
    setSelectedTemplate(null);
    setMessageType(value);
  };
  const onChangeWhatsAppTemplate = (id, componentsParams = {}, mode) => {
    let templateParams = {};
    const currentMessageType = mode || messageType;

    if (id) {
      templateParams = (currentMessageType.value === 2
        ? approvedTemplates
        : templates
      ).find(I => I.id === id);
      setSelectedTemplate(id);
    }
    if (currentMessageType.value === 2) {
      setSender(templateParams.senderId);
      updateRcsParams({
        isReset: true,
        namespace: templateParams.namespace,
        language: {
          policy: 'deterministic',
          code: templateParams.language,
        },
        name: templateParams.name,
        components: templateParams.components,
        ...convertTemplateToConstructorFormat({
          components: templateParams.components,
          category: templateParams.category,
          useEmptyValues: isEmpty(channel),
        }),
      });
    } else {
      const templateContent = templateParams?.content ? JSON.parse(templateParams?.content) : {};
      let channelCopy = {
        ...templateContent,
        ...componentsParams,
      };
      if (templateContent.interactive) {
        channelCopy = { ...templateContent.interactive };
      }
      if (!(templateContent.interactive?.action?.sections || channelCopy.action?.sections) && !channelCopy.suggestionsInside) {
        channelCopy.suggestionsInside = channelCopy.action?.buttons?.map(I => ({
          id: I.reply.id,
          text: I.reply.title,
          suggestionType: I.type,
        }));
        delete channelCopy.action;
      }
      updateRcsParams({ isReset: true, ...channelCopy });
    }
  };
  const onChangeSender = (value) => {
    if (value) {
      setShowError(false);
    }
    setSender(value);
  };
  const onSaveTemplate = () => {
    let templateType;
    let saveAllowed = true;
    let rcsParamsCopy = { ...rcsParams };

    switch (rcsParams.selectedType) {
      case 'whatsAppMenu':
        if (!rcsParams?.body?.text || !rcsParams?.action?.sections.length) {
          saveAllowed = false;
          error(t('NEW_TRANSLATES.TOAST_ERRORS.WHATSAPP_LIST_REQUIREMENTS'));
        }
        templateType = 'List';
        rcsParamsCopy = {
          recipient_type: 'individual',
          type: 'interactive',
          interactive: {
            type: 'list',
            ...rcsParamsCopy,
          },
        };
        break;
      case 'whatsAppCard':
        if (rcsParams?.body?.text && (rcsParams?.suggestionsInside && rcsParams?.suggestionsInside.length)) {
          const copyOfSuggestions = rcsParamsCopy.suggestionsInside;
          const buttons = copyOfSuggestions.map(I => ({
            type: 'reply',
            reply: {
              id: I.id,
              title: I.text,
            },
          }));
          rcsParamsCopy.action = { buttons };
          delete rcsParamsCopy.suggestionsInside;
          rcsParamsCopy = {
            recipient_type: 'individual',
            type: 'interactive',
            interactive: {
              type: 'button',
              ...rcsParamsCopy,
            },
          };
          templateType = 'Card';
        } else {
          saveAllowed = false;
          error(t('NEW_TRANSLATES.TOAST_ERRORS.WHATSAPP_CARD_REQUIREMENTS'));
        }
        break;
      case 'whatsAppText':
        if (!rcsParams?.text?.body) {
          saveAllowed = false;
          error(t('NEW_TRANSLATES.TOAST_ERRORS.WHATSAPP_TEXT_REQUIREMENTS'));
        } else {
          templateType = 'Text';
          rcsParamsCopy = {
            recipient_type: 'individual',
            type: 'text',
            ...rcsParamsCopy,
          };
        }
        break;
      case 'whatsAppSendGeolocation':
        if (!rcsParams?.body?.text) {
          saveAllowed = false;
          error(t('NEW_TRANSLATES.TOAST_ERRORS.WHATSAPP_TEXT_REQUIREMENTS'));
        } else {
          templateType = 'Location';
          rcsParamsCopy = {
            recipient_type: 'individual',
            type: 'interactive',
            interactive: {
              ...rcsParamsCopy,
            },
          };
        }

        break;
      case 'whatsAppGetGeolocation':
        if (!rcsParams?.location?.latitude && !rcsParams?.location?.longitude) {
          saveAllowed = false;
          error(t('NEW_TRANSLATES.TOAST_ERRORS.WHATSAPP_GEOLOCATION_REQUIREMENTS'));
        } else {
          templateType = 'Location';
          rcsParamsCopy = {
            recipient_type: 'individual',
            type: 'location',
            ...rcsParamsCopy,
          };
        }
        break;
      case 'whatsAppFile':
        if (!rcsParams?.image
            && !rcsParams?.document
            && !rcsParams?.video
            && !rcsParams?.audio
        ) {
          saveAllowed = false;
          error(t('NEW_TRANSLATES.TOAST_ERRORS.WHATSAPP_FILE_REQUIREMENTS'));
        } else {
          templateType = 'File';
          let type = 'document';
          if (rcsParams?.image) {
            type = 'image';
          }
          if (rcsParams?.video) {
            type = 'video';
          }
          if (rcsParams?.audio) {
            type = 'audio';
          }
          rcsParamsCopy = {
            recipient_type: 'individual',
            type,
            ...rcsParamsCopy,
          };
        }
        break;
      default:
        saveAllowed = false;
        error(t('NEW_TRANSLATES.TOAST_ERRORS.SELECT_MESSAGE_TYPE'));
        break;
    }

    if (saveAllowed) {
      const templateParams = typeof freeMessage.value === 'number' ? templates.find(I => I.id === selectedTemplate) : {
        channel: 4,
        name: freeMessage.value,
      };
      const templateBody = {
        channel: templateParams.channel,
        content: JSON.stringify(rcsParamsCopy),
        name: templateParams.name,
        type: templateType,
      };
      if (freeMessage.isCustom) {
        postCreateTemplate(templateBody)
          .then(() => {
            getTemplates({ limit: 1000 });
          });
      } else {
        patchUpdateTemplate({
          ...templateBody,
          id: freeMessage.value,
        }).then(() => {
          getTemplates({ limit: 1000 });
        });
      }
    }
  };
  const onDeleteTemplate = () => {
    deleteTemplate(selectedTemplate)
      .then(() => {
        setSelectedTemplate(null);
        setFreeMessage('');
        updateRcsParams({ isReset: true });
        getTemplates({
          limit: 1000,
        });
      });
  };

  const closeDrawer = () => {
    setEditableSuggestion(null);
    setOpenedDrawer('');
  };

  const getConfigInput = () => (messageType.value === 2 ? {
    value: approvedTemplate,
    onChange: setApprovedTemplate,
  } : {
    value: freeMessage,
    onChange: setFreeMessage,
  });

  useEffect(() => {
    const idTemplate = messageType.value === 2 ? approvedTemplate.value : freeMessage.value;
    if (typeof idTemplate === 'number') {
      onChangeWhatsAppTemplate(idTemplate, channel);
    } else if (messageType.value === 1) {
      onChangeWhatsAppTemplate(undefined, rcsParams);
    }
  }, [freeMessage, approvedTemplate]);

  useEffect(() => {
    if (!isEmpty(approvedTemplate)) {
      const foundItem = approvedTemplatesOptions.find(I => I.title === approvedTemplate.value
          || I.id === freeMessage.value);

      if (foundItem) {
        setApprovedTemplate({ value: foundItem.id, isCustom: false });
      } else {
        setApprovedTemplate({});
      }
    }
    if (!isEmpty(freeMessage)) {
      const foundItem = templatesOptions.find(I => I.title === freeMessage.value
          || I.id === freeMessage.value);

      if (foundItem) {
        setFreeMessage({ value: foundItem.id, isCustom: false });
      } else {
        setFreeMessage({});
      }
    }
  }, [approvedTemplatesOptions, templatesOptions]);

  useEffect(() => {
    if (channel
        && !channel.name
        && !rcsParams?.text?.body
        && !rcsParams?.interactive?.header?.text
        && !rcsParams?.interactive?.body?.text
        && !rcsParams?.interactive?.footer?.text
    ) {
      updateRcsParams({ isReset: true, ...channel });
    }
  }, [channel]);

  useEffect(() => {
    if ((channel?.selectedType === 'whatsAppSendGeolocation'
            || channel?.selectedType === 'whatsAppCard'
            || channel?.selectedType === 'whatsAppText'
            || channel?.selectedType === 'whatsAppFile'
            || channel?.selectedType === 'whatsAppMenu')
        && templates.length
    ) {
      setMessageType({ value: 1, isCustom: false });
      onChangeWhatsAppTemplate(
        channel?.templateId,
        channel,
        { value: 1, isCustom: false },
      );
    } else if (channel?.name && approvedTemplates.length && senders.length) {
      setMessageType({ value: 2, isCustom: false });
      const approvedTemplateId = approvedTemplates.find(I => I.name === channel.name)?.id;
      if (approvedTemplateId) {
        setApprovedTemplate({ value: approvedTemplateId, isCustom: false });
        onChangeWhatsAppTemplate(
          approvedTemplateId,
          channel,
          { value: 2, isCustom: false },
        );
      } else if (!toastClass.isActive('deletedTemplate')) {
        toast.error(t('ERRORS.DELETED_WHATS_APP_TEMPLATE'), { toastId: 'deletedTemplate' });
      }
    }
  }, [channel?.name, approvedTemplates, templates, senders]);

  useEffect(() => {
    getSenders({ limit: 1000, onlyActiveSenders: false });
    getAttributes({ variablesOnly: true });
    getApprovedTemplates({
      limit: 1000,
    });
    getTemplates({
      limit: 1000,
    });
  }, []);

  if (isPendingGetTemplates || isPendingGetSenders || isPendingGetAttributes) {
    return <LoadingSpinner fullSize />;
  }

  return (
    <div className="whats-app-block">
      <h2 className="whats-app-block__header">
        {t('SCREENS.BROADCASTS.WHATSAPP_MESSAGE')}
      </h2>
      <div style={{ display: 'flex' }}>
        <div className="whats-app-block__dropdown-fields">
          <div className="whats-app-block__dropdown-specify-field">
            <span className="dropdown-specify-field__label">
              {t('SCREENS.BROADCASTS.TYPE')}
            </span>
            <DropdownSpecifyField
              input={{
                value: messageType,
                onChange: onChangeMessageType,
              }}
              items={messageTypes}
              style={{ width: 316 }}
              disabled={readOnly}
            />
          </div>
          <div className="whats-app-block__dropdown-specify-field">
            <span className="dropdown-specify-field__label">
              {t('SCREENS.BROADCASTS.TEMPLATE')}
            </span>
            <DropdownSpecifyField
              input={getConfigInput()}
              placeholder={isWhatsAppTemplate ? '' : t('SCREENS.BROADCASTS.TEMPLATE')}
              items={isWhatsAppTemplate ? approvedTemplatesOptions : templatesOptions}
              style={{ width: 316 }}
              disabled={readOnly}
            />
          </div>
        </div>
        <div className="whats-app-block__btn-icon">
          <IconButton
            icon={disketteDarkIcon}
            onClick={onSaveTemplate}
            isDisabled={isEmpty(freeMessage) || (isWhatsAppTemplate) || readOnly}
            filterType="white"
          />
          <IconButton
            icon={trashIcon}
            onClick={onDeleteTemplate}
            isDisabled={isEmpty(freeMessage) || freeMessage.isCustom || (isWhatsAppTemplate) || readOnly}
            filterType="white"
          />
        </div>
      </div>
      <div className={wrapperClass}>
        <div className={wrapperHeaderClass}>
          {rcsParams.selectedType
            && rcsParams.selectedType !== 'whatsAppTemplate'
            && !(isWhatsAppTemplate || readOnly)
            && (
              <BasicButton
                onClick={resetType}
                disabled={readOnly}
                style={headerButtonStyle}
                icon={arrowLeftIcon}
              />
            )}
          {
            withTools && (
              <SendersDropdown
                values={sendersOptions}
                value={sender}
                onChange={onChangeSender}
                showError={showError}
                disabled={isWhatsAppTemplate || readOnly}
                isNewDesign={messageType.value === 1}
                channel="whats-app"
              />
            )
          }
        </div>
        <ConstructorErrorBoundary>
          <ConstructorEditor
            rcsParams={rcsParams}
            updateRcsParams={updateRcsParams}
            channel={channelNumber}
            readOnly={isWhatsAppTemplate || readOnly}
            openCreateSuggestion={openCreateSuggestion}
            openMediaSelect={() => setOpenedDrawer('media')}
            openGeolocation={() => setOpenedDrawer('geolocation')}
            withShortLink={withShortLink}
            setShortLinks={setShortLinks}
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
            emptyTemplateMessage={isWhatsAppTemplate
              && !selectedTemplate}
            enableValueEntry
          />
        </ConstructorErrorBoundary>
        {
          isWhatsAppTemplate && !selectedTemplate && (
            <div className="whats-app-block__disabler" />
          )
        }
      </div>
      {
        withTools && (
          <>
            <div className="whats-app-block__time-tools" style={{ marginTop: 24 }}>
              <span className="whats-app-block__label">
                {t('SCREENS.BROADCASTS.WAIT_FOR')}
              </span>
              <DropdownField
                input={{
                  value: waitFor,
                  onChange: setWaitFor,
                }}
                type="grey-border"
                values={[
                  {
                    id: 'DELIVERY_SUCCESS',
                    title: t('NEW_TRANSLATES.INSTANCES.MESSAGE_CONSTRUCTOR.DELIVERY_SUCCESS'),
                  },
                  {
                    id: 'SUBMIT_SUCCESS',
                    title: t('NEW_TRANSLATES.INSTANCES.MESSAGE_CONSTRUCTOR.SUBMIT_SUCCESS'),
                  },
                  {
                    id: 'DISPLAY_SUCCESS',
                    title: t('NEW_TRANSLATES.INSTANCES.MESSAGE_CONSTRUCTOR.DISPLAY_SUCCESS'),
                  },
                ]}
                isDisabled={readOnly}
                withoutCallbackDestroy
              />
            </div>
            <div className="whats-app-block__time-tools">
              <span className="whats-app-block__label">
                {t('SCREENS.BROADCASTS.WAITING_TIME')}
              </span>
              <TimePickerField
                input={{
                  value: waitingTime,
                  onChange: setWaitingTime,
                }}
                styles={timeFieldStyle}
                typeOverlay="primary-color"
                withTimezone={false}
                disabled={readOnly}
              />
            </div>
          </>
        )
      }
      <div className="whats-app-block__buttons">
        <BasicButton
          text={t('CONTROLS.CANCEL')}
          type={BasicButton.types.CANCEL}
          icon={crossIcon}
          onClick={onCancel}
          isNewStyle
        />
        <BasicButton
          text={t('CONTROLS.SAVE')}
          type={BasicButton.types.ACCENT}
          icon={disketteIcon}
          onClick={onApply}
          disabled={
            !rcsParams.selectedType
            || (rcsParams.selectedType === 'whatsAppTemplate'
              && isEmpty(rcsParams.components))
          }
        />
      </div>
      <ElementConfigDrawer
        isOpen={!!openedDrawer}
        configType={openedDrawer}
        closeDrawer={closeDrawer}
        constructorContent={rcsParams}
        updateConstructorContent={updateRcsParams}
        editableSuggestion={editableSuggestion}
        selectedCard={selectedCard}
        channelType={4}
        readOnly={isWhatsAppTemplate || readOnly}
        whatsAppMessageType={isWhatsAppTemplate ? 'template' : 'freeMessage'}
        enableValueEntryForWhatsApp
      />
    </div>
  );
};

export default WhatsAppBlock;
