/* eslint-disable */
import React from "react";
import InfographicsBase from "./InfographicsBase";
import { useTranslation } from 'react-i18next';
import img from "assets/icons/people.svg";

export default (props) => {
  const { t } = useTranslation();

  return (
    <InfographicsBase {...props} img={img} filterType="white" title={t('INSTANCES.CAMPAIGNS.RECIPIENTS')} />
  )
}
