import endpoints from 'const/endpoints';

export default ({
  body, successCallback = Function.prototype, errorCallback = Function.prototype,
}) => ({
  url: endpoints.getWhatsAppUrl(),
  body,
  options: {
    method: 'POST',
  },
  meta: {
    authToken: true,
    successCallback,
    errorCallback,
  },
});
