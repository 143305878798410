import React from 'react';

import './style.scss';

const RcsTypeButton = ({
  onClick,
  style,
  text,
  icon,
  disabled,
}) => (
  <button
    className="mkit-rcs-type-button"
    type="button"
    onClick={onClick}
    style={style}
    disabled={disabled}
  >
    <img src={icon} alt="" className="rcs-type-button__icon" />
    {text}
  </button>
);

export default RcsTypeButton;
