import React from 'react';
import TrafficStatistic from 'mkit-front-ds/dist/screens/TrafficStatistic';

import './style.scss';

const AuditScreen = () => (
  <div className="traffic-statistics-screen-container">
    <TrafficStatistic />
  </div>
);

export default AuditScreen;
