import React, { useMemo } from 'react';
import { Button } from 'antd';

/*eslint-disable */
import { useTheme } from 'hooks';
import i18n from 'i18next';
import uikit from 'components/UIKit';
import {
  CAMPAIGN_CHANNEL_IMAGES,
  CAMPAIGN_CHANNEL_ACTION_TYPE,
} from 'const';
import { useDictionariesApi } from 'hooks/api';
import CampaignChannelAction from '../CampaignChannel/CampaignChannelAction';

import './CampaignEditFallback.scss';

const { Dropdown } = uikit;

const channels = [
  {
    title: 'SMS',
    type: 1,
  },
  {
    title: 'RCS',
    type: 2,
  },
  {
    title: 'Viber',
    type: 3,
  },
  {
    title: 'WhatsApp',
    type: 4,
  },
];

const CampaignEditFallbackChannelAdd = ({ addChannel, flowSteps }) => {
  const { isDarkTheme } = useTheme();
  const { availableChannelsOptions } = useDictionariesApi();

  const menuItems = useMemo(() =>
      availableChannelsOptions.map(({ label, value }) => ({
        text: i18n.language !== 'en' ? ` ${label}` : (value === 1 || value === 7) ? ` ${label}` : ` ${label} messenger`,
        icon: CAMPAIGN_CHANNEL_IMAGES[value]?.(isDarkTheme),
        action: () => addChannel({
          channelType: value,
        }),
      })),
    [flowSteps]);

  return (
    <Dropdown menu={menuItems} trigger="click">
      <Button type="link" className="campaign-create-channel">
        <CampaignChannelAction type={CAMPAIGN_CHANNEL_ACTION_TYPE.add} text="New channel" />
      </Button>
    </Dropdown>
  );
};

export default CampaignEditFallbackChannelAdd;
