import React from 'react';
import BroadcastsScreenSource from 'mkit-front-ds/dist/screens/BroadcastsScreen';

import styles from './BroadcastsScreen.module.scss';

const BroadcastsScreen = ({ history }) => (
  <div className={styles.screen}>
    <BroadcastsScreenSource history={history} />
  </div>
);

export default BroadcastsScreen;
