import React from 'react';
import SignInScreenSource from 'mkit-front-ds/dist/screens/SignInScreen';

import { useToken, useTheme } from 'hooks';

const SignInScreen = () => (
  <SignInScreenSource useTokenHook={useToken} useThemeHook={useTheme} />
);

export default SignInScreen;
