import { useMutation } from 'redux-query-react';
import { useCallback } from 'react';

import {
  getApprovedTemplates as getApprovedTemplatesApi,
  getWhatsAppLanguages as getWhatsAppLanguagesApi,
  getWhatsAppCategories as getWhatsAppCategoriesApi,
  getWhatsAppTemplates as getWhatsAppTemplatesApi,
  postWhatsAppTemplate as postWhatsAppTemplateApi,
  deleteWhatsAppTemplate as deleteWhatsAppTemplateApi,
  putWhatsAppTemplate as putWhatsAppTemplateApi,
} from 'queries/whatsAppTemplates';

import { getArray, getObject, useISESelector } from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetTemplates,
    },
    getWhatsAppTemplates,
  ] = useMutation(getWhatsAppTemplatesApi);
  const [
    {
      isPending: isPendingPostTemplate,
    },
    postWhatsAppTemplate,
  ] = useMutation(postWhatsAppTemplateApi);
  const [
    {
      isPending: isPendingDeleteTemplate,
    },
    deleteWhatsAppTemplate,
  ] = useMutation(deleteWhatsAppTemplateApi);
  const [
    {
      isPending: isPendingPutTemplate,
    },
    putWhatsAppTemplate,
  ] = useMutation(putWhatsAppTemplateApi);
  const [, getApprovedTemplates] = useMutation(getApprovedTemplatesApi);
  const [, getWhatsAppLanguages] = useMutation(getWhatsAppLanguagesApi);
  const [, getWhatsAppCategories] = useMutation(getWhatsAppCategoriesApi);

  const selector = useCallback(state => ({
    approvedTemplates: getArray(state, 'approvedTemplates'),
    whatsAppLanguages: getArray(state, 'whatsAppLanguages'),
    whatsAppCategories: getArray(state, 'whatsAppCategories'),
    whatsAppTemplates: getArray(state, 'whatsAppTemplates'),
    whatsAppTemplatesMeta: getObject(state, 'whatsAppTemplatesMeta'),
  }), []);

  const data = useISESelector(selector);

  return {
    getApprovedTemplates,
    getWhatsAppLanguages,
    getWhatsAppCategories,
    getWhatsAppTemplates,
    postWhatsAppTemplate,
    deleteWhatsAppTemplate,
    putWhatsAppTemplate,
    isPendingGetTemplates,
    isPendingPostTemplate,
    isPendingDeleteTemplate,
    isPendingPutTemplate,
    ...data,
  };
};
