import React, { useState } from 'react';
import classNames from 'classnames';

import Tag from 'components/Tags/Tag';
import AddTag from 'components/Tags/AddTag';
/*eslint-disable */
import './style.scss';

const EditTags = ({
  value = [], // string[]
  tags = [], // string[] - список тегов
  wrapperStyle,
  onTagAdd,
  onTagRemove,
  isEditable = true,
  isPending,
}) => {
  // variableTags - изменяемые теги
  const [variableTags, setVariableTags] = useState(value);
  // remove tag from tags array
  const removeTag = (tagText) => {
    let newArray = [...variableTags];
    newArray.splice(newArray.indexOf(newArray.find(el => el === tagText)), 1);
    setVariableTags(newArray);
    onTagRemove(tagText);
  };

  // add empty tag
  const addTag = (newTagValue) => {
    let newTags = [...variableTags];
    newTags.push(newTagValue);
    setVariableTags(newTags);
    onTagAdd(newTagValue);
  };

  const groupWrapperClass = classNames({
    'mkit-edit-tags-group': true,
  });

  return (
    <div className={groupWrapperClass} style={wrapperStyle}>
      <div className="mkit-edit-tags-group__tags">
        {variableTags.map(tag => (
          <Tag
            tagText={tag}
            allTagsValues={tags}
            isPrimaryTag={false}
            isEditable={isEditable}
            disabled={isPending}
            key={tag}
            onRemove={() => removeTag(tag)}
          />
        ))}
        {
          isEditable && (
            <AddTag
              allTagsValues={tags}
              selectedTagsValue={variableTags}
              onTagAdd={newTagValue => addTag(newTagValue)}
            />
          )
        }
      </div>
    </div>
  );
};

export default EditTags;
