import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import crossIcon from 'assets/icons/cross.svg';

import './styles.scss';

const AgColumnHeader = ({
  showColumnMenu,
  displayName,
  column,
  api,
  enableSorting,
  setSort,
}) => {
  const menuButton = useRef();
  const [sortingOrder, setSortingOrder] = useState('');

  const sortIndicatorClass = classNames('mkit-ag-header-sort-indicator', {
    [`mkit-ag-header-sort-indicator_${sortingOrder}`]: !!sortingOrder,
  });

  const onMenuClicked = () => {
    showColumnMenu(menuButton.current);
  };
  const onResetFilter = () => {
    api.getFilterInstance(column.colId, (countryFilterComponent) => {
      countryFilterComponent.setModel();
      api.onFilterChanged();
    });
  };
  const onSort = () => {
    let nextSortingOrder;
    switch (sortingOrder) {
      case 'asc': {
        nextSortingOrder = 'desc';
        break;
      }
      case 'desc': {
        nextSortingOrder = '';
        break;
      }
      default: {
        nextSortingOrder = 'asc';
        break;
      }
    }
    setSort(nextSortingOrder);
    setSortingOrder(nextSortingOrder);
  };

  useEffect(() => {
    const onSortChanged = () => {
      if (!column.isSortAscending() && !column.isSortDescending()) {
        setSortingOrder('');
      }
    };

    column.addEventListener('sortChanged', onSortChanged);

    return () => {
      column.removeEventListener('sortChanged', onSortChanged);
    };
  }, []);

  return (
    <div className="mkit-ag-column-header">
      {
        enableSorting ? (
          <button
            type="button"
            className="mkit-ag-header-cell-label mkit-ag-header-cell-label_button"
            onClick={onSort}
          >
            <span className="mkit-ag-header-cell-text">
              {displayName}
            </span>
            <div className={sortIndicatorClass} />
          </button>
        ) : (
          <div className="mkit-ag-header-cell-label">
            <span className="mkit-ag-header-cell-text">
              {displayName}
            </span>
          </div>
        )
      }
      {
        column.colDef.filter && (
          <div
            role="presentation"
            aria-hidden="true"
            ref={menuButton}
            className="mkit-ag-column-header__filter"
            onClick={onMenuClicked}
          />
        )
      }
      <img
        className="mkit-ag-column-header__reset-filter"
        role="presentation"
        alt="filter"
        src={crossIcon}
        onClick={onResetFilter}
      />
    </div>
  );
};

export default AgColumnHeader;
