import React from 'react';
import classNames from 'classnames';

import okIcon from 'assets/icons/ok.svg';

import './style.scss';

const CheckboxField = ({
  input: {
    name,
    value,
    onChange,
  },
  label,
  icon,
  wrapperStyle,
  styles = {},
  isDisabled = false,
  isMirror,
  readOnly,
  fieldId,
}) => {
  const checkboxClass = classNames('mkit-checkbox-field', {
    'mkit-checkbox-field_checked': value,
    'mkit-checkbox-field_disabled': isDisabled,
    'mkit-checkbox-field_mirror': isMirror,
  });

  const onChangeHandler = () => onChange(!value);

  return (
    <label
      style={{ ...wrapperStyle, ...(styles.container || {}) }}
      className={checkboxClass}
      // htmlFor={name}
    >
      <div className="mkit-checkbox-field__radio">
        {
          value && (
            <img src={okIcon} alt="ok" className="mkit-checkbox-field__radio__img" />
          )
        }
      </div>
      {icon && (
        <img
          className="mkit-checkbox-field__icon"
          src={icon}
          alt=""
        />
      )}
      <span className="mkit-checkbox-field__label" style={styles.label}>
        {label}
      </span>
      <input
        name={name}
        type="checkbox"
        checked={value}
        onChange={onChangeHandler}
        disabled={isDisabled}
        style={styles.inputContainer}
        readOnly={readOnly}
        data-testid={`${window.location.pathname}/checkbox-field/${label?.toLowerCase().replace(/\s+/g, '') || fieldId}`}
      />
    </label>
  );
};

export default CheckboxField;
