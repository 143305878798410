import React from 'react';
import ServicesScreen from 'mkit-front-ds/dist/screens/ServicesScreen';

import './style.scss';

const RatePlansScreen = () => (
  <div className="rate-plans-layout">
    <ServicesScreen />
  </div>
);

export default RatePlansScreen;
