import endpoints from 'const/endpoints';

export default ({ body, successCallback }) => ({
  url: endpoints.getFiltersUrl(),
  body,
  options: {
    method: 'POST',
  },
  meta: {
    authToken: true,
    successCallback,
  },
  transform: response => ({
    filters: response,
  }),
  update: {
    filters: (oldValue = [], newValue) => [...oldValue, {
      id: +newValue.id,
      ...body,
    }],
  },
});
