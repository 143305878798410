import React from 'react';

const HeaderCell = ({
  headerName,
  field,
  sort,
  setSort,
}) => {
  const onClick = () => {
    if (sort.field === field) {
      if (sort.order === 'asc') {
        setSort({ field, order: 'desc' });
      } else {
        setSort({});
      }
    } else {
      setSort({ field, order: 'asc' });
    }
  };

  return (
    <div
      role="presentation"
      onClick={onClick}
    >
      {headerName}
      {sort.field === field && (
        sort.order === 'asc' ? '↑' : '↓'
      )}
    </div>
  );
};

export default HeaderCell;
