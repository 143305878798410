import React from 'react';
import { useHistory } from 'react-router-dom';
import FinanceScreen from 'mkit-front-ds/dist/screens/FinanceScreen';

import { useUsersApi } from 'hooks/api';

import './style.scss';

const FinancialDocumentsScreen = () => {
  const history = useHistory();
  const { currentUser } = useUsersApi();

  return (
    <div className="financial-documents-layout">
      <FinanceScreen
        externalHistory={history}
        userPermissions={currentUser?.roles || []}
      />
    </div>
  );
};

export default FinancialDocumentsScreen;
