import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';

import en from './translates/en.json';
import ru from './translates/ru.json';

import 'moment/locale/ru';

// the translations
// (tip move them in a JSON file and import them)

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: ['en', 'ru'],
    debug: process.env.NODE_ENV !== 'production',
    resources: {
      en: {
        translation: en,
      },
      ru: {
        translation: ru,
      },
    },

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

// изменяем язык в моменте при изменении языка в i18n и загружаем выбранный язык
i18n.on('languageChanged', (lng) => {
  moment.locale(lng);
});

// устанавливаем язык в моменте при инициализации
moment.locale(i18n.language);

export default i18n;
