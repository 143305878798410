import React, { useState } from 'react';
import { Dropdown as DropdownSource, Menu } from 'antd';

import DropdownItem from '../DropdownItem/DropdownItem';

import './Dropdown.scss';

const Dropdown = ({
  children,
  menu, // object[]: {text: , icon: , action: ,}
  width,
  ...props
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <DropdownSource
      visible={visible}
      onVisibleChange={setVisible}
      overlay={(
        <Menu>
          {menu && menu.map(menuItem => (
            <DropdownItem
              key={menuItem.text}
              menuValue={{
                text: menuItem.text,
                icon: menuItem.icon,
                filterType: menuItem.filterType,
                styleIcon: menuItem.styleIcon,
                action: (...args) => {
                  setVisible(false);
                  menuItem.action({ setVisible, ...args });
                },
              }}
            />
          ))}
        </Menu>
      )}
      {...props}
    >
      {children}
    </DropdownSource>
  );
};

export default Dropdown;
