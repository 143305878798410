import React from 'react';
import NewWhatsAppTemplatesScreen from 'mkit-front-ds/dist/screens/WhatsAppTemplatesScreen';

import { useUsersApi } from 'hooks/api';

import './style.scss';

const WhatsAppTemplatesScreen = () => {
  const { currentUser } = useUsersApi();

  return (
    <div className="whats-app-templates-screen-container">
      <NewWhatsAppTemplatesScreen userPermissions={currentUser?.roles || []} />
    </div>
  );
};

export default WhatsAppTemplatesScreen;
