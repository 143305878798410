import endpoints from 'const/endpoints';

export default ({ expandType = true, variablesOnly = false } = {}) => ({
  url: endpoints.getAttributesUrl(),
  body: {
    expandType,
    variablesOnly,
  },
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
  },
  transform: response => ({
    attributes: response.results,
  }),
  update: {
    attributes: (oldValue, newValue) => newValue,
  },
});
