import endpoints from 'const/endpoints';

export default ({
  recipient,
  successCallback,
  errorCallback,
} = {}) => ({
  url: endpoints.postRecipientUrl(),
  queryKey: endpoints.postRecipientUrl(),
  body: recipient,
  options: {
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
  },
  meta: {
    successCallback,
    errorCallback,
    authToken: true,
  },
  // update: {
  //   recipients: (oldValue, newValue) => newValue,
  //   recipientsInfo: (oldValue, newValue) => newValue,
  //   recipientsPagination: (oldValue, newValue) => newValue,
  // },
});
