import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import CampaignChannel from 'components/CampaignChannel/CampaignChannel';
import { CAMPAIGN_HINTS, CAMPAIGN_CHANNEL_ACTION_TYPE, channels } from 'const';
import {
  useDictionariesApi, useSendersApi, useFlowsNewApi, useCompaniesApi,
} from 'hooks/api';
import { Hint } from 'components';
import uikit from 'components/UIKit';
import { DropdownFieldNew } from 'components/fields';

import CampaignCreateFallbackChannelAdd from './CampaignEditFallbackChannelAdd';
import CampaignCreateChannel from './CampaignEditChannel';

import Card from '../UIKit/Card/Card';
import CardTitle from '../UIKit/Card/CardTitle';
import CampaignChannelAction from '../CampaignChannel/CampaignChannelAction';

import './CampaignEditFallback.scss';

const { Switch } = uikit;

const CampaignEditFallback = ({
  activeHint,
  setNextHint,
  skipAll,
  disabled,
  setShortLinks,
}) => {
  const { t } = useTranslation();
  const stylesDropdownField = {
    label: { flex: 3 },
    content: { flex: 7 },
    container: { marginBottom: 12 },
  };

  const [editedChannel, changeEditedChannel] = useState();
  const [editedIndex, setEditedIndex] = useState();
  // const [isScript, setIsScript] = useState(false);

  const {
    getDeliveryConditions,
  } = useDictionariesApi();
  const {
    getSenders,
    senders,
  } = useSendersApi();
  const {
    getFlowsAll,
    flowsAll,
  } = useFlowsNewApi();
  const {
    ownCompany,
    getOwnCompany,
  } = useCompaniesApi();

  const form = useForm();
  const { values } = useFormState({ subscription: { values: true, initialValues: true } });
  const dropStep = useCallback((channel) => {
    const flowSteps = values.flowSteps.filter(step => step !== channel);
    form.change('flowSteps', flowSteps);
  }, [values]);

  const flowsOptions = useMemo(() => {
    let flowsCopy = [...flowsAll];
    if (ownCompany?.isModerated) {
      flowsCopy = flowsAll.filter(I => I.status === 1);
    }
    return flowsCopy.map(({ id, name, channel }) => ({
      label: `${name} (${channels.CHANNELS_IDS[channel]})`,
      value: id,
    }));
  }, [flowsAll]);

  const sendersOptions = useMemo(() => {
    const selectedFlow = flowsAll.find(({ id }) => id === values.flowId);

    return senders
      .filter(({ channel, status }) => channel === selectedFlow?.channel && status === 2)
      .map(({ senderId, displayName, channel }) => ({
        label: displayName,
        value: `${senderId}/${channel}`,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [values.flowId, senders, flowsAll]);

  useEffect(() => {
    getDeliveryConditions();
    getSenders({ limit: 1000, onlyActiveSenders: false });
    getFlowsAll();
    getOwnCompany();
  }, []);

  return (
    <Card className="campaign-create-fallback">
      <CardTitle>
        <div className="campaign-create-fallback__header">
          {t('INSTANCES.CAMPAIGNS.FALLBACK')}
          <Field name="isScript">
            {({ input: { onChange, value } }) => (
              <Switch onChange={onChange} checked={value} disabled={disabled} />
            )}
          </Field>
          {t('INSTANCES.CAMPAIGNS.SCENARIO')}
        </div>
      </CardTitle>
      {
        values.isScript
          ? (
            <div className="campaign-create-fallback__script">
              <Field name="flowId">
                {({ input }) => (
                  <DropdownFieldNew
                    input={input}
                    options={flowsOptions}
                    label={t('INSTANCES.CAMPAIGNS.SCENARIO')}
                    styles={stylesDropdownField}
                    placeholder={t('PLACEHOLDERS.CAMPAIGNS.SELECT_SCENARIO')}
                    withTranslate={false}
                    disabled={disabled}
                    withSearch
                  />
                )}
              </Field>
              <Field name="senderId">
                {({ input }) => (
                  <DropdownFieldNew
                    input={input}
                    options={sendersOptions}
                    label={t('INSTANCES.CAMPAIGNS.SENDER')}
                    styles={stylesDropdownField}
                    placeholder={t('CONVERSATIONS.INSTANCES.SELECT_SENDER')}
                    disabled={!values.flowId || disabled}
                    withTranslate={false}
                    withSearch
                  />
                )}
              </Field>
            </div>
          )
          : (
            <div className="campaign-create-fallback__channels">
              {
                activeHint === 'addFallback' && !values.flowSteps.length && (
                  <Hint
                    setNextHint={setNextHint}
                    {...CAMPAIGN_HINTS[activeHint]}
                    onSkip={skipAll}
                  />
                )
              }
              <CampaignChannelAction type={CAMPAIGN_CHANNEL_ACTION_TYPE.start} text="Start" />
              {values.flowSteps.map((channel, index) => (
                <CampaignChannel
                  {...channel}
                  onClick={() => {
                    changeEditedChannel(channel);
                    setEditedIndex(index);
                  }}
                  onClose={!disabled && (() => {
                    dropStep(channel);
                  })}
                />
              ))}
              {
                // values.flowSteps.length === 3
                //   ? (
                //     <CampaignChannelAction
                //       type={CAMPAIGN_CHANNEL_ACTION_TYPE.stop}
                //       text="Stop"
                //     />
                //   )
                //   : (
                //     <CampaignCreateFallbackChannelAdd
                //       addChannel={changeEditedChannel}
                //       flowSteps={values.flowSteps}
                //     />
                //   )
              }
              {!disabled && (
                <CampaignCreateFallbackChannelAdd
                  addChannel={changeEditedChannel}
                  flowSteps={values.flowSteps}
                />
              )}
            </div>
          )
      }
      {editedChannel && (
      <Field name="flowSteps">
        {({ input }) => (
          <CampaignCreateChannel
            channel={editedChannel}
            index={editedIndex}
            readOnly={disabled}
            setShortLinks={setShortLinks}
            onSave={({ index, ...value }) => {
              const newValues = [...input.value];
              if (index !== undefined) {
                newValues.splice(index, 1, value);
              } else {
                newValues.push(value);
              }
              input.onChange(newValues);
              changeEditedChannel(undefined);
              setEditedIndex(undefined);
            }}
            onClose={() => {
              changeEditedChannel(undefined);
              setEditedIndex(undefined);
            }}
          />
        )}
      </Field>
      )}
    </Card>
  );
};

export default CampaignEditFallback;
