import moment from 'moment';

import audioIcon from 'assets/icons/musical-note.svg';
import documentIcon from 'assets/icons/document-light.svg';

import bytesToSize from '../bytesToSize';

const getPreview = (mediaType, thumbnailUri) => {
  switch (mediaType) {
    case 'video':
    case 'image':
      return thumbnailUri;
    case 'audio': return audioIcon;
    default: return documentIcon;
  }
};

export default assets => assets.map((item) => {
  const [mediaType, format] = item.fileMimeType.split('/');

  return ({
    ...item,
    resolution: `${item.fileWidth} x ${item.fileHeight} px`,
    weight: bytesToSize(item.fileSize),
    mediaType: mediaType[0].toUpperCase() + mediaType.slice(1),
    format: format.toUpperCase(),
    date: moment(item.fileLinkCreatedAt).format('DD.MM.YYYY / HH:mm:ss'),
    thumbnailUri: getPreview(mediaType, item.thumbnailUri),
  });
});
