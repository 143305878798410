import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';

import {
  getUsers as getUsersApi,
  deleteUser as deleteUserApi,
  postUser as postUserApi,
  putUser as putUserApi,
  patchAuthorities as patchAuthoritiesApi,
  getCurrentUser as getCurrentUserApi,
} from 'queries/users';

import {
  getArray,
  getObject,
  useISESelector,
} from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetUsers,
      lastUpdated: lastUpdatedGetUsers,
    },
    getUsers,
  ] = useMutation(getUsersApi);
  const [
    {
      isPending: isPendingDeleteUser,
    },
    deleteUser,
  ] = useMutation(deleteUserApi);
  const [
    {
      isPending: isPendingPostUser,
    },
    postUser,
  ] = useMutation(postUserApi);
  const [
    {
      isPending: isPendingPutUser,
    },
    putUser,
  ] = useMutation(putUserApi);
  const [
    {
      isPending: isPendingPatchAuthorities,
    },
    patchAuthorities,
  ] = useMutation(patchAuthoritiesApi);
  const [
    {
      isPending: isPendingGetCurrentUser,
    },
    getCurrentUser,
  ] = useMutation(getCurrentUserApi);

  const selector = useCallback(state => ({
    users: getArray(state, 'users'),
    usersMeta: getObject(state, 'usersMeta'),
    currentUser: getObject(state, 'currentUser'),
  }), []);
  const data = useISESelector(selector);

  return {
    getUsers,
    deleteUser,
    postUser,
    putUser,
    patchAuthorities,
    getCurrentUser,
    isPendingGetUsers,
    isPendingDeleteUser,
    isPendingPostUser,
    isPendingPutUser,
    isPendingPatchAuthorities,
    isPendingGetCurrentUser,
    lastUpdatedGetUsers,
    ...data,
  };
};
