export default ({
  keyValue,
  value,
  onClick,
  buttonsConfig,
}) => buttonsConfig.map(I => ({
  icon: value === I.activeKey ? I.activeIcon : I.icon,
  text: I.text,
  onClick: (e) => {
    e.stopPropagation();
    onClick({ [keyValue]: I.activeKey });
  },
}));
