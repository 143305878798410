import endpoints from 'const/endpoints';

export default ({ body, successCallback, errorCallback }) => ({
  url: endpoints.getRecipientsAttributesUrl(),
  body,
  options: {
    method: 'POST',
  },
  meta: {
    authToken: true,
    successCallback,
    errorCallback,
  },
  transform: response => ({
    attributes: response,
  }),
  update: {
    attributes: (oldValue = [], newValue) => [...oldValue, newValue],
  },
});
