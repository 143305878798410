import endpoints from 'const/endpoints';

export default ({
  body,
  successCallback,
  errorCallback,
} = {}) => ({
  url: endpoints.getFlowsNewUrl(),
  queryKey: `post${endpoints.getFlowsNewUrl()}`,
  body,
  options: {
    method: 'POST',
  },
  meta: {
    authToken: true,
    successCallback,
    errorCallback,
  },
  transform: response => ({ flowsAll: response }),
  update: {
    flowsAll: (oldValue, newValue) => {
      if (oldValue) {
        return [...oldValue, newValue];
      }

      return oldValue;
    },
  },
});
