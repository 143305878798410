import endpoints from 'const/endpoints';
import { CHANNELS_DICTIONARY } from 'const';

export default token => ({
  url: endpoints.getAvailableChannelsUrl(),
  options: {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  },
  transform: ({ resourceList }) => ({
    mkitAvailableChannelsOptions: (resourceList?.length ? resourceList : [1]).filter(channel => channel !== 5)
      .map(channelKey => ({
        value: channelKey,
        ...CHANNELS_DICTIONARY[channelKey],
      })),
  }),
  update: {
    mkitAvailableChannelsOptions: (oldValue, newValue) => newValue,
  },
});
