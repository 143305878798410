import React, { useState, useEffect, useMemo } from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import LoadingSpinner from 'components/LoadingSpinner';
import { CreateReportForm } from 'components/forms';
import { SearchField, TreeDropdownField } from 'components/fields';

import ErrorBoundary from './ErrorBoundary';

import './style.scss';

const CreateReportBlock = ({
  list,
  isListLoading,
  onSelectReport,
  isParamsLoading,
  reportParameters,
  postCreateReport,
  reports,
  setActiveTab,
  editableReport,
  isSuccessGetParameters,
  isPendingPostCreateReport,
}) => {
  const searchStyle = {
    width: '100%',
    marginBottom: 10,
  };

  const { t } = useTranslation();

  const [selectedSection, setSelectedSection] = useState({});
  const [selectedSubsection, setSelectedSubsection] = useState({});
  const [searchValue, setSearchValue] = useState('');

  const onSelectSubsection = (template) => {
    setSelectedSubsection(template);
    onSelectReport(template.id);
  };

  const onRunReport = (values) => {
    const equalReportsCount = reports
      .filter(report => report.templateId === selectedSubsection.id).length;
    let reportName = `(${equalReportsCount + 1}) ${selectedSection.title}: ${selectedSubsection.title}`;

    if (reports.find(R => R.name === reportName)) {
      reportName = `(${equalReportsCount + 2}) ${selectedSection.title}: ${selectedSubsection.title}`;
    }
    let valuesModify = {};

    const getMomentValue = (key) => {
      const valueModify = values[key];
      const { parameterType } = reportParameters.find(I => I.bindName === key);

      switch (parameterType) {
        case 'Date':
          valueModify.set('hour', 0).set('minute', 0).set('second', 0);
          break;
        case 'Month and year':
          valueModify.set('date', 1).set('hour', 0).set('minute', 0).set('second', 0);
          break;
        case 'Year':
          valueModify.set('month', 0).set('date', 1).set('hour', 0).set('minute', 0)
            .set('second', 0);
          break;
        default: break;
      }

      return `${moment(valueModify).format('YYYY-MM-DDTHH:mm:ss')}Z`;
    };

    Object.keys(values).forEach((key) => {
      valuesModify = {
        ...valuesModify,
        [key]: moment.isMoment(values[key]) ? getMomentValue(key) : values[key],
      };
    });

    postCreateReport({
      body: {
        name: reportName,
        templateId: selectedSubsection.id,
        params: {
          ...valuesModify,
        },
        limit: 100,
        offset: 0,
      },
      successCallback: () => {
        setActiveTab(reportName);
        setSelectedSection({});
        setSelectedSubsection({});
      },
    });
  };

  const displayedList = useMemo(() => {
    const modifySearchValue = searchValue.trim().toLowerCase();
    if (modifySearchValue) {
      return list
        .filter(L => L.name.toLowerCase().includes(modifySearchValue) || L.reports
          ?.find(R => R.name.toLowerCase().includes(modifySearchValue)));
    }

    return list;
  }, [list, searchValue]);

  const initialValues = useMemo(() => {
    if (editableReport.templateId === selectedSubsection.id
      && !isEmpty(editableReport.initialValues)) {
      return editableReport.initialValues;
    }

    return reportParameters.reduce((acc, item) => ({
      ...acc,
      [item.bindName]: item.parameterType === 'Date' && item.defaultExpression
        ? moment(item.defaultExpression)
        : item.defaultExpression,
    }), {});
  }, [editableReport, selectedSubsection, reportParameters]);

  useEffect(() => {
    if (!isEmpty(editableReport)) {
      const section = list.find(item => item.reports
        .find(R => R.id === editableReport.templateId));
      const subsection = section.reports
        .find(item => item.id === editableReport.templateId);

      onSelectReport(subsection.id);
      setSelectedSection({ id: section.id, title: section.name });
      setSelectedSubsection({
        id: subsection.id,
        title: subsection.name,
        description: subsection.description,
      });
    }
  }, [editableReport]);

  return (
    <div className="create-report-block">
      <div className="create-report-block__card">
        <div className="create-report-block__card__item">
          <h2 className="create-report-block__card__title" data-testid={`${window.location.pathname}/title-card/REPORTS_BY_CATEGORY`}>
            {t('SCREENS.REPORTS.REPORTS_BY_CATEGORY')}
          </h2>
          {
            isListLoading
              ? <LoadingSpinner fullSize style={{ width: 'auto' }} />
              : (
                <>
                  <SearchField
                    input={{
                      onChange: setSearchValue,
                      name: 'searchReport',
                    }}
                    placeholder={t('PLACEHOLDERS.REPORTS.SEARCH_REPORT')}
                    styleWrapper={searchStyle}
                    type="full-border"
                  />
                  <div className="create-report-block__card__item__list">
                    {
                      displayedList.map(item => (
                        <TreeDropdownField
                          id={item.id}
                          title={item.name}
                          subItems={item.reports.filter(report => report.public).map(template => ({
                            id: template.id,
                            title: template.name,
                            description: template.description,
                          }))}
                          isSelected={selectedSection.id === item.id}
                          onSelectSection={setSelectedSection}
                          selectedSubsection={selectedSubsection.id}
                          key={item.id}
                          onSelectItem={onSelectSubsection}
                        />
                      ))
                    }
                  </div>
                </>
              )
          }
        </div>
        <div className="create-report-block__card__line" />
        <div className="create-report-block__card__item">
          <h2 className="create-report-block__card__title" data-testid={`${window.location.pathname}/title-card/REPORT_PARAMS`}>
            {t('SCREENS.REPORTS.REPORT_PARAMS')}
          </h2>
          {
            isParamsLoading && <LoadingSpinner fullSize style={{ width: 'auto' }} />
          }
          {
            selectedSubsection.id && isSuccessGetParameters && (
              <div className="create-report-block__card__item__list">
                <ErrorBoundary>
                  <CreateReportForm
                    onSubmit={onRunReport}
                    params={reportParameters}
                    description={selectedSubsection.description}
                    initialValues={initialValues}
                    isLoading={isPendingPostCreateReport}
                  />
                </ErrorBoundary>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default CreateReportBlock;
