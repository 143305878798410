import React from 'react';
import UsersScreen from 'mkit-front-ds/dist/screens/UsersScreen';

import { useUsersApi } from 'hooks/api';

import './style.scss';

const UserManagementScreen = () => {
  const { currentUser } = useUsersApi();

  return (
    <div className="users-container">
      <UsersScreen
        userId={currentUser?.id}
        userPermissions={currentUser?.roles || []}
      />
    </div>
  );
};

export default UserManagementScreen;
