/* eslint-disable */
import getTransformedRcs from './transforms/getTransformedRcs';
import getTransformedWhatsApp from './transforms/getTransformedWhatsApp';

export default (body) => {
  try {
    const parsedBody = JSON.parse(body);

    if (typeof parsedBody === 'object') {
      // whatsapp text
      if (parsedBody.text) {
        parsedBody.type = 'text';
        return getTransformedWhatsApp({ contentPattern: parsedBody });
      }
      // whatsapp MO
      if (parsedBody.messages) {
        const messageType = parsedBody.messages[0].type;

        switch (messageType) {
          case 'image':
          case 'video':
          case 'document':
            return getTransformedWhatsApp({
	            contentPattern: {
		            fileMimeType: messageType,
		            ...parsedBody.messages[0],
	            },
            });
          case 'button': return getTransformedWhatsApp({
	          contentPattern: {
	          	type: 'text',
		          text: {
	          		body: parsedBody.messages[0][messageType].text,
		          },
	          },
          });
          default: return getTransformedWhatsApp({
	          contentPattern: {
		          type: 'text',
		          text: {
			          body: parsedBody.messages[0].text.body,
		          },
	          },
          });
        }
      }

      // whatsapp location
      if (parsedBody.type === 'location') {
        return getTransformedWhatsApp({
	        contentPattern: parsedBody,
        });
      }

      // whatsapp any file
      if (['image', 'video', 'document'].includes(parsedBody.type)) {
        return getTransformedWhatsApp({
	        contentPattern: parsedBody,
        });
      }

      // whatsapp template
      if (parsedBody.type === 'template') {
        return ({
	        selectedType: 'whatsAppTemplate',
	        components: parsedBody.template.templateComponents,
	        ...getTransformedWhatsApp({
		        contentPattern: parsedBody,
	        }),
        });
      }

      // rcs mo reply
      if (parsedBody.suggestedResponse) {
      	return getTransformedRcs({
		      contentPattern: {
		      	RCSMessage: {
		      		textMessage: parsedBody.suggestedResponse?.response?.reply?.displayText
					      || parsedBody.suggestedResponse?.response?.action?.displayText
					      || 'Action button pressed',
			      },
		      },
	      });
      }

      // rcs file
      if (parsedBody?.RCSMessage?.fileMessage || parsedBody?.fileMessage) {
        const fileMessage = parsedBody.fileMessage || parsedBody.RCSMessage.fileMessage;

        return getTransformedRcs({
	        contentPattern: {
		        RCSMessage: {
			        fileMessage,
		        },
	        },
        });
      }

      // rcs richcard
      if (parsedBody?.RCSMessage?.richcardMessage) {
        return getTransformedRcs({
	        contentPattern: parsedBody,
        });
      }

      // file
      // if (parsedBody?.imageUrl) {
      //   const fileName = parsedBody.imageUrl.split('/');
      //   return {
      //     name: fileName[fileName.length - 1],
      //     type: 'file',
      //   };
      // }

      // rcs MT message
      if (parsedBody.RCSMessage?.textMessage) {
        return getTransformedRcs({
	        contentPattern: parsedBody,
        });
      }
      if (parsedBody?.textMessage || parsedBody?.message) {
	      return getTransformedRcs({
		      contentPattern: {
			      RCSMessage: parsedBody,
		      },
	      });
      }
      return parsedBody;
    }
	  return body;
  } catch (e) {
	  return getTransformedRcs({
		  contentPattern: {
			  RCSMessage: {
				  textMessage: body,
			  },
		  },
	  });
  }
};
