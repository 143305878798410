import { DefaultPortFactory } from '@projectstorm/react-diagrams';

import PortModel from './PortModel';

export default class AdvancedPortFactory extends DefaultPortFactory {
  constructor() {
    super('default');
  }

  static generateModel() {
    return new PortModel();
  }
}
