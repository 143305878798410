import endpoints from 'const/endpoints';

export default () => ({
  url: endpoints.getTimezones(),
  queryKey: endpoints.getTimezones(),
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
  },
  transform: response => ({ timezones: response }),
  update: {
    timezones: (oldValue, newValue) => newValue,
  },
});
