import React from 'react';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';

import { required } from 'helpers';
import {
  BasicField,
  DropdownFieldNew,
  ConditionFieldArray,
  ResponseGroupFieldArray,
  ReportField,
} from 'components/fields';
import { TRIGGER_TYPE_OPTIONS, CONDITION_TYPE_OPTIONS } from 'const/scenario';
import { BasicButton } from 'components/buttons';
import cancelIcon from 'assets/icons/cross-grey.svg';
import saveIcon from 'assets/icons/save.svg';
import trashIcon from 'assets/icons/trash.svg';

import './style.scss';

const EditNodeConditionForm = ({
  onCancel,
  onSubmit,
  initialValues,
  onDelete,
  reportFields,
  readOnly = false,
  isFirstNode,
}) => {
  const { t } = useTranslation();

  const stylesDropdownField = {
    label: { flex: 3 },
    content: { flex: 7 },
    container: { marginBottom: 12 },
  };
  const stylesBasicField = {
    label: { flex: 3 },
    inputContainer: { flex: 7 },
    container: { marginBottom: 12 },
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
      render={
        ({
          handleSubmit,
          values,
          submitting,
        }) => (
          <form onSubmit={handleSubmit} className="edit-node-condition-form">
            <h1 className="edit-node-condition-form__title">
              {t('SCENARIOS_CONSTRUCTOR.INSTANCES.STEP_EDIT')}
            </h1>
            <h2 className="edit-node-condition-form__sub-title">
              {t('SCENARIOS_CONSTRUCTOR.INSTANCES.GENERAL')}
            </h2>
            <Field
              name="label"
              type="border"
              label={t('NEW_TRANSLATES.SCENARIOS_CONSTRUCTOR.INSTANCES.LABEL')}
              component={BasicField}
              styles={stylesBasicField}
              readOnly={readOnly}
              isRequired
              validate={value => required(value, t)}
            />
            <Field
              name="triggerType"
              type="border"
              label={t('SCENARIOS_CONSTRUCTOR.INSTANCES.TRIGGER_TYPE')}
              component={DropdownFieldNew}
              styles={stylesDropdownField}
              options={TRIGGER_TYPE_OPTIONS}
              readOnly={readOnly || isFirstNode}
            />
            <Field
              name="type"
              type="border"
              label={t('INSTANCES.TYPE')}
              component={DropdownFieldNew}
              styles={stylesDropdownField}
              options={CONDITION_TYPE_OPTIONS}
              initialValue={initialValues.isNewNode && 1}
              readOnly={readOnly}
            />
            {
              values.type === 1 ? (
                <FieldArray
                  name="userResponses"
                  key="userResponses"
                  component={ResponseGroupFieldArray}
                  buttonText={t('SCENARIOS_CONSTRUCTOR.CONTROLS.ADD_RESPONSE_GROUP')}
                  readOnly={readOnly}
                  reportFields={reportFields}
                  initialValue={values.userResponses || []}
                />
              ) : (
                <FieldArray
                  name="conditions"
                  key="conditions"
                  component={ConditionFieldArray}
                  buttonText={t('SCENARIOS_CONSTRUCTOR.CONTROLS.ADD_CONDITION')}
                  readOnly={readOnly}
                  reportFields={reportFields}
                  initialValue={values.conditions || []}
                />
              )
            }
            <h2
              className="edit-node-condition-form__sub-title"
              style={{
                marginTop: 12,
              }}
            >
              {t('SCENARIOS_CONSTRUCTOR.INSTANCES.ELSE')}
            </h2>
            <Field
              name="elseLabel"
              type="border"
              label={t('NEW_TRANSLATES.SCENARIOS_CONSTRUCTOR.INSTANCES.LABEL')}
              component={BasicField}
              styles={stylesBasicField}
              readOnly={readOnly}
            />
            <Field
              name="reportField"
              type="border"
              label=""
              component={ReportField}
              readOnly={readOnly}
              options={reportFields}
            />
            <div className="edit-node-condition-form__buttons">
              {
                (!initialValues.isNewNode && !readOnly && !isFirstNode) && (
                  <BasicButton
                    text={t('SCENARIOS_CONSTRUCTOR.CONTROLS.DELETE_STEP')}
                    type={BasicButton.types.WARN}
                    icon={trashIcon}
                    filterType="only-white"
                    onClick={onDelete}
                  />
                )
              }
              <div className="edit-node-condition-form__buttons__right">
                <BasicButton
                  text={t('CONTROLS.CANCEL')}
                  type={BasicButton.types.CANCEL}
                  icon={cancelIcon}
                  onClick={onCancel}
                  disabled={submitting}
                  isNewStyle
                />
                {
                  !readOnly && (
                    <BasicButton
                      text={t('SCENARIOS_CONSTRUCTOR.CONTROLS.SAVE_STEP')}
                      type={BasicButton.types.ACCENT}
                      icon={saveIcon}
                      onClick={handleSubmit}
                      loading={submitting}
                      isNewStyle
                    />
                  )
                }
              </div>
            </div>
          </form>
        )
      }
    />
  );
};

export default EditNodeConditionForm;
