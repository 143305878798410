import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'antd';
import classNames from 'classnames';
import { Field } from 'react-final-form';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import InfographicsRecipients from 'components/Infographics/InfographicsRecipients';
import './CampaignEditTargets.scss';
import { tags } from 'components';
import uikit from 'components/UIKit';
import { useFiltersApi, useRecipientsApi } from 'hooks/api';

import CampaignCreateControlledOutside from './CampaignEditControlledOutside';

const { Card, CardTitle, FormControl } = uikit;

const CampaignEditTargets = ({
  externalTrigger,
  filterIds,
  broadcastName,
  runDate,
  lastSummary,
  lastUnsuccessful,
  readOnly,
}) => {
  const { t } = useTranslation();
  const {
    isPendingGetFilters,
    getFilters,
    filters,
  } = useFiltersApi();
  const {
    isPendingGetStatsByFilters,
    getStatsByFilters,
    statsByFilters,
    clearStatsByFilters,
    cancelStatsByFiltersQuery,
  } = useRecipientsApi();

  const [isEmptyFilter, setIsEmptyFilter] = useState(false);

  const filtersItems = useMemo(
    () => filters.map(filter => ({
      id: filter.id,
      name: filter.filterInfo.name,
    })),
    [filters],
  ) || [];

  useEffect(() => {
    getFilters();

    if (filterIds.length) {
      getStatsByFilters({
        params: {
          filters: filterIds.toString(),
        },
      });
    }
  }, [filterIds]);
  useEffect(() => () => {
    cancelStatsByFiltersQuery();
    clearStatsByFilters();
  }, []);

  return (
    <Card className={classNames('campaign-create-targets campaign-create__card', {
      'campaign-create__card--controlled-outside': externalTrigger,
    })}
    >
      {!isPendingGetFilters && (
      <>
        <CardTitle>
          <Row type="flex" justify="space-between">
            <Col>
              {t('INSTANCES.CAMPAIGNS.TARGETS')}
            </Col>
          </Row>
        </CardTitle>
        {!externalTrigger ? (
          <>
            <div className="campaign-create-targets__infographic">
              <InfographicsRecipients
                isLoading={isPendingGetStatsByFilters}
                numbers={lastUnsuccessful ?? (isEmptyFilter ? 0 : statsByFilters)}
                text={t('INSTANCES.CAMPAIGNS.NEXT_RUN')}
                size="big"
              />
            </div>
            {
              (!!lastSummary || !!lastUnsuccessful) && (
                <div className="campaign-create-targets__rerun-info">
                  {`${t('INSTANCES.CAMPAIGNS.UNSUCCESSFUL_FROM')} ${lastSummary} ${t('INSTANCES.CAMPAIGNS.OF')} ${broadcastName} ${t('INSTANCES.CAMPAIGNS.AT')} ${moment(runDate).format('HH:mm:ss DD.MM.YYYY')}`}
                </div>
              )
            }
            <div className="campaign-create-targets__tags">
              <Field name="filterIds">
                {({ input, ...props }) => (
                  <FormControl meta={props.meta}>
                    <tags.EditTags
                      tags={filtersItems
                        .filter(item => !input.value.find(I => I === item.id))
                        .map(item => item.name)}
                      value={filtersItems
                        .filter(item => input.value.includes(item.id))
                        .map(item => item.name)}
                      isEditable={!lastSummary && !lastUnsuccessful && !readOnly}
                      isPending={isPendingGetStatsByFilters}
                      onTagAdd={(tag) => {
                        const tagItem = filtersItems.find(item => item.name === tag);
                        input.onBlur();
                        setIsEmptyFilter(false);
                        getStatsByFilters({
                          params: {
                            filters: [...input.value, tagItem.id].toString(),
                          },
                        });
                        input.onChange([...input.value, tagItem.id]);
                      }}
                      onTagRemove={(tag) => {
                        const tagItem = filtersItems.find(item => item.name === tag);
                        input.onBlur();
                        const newValue = input.value.filter(valueItem => valueItem !== tagItem.id);
                        if (newValue.toString() === '') {
                          setIsEmptyFilter(true);
                        } else {
                          getStatsByFilters({
                            params: {
                              filters: newValue.toString(),
                            },
                          });
                        }
                        input.onChange(newValue);
                      }}
                    />
                  </FormControl>
                )}
              </Field>
            </div>
          </>
        ) : <CampaignCreateControlledOutside />}
      </>
      )}
    </Card>
  );
};

export default CampaignEditTargets;
