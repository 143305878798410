import React from 'react';
import classNames from 'classnames';

import locationIcon from 'assets/icons/rcs/location.svg';

import './style.scss';

const GeolocationElement = ({
  rcsParams: {
    location: {
      latitude,
      longitude,
      name,
      address,
    } = {},
  },
  openGeolocation,
  readOnly,
  type,
}) => {
  const elementClass = classNames('mkit-geolocation-element', {
    'mkit-geolocation-element_with-bg': latitude && longitude,
    [`mkit-geolocation-element_${type}`]: type,
  });

  const onClick = () => {
    if (!readOnly) {
      openGeolocation();
    }
  };

  return (
    <div
      role="presentation"
      className={elementClass}
      onClick={onClick}
    >
      {
        latitude && longitude
          ? (
            <div className="mkit-geolocation-element__values">
              <div className="mkit-geolocation-element__values__row">
                <span className="mkit-geolocation-element__values__row__label">Latitude:</span>
                <span>{latitude}</span>
              </div>
              <div className="mkit-geolocation-element__values__row">
                <span className="mkit-geolocation-element__values__row__label">Longitude:</span>
                <span>{longitude}</span>
              </div>
              {
              name && (
                <div className="mkit-geolocation-element__values__row">
                  <span className="mkit-geolocation-element__values__row__label">Name:</span>
                  <span>{name}</span>
                </div>
              )
            }
              {
              address && (
                <div className="mkit-geolocation-element__values__row">
                  <span className="mkit-geolocation-element__values__row__label">Address:</span>
                  <span>{address}</span>
                </div>
              )
            }
            </div>
          )
          : (
            <img
              src={locationIcon}
              className="mkit-geolocation-element__image"
              alt=""
            />
          )
      }
    </div>
  );
};

export default GeolocationElement;
