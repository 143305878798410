import React from 'react';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';

import { CardDisplay } from 'components/blocks';
import { uikit } from 'components';
import { getEdrMessage } from 'helpers';

import './style.scss';

const EdrBodyCellRenderer = ({ valueFormatted }) => {
  const { t } = useTranslation();

  const [isOpen, toggleOpen] = useToggle(false);

  return (
    <div className="edr-body">
      <div
        role="presentation"
        className="edr-body__button"
        onClick={toggleOpen}
      >
        {t('INSTANCES.EDR.SHOW_CONSTRUCTOR')}
      </div>
      <uikit.Drawer
        visible={isOpen}
        width={533}
        onClose={toggleOpen}
        destroyOnClose
      >
        {
          isOpen && (
            <CardDisplay
              onClose={toggleOpen}
              channel={getEdrMessage(valueFormatted)}
            />
          )
        }
      </uikit.Drawer>
    </div>
  );
};

export default EdrBodyCellRenderer;
