import React, { useMemo } from 'react';

import './style.scss';

const TextBlock = ({
  text,
  type,
  componentsParams,
  className,
}) => {
  const displayText = useMemo(() => {
    if (componentsParams[type]) {
      return text.replace(/{{\d}}/g, (string) => {
        const variableValue = componentsParams[type][string.replace(/{{/g, '').replace(/}}/g, '')];

        return variableValue || string;
      });
    }
    return text;
  }, [text, componentsParams]);

  return (
    <div className={className}>
      {displayText}
    </div>
  );
};

export default TextBlock;
