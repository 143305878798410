import React from 'react';

import {
  DnDSuggestionsOutside,
  ImageElement,
} from 'components/constructor';
import { bytesToSize } from 'helpers';

import './style.scss';

const FileMessage = ({
  rcsParams: {
    suggestionsOutside,
    ...rcsParams
  },
  openCreateSuggestion,
  updateRcsParams,
  openMediaSelect,
  readOnly,
  isOpenInTab,
  openUploadMedia,
}) => {
  const outsideSuggestionsStyles = {
    button: {
      width: 306,
      margin: '24px auto 0',
    },
  };

  const onSuggestionChange = (text, id) => {
    const changedSuggestion = suggestionsOutside.find(I => I.id === id);

    suggestionsOutside[suggestionsOutside.indexOf(changedSuggestion)] = {
      ...changedSuggestion,
      text,
    };

    updateRcsParams({
      [suggestionsOutside]: suggestionsOutside,
    });
  };

  const onAddSuggestion = () => {
    openCreateSuggestion({ place: 'outside' });
  };

  const onRemoveSuggestion = (place, id) => {
    updateRcsParams({ suggestionsOutside: suggestionsOutside.filter(I => I.id !== id) });
  };

  const onFileClick = () => {
    window.open(rcsParams.image.fileUrl, '_blank');
  };

  return (
    <div className="mkit-file-message">
      {
        !rcsParams.image || rcsParams.image?.fileMimeType?.includes('image') || rcsParams.image?.fileMimeType?.includes('video')
          ? (
            <ImageElement
              rcsParams={rcsParams}
              updateRcsParams={updateRcsParams}
              openMediaSelect={openMediaSelect}
              type="any-file"
              readOnly={readOnly}
              onImageClick={isOpenInTab && onFileClick}
              openUploadMedia={openUploadMedia}
              withThumbnailSelect
            />
          )
          : (
            <div className="mkit-file-message__row">
              <div
                role="presentation"
                className="mkit-file-message__staple-button"
                onClick={isOpenInTab ? onFileClick : openMediaSelect}
              />
              <div className="mkit-file-message__info">
                <span>{rcsParams.image.fileName}</span>
                <span>{bytesToSize(rcsParams.image.fileSize)}</span>
              </div>
            </div>
          )
      }
      <DnDSuggestionsOutside
        updateRcsParams={updateRcsParams}
        suggestionsOutside={suggestionsOutside}
        onSuggestionChange={onSuggestionChange}
        onRemoveSuggestion={onRemoveSuggestion}
        openCreateSuggestion={openCreateSuggestion}
        readOnly={readOnly}
        styles={outsideSuggestionsStyles}
        onAddSuggestion={onAddSuggestion}
      />
    </div>
  );
};

export default FileMessage;
