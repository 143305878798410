import React from 'react';

import MiniatureItem from './MiniatureItem';

import './style.scss';

const MediaMiniatureBlock = ({
  assets,
  copyInClipboard,
  saveAs,
  deleteAsset,
  type = '',
  selectedId,
  onSelectItem,
  onScroll = () => {},
  onDoubleClick = () => {},
  firstElement = null,
}) => {
  const onItemPress = (item) => {
    if (onSelectItem) {
      onSelectItem({
        ...item,
        thumbnailUri: item.mediaType !== 'Video' && item.mediaType !== 'Image' ? undefined : item.thumbnailUri,
      });
    }
  };

  return (
    <div className="mkit-media-miniature-block" onScroll={onScroll}>
      {firstElement}
      {
        assets.map(item => (
          <MiniatureItem
            preview={item.thumbnailUri}
            mediaType={item.mediaType}
            fileName={item.fileName}
            fileUrl={item.fileUrl}
            key={item.fileLinkId}
            format={item.format}
            size={item.resolution}
            weight={item.weight}
            fileLinkId={item.fileLinkId}
            copyInClipboard={copyInClipboard}
            saveAs={saveAs}
            deleteAsset={deleteAsset}
            type={type}
            selected={selectedId === item.fileLinkId}
            onSelectItem={() => onItemPress(item)}
            onDoubleClick={() => onDoubleClick(item)}
          />
        ))
      }
    </div>
  );
};

export default MediaMiniatureBlock;
