export const decimal = ({
  integerLimit = '',
  decimalLimit = '',
  withMinus = false,
  withSpaces = true,
} = {}) => `^${withMinus ? '-?' : ''}(?:0|[1-9][${withSpaces ? ' ' : ''}0-9]{0,${integerLimit}})?([?:\\.]|$)([${withSpaces ? ' ' : ''}0-9]{0,${decimalLimit}})?$`;

export const lettersOrNumbers = () => '^[a-z\\d_]{1,}$';

export const letters = ({
  withRussian = true,
  withNumbers = false,
  withSpaces = true,
} = {}) => `^[A-Za-z${withRussian ? 'А-Яа-яЁё' : ''}${withNumbers ? '\\d' : ''}${withSpaces ? '\\s' : ''}]{1,}$`;

export const text = ({
  withRussian = true,
  withSpaces = false,
} = {}) => `^[A-Za-z${withRussian ? 'А-Яа-яЁё' : ''}\\.,\\/#!$%\\^&\\*;:{}#№@"=+'\\?\\-_~\\(\\)\\d${withSpaces ? '\\s' : ''}]{1,}$`;

export const any = () => '^.*$';

export const numbers = ({
  maxLength = 0,
  withPlus = false,
  withSpaces = false,
} = {}) => `^${withPlus ? '[+]?' : ''}[${withSpaces ? '\\s' : ''}\\d]{0,${maxLength || ''}}$`;
