import endpoints from 'const/endpoints';

export default ({
  body,
  successCallback,
}) => ({
  url: endpoints.getFlowNodesUrl(),
  options: {
    method: 'POST',
  },
  body,
  meta: {
    authToken: true,
    successCallback,
  },
});
