import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';

import {
  getTimezones as getTimezonesApi,
  getUserInfo as getUserInfoApi,
  getApiCalls as getApiCallsApi,
  getOperations as getOperationsApi,
  putUserInfo as putUserInfoApi,
} from 'queries/account';

import {
  getArray,
  getObject,
  useISESelector,
} from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetTimezones,
      lastUpdated: lastUpdatedGetTimezones,
    },
    getTimezones,
  ] = useMutation(getTimezonesApi);
  const [, putUserInfo] = useMutation(putUserInfoApi);
  const [
    {
      isPending: isPendingGetUserInfo,
      lastUpdated: lastUpdatedGetUserInfo,
    },
    getUserInfo,
  ] = useMutation(getUserInfoApi);
  const [
    {
      isPending: isPendingGetApiCalls,
      lastUpdated: lastUpdatedGetApiCalls,
    },
    getApiCalls,
  ] = useMutation(getApiCallsApi);
  const [
    {
      isPending: isPendingGetOperations,
      lastUpdated: lastUpdatedGetOperations,
    },
    getOperations,
  ] = useMutation(getOperationsApi);

  const selector = useCallback(state => ({
    timezones: getArray(state, 'timezones'),
    userInfo: getObject(state, 'userInfo'),
    apiCalls: getArray(state, 'apiCalls'),
    apiCallsMeta: getObject(state, 'apiCallsMeta'),
    operations: getArray(state, 'operations'),
  }), []);
  const data = useISESelector(selector);

  return {
    getTimezones,
    getUserInfo,
    getApiCalls,
    getOperations,
    putUserInfo,
    isPendingGetTimezones,
    isPendingGetUserInfo,
    isPendingGetApiCalls,
    isPendingGetOperations,
    lastUpdatedGetTimezones,
    lastUpdatedGetUserInfo,
    lastUpdatedGetApiCalls,
    lastUpdatedGetOperations,
    ...data,
  };
};
