// const CHANNEL_TYPE = {
//   VK: 'vk',
//   Whats_App: 'whathsapp',
//   viber: 'viber',
//   telegram: 'telegram',
//   sms: 'SMS',
//   rcs: 'RCS',
// };
//
// const CHANNELS_ICON = {
//
// };
// import whatsAppIcon from 'assets/socials/whatsapp.svg';
// import telegramIcon from 'assets/socials/telegram.svg';
// import telegramIcon from 'assets/socials/telegram.svg';
import viberIcon from 'assets/icons/viber.svg';
import smsIcon from 'assets/icons/sms.svg';
import rcsIcon from 'assets/icons/rcs.svg';
import whatsAppIcon from 'assets/socials/whatsapp.svg';
import rcsDarkIcon from 'assets/socials/rcs-dark.svg';
import smsDarkIcon from 'assets/socials/sms-dark.svg';

export const CHANNELS = {
  SMS: 1,
  RCS: 2,
  VIBER: 3,
  WHATSAPP: 4,
  WECHAT: 6,
  EMAIL: 7,
};

export const CHANNELS_OPTIONS = [
  {
    icon: whatsAppIcon,
    label: 'WhatsApp',
    value: 4,
  },
  {
    icon: viberIcon,
    label: 'Viber',
    value: 3,
  },
  // {
  //   id: 'telegram',
  //   icon: telegramIcon,
  //   name: 'Telegram',
  // },
  {
    label: 'SMS',
    icon: smsIcon,
    lightThemeIcon: smsDarkIcon,
    value: 1,
  },
  {
    label: 'RCS',
    icon: rcsIcon,
    lightThemeIcon: rcsDarkIcon,
    value: 2,
  },
];
