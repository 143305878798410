import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useBoolean } from 'hooks';
import {
  useFlowsNewApi,
  useSendersApi,
} from 'hooks/api';
import { uikit } from 'components/index';
import { DialogueScriptBlock } from 'components/blocks/index';
import { DropdownFieldNew, SearchField } from 'components/fields';
import { BasicButton, ExpandButton } from 'components/buttons';
import crossIcon from 'assets/icons/red-cross.svg';
import scenarioTreeIcon from 'assets/icons/scenario-tree.svg';

import './style.scss';

const SelectScenario = ({
  onClose,
  threadId,
  onSelect,
  postChangeThread,
  postCreateThread,
  isPostback,
  number,
  initialFlowId,
  initialNodeId,
  channelToFilter,
}) => {
  const searchStyle = {
    width: '100%',
    marginBottom: 20,
  };
  const buttonStyle = {
    container: {
      flex: 1,
      marginRight: 12,
    },
  };
  const buttonRightStyle = {
    container: {
      flex: 1,
    },
  };
  const drawerButtonStyle = {
    container: {
      width: 305,
    },
  };
  const sendersDropdownStyle = {
    container: {
      marginTop: 24,
    },
  };

  const { t } = useTranslation();

  const {
    getFlowsAll,
    flowsAll,
  } = useFlowsNewApi();
  const {
    getSenders,
    senders,
  } = useSendersApi();

  const [
    isOpenCDrawer,
    openDrawer,
    closeDrawer,
  ] = useBoolean(!!initialNodeId);
  const [
    isOpenSender,
    openSender,
    closeSender,
  ] = useBoolean(false);

  const [selectedScenario, setSelectedScenario] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [selectedNodeId, setSelectedNodeId] = useState();
  const [selectedSender, setSelectedSender] = useState();

  const displayedFlows = useMemo(
    () => flowsAll
      .filter(({ name, channel }) => {
        if (channelToFilter) {
          return name?.toLowerCase().includes(searchValue?.toLowerCase()) && channel === channelToFilter;
        }
        return name?.toLowerCase().includes(searchValue?.toLowerCase());
      }),
    [searchValue, channelToFilter],
  );

  const threadOptions = useMemo(() => [
    {
      label: t('CONVERSATIONS.INSTANCES.CONTINUE_IN_CURRENT'),
      value: 'save',
    },
    {
      label: t('CONVERSATIONS.INSTANCES.OPEN_NEW'),
      value: 'create',
    },
  ], []);

  const itemClass = id => classNames('mkit-select-scenario__list__item', {
    'mkit-select-scenario__list__item_selected': id === selectedScenario?.id,
  });

  const sendersOptions = useMemo(() => {
    const selectedFlow = flowsAll.find(({ id }) => id === selectedScenario?.id);

    return senders
      .filter(({ channel }) => channel === selectedFlow?.channel)
      .map(({ senderId, displayName, channel }) => ({
        label: displayName,
        value: `${senderId}/${channel}`,
      }));
  }, [selectedScenario, senders, flowsAll]);

  const onSwitch = () => {
    if (onSelect) {
      onSelect({ scenario: selectedScenario, nodeId: selectedNodeId });
    } else {
      postChangeThread({
        threadId,
        flowId: selectedScenario.id,
        nodeId: selectedNodeId,
      });
    }
    closeDrawer();
    onClose();
  };

  const onCreate = () => {
    const [senderId, channel] = selectedSender.split('/');

    postCreateThread({
      phoneNumber: number,
      channel: +channel,
      senderId,
      flowId: selectedScenario?.id,
      nodeId: selectedNodeId,
    });
    closeSender();
    closeDrawer();
    onClose();
  };

  const onSelectThreadOption = (value) => {
    if (value === 'save') {
      onSwitch();
    } else {
      openSender();
    }
  };

  const onClosePreview = () => {
    setSelectedNodeId();
    closeDrawer();
  };

  const onExpandClick = useMemo(() => {
    if (isPostback) {
      return onSwitch;
    }
    if (!threadId) {
      return openSender;
    }
    return null;
  }, [isPostback, threadId, selectedScenario, selectedNodeId]);

  const onClickNode = useCallback(({ node, widget }) => {
    if (initialNodeId && selectedScenario?.id === initialFlowId) {
      widget.current.updateNode({
        id: initialNodeId,
        entity: { selected: false },
      });
    }
    if (selectedNodeId === node.id) {
      widget.current.updateNode({
        id: node.id,
        entity: { selected: false },
      });
      setSelectedNodeId(null);
    } else {
      if (selectedNodeId) {
        widget.current.updateNode({
          id: selectedNodeId,
          entity: { selected: false },
        });
      }
      widget.current.updateNode({
        id: node.id,
        entity: { selected: true },
      });
      setSelectedNodeId(node.id);
    }

    widget.current.repaintCanvas();
  }, [selectedNodeId, selectedScenario]);

  useEffect(() => {
    if (!flowsAll?.length) {
      getFlowsAll({
        successCallback: (res) => {
          if (initialFlowId) {
            setSelectedScenario(res.find(I => I.id === initialFlowId));
          }
        },
      });
    } else if (initialFlowId) {
      setSelectedScenario(flowsAll.find(I => I.id === initialFlowId));
    }
    getSenders({ limit: 1000, onlyActiveSenders: false });
  }, []);

  return (
    <div className="mkit-select-scenario">
      <h1 className="mkit-select-scenario__header">
        {t('CONVERSATIONS.INSTANCES.SELECT_SCENARIO')}
      </h1>
      <SearchField
        value={searchValue}
        onChange={setSearchValue}
        styleWrapper={searchStyle}
        placeholder={t('CONVERSATIONS.PLACEHOLDERS.SEARCH_SCENARIOS', { count: displayedFlows?.length || 0 })}
        type="full-border"
      />
      <div className="mkit-select-scenario__list">
        {
          displayedFlows.map(I => (
            <div
              className={itemClass(I.id)}
              role="presentation"
              onClick={() => setSelectedScenario(I)}
              key={I.id}
            >
              {I.name}
            </div>
          ))
        }
      </div>
      <div className="mkit-select-scenario__buttons">
        <BasicButton
          onClick={onClose}
          type={BasicButton.types.PRIMARY}
          text={t('CONTROLS.CANCEL')}
          styles={buttonStyle}
          icon={crossIcon}
        />
        <BasicButton
          onClick={openDrawer}
          type={BasicButton.types.PRIMARY}
          text={t('CONVERSATIONS.CONTROLS.PREVIEW_SCRIPT')}
          styles={buttonStyle}
          icon={scenarioTreeIcon}
          filterType="white"
          disabled={!selectedScenario}
        />
        <ExpandButton
          onClick={onExpandClick}
          text={t(`CONVERSATIONS.${isPostback ? 'START_SCRIPT' : 'SWITCH_TO_SCRIPT'}`)}
          styles={buttonRightStyle}
          disabled={!selectedScenario}
          options={threadOptions}
          onItemClick={onSelectThreadOption}
        />
      </div>
      <uikit.Drawer
        visible={isOpenCDrawer}
        onClose={onClosePreview}
        width={750}
        destroyOnClose
      >
        <div className="mkit-select-scenario__preview">
          <h1 className="mkit-select-scenario__header">
            {t('CONVERSATIONS.INSTANCES.PREVIEW_SCENARIO', { name: selectedScenario?.name })}
          </h1>
          <DialogueScriptBlock
            flowId={selectedScenario?.id}
            currentNodeId={initialFlowId === selectedScenario?.id ? initialNodeId : undefined}
            onClickNode={onClickNode}
            readOnlyAlways
          />
          <div className="mkit-select-scenario__buttons">
            <BasicButton
              onClick={onClosePreview}
              type={BasicButton.types.PRIMARY}
              text={t('CONTROLS.CANCEL')}
              styles={drawerButtonStyle}
              icon={crossIcon}
            />
            <ExpandButton
              onClick={onExpandClick}
              text={t(`CONVERSATIONS.${isPostback ? 'START_FROM_STEP' : 'SWITCH_TO_STEP'}`)}
              styles={drawerButtonStyle}
              disabled={!selectedNodeId}
              options={threadOptions}
              onItemClick={onSelectThreadOption}
            />
          </div>
        </div>
      </uikit.Drawer>
      <uikit.Drawer
        visible={isOpenSender}
        onClose={closeSender}
        width={480}
        destroyOnClose
      >
        <div className="mkit-select-scenario__preview">
          <h1 className="mkit-select-scenario__header">
            {t('CONVERSATIONS.INSTANCES.SELECT_SENDER')}
          </h1>
          <DropdownFieldNew
            value={selectedSender}
            onChange={setSelectedSender}
            options={sendersOptions}
            label={t('INSTANCES.SENDER')}
            styles={sendersDropdownStyle}
          />
          <div className="mkit-select-scenario__buttons">
            <BasicButton
              onClick={closeSender}
              type={BasicButton.types.PRIMARY}
              text={t('CONTROLS.CANCEL')}
              icon={crossIcon}
            />
            <BasicButton
              onClick={onCreate}
              type={BasicButton.types.ACCENT}
              text={t(`CONVERSATIONS.${isPostback ? 'START_SCRIPT' : 'SWITCH_TO_SCRIPT'}`)}
              disabled={!selectedSender}
            />
          </div>
        </div>
      </uikit.Drawer>
    </div>
  );
};

export default SelectScenario;
