import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { isEmpty } from 'lodash';
import classNames from 'classnames';

import './style.scss';

const PortWidget = ({
  style,
  className,
  port,
  engine,
}) => {
  const ref = useRef();
  const [isFinal, setIsFinal] = useState(port.options.isFinal);

  const portClass = classNames('port port-out', {
    [className]: className,
    'port-out_end': isFinal,
    'port-out_connected': !isEmpty(port.links),
    [`port-out_${port.getPositionCircle()}`]: !!port.getPositionCircle(),
  });

  const {
    portName,
    nodeId,
  } = useMemo(() => ({
    portName: port?.getName(),
    nodeId: port?.getNode()?.getID(),
  }), [port]);

  const report = () => {
    port.updateCoords(engine.getPortCoords(port, ref.current));
  };
  const handleClick = () => {
    if (!port.options.in && !engine.getModel().isLocked() && isEmpty(port.links)) {
      port.changeTypePort(!isFinal);
      setIsFinal(prevIsFinal => !prevIsFinal);
    }
  };

  useEffect(() => {
    const engineListenerHandle = engine.registerListener({
      canvasReady: () => report(),
    });

    if (engine.getCanvas()) {
      report();
    }

    return () => {
      if (engineListenerHandle) {
        engineListenerHandle.deregister();
      }
    };
  }, []);
  useEffect(() => {
    if (!port.reportedPosition) {
      report();
    }
  }, [port.reportedPosition]);

  return (
    <div
      style={style}
      ref={ref}
      className={portClass}
      data-name={portName}
      data-nodeid={nodeId}
    >
      <div
        role="presentation"
        className="port-out__circle port-out__circle_left"
        onClick={handleClick}
        data-testid={`${window.location.pathname}/${portName}/${nodeId}/left`}
        // data-id="circle"
      />
      {
        port.getOptions().withBottomCircle && (
          <div
            role="presentation"
            className="port-out__circle port-out__circle_bottom"
            onClick={handleClick}
            data-testid={`${window.location.pathname}/${portName}/${nodeId}/bottom`}
            // data-id="circle"
          />
        )
      }
      <div
        role="presentation"
        className="port-out__circle port-out__circle_right"
        data-testid={`${window.location.pathname}/${portName}/${nodeId}/right`}
        onClick={handleClick}
        // data-id="circle"
      />
    </div>
  );
};

export default PortWidget;
