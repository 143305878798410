import React from 'react';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { BasicField } from 'components/fields';
import { BasicButton } from 'components/buttons';
import { required } from 'helpers/validators';

import './style.scss';

const ConfigButtonForm = ({
  onSubmit,
  initialValues,
  onClose,
  readOnly,
}) => {
  const basicButtonStyle = {
    alignSelf: 'center',
    justifyContent: 'center',
  };
  const commonFieldStyle = {
    marginBottom: 12,
  };

  const { t } = useTranslation();

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit} className="mkit-config-button-form">
          <h2 className="mkit-config-button-form__title">
            {t('MESSAGE_CONSTRUCTOR.INSTANCES.ACTION_SETTINGS')}
          </h2>
          <Field
            name="buttonCaption"
            component={BasicField}
            label={t('INSTANCES.TEXT')}
            styleWrapper={commonFieldStyle}
            type="with-border"
            isRequired
            validate={value => required(value, t)}
            maxLength={30}
            isDisabled={readOnly}
          />
          <Field
            name="buttonUrl"
            component={BasicField}
            label={t('MESSAGE_CONSTRUCTOR.INSTANCES.URL')}
            styleWrapper={commonFieldStyle}
            type="with-border"
            isRequired
            validate={value => required(value, t)}
            maxLength={32}
            isDisabled={readOnly}
          />
          <div className="mkit-config-button-form__buttons">
            <BasicButton
              text={t('CONTROLS.CANCEL')}
              type={BasicButton.types.CANCEL}
              onClick={onClose}
              style={basicButtonStyle}
              isNewStyle
            />
            <BasicButton
              text={t('CONTROLS.APPLY')}
              type={BasicButton.types.ACCENT}
              onClick={handleSubmit}
              style={basicButtonStyle}
              disabled={readOnly}
              isNewStyle
            />
          </div>
        </form>
      )}
    />
  );
};

export default ConfigButtonForm;
