import { useState } from 'react';

export default (initialValue = false) => {
  const [isModalOpen, setIsModalOpen] = useState(initialValue);
  const isManyModal = typeof initialValue === 'string';

  const closeModal = () => setIsModalOpen(isManyModal ? '' : false);
  const openModal = name => setIsModalOpen(isManyModal ? name : true);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  return [
    isModalOpen,
    {
      openModal,
      closeModal,
      toggleModal,
    },
  ];
};
