import React from 'react';
import SendersScreen from 'mkit-front-ds/dist/screens/SendersScreen';

import { useTheme } from 'hooks';
import { useUsersApi } from 'hooks/api';

import './style.scss';

const SenderIdsScreen = () => {
  const { isDarkTheme } = useTheme();
  const { currentUser } = useUsersApi();

  return (
    <div className="sender-ids-screen">
      <SendersScreen
        externalDarkTheme={isDarkTheme}
        userPermissions={currentUser?.roles || []}
      />
    </div>
  );
};

export default SenderIdsScreen;
