import React, { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';

import Tag from 'components/Tags/Tag';
/*eslint-disable */
import './style.scss';

const ViewTags = ({
  tags = [], // string[] - список тегов
  maxTagsToDisplay = 2, // number - сколько тегов отображать, остальное уходит под "+N". To display all - set undefined
  wrapperStyle,
  onDisplayAllTags = Function.prototype,
}) => {
  const [displayAllTags, setDisplayAllTags] = useState(tags.length <= maxTagsToDisplay);

  const tagsGroupWrapperClass = classNames({
    'mkit-tags-group': true,
    'mkit-tags-group_detail': true,
  });

  const restTagNames = useMemo(() => {
    const tooltipTags = tags.slice(maxTagsToDisplay, tags.length);

    return tooltipTags.reduce((acc, item) => {
      acc += acc ? `, ${item}` : item;

      return acc;
    }, '');
  }, [maxTagsToDisplay, tags]);

  useEffect(() => {
    if (tags.length <= maxTagsToDisplay) return undefined;

    const handleClickOutside = () => {
      if (displayAllTags) {
        setDisplayAllTags(false);
        onDisplayAllTags();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [displayAllTags]);

  const groupWrapperStyle = wrapperStyle || {
    maxWidth: 0,
  };

  return (
    <div className={tagsGroupWrapperClass} style={groupWrapperStyle}>
      <div className="mkit-tags-group__tags">
        {(!!maxTagsToDisplay && !displayAllTags) ? tags.slice(0, maxTagsToDisplay).map(tag => (
          <Tag
            tagText={tag}
            allTagsValues={tags}
            isEditable={false}
            key={tag}
          />
        ))
          : tags.map(tag => (
            <Tag
              tagText={tag}
              allTagsValues={tags}
              isEditable={false}
              key={tag}
            />
          ))}
      </div>
      {(tags.length > maxTagsToDisplay && !!maxTagsToDisplay && !displayAllTags) && (
        <div onClick={(e) => {
            e.stopPropagation();
            setDisplayAllTags(true);
            onDisplayAllTags();
        }}>
          <Tag
            isMany
            wrapperStyle={{ marginLeft: '6px' }}
            tagText={'+' + (tags.length - maxTagsToDisplay)}
            tooltipText={restTagNames}
          />
        </div>
      )}
    </div>
  );
};

export default ViewTags;
