import React, { useEffect, useRef, useMemo } from 'react';
import echarts from 'echarts';
import { merge } from 'lodash';

import { useTheme } from 'hooks';

import './style.scss';

const getTextColor = isDark => (isDark ? '#fff' : '#002D3E');
const getAxisColor = isDark => (isDark ? '#C4C4C4' : '#C4C4C4');
const getDefaultColorPalete = isDark => (isDark
  ? ['rgba(12, 145, 192, 1)', 'rgba(12, 145, 192, 0.3)']
  : ['rgba(77, 138, 160, 1)', 'rgba(12, 145, 192, 0.3)']
);

const ECharts = ({
  options,
}) => {
  const { isDarkTheme } = useTheme();
  const ref = useRef();
  const chartRef = useRef();

  const defaultOptions = useMemo(() => {
    const globalTextStyle = {
      fontFamily: 'notosans-regular',
      fontSize: 12,
      lineHeight: 16,
      color: getTextColor(isDarkTheme),
    };

    return {
      textStyle: globalTextStyle,
      title: {
        left: '49%',
        textAlign: 'center',
        textStyle: globalTextStyle,
      },
      legend: {
        textStyle: globalTextStyle,
        itemGap: 22,
      },
      tooltip: {
        show: true,
        trigger: 'item',
      },
      color: getDefaultColorPalete(isDarkTheme),
      grid: {
        left: '3%',
        right: '10%',
        top: '5%',
        bottom: '5%',
        containLabel: true,
      },
      xAxis: {
        splitLine: { show: false },
        axisLine: {
          lineStyle: { color: getAxisColor(isDarkTheme) },
        },
        axisTick: { show: false },
      },
      yAxis: {
        splitLine: { show: false },
        axisLine: {
          lineStyle: { color: getAxisColor(isDarkTheme) },
        },
        axisTick: {
          lineStyle: { color: getAxisColor(isDarkTheme) },
        },
        boundaryGap: false,
      },
    };
  }, [isDarkTheme]);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.clear();
    }
    chartRef.current = echarts.init(ref.current);
    chartRef.current.setOption(merge({}, defaultOptions, options));
  }, [defaultOptions, options]);

  useEffect(() => {
    const resize = () => chartRef.current?.resize();

    window.addEventListener('resize', resize, { passive: true });
    return () => {
      window.removeEventListener('resize', resize);
      if (chartRef.current) {
        chartRef.current.clear();
      }
    };
  }, []);

  return <div ref={ref} className="echarts-container" />;
};

export default ECharts;
