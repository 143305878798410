import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { toast } from 'helpers';
import { CONDITION_TYPES, TRIGGER_TYPES } from 'const/scenario';
import arrowsTwoIcon from 'assets/icons/arrows-two.svg';
import userReplyIcon from 'assets/icons/user-reply.svg';

import PortInWidget from '../PortIn/PortInWidget';
import PortWidget from '../Port/PortWidget';

import './style.scss';

const ConditionNodeWidget = ({
  node,
  engine,
}) => {
  const {
    entity: {
      label,
      type,
      triggerType,
      selected,
    } = {},
  } = node;
  const { t } = useTranslation();

  const onCrossClick = () => {
    if (triggerType !== 0) {
      engine.fireEvent({ node }, 'deleteNode');
    } else {
      toast.error(t('TOAST.START_NODE_DELETE'));
    }
  };
  const onCloneNode = () => {
    engine.fireEvent({ node }, 'nodeCloned');
  };

  const labelNode = useMemo(() => {
    switch (triggerType) {
      case TRIGGER_TYPES.START:
        return {
          class: 'start',
          textKey: 'SCENARIOS_CONSTRUCTOR.INSTANCES.START',
        };
      case TRIGGER_TYPES.FAILOVER:
        return {
          class: 'failover',
          textKey: 'SCENARIOS_CONSTRUCTOR.INSTANCES.FAILOVER',
        };
      case TRIGGER_TYPES.RESPONSE_TIMEOUT:
        return {
          class: 'response-timeout',
          textKey: 'SCENARIOS_CONSTRUCTOR.INSTANCES.RESP_TIMEOUT',
        };
      case TRIGGER_TYPES.STUCK_TIMEOUT:
        return {
          class: 'stuck-timeout',
          textKey: 'SCENARIOS_CONSTRUCTOR.INSTANCES.STUCK_TIMEOUT',
        };
      case TRIGGER_TYPES.OPT_OUT:
        return {
          class: 'opt-out',
          textKey: 'SCENARIOS_CONSTRUCTOR.INSTANCES.OPT_OUT',
        };
      default: return {};
    }
  }, [triggerType]);

  const onDoubleClick = () => {
    engine.fireEvent({ node }, 'onClickNode');
  };

  const containerClass = classNames('condition-node', {
    'condition-node_selected': selected,
  });
  const labelClass = classNames('condition-node__label', {
    [`condition-node__label_${labelNode.class}`]: !!labelNode.class,
  });

  // move else block to end & sorting by id of first element in a.options.condition
  const outPorts = node.getOutPorts().sort((a, b) => {
    if (a?.options?.condition && a?.options?.condition?.some(c => c?.conditionType === 5)) {
      return 1;
    }
    if (b?.options?.condition && b?.options?.condition.some(c => c?.conditionType === 5)) {
      return -1;
    }
    return 0;
  }).map((obj) => {
    if (obj?.options?.condition) {
      obj?.options?.condition?.sort((c1, c2) => c1?.id?.localeCompare(c2?.id));
    }
    return obj;
  });

  const isNeedToShowElsePorts = (type === 1
          && node.getOutPorts()?.some(item => item?.options?.condition?.some(condition => condition?.conditionType)))
  || (type !== 1 && node.getOutPorts()?.some(el => el?.options?.condition[0].conditionType === 5));

  return (
    <div
      className={containerClass}
      onDoubleClick={onDoubleClick}
    >
      {
        !engine.getModel().isLocked() && (
          <button
            data-id="delete-button"
            aria-label="delete"
            className="condition-node__delete"
            type="button"
            onClick={onCrossClick}
          />
        )
      }
      {
        !engine.getModel().isLocked() && (
          <button
            data-id="clone-button"
            aria-label="clone"
            className="condition-node__clone"
            type="button"
            onClick={onCloneNode}
          />
        )
      }
      {
        triggerType !== null && (
          <div className={labelClass}>
            {t(labelNode.textKey)}
          </div>
        )
      }
      <div className="condition-node__header">
        <div className="condition-node__header__ports-in">
          <div className="condition-node__header__ports-in__left">
            <PortInWidget
              engine={engine}
              port={node.getPort('in-1')}
            />
          </div>
          <div className="condition-node__header__ports-in__center">
            {
              !!node.getPort('in-2') && (
                <PortInWidget
                  engine={engine}
                  port={node.getPort('in-2')}
                />
              )
            }
          </div>
          <div className="condition-node__header__ports-in__right">
            <PortInWidget
              engine={engine}
              port={node.getPort('in-3')}
            />
          </div>
        </div>
        <img
          className={`condition-node__header__icon ${type === 2 ? ' condition-node__header__icon_condition' : ''}`}
          src={type === 2 ? arrowsTwoIcon : userReplyIcon}
          alt=""
        />
        <span className="condition-node__header__label">
          {label}
        </span>
      </div>
      {
        type === 1 ? (
          outPorts.map(item => (
            <div key={item.getID()} className="condition-node__item">
              {item.options.condition && item.options.condition.map(condition => (
                <div
                  className="condition-node__item__condition"
                  key={`${condition.condition}-${condition.conditionType}`}
                >
                  <div
                    className="condition-node__item__condition__type"
                  >
                    {CONDITION_TYPES[condition.conditionType]}
                  </div>
                  <div className="condition-node__item__condition__value">
                    {condition.condition}
                  </div>
                </div>
              ))}
              <PortWidget
                engine={engine}
                port={item}
              />
            </div>
          ))
        ) : (
          outPorts.map((item) => {
            if (item.options.condition[0] && item.options.condition[0].conditionType === 5) {
              return (
                <div className="condition-node__else-block">
                  <div
                    className="condition-node__item__condition__type"
                  >
                    {CONDITION_TYPES[item.options.condition[0].conditionType]}
                  </div>
                  <div className="condition-node__item__condition__value">
                    {item.options.condition[0].condition}
                  </div>
                  <PortWidget
                    engine={engine}
                    port={item}
                  />
                </div>
              );
            }
            if (item.options.condition[0] && typeof item.options.condition[0].condition === 'string') {
              return (
                <div key={item.getID()} className="condition-node__item">
                  <span className="condition-node__item__text">
                    {item.options.condition[0].condition}
                  </span>
                  <PortWidget
                    engine={engine}
                    port={item}
                  />
                </div>
              );
            }
            return (
              <div key={item.getID()} className="condition-node__item">
                <span className="condition-node__item__text">
                  {item.options.condition[0]}
                </span>
                <PortWidget
                  engine={engine}
                  port={item}
                />
              </div>
            );
          })
        )
      }
      {
        // type === 1 ? (
        //   userResponses && userResponses.map((conditionGroup, index) => (
        //     <div
        //       className="condition-node__item"
        //       key={index}
        //     >
        //       {conditionGroup.map(condition => (
        //         <div
        //           className="condition-node__item__condition"
        //           key={`${condition.condition}-${condition.conditionType}`}
        //         >
        //           <div
        //             className="condition-node__item__condition__type"
        //             // data-for="typeCondition"
        //             // data-tip={CONDITION_TYPES[condition.conditionType]}
        //           >
        //             {CONDITION_TYPES[condition.conditionType]}
        //           </div>
        //           <div className="condition-node__item__condition__value">
        //             {condition.condition}
        //           </div>
        //         </div>
        //       ))}
        //       {/*<Tooltip id="typeCondition" place="left" />*/}
        //       <PortWidget
        //         engine={engine}
        //         port={node.getPort(outPorts[index])}
        //       />
        //     </div>
        //   ))
        // ) : (
        //   conditions && conditions.map((item, index) => (
        //     <div className="condition-node__item">
        //       <span className="condition-node__item__text">
        //         {item}
        //       </span>
        //       <PortWidget
        //         engine={engine}
        //         port={node.getPort(outPorts[index])}
        //       />
        //     </div>
        //   ))
        // )
      }
      {
        // eslint-disable-next-line max-len
        !isNeedToShowElsePorts && node.getElsePort() && (
          <div className="condition-node__else-block">
            ELSE
            <PortWidget
              engine={engine}
              port={node.getElsePort()}
            />
          </div>
        )
      }
    </div>
  );
};

export default ConditionNodeWidget;
