import React, { useMemo } from 'react';
import { Table } from 'antd';

import './style.scss';

const emptyText = 'No Data Found';

const AntTable = ({
  columns,
  filteredColumns,
  wrapperRef,
  rowKey = 'id',
  loading,
  ...props
}) => {
  const viewColumns = useMemo(() => {
    if (!filteredColumns) {
      return columns;
    }

    return columns.filter(({ dataIndex }) =>
      filteredColumns[dataIndex] === undefined
      || filteredColumns[dataIndex]);
  }, [filteredColumns]);

  return (
    <Table
      pagination={false}
      rowKey={rowKey}
      locale={{
        emptyText: loading ? ' ' : emptyText,
      }}
      columns={viewColumns}
      loading={loading}
      {...props}
    />
  );
};

export default AntTable;
