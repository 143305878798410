import React, { useState } from 'react';
import cx from 'classnames';
import moment from 'moment';

import styles from './Row.module.scss';

const Row = ({
  row,
  referenceBuildBranch,
}) => {
  const buildBranchClass = cx({
    [styles.missMatchVersion]: row['build.branch'] && referenceBuildBranch
    && row['build.branch'] !== referenceBuildBranch,
  });
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const onClick = () => setIsPopoverOpen(!isPopoverOpen);

  return (
    <div className={styles.row}>
      <div>{row.serviceName}</div>
      <div>{row.version}</div>
      <div>{row['build.date'] ? moment(row['build.date']).format('DD.MM.YYYY HH:mm:ss') : undefined}</div>
      <div>{row['commit.date'] ? moment(row['commit.date']).format('DD.MM.YYYY HH:mm:ss') : undefined}</div>
      <div className={buildBranchClass}>{row['build.branch']}</div>
      <div>{row['build.number']}</div>
      <div style={{ color: `var(--${row.health === 'UP' ? 'green' : 'red'}60)` }}>{row.health}</div>
      <div className={styles.fakeCell}>
        {isPopoverOpen && (
          <div className={styles.overlay}>
            {Object.entries(row).map(([key, value]) => (
              <div className={styles.line} key={key}>
                <span>{key}</span>
                <span>{value}</span>
              </div>
            ))}
          </div>
        )}
      </div>
      <div
        className={styles.button}
        role="presentation"
        onClick={onClick}
        style={isPopoverOpen ? { color: 'var(--orange70)' } : undefined}
      >
        {`${isPopoverOpen ? 'Hide' : 'View'} all data`}
      </div>
    </div>
  );
};

export default Row;
