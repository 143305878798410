/* eslint-disable */
import React from 'react';
import { useTranslation } from 'react-i18next';
import SortableList, { SortableItem } from 'react-easy-sort';

import { SuggestionItem } from 'components/constructor';
import { SuggestionButton } from 'components/buttons';

import './style.scss';

const DnDSuggestionsOutside = ({
  updateRcsParams,
  suggestionsOutside,
  onSuggestionChange,
  onRemoveSuggestion,
  openCreateSuggestion,
  cardIndex,
  readOnly,
  onAddSuggestion,
  styles = {},
}) => {
  const { t } = useTranslation();

  const onDragEnd = (oldIndex, newIndex) => {
    const result = Array.from(suggestionsOutside);
    const [removed] = result.splice(oldIndex, 1);
    result.splice(newIndex, 0, removed);

    updateRcsParams({ suggestionsOutside: result });
  };

  return (
    <>
      <SortableList
        onSortEnd={onDragEnd}
        className="mkit-dnd-suggestions-outside"
        style={styles.wrapper}
      >
        {
					suggestionsOutside.map(I => (
						<SortableItem key={I.id}>
              <div className="mkit-dnd-suggestions-outside__wrapper">
                <SuggestionItem
                  text={I.text}
                  icon={I.icon}
                  onChangeText={value => onSuggestionChange(value, I.id, 'outside')}
                  id={I.id}
                  onRemove={() => onRemoveSuggestion('outside', I.id)}
                  onEdit={() => openCreateSuggestion({
                    place: 'outside',
                    initialValues: I,
                    isReply: I.type === 'reply',
                  })}
                  cardIndex={cardIndex}
                  readOnly={readOnly}
                />
              </div>
						</SortableItem>
					))
        }
      </SortableList>
	    {
	    	!readOnly && (
	    		<SuggestionButton
				    text={suggestionsOutside.length >= 11 ? t('MESSAGE_CONSTRUCTOR.CONTROLS.MAXIMUM_CHIPS', { count: 11 }) : t('MESSAGE_CONSTRUCTOR.CONTROLS.REPLY_OR_ACTION')}
				    disabled={suggestionsOutside.length >= 11}
				    type="outside"
				    style={styles.button}
				    onClick={() => onAddSuggestion('outside')}
			    />
		    )
	    }
    </>
  );
};

export default DnDSuggestionsOutside;
