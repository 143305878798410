import React from 'react';
import DashboardScreen from 'mkit-front-ds/dist/screens/DashboardScreen';

import './style.scss';

const NewDashboard = () => (
  <div className="new-dashboard-screen">
    <DashboardScreen />
  </div>
);

export default NewDashboard;
