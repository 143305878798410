import React from 'react';

import './style.scss';

const OperationCellRenderer = ({ valueFormatted }) => (
  <div className="operation-cell-renderer">
    {valueFormatted.operation}
    <span className="operation-cell-renderer__count">
      {valueFormatted.entriesCount}
    </span>
  </div>
);

export default OperationCellRenderer;
