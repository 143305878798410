import { DefaultNodeModel } from '@projectstorm/react-diagrams';
import { PortModelAlignment } from '@projectstorm/react-diagrams-core';

import PortInModel from '../PortIn/PortInModel';
import PortModel from '../Port/PortModel';

export default class ActionNodeModel extends DefaultNodeModel {
  constructor(options = {}) {
    super({
      ...options,
      type: 'action-node',
    });
    this.entity = options.entity;

    // setup an in and out port
    this.addPort(
      new PortInModel({
        in: true,
        name: 'in-1',
      }),
    );

    if (!options.withoutTopPort) {
      this.addPort(
        new PortInModel({
          in: true,
          name: 'in-2',
        }),
      );
    }

    this.addPort(
      new PortInModel({
        in: true,
        name: 'in-3',
      }),
    );
  }

  serialize() {
    return {
      ...super.serialize(),
      entity: this.entity,
      locked: this.isLocked(),
    };
  }

  deserialize(ob, engine) {
    super.deserialize(ob, engine);
    this.entity = ob.entity;
  }

  addOutPort(options, after = true) {
    const port = new PortModel({
      in: false,
      name: options.id,
      label: options.id,
      ...options,
      alignment: PortModelAlignment.RIGHT,
    });
    if (!after) {
      this.portsOut.splice(0, 0, port);
    }
    return this.addPort(port);
  }

  addInPort(label, after = true) {
    const p = new PortInModel({
      in: true,
      name: label,
      label,
      alignment: PortModelAlignment.LEFT,
    });
    if (!after) {
      this.portsIn.splice(0, 0, p);
    }
    return this.addPort(p);
  }

  removePort(port) {
    if (this.getLink(port.getID())) {
      // todo костыль, что бы при удалениии линка не вызывал событие 'portUpdated'
      port.options.isRemove = true;
      this.getLink(port.getID()).remove();
    }

    //  если удаляется порт 'out', вызываем событие 'portRemoved'
    if (!port.getOptions().in) {
      this.getParentCanvasModel().fireEvent({ port }, 'portRemoved');
    }

    super.removePort(port);
  }

  remove() {
    super.remove();

    const parentCanvasModel = this.getParentCanvasModel();
    if (parentCanvasModel) {
      parentCanvasModel.fireEvent({ node: this }, 'nodeRemoved');
    }
  }

  getInPort() {
    return this.portsIn[0];
  }

  // getOutPorts() {
  //   return filter(this.ports, portModel => !portModel.in);
  // }
}
