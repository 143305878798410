import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';

import tagIcon from 'assets/icons/tag.svg';
import closeIcon from 'assets/icons/cross_blue.svg';
import Autocomplete from 'components/fields/Autocomplete';
/*eslint-disable */
import './style.scss';

const AddTag = ({
  allTagsValues, // string[] - значения всех возможных тегов (для автодополнения)
  selectedTagsValue, // string[] - значения всех возможных тегов (для автодополнения)
  onTagAdd, // output event: добавление тега
}) => {
  const allTagsMappedValues = allTagsValues ? allTagsValues.map((el, i) => ({ id: i, text: el })) : [];
  const selectedTagsMappedValue = selectedTagsValue ? selectedTagsValue.map((el, i) => ({ id: i, text: el })) : [];

  const [isEditableNow, setIsEditableNow] = useState(false);
  const [innerTagValue, setInnerTagValue] = useState('');
  const [isInputCorrect, setIsInputCorrect] = useState(true);
  const tagRef = useRef(null);

  const handleKeyPress = (event) => {
    if (event.key !== 'Enter') return;
    // Доп. проверки: значение != пустой строке и такого тега еще нет
    if (innerTagValue === '' || selectedTagsMappedValue.indexOf(innerTagValue) >= 0) {
      setIsInputCorrect(false);
      return;
    }

    setIsInputCorrect(true);
    setIsEditableNow(false);
    onTagAdd(innerTagValue);
    setInnerTagValue('');
  };

  const onSelectTag = (data) => {
    setIsInputCorrect(true);
    setIsEditableNow(false);
    onTagAdd(data);
    setInnerTagValue('');
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!isEditableNow) return;
      if (tagRef && tagRef.current && tagRef.current.contains(e.target)) return;

      // если такой тег уже есть - подсвечиваем ошибку
      if (selectedTagsMappedValue.indexOf(innerTagValue) >= 0) {
        setIsInputCorrect(false);
        return;
      }
      // если пустая строка - просто закрываем
      if (innerTagValue === '') {
        setIsInputCorrect(true);
        setIsEditableNow(false);
        return;
      }

      setIsInputCorrect(true);
      setIsEditableNow(false);
      onTagAdd(innerTagValue);
      setInnerTagValue('');
    };

    document.addEventListener('mousedown', handleClickOutside, false);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside, false);
    };
  });

  const addTagWrapperClass = classNames({
    'mkit-add-tag__wrapper': true,
    'mkit-add-tag__wrapper_opened': isEditableNow,
    'mkit-add-tag__wrapper_incorrect': !isInputCorrect,
  });

  return (
    <div style={{ width: '100px' }}>
      <div
        className={addTagWrapperClass}
        ref={tagRef}
        onClick={() => setIsEditableNow(true)}
        onKeyPress={(e) => { handleKeyPress(e); }}
      >
        {!isEditableNow && <img className="mkit-add-tag__button" alt="" src={closeIcon} />}
        {isEditableNow && (
        <Autocomplete
          values={allTagsMappedValues}
          inputProps={{ placeHolder: 'Enter Value' }}
          wrapperStyle={{}}
          inputIcon={tagIcon}
          onChange={data => setInnerTagValue(data)}
          onSelect={onSelectTag}
        />
        )}
      </div>
    </div>
  );
};

export default AddTag;
