import endpoints from 'const/endpoints';
import { getTransformedAssets } from 'helpers';

export default ({
  limit = 10,
  offset = 0,
  successCallback = Function.prototype,
  errorCallback = Function.prototype,
  saveToRedux = true,
  ...queries
} = {}) => ({
  url: endpoints.getAssetsUrl(),
  queryKey: endpoints.getAssetsUrl(),
  body: {
    limit,
    offset: offset ? ((offset - 1) * limit) : offset,
    ...queries,
  },
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
    successCallback,
    errorCallback,
  },
  transform: (response) => {
    const {
      list,
      ...other
    } = response;

    return {
      assets: getTransformedAssets(list),
      assetsMeta: other,
    };
  },
  update: {
    assets: (oldValue, newValue) => (saveToRedux ? newValue : oldValue),
    assetsMeta: (oldValue, newValue) => (saveToRedux ? newValue : oldValue),
  },
});
