import rcsIcon from 'assets/socials/rcs.svg';
import rcsDarkIcon from 'assets/socials/rcs-dark.svg';
import smsIcon from 'assets/socials/sms.svg';
import smsDarkIcon from 'assets/socials/sms-dark.svg';
import viberIcon from 'assets/socials/viber.svg';
import whatsAppIcon from 'assets/socials/whatsapp.svg';
import rcsBlockedIcon from 'assets/socials/rcs-blocked.svg';
import rcsBlockedDarkIcon from 'assets/socials/rcs-blocked-dark.svg';
import smsBlockedIcon from 'assets/socials/sms-blocked.svg';
import smsBlockedDarkIcon from 'assets/socials/sms-blocked-dark.svg';
import viberBlockedIcon from 'assets/socials/viber-blocked.svg';
import viberBlockedDarkIcon from 'assets/socials/viber-blocked-dark.svg';
import whatsAppBlockedIcon from 'assets/socials/whatsapp-blocked.svg';
import whatsAppBlockedDarkIcon from 'assets/socials/whatsapp-blocked-dark.svg';
import weChatIcon from 'assets/socials/wechat.svg';
import emailIcon from 'assets/socials/email.svg';

export default (value, isDark = true, isAllChannel) => {
  let channel;
  if (Number.isInteger(value)) {
    channel = value;
  } else if (value) {
    channel = value.toUpperCase();
  }

  switch (channel) {
    case 1:
    case 'SMS':
      if (isAllChannel) {
        return isDark ? smsBlockedIcon : smsBlockedDarkIcon;
      }

      return isDark ? smsIcon : smsDarkIcon;
    case 2:
    case 'RCS':
      if (isAllChannel) {
        return isDark ? rcsBlockedIcon : rcsBlockedDarkIcon;
      }

      return isDark ? rcsIcon : rcsDarkIcon;
    case 3:
    case 'VIBER':
      if (isAllChannel) {
        return isDark ? viberBlockedIcon : viberBlockedDarkIcon;
      }

      return viberIcon;
    case 4:
    case 'WHATSAPP':
      if (isAllChannel) {
        return isDark ? whatsAppBlockedIcon : whatsAppBlockedDarkIcon;
      }

      return whatsAppIcon;
    case 6:
    case 'WECHAT':
      if (isAllChannel) {
        return weChatIcon;
      }

      return weChatIcon;
    case 7:
    case 'EMAIL':
      if (isAllChannel) {
        return emailIcon;
      }

      return emailIcon;
    default: return '';
  }
};
