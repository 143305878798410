import React from 'react';
import OptOutsScreen from 'mkit-front-ds/dist/screens/OptOutsScreen';

import { useUsersApi } from 'hooks/api';

import './style.scss';

const StopWordsScreen = () => {
  const { currentUser } = useUsersApi();

  return (
    <div className="opt-outs-container">
      <OptOutsScreen userPermissions={currentUser?.roles || []} />
    </div>
  );
};

export default StopWordsScreen;
