import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Field, FormSpy } from 'react-final-form';

import waringImg from 'assets/icons/warning.svg';
import calendarImg from 'assets/icons/calendar.svg';
import { buttons } from 'components';
import { TimePickerField } from 'components/fields';
import uikit from 'components/UIKit';

const {
  FormLayout, InlineCalendar, FormControl,
} = uikit;
const { BasicButton } = buttons;

const formItemLayout = {
  labelCol: { sm: 4, xxl: 8 },
  wrapperCol: { sm: 20, xxl: 16 },
  labelAlign: 'left',
};

const CampaignEditLaunchTimeDefault = ({ disabled }) => {
  const { t } = useTranslation();

  return (
    <FormLayout {...formItemLayout}>
      <Field subscription={{ value: true }} defaultValue name="isInstantly">
        {fieldProps => (
          <div className="campaign-create-launch-time__type">
            <span className="campaign-create-launch-time__type__label">
              {t('INSTANCES.CAMPAIGNS.DATE')}
            </span>
            <div className="campaign-create-launch-time__type__buttons">
              <BasicButton
                icon={waringImg}
                text={t('INSTANCES.CAMPAIGNS.INSTANTLY')}
                type={fieldProps.input.value
                  ? BasicButton.types.ACCENT
                  : BasicButton.types.PRIMARY}
                onClick={() => fieldProps.input.onChange(true)}
                disabled={disabled}
              />
              <BasicButton
                icon={calendarImg}
                filterType="white"
                text={t('INSTANCES.CAMPAIGNS.SCHEDULE')}
                type={fieldProps.input.value
                  ? BasicButton.types.PRIMARY
                  : BasicButton.types.ACCENT}
                onClick={() => fieldProps.input.onChange(false)}
                disabled={disabled}
              />
            </div>
          </div>
        )}
      </Field>
      <FormSpy subscription={{ values: true }}>
        {({ values }) => (
          <div className={classNames('campaign-create-launch-time__time', {
            'campaign-create-launch-time__disabled-block': values.isInstantly,
          })}
          >
            <Field
              label={t('INSTANCES.CAMPAIGNS.HOUR')}
              name="time"
              typeOverlay="primary-color"
              component={TimePickerField}
              styles={{
                container: { justifyContent: 'space-between' },
                input: { maxWidth: 282 },
              }}
            />
            <Field name="date">
              {({ input, ...props }) => (
                <FormControl meta={props.meta}>
                  <InlineCalendar minDate={new Date()} {...input} {...props} />
                </FormControl>
              )}
            </Field>
          </div>
        )}
      </FormSpy>
    </FormLayout>
  );
};

export default CampaignEditLaunchTimeDefault;
