import endpoints from 'const/endpoints';

export default ({
  body,
  id,
  successCallback,
  errorCallback,
}) => ({
  url: endpoints.getRecipientsByIdUrl(id),
  body,
  options: {
    method: 'PUT',
  },
  meta: {
    authToken: true,
    successCallback,
    errorCallback,
  },
});
