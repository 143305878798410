import React, { useEffect } from 'react';
import { Field, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { uikit } from 'components';
import { BasicField, DropdownFieldNew, SwitchField } from 'components/fields';
import { required } from 'helpers/validators';
import { inputPatterns } from 'helpers';
import locationIcon from 'assets/icons/rcs/location.svg';
import pushIcon from 'assets/icons/rcs/push.svg';

const DisplayedFields = ({
  values: {
    category,
    application,
    type,
    by,
  },
  form: {
    change,
  },
  readOnly,
}) => {
  const commonFieldStyle = {
    marginBottom: 12,
  };
  const dropdownFieldStyles = {
    container: {
      marginBottom: 12,
    },
    label: {
      display: 'flex',
      flex: 3,
      paddingRight: 12,
    },
    content: {
      flex: 7,
    },
  };
  const datePickerStyle = { flex: 7, paddingLeft: 24 };
  const dateLabelStyle = { display: 'flex', flex: 3 };

  const { t } = useTranslation();

  const callTypes = [
    {
      value: 'dialPhoneNumber',
      label: t('MESSAGE_CONSTRUCTOR.INSTANCES.DIAL_PHONE_NUMBER'),
    },
    {
      value: 'dialEnrichedCall',
      label: t('MESSAGE_CONSTRUCTOR.INSTANCES.DIAL_ENRICHED_CALL'),
    },
    {
      value: 'dialVideoCall',
      label: t('MESSAGE_CONSTRUCTOR.INSTANCES.DIAL_VIDEO_CALL'),
    },
  ];
  const locationTypes = [
    {
      value: 'showLocation',
      label: t('MESSAGE_CONSTRUCTOR.INSTANCES.SHOW_LOCATION'),
      icon: locationIcon,
    },
    {
      value: 'requestLocationPush',
      label: t('MESSAGE_CONSTRUCTOR.INSTANCES.REQUEST_LOCATION_PUSH'),
      icon: pushIcon,
    },
  ];
  const applicationOptions = [
    {
      value: 'browser',
      label: t('MESSAGE_CONSTRUCTOR.INSTANCES.BROWSER'),
    },
    {
      value: 'webview',
      label: t('MESSAGE_CONSTRUCTOR.INSTANCES.WEBVIEW'),
    },
  ];
  const viewModeOptions = [
    {
      value: 'full',
      label: t('MESSAGE_CONSTRUCTOR.INSTANCES.FULL'),
    },
    {
      value: 'half',
      label: t('MESSAGE_CONSTRUCTOR.INSTANCES.HALF'),
    },
    {
      value: 'tall',
      label: t('MESSAGE_CONSTRUCTOR.INSTANCES.TALL'),
    },
  ];

  useEffect(() => {
    switch (category) {
      case 'urlAction':
        change('type', 'openUrl');

        if (!application) {
          change('application', 'browser');
        }
        break;
      case 'dialerAction':
        if (!callTypes.find(I => I.value === type)) {
          change('type', 'dialPhoneNumber');
        }
        break;
      case 'mapAction':
        if (!locationTypes.find(I => I.value === type)) {
          change('type', 'showLocation');
        }
        break;
      case 'calendarAction':
        change('type', 'createCalendarEvent');
        break;
      default: break;
    }
  }, [category]);

  return (
    <>
      {
        category === 'urlAction' && (
          <>
            <Field
              name="type"
              component={DropdownFieldNew}
              options={[{ value: 'openUrl', label: t('MESSAGE_CONSTRUCTOR.INSTANCES.OPEN_URL') }]}
              label={t('MESSAGE_CONSTRUCTOR.INSTANCES.ACTION_TYPE')}
              styles={dropdownFieldStyles}
              type="border"
              disabled={readOnly}
            />
            <Field
              name="url"
              component={BasicField}
              label={t('MESSAGE_CONSTRUCTOR.INSTANCES.URL')}
              styleWrapper={commonFieldStyle}
              type="with-border"
              placeholder="http://"
              validate={value => required(value, t)}
              isRequired
              isDisabled={readOnly}
            />
            <Field
              name="application"
              component={DropdownFieldNew}
              options={applicationOptions}
              label={t('MESSAGE_CONSTRUCTOR.INSTANCES.APPLICATION')}
              styles={dropdownFieldStyles}
              type="border"
              disabled={readOnly}
            />
            {
              application === 'webview' && (
                <Field
                  name="viewMode"
                  component={DropdownFieldNew}
                  options={viewModeOptions}
                  label={t('MESSAGE_CONSTRUCTOR.INSTANCES.VIEW_MODE')}
                  styles={dropdownFieldStyles}
                  type="border"
                  disabled={readOnly}
                />
              )
            }
          </>
        )
      }
      {
        category === 'dialerAction' && (
          <>
            <Field
              name="type"
              component={DropdownFieldNew}
              options={callTypes}
              label={t('MESSAGE_CONSTRUCTOR.INSTANCES.ACTION_TYPE')}
              styles={dropdownFieldStyles}
              type="border"
              disabled={readOnly}
            />
            <Field
              name="phoneNumber"
              component={BasicField}
              label={t('MESSAGE_CONSTRUCTOR.INSTANCES.PHONE_NUMBER')}
              styleWrapper={commonFieldStyle}
              type="with-border"
              isDisabled={readOnly}
            />
            {
              type === 'dialEnrichedCall' && (
                <Field
                  name="subject"
                  component={BasicField}
                  label={t('MESSAGE_CONSTRUCTOR.INSTANCES.SUBJECT')}
                  styleWrapper={commonFieldStyle}
                  type="with-border"
                  isDisabled={readOnly}
                />
              )
            }
            <Field
              name="fallbackUrl"
              component={BasicField}
              label={t('MESSAGE_CONSTRUCTOR.INSTANCES.FALLBACK_URL')}
              styleWrapper={commonFieldStyle}
              type="with-border"
              placeholder="https://"
              isDisabled={readOnly}
            />
          </>
        )
      }
      {
        category === 'mapAction' && (
          <>
            <Field
              name="type"
              component={DropdownFieldNew}
              options={locationTypes}
              label={t('MESSAGE_CONSTRUCTOR.INSTANCES.ACTION_TYPE')}
              styles={dropdownFieldStyles}
              type="border"
              disabled={readOnly}
            />
            {
              type === 'showLocation' && (
                <>
                  <Field
                    name="by"
                    component={SwitchField}
                    label={t('MESSAGE_CONSTRUCTOR.INSTANCES.BY_COORDINATES')}
                    wrapperStyle={commonFieldStyle}
                    isDisabled={readOnly}
                  />
                  {
                    by
                      ? (
                        <Field
                          name="query"
                          component={BasicField}
                          validate={value => required(value, t)}
                          label={t('MESSAGE_CONSTRUCTOR.INSTANCES.QUERY')}
                          styleWrapper={commonFieldStyle}
                          type="with-border"
                          isDisabled={readOnly}
                          isRequired
                        />
                      )
                      : (
                        <>
                          <Field
                            name="latitude"
                            component={BasicField}
                            label={t('MESSAGE_CONSTRUCTOR.INSTANCES.LATITUDE')}
                            styleWrapper={commonFieldStyle}
                            validate={value => required(value, t)}
                            pattern={
                              inputPatterns.decimal({
                                withSpaces: false,
                                withMinus: true,
                              })
                            }
                            type="with-border"
                            isDisabled={readOnly}
                            isRequired
                          />
                          <Field
                            name="longitude"
                            component={BasicField}
                            label={t('MESSAGE_CONSTRUCTOR.INSTANCES.LONGITUDE')}
                            styleWrapper={commonFieldStyle}
                            validate={value => required(value, t)}
                            pattern={
                              inputPatterns.decimal({
                                withSpaces: false,
                                withMinus: true,
                              })
                            }
                            type="with-border"
                            isDisabled={readOnly}
                            isRequired
                          />
                        </>
                      )
                  }
                  <Field
                    name="label"
                    component={BasicField}
                    label={t('MESSAGE_CONSTRUCTOR.INSTANCES.LABEL')}
                    styleWrapper={commonFieldStyle}
                    type="with-border"
                    isDisabled={readOnly}
                  />
                  <Field
                    name="fallbackUrl"
                    component={BasicField}
                    label={t('MESSAGE_CONSTRUCTOR.INSTANCES.FALLBACK_URL')}
                    styleWrapper={commonFieldStyle}
                    type="with-border"
                    placeholder="https://"
                    isDisabled={readOnly}
                  />
                  <FormSpy
                    subscription={{ values: true }}
                    onChange={(props) => {
                      if (props.values.by !== by) {
                        if (props.values.by) {
                          change('latitude', undefined);
                          change('longitude', undefined);
                        } else {
                          change('query', undefined);
                        }
                      }
                    }}
                  />
                </>
              )
            }
          </>
        )
      }
      {
        category === 'calendarAction' && (
          <>
            <Field
              name="type"
              component={DropdownFieldNew}
              options={[{ value: 'createCalendarEvent', label: t('MESSAGE_CONSTRUCTOR.INSTANCES.CREATE_CALENDAR_EVENT') }]}
              label={t('MESSAGE_CONSTRUCTOR.INSTANCES.ACTION_TYPE')}
              styles={dropdownFieldStyles}
              type="border"
              disabled={readOnly}
            />
            <Field
              name="startTime"
              component={uikit.DatePicker}
              label={t('MESSAGE_CONSTRUCTOR.INSTANCES.START_TIME')}
              styleWrapper={commonFieldStyle}
              style={datePickerStyle}
              labelStyle={dateLabelStyle}
              closeOnSelect={false}
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
              disabled={readOnly}
            />
            <Field
              name="endTime"
              component={uikit.DatePicker}
              label={t('MESSAGE_CONSTRUCTOR.INSTANCES.END_TIME')}
              styleWrapper={commonFieldStyle}
              style={datePickerStyle}
              labelStyle={dateLabelStyle}
              closeOnSelect={false}
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
              disabled={readOnly}
            />
            <Field
              name="title"
              component={BasicField}
              label={t('MESSAGE_CONSTRUCTOR.INSTANCES.TITLE')}
              styleWrapper={commonFieldStyle}
              type="with-border"
              validate={value => required(value, t)}
              isRequired
              isDisabled={readOnly}
            />
            <Field
              name="description"
              component={BasicField}
              label={t('MESSAGE_CONSTRUCTOR.INSTANCES.DESCRIPTION')}
              styleWrapper={commonFieldStyle}
              type="with-border"
              isDisabled={readOnly}
            />
            <Field
              name="fallbackUrl"
              component={BasicField}
              label={t('MESSAGE_CONSTRUCTOR.INSTANCES.FALLBACK_URL')}
              styleWrapper={commonFieldStyle}
              type="with-border"
              placeholder="https://"
              isDisabled={readOnly}
            />
          </>
        )
      }
    </>
  );
};

export default DisplayedFields;
