import endpoints from 'const/endpoints';

export default ({
  limit = 10,
  offset = 0,
  entityName = 'senders',
  onlyActiveSenders = true,
  successCallback = Function.prototype,
  errorCallback = Function.prototype,
  ...queries
} = {}) => ({
  url: endpoints.getSendersUrl(),
  queryKey: endpoints.getSendersUrl(),
  body: {
    limit,
    offset: offset ? ((offset - 1) * limit) : offset,
    ...queries,
  },
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
    successCallback,
    errorCallback,
  },
  transform: (response) => {
    const {
      resourceList,
      ...other
    } = response;

    return {
      [entityName]: onlyActiveSenders
        ? resourceList.filter(item => item.status === 2)
        : resourceList,
      [`${entityName}Meta`]: other,
    };
  },
  update: {
    [entityName]: (oldValue, newValue) => newValue,
    [`${entityName}Meta`]: (oldValue, newValue) => newValue,
  },
});
