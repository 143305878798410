import React, { useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Field, FormSpy } from 'react-final-form';

import uikit from 'components/UIKit';
import { TimePickerField } from 'components/fields';

const {
  FormLayout, FormItem, DatePicker, Switch, CheckableTagList, FormControl,
} = uikit;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
  labelAlign: 'left',
};

const CampaignEditLaunchTimeRecurrent = () => {
  const { t } = useTranslation();
  const daysOfMonths = useMemo(() => Array.apply(0, Array(31)).map((_, index) => ({
    value: index + 1,
    label: index + 1,
  })), [true]);
  const daysOfWeek = useMemo(() => moment.weekdaysShort().map((item, index) => ({
    value: index,
    label: item,
  })), [true]);
  const months = useMemo(() => moment.monthsShort().map((item, index) => ({
    value: index + 1,
    label: item,
  })), [true]);

  return (
    <div className="campaign-create-launch-time__recurrent">
      <FormLayout {...formItemLayout}>
        <FormItem label={t('INSTANCES.CAMPAIGNS.START')}>
          <Field name="launchTimeStart" placeholder={t('PLACEHOLDERS.CAMPAIGNS.SELECT_DATE_AND_TIME')}>
            {({ input, ...props }) => (
              <FormControl meta={props.meta}>
                <DatePicker showTime input={input} {...props} />
              </FormControl>
            )}
          </Field>
        </FormItem>
        <FormItem label={t('INSTANCES.CAMPAIGNS.FINISH')}>
          <Field name="launchTimeEnd" placeholder={t('PLACEHOLDERS.CAMPAIGNS.SELECT_DATE_AND_TIME')}>
            {({ input, ...props }) => (
              <FormControl meta={props.meta}>
                <DatePicker showTime input={input} {...props} />
              </FormControl>
            )}
          </Field>
        </FormItem>
        <div className="campaign-create-launch-time__recurrent__text">{t('INSTANCES.CAMPAIGNS.DURING_THIS_PERIOD')}</div>
        <Field
          label={t('INSTANCES.CAMPAIGNS.HOUR')}
          name="time"
          typeOverlay="primary-color"
          component={TimePickerField}
        />
        <div className="campaign-create-launch-time__recurrent__switch">
          <span>
            {t('INSTANCES.CAMPAIGNS.DAY_OF_WEEK')}
          </span>
          <Field name="launchTimeDayOfMonth">
            {({ input, ...props }) => (
              <Switch
                className="campaign-create-launch-time__recurrent__switch__control"
                {...input}
                {...props}
                checked={input.value}
              />
            )}
          </Field>
          <span>
            {t('INSTANCES.CAMPAIGNS.OF_MONTH')}
          </span>
        </div>
        <div className="campaign-create-launch-time__recurrent__tags">
          <FormSpy subscription={{ values: true }}>
            {({ values }) => {
              const { launchTimeDayOfMonth } = values;
              return (
                <>
                  <div style={!launchTimeDayOfMonth ? { display: 'none' } : {}}>
                    <Field name="daysOfMonths">
                      {({ input, ...props }) => (
                        <FormControl meta={props.meta}>
                          <CheckableTagList
                            {...input}
                            {...props}
                            items={daysOfMonths}
                          />
                        </FormControl>
                      )}
                    </Field>
                  </div>
                  <div style={launchTimeDayOfMonth ? { display: 'none' } : {}}>
                    <Field name="daysOfWeek">
                      {({ input, ...props }) => (
                        <FormControl meta={props.meta}>
                          <CheckableTagList
                            {...input}
                            {...props}
                            items={daysOfWeek}
                          />
                        </FormControl>
                      )}
                    </Field>
                  </div>
                </>
              );
            }}
          </FormSpy>
        </div>
        <div className="campaign-create-launch-time__recurrent__block-title">
          {t('INSTANCES.CAMPAIGNS.MONTHS')}
        </div>
        <div className="campaign-create-launch-time__recurrent__tags">
          <Field name="launchTimeMonths">
            {({ input, ...props }) => (
              <FormControl meta={props.meta}>
                <CheckableTagList items={months} {...input} {...props} />
              </FormControl>
            )}
          </Field>
        </div>
      </FormLayout>
    </div>
  );
};

export default CampaignEditLaunchTimeRecurrent;
