// const CHANNEL_TYPE = {
//   VK: 'vk',
//   Whats_App: 'whathsapp',
//   viber: 'viber',
//   telegram: 'telegram',
//   sms: 'SMS',
//   rcs: 'RCS',
// };
//
// const CHANNELS_ICON = {
//
// };
// import whatsAppIcon from 'assets/socials/whatsapp.svg';
// import telegramIcon from 'assets/socials/telegram.svg';
import viberIcon from 'assets/icons/viber.svg';
// import telegramIcon from 'assets/socials/telegram.svg';
import smsIcon from 'assets/icons/sms.svg';
import rcsIcon from 'assets/icons/rcs.svg';
import whatsAppIcon from 'assets/socials/whatsapp.svg';
import rcsDarkIcon from 'assets/socials/rcs-dark.svg';
import smsDarkIcon from 'assets/socials/sms-dark.svg';

// SMS, RCS, VIBER
export const CHANNEL = {
  WHATSAPP: {
    id: 'WHATSAPP',
    icon: whatsAppIcon,
    name: 'WhatsApp',
  },
  VIBER: {
    id: 'VIBER',
    icon: viberIcon,
    name: 'Viber',
  },
  // telegram: {
  //   id: 'telegram',
  //   icon: telegramIcon,
  //   name: 'Telegram',
  // },
  SMS: {
    id: 'SMS',
    icon: smsIcon,
    name: 'SMS',
  },
  RCS: {
    id: 'RCS',
    icon: rcsIcon,
    name: 'RCS',
  },
};

export const CHANNELS = [
  {
    id: 'WHATSAPP',
    icon: whatsAppIcon,
    name: 'WhatsApp',
  },
  {
    id: 'VIBER',
    icon: viberIcon,
    name: 'Viber',
  },
  // {
  //   id: 'telegram',
  //   icon: telegramIcon,
  //   name: 'Telegram',
  // },
  {
    id: 'SMS',
    icon: smsIcon,
    name: 'SMS',
    lightThemeIcon: smsDarkIcon,
  },
  {
    id: 'RCS',
    icon: rcsIcon,
    name: 'RCS',
    lightThemeIcon: rcsDarkIcon,
  },
];

export const CHANNELS_IDS = {
  1: 'SMS',
  2: 'RCS',
  3: 'Viber',
  4: 'WhatsApp',
};
