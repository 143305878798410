import React from 'react';
import ReactTooltip from 'react-tooltip';

import './style.scss';

const Tooltip = ({
  place = 'top',
  multiline = false,
  delayShow = 100,
  effect = 'solid',
  isError,
  ...props
}) => (
  <ReactTooltip
    place={place}
    multiline={multiline}
    delayShow={delayShow}
    effect={effect}
    className={isError ? 'error-tooltip' : ''}
    {...props}
  />
);

export default Tooltip;
