import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';
import { updateEntities } from 'redux-query';
import { useDispatch } from 'react-redux';

import {
  getGroups as getGroupsApi,
  getParameters as getParametersApi,
  postCreateReport as postCreateReportApi,
} from 'queries/reports';

import {
  getArray,
  useISESelector,
} from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetGroups,
      lastUpdated: lastUpdatedGetGroups,
    },
    getGroups,
  ] = useMutation(getGroupsApi);
  const [
    {
      isPending: isPendingGetParameters,
      lastUpdated: lastUpdatedGetParameters,
      status: statusGetParameters,
    },
    getParameters,
  ] = useMutation(getParametersApi);
  const [
    {
      isPending: isPendingPostCreateReport,
    },
    postCreateReport,
  ] = useMutation(postCreateReportApi);

  const selector = useCallback(state => ({
    groups: getArray(state, 'groups'),
    reportParameters: getArray(state, 'reportParameters'),
    reports: getArray(state, 'reports'),
  }), []);
  const data = useISESelector(selector);

  const dispatch = useDispatch();

  const deleteReport = (reportName) => {
    dispatch(
      updateEntities({
        reports: () => data.reports.filter(R => R.name !== reportName),
      }),
    );
  };

  return {
    getGroups,
    getParameters,
    postCreateReport,
    isPendingGetGroups,
    lastUpdatedGetGroups,
    isPendingGetParameters,
    lastUpdatedGetParameters,
    isPendingPostCreateReport,
    isSuccessGetParameters: statusGetParameters === 200,
    deleteReport,
    ...data,
  };
};
