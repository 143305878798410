import React, { useState } from 'react';
import newEndpoints from 'mkit-front-ds/dist/consts/endpoints';

import endpoints from 'const/endpoints';

import CheckQueryItem from './CheckQueryItem';

import './style.scss';

const paginationParams = { limit: 1, offset: 0 };

const queries = {
  accountService: {
    title: 'account-service',
    queriesList: [
      {
        queryKey: 'getAudit',
        text: 'Get Audit',
        url: endpoints.getApiCallsUrl(),
        body: paginationParams,
        method: 'GET',
      },
      {
        queryKey: 'getAccountInfo',
        text: 'Get Account Info',
        url: endpoints.getUserInfo(),
        method: 'GET',
      },
    ],
  },
  balanceService: {
    title: 'balance-updater-service',
    queriesList: [
      {
        queryKey: 'getBalance',
        text: 'Get Balance',
        url: endpoints.getBalanceUrl(),
        method: 'GET',
      },
    ],
  },
  backofficeService: {
    title: 'backoffice-service',
    queriesList: [
      {
        queryKey: 'getSenders',
        text: 'Get Senders',
        url: newEndpoints.getSendersUrl(),
        body: paginationParams,
        method: 'GET',
      },
      {
        queryKey: 'postApplication',
        text: 'Create application',
        url: newEndpoints.getApplicationUrl(),
        method: 'POST',
      },
    ],
  },
  conversationService: {
    title: 'conversation-service',
    queriesList: [
      {
        queryKey: 'getConversationsList',
        text: 'Get Conversations List',
        url: endpoints.getConversationsListUrl(),
        body: paginationParams,
        method: 'GET',
      },
      {
        queryKey: 'postSendMessage',
        text: 'Send Message',
        url: endpoints.getSendMessageUrl(),
        body: {},
        method: 'POST',
      },
    ],
  },
  contentTemplatesService: {
    title: 'content-template-service',
    queriesList: [
      {
        queryKey: 'getTemplates',
        text: 'Get Templates',
        url: endpoints.getTemplatesUrl(),
        body: paginationParams,
        method: 'GET',
      },
      {
        queryKey: 'postTemplate',
        text: 'Create Template',
        url: endpoints.getTemplatesUrl(),
        method: 'POST',
      },
    ],
  },
  contentService: {
    title: 'content-service',
    queriesList: [
      {
        queryKey: 'getAssets',
        text: 'Get Assets',
        url: endpoints.getAssetsUrl(),
        method: 'GET',
        body: paginationParams,
      },
      {
        queryKey: 'postAsset',
        text: 'Add Media',
        url: endpoints.getAddAssetUrl(),
        body: new FormData(),
        method: 'POST',
      },
    ],
  },
  campaignService: {
    title: 'campaign-service',
    queriesList: [
      {
        queryKey: 'getBroadcasts',
        text: 'Get Broadcasts',
        url: endpoints.getCampaignsUrl(),
        body: paginationParams,
        method: 'GET',
      },
      {
        queryKey: 'postBroadcast',
        text: 'Create Broadcast',
        url: endpoints.createCampaignsUrl(),
        body: {},
        method: 'POST',
      },
    ],
  },
  edrService: {
    title: 'edrs-restapi-service',
    queriesList: [
      {
        queryKey: 'getEdrs',
        text: 'Get EDRs',
        url: endpoints.getEdrUrl(),
        body: paginationParams,
        method: 'GET',
      },
    ],
  },
  financeService: {
    title: 'finance-service',
    queriesList: [
      {
        queryKey: 'getTotalTraffic',
        text: 'Get total traffic',
        url: newEndpoints.getTotalTrafficUrl(),
        method: 'GET',
        body: {
          param_channel_type: 0,
          param_granularity: 'day',
          param_period_end: '2022-09-06',
          param_period_start: '2022-08-06',
          default_format: 'JSON',
        },
      },
      {
        queryKey: 'getRingsByChannel',
        text: 'Get rings by channel',
        url: newEndpoints.getRingsByChannelUrl(),
        method: 'GET',
        body: {
          param_channel_type: 2,
          param_granularity: 'day',
          param_period_end: '2022-09-06',
          param_period_start: '2022-08-06',
          default_format: 'JSON',
        },
      },
    ],
  },
  flowService: {
    title: 'flow-service',
    queriesList: [
      {
        queryKey: 'getFlows',
        text: 'Get Flows',
        url: endpoints.getFlowsNewUrl(),
        body: paginationParams,
        method: 'GET',
      },
      {
        queryKey: 'postFlow',
        text: 'Create Flow',
        url: endpoints.getFlowsNewUrl(),
        method: 'POST',
      },
    ],
  },
  importService: {
    title: 'import-service',
    queriesList: [
      {
        queryKey: 'getPresets',
        text: 'Get presets',
        url: newEndpoints.getPresetsUrl(),
        method: 'GET',
      },
      {
        queryKey: 'postCreateDraft',
        text: 'Post create draft',
        url: newEndpoints.getCreateDraftUrl(),
        body: new FormData(),
        method: 'POST',
      },
    ],
  },
  optOutService: {
    title: 'opt-out-stop-words-service',
    queriesList: [
      {
        queryKey: 'getStopWords',
        text: 'Get Stop Words',
        url: endpoints.getWordsUrl(),
        body: paginationParams,
        method: 'GET',
      },
      {
        queryKey: 'postStopWord',
        text: 'Create Stop Word',
        url: endpoints.getWordsUrl(),
        method: 'POST',
      },
    ],
  },
  recipientsService: {
    title: 'recipient-service',
    queriesList: [
      {
        queryKey: 'getRecipients',
        text: 'Get Recipients',
        url: endpoints.getRecipientsUrl(),
        body: paginationParams,
        method: 'GET',
      },
      {
        queryKey: 'postRecipient',
        text: 'Create Recipient',
        url: endpoints.postRecipientUrl(),
        method: 'POST',
      },
    ],
  },
  referenceService: {
    title: 'reference-service',
    queriesList: [
      {
        queryKey: 'getInvoices',
        text: 'Get Invoices',
        url: endpoints.getInvoicesUrl(),
        body: {
          invAccId: 5,
          ...paginationParams,
        },
        method: 'GET',
      },
      {
        queryKey: 'getPayments',
        text: 'Get Payments',
        url: endpoints.getPaymentsUrl(),
        body: {
          // carId: 5,
          ...paginationParams,
        },
        method: 'GET',
      },
    ],
  },
  reportsService: {
    title: 'report-service',
    queriesList: [
      {
        queryKey: 'getGroups',
        text: 'Get Groups',
        url: endpoints.getGroupsUrl(),
        body: paginationParams,
        method: 'GET',
      },
      {
        queryKey: 'postReport',
        text: 'Create Report',
        url: endpoints.getCreateReportUrl(),
        method: 'POST',
      },
    ],
  },
  responderService: {
    title: 'responder-service',
    queriesList: [
      {
        queryKey: 'postStopThread',
        text: 'Stop thread',
        url: endpoints.getFinishThreadUrl(),
        method: 'POST',
      },
      {
        queryKey: 'createThread',
        text: 'Create thread',
        url: endpoints.getCreateThreadUrl(),
        method: 'POST',
      },
    ],
  },
};

const TestServicesScreen = () => {
  const [activeQuery, setActiveQuery] = useState('getAudit');

  return (
    <div className="test-services-layout">
      <h1 className="test-services-layout__header">
        Services Test
      </h1>
      <div className="test-services-layout__wrapper">
        {
          Object.keys(queries).map((category, categoryIndex) => (
            <React.Fragment key={category}>
              <div className="test-services-layout__tests-block">
                <h1 className="test-services-layout__header">
                  {queries[category].title}
                </h1>
                {
                  queries[category].queriesList.map((item, index) => (
                    <CheckQueryItem
                      activeQuery={activeQuery}
                      setNextQuery={() => setActiveQuery(
                        queries[category].queriesList[index + 1]?.queryKey
                        // eslint-disable-next-line max-len
                        || queries[Object.keys(queries)[categoryIndex + 1]]?.queriesList[0]?.queryKey,
                      )}
                      key={item.queryKey}
                      {...item}
                    />
                  ))
                }
              </div>
            </React.Fragment>
          ))
        }
      </div>
    </div>
  );
};

export default TestServicesScreen;
