/*eslint-disable */
import React, { useEffect } from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
} from 'react-beautiful-dnd';
import classNames from 'classnames';

import imagePlaceholderIcon from 'assets/icons/rcs/image-placeholder.svg';
import imagePlaceholderActuveIcon from 'assets/icons/rcs/image-placeholder-active.svg';

import './style.scss';

const DnDSlides = ({
  cards,
  updateRcsParams,
  selectedCard,
  setSelectedCard,
  readOnly,
}) => {
  const itemClass = index => classNames('mkit-dnd-slides__item', {
    'mkit-dnd-slides__item_selected': index === selectedCard,
  });
  const imageClass = fileUrl => classNames('mkit-dnd-slides__item__image', {
    'mkit-dnd-slides__item__image_placeholder': !fileUrl,
  });

  const getIcon = (thumbnailUri, index) => {
    if (thumbnailUri) {
      return thumbnailUri;
    }

    if (selectedCard === index) {
      return imagePlaceholderActuveIcon;
    }

    return imagePlaceholderIcon;
  };

  const onDragEnd = ({ destination, source }) => {
    const result = Array.from(cards);
    const [removed] = result.splice(source.index, 1);
    result.splice(destination.index, 0, removed);

    updateRcsParams({ cards: result });
    setSelectedCard(destination.index);
  };

  useEffect(() => {
    const elem = document.getElementById(`dnd-slide-${selectedCard}`);
    elem.scrollIntoView({ block: 'nearest', inline: 'center', behavior: 'smooth' });
  }, [selectedCard]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId="slides"
        direction="horizontal"
      >
        {provided => (
          <div className="mkit-dnd-slides" ref={provided.innerRef}>
            {
              cards.map((I, index) => (
                <Draggable
                  draggableId={I.axis + index}
                  index={index}
                  key={I.axis + index}
                  placeholder={provided.placeholder}
                  isDragDisabled={readOnly}
                >
                  {itemProvided => (
                    <div
                      className={itemClass(index)}
                      ref={itemProvided.innerRef}
                      role="presentation"
                      id={`dnd-slide-${index}`}
                      onClick={() => setSelectedCard(index)}
                      {...itemProvided.draggableProps}
                      {...itemProvided.dragHandleProps}
                    >
                      <img src={getIcon(I.image?.thumbnailUri, index)} alt="" className={imageClass(I.image?.thumbnailUri)} />
                    </div>
                  )}
                </Draggable>
              ))
            }
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DnDSlides;
