import React from 'react';
import ContactsScreen from 'mkit-front-ds/dist/screens/ContactsScreen';

import { useUsersApi } from 'hooks/api';

import './style.scss';

const RecipientsScreen = ({ history }) => {
  const { currentUser } = useUsersApi();

  return (
    <div className="contacts-container">
      <ContactsScreen history={history} userPermissions={currentUser?.roles || []} />
    </div>
  );
};

export default RecipientsScreen;
