import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { Col, Row } from 'antd';
import Calendar from 'react-calendar';
import { useTranslation } from 'react-i18next';

import Select from 'components/UIKit/Select/Select';
import SelectOption from 'components/UIKit/Select/SelectOption';

import './InlineCalendar.scss';

/*eslint-disable */
const getYearsList = (minDate, maxDate) => {
  const minYear = minDate ? moment(minDate).year() : moment().year();
  const maxYear = maxDate ? moment(maxDate).year() : minYear + 10;
  const years = [];
  const year = moment().year();
  years.push(year);
  let counter = 1;
  while (years + counter <= maxYear) {
    years.push(year + counter);
    counter += 1;
  }
  return years;
};

const getMonthsList = () => {
  const months = [];
  const date = moment().set('month', 12);
  let i = 1;
  while (i <= 12) {
    i += 1;
    months.push({
      label: date.format('MMMM'),
      index: date.format('MM'),
    });
    date.add('month', 1);
  }
  return months;
};


const InlineCalendar = ({
  onChange, value, minDate, maxDate, ...props
}) => {
  const { i18n } = useTranslation();
  const [date, changeDate] = useState(value ? value.toDate() : new Date());
  const years = getYearsList(minDate, maxDate);
  const months = getMonthsList();
  const year = moment(date).year();
  const month = moment(date).format('MM');
  const yearCallback = useCallback((changeValue) => {
    let nextMonth = date.getMonth() + 1;
    nextMonth = nextMonth < 10 ? `0${nextMonth}` : nextMonth;
    const newDate = moment(`${changeValue}-${nextMonth}-${date.getDate()}`, 'YYYY-MM-DD');
    changeDate(newDate.toDate());
    onChange(newDate);
  }, [date, changeDate, onChange]);

  const onCalendarChange = useCallback((newDate) => {
    changeDate(newDate);
    onChange(moment(newDate));
  }, [onChange, changeDate]);

  const monthCallback = useCallback((changeValue) => {
    const lastDayOfMonth = moment(`${date.getFullYear()}-${changeValue}`, 'YYYY-MM')
      .endOf('month').format('D');
    const currentDate = date.getDate() > lastDayOfMonth ? lastDayOfMonth : date.getDate();
    const newDate = moment(`${date.getFullYear()}-${changeValue}-${currentDate}`, 'YYYY-MM-DD');
    changeDate(newDate.toDate());
    onChange(newDate);
  }, [date, changeDate, onChange]);

  return (
    <div className="inline-calendar">
      <Row gutter={16} type="flex">
        <Col span={12} className="inline-calendar__select">
          <Select defaultValue={year} onChange={yearCallback}>
            {years.map(item => (
              <SelectOption value={item} key={item}>{item}</SelectOption>
            ))}
          </Select>
        </Col>
        <Col span={12} className="inline-calendar__select">
          <Select defaultValue={month} onChange={monthCallback}>
            {months.map(item => (
                <SelectOption
                  disabled={minDate && item.index < minDate.getMonth() + 1}
                  value={item.index}
                  key={item.month}
                >
                  {item.label}
                </SelectOption>
            ))}
          </Select>
        </Col>
      </Row>
      <div>
        <Calendar
          locale={i18n.language}
          minDate={minDate}
          maxDate={maxDate}
          className="inline-calendar__days"
          showNavigation={false}
          onChange={onCalendarChange}
          value={date}
          {...props}
        />
      </div>
    </div>
  );
};

export default InlineCalendar;
