import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme } from 'hooks';
import checkIcon from 'assets/icons/check-in-circle.svg';
import alertIcon from 'assets/icons/alert.svg';

import './style.scss';

const StatusCellRenderer = ({ valueFormatted }) => {
  const { t } = useTranslation();
  const { isDarkTheme } = useTheme();

  const { icon, color } = useMemo(() => {
    if (valueFormatted === t('INSTANCES.ACTIVE')) {
      return {
        icon: checkIcon,
        color: isDarkTheme ? '#38A213' : '#2BA300',
      };
    }

    return {
      icon: alertIcon,
      color: isDarkTheme ? '#FF4040' : '#DC3535',
    };
  }, [valueFormatted, isDarkTheme]);

  return (
    <div className="status-cell-renderer" style={{ color }}>
      <img
        src={icon}
        alt=""
        className="status-cell-renderer__icon"
      />
      {valueFormatted}
    </div>
  );
};

export default StatusCellRenderer;
