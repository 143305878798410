import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { Table } from 'components';
import LoadingSpinner from 'components/LoadingSpinner';
import { BasicButton } from 'components/buttons';
import { MenuDropdown } from 'components/dropdowns';
import { useUsersApi } from 'hooks/api';
import { useToken, useTable } from 'hooks';
import redefineIcon from 'assets/icons/redefine.svg';
import endpoints from 'const/endpoints';
import dotsIcon from 'assets/icons/3dots.svg';

import './style.scss';

const ReportBlock = ({
  report,
  setEditableReport,
  setActiveTab,
  postCreateReport,
  isLoading,
}) => {
  const buttonStyle = { minWidth: 50 };

  const { t } = useTranslation();
  const { currentUser } = useUsersApi();
  const { token } = useToken();
  const tableRef = useRef(null);
  const [columns, setColumns] = useState(report.columns);
  const [isDownloading, setIsDownloading] = useState(false);
  const downloadPermission = useMemo(() =>
    currentUser?.roles?.includes?.('reports-export-results'), []);

  const dropdownClass = cx('report-block__dropdown', {
    'report-block__dropdown_disabled': !downloadPermission,
  });

  const {
    storageKeyColumns,
    storageKeyFilters,
  } = useMemo(() => {
    const nameReport = report.name.replace(/\s+/g, '').toLowerCase();
    return {
      storageKeyColumns: `columns_${window.location.pathname}_${nameReport}`,
      storageKeyFilters: `${window.location.pathname}_${nameReport}`,
    };
  }, [report.name]);

  const onPressDownloadFile = (type) => {
    setIsDownloading(true);
    const oReq = new XMLHttpRequest();
    oReq.open('POST', endpoints.getReportFileUrl(), true);
    oReq.responseType = 'blob';
    oReq.setRequestHeader('Authorization', `Bearer ${token}`);

    oReq.send(JSON.stringify({
      name: report.name,
      templateId: report.templateId,
      parameters: report.initialParameters,
      type,
    }));

    oReq.onload = () => {
      setIsDownloading(false);
      saveAs(
        oReq.response,
        `${report.name}.${type}`,
      );
    };
  };

  const onRefineClick = () => {
    setEditableReport({
      name: report.name,
      templateId: report.templateId,
      initialValues: { ...report.parameters },
    });
    setActiveTab('control');
  };

  const {
    offset,
    limit,
    setOffset,
    setLimit,
    onSortChanged,
    onFilterChanged,
    sendQuery,
  } = useTable({
    useNewFilters: true,
    initialFilters: JSON.parse(window.sessionStorage.getItem(storageKeyFilters)),
    sessionKey: storageKeyFilters,
    tableRef,
    sendQuery: (body) => {
      let valuesModify = {};

      Object.keys(report.parameters).forEach((key) => {
        valuesModify = {
          ...valuesModify,
          [key]: report.parameters[key] || '',
        };
      });

      postCreateReport({
        body: {
          name: report.name,
          params: valuesModify,
          templateId: report.templateId,
          ...body,
          offset: body?.offset ? ((body.offset - 1) * body.limit) : body.offset,
        },
      });
    },
  });

  useEffect(() => {
    if (report.columns.length > 0) {
      window.sessionStorage.setItem(storageKeyColumns, JSON.stringify(columns, (key, value) =>
        (key === 'columnParams' ? undefined : value)));
    } else {
      setColumns(JSON.parse(window.sessionStorage.getItem(storageKeyColumns)));
    }
  }, []);

  return (
    <Table
      tableRef={tableRef}
      rowData={report.list}
      columnDefs={columns}
      title={report.name}
      limit={limit}
      total={report.size}
      offset={offset}
      resizableColDef
      onChangeLimit={setLimit}
      onChangePage={setOffset}
      onFilterChanged={onFilterChanged}
      onSortChanged={onSortChanged}
      loading={isLoading}
      onRefreshPress={sendQuery}
      rightComponent={(
        <>
          <BasicButton
            icon={redefineIcon}
            onClick={onRefineClick}
            text={t('SCREENS.REPORTS.REFINE')}
            style={buttonStyle}
          />
          {
            isDownloading
              ? (
                <>
                  <LoadingSpinner small />
                  <span className="report-block__loading-label">{t('STATES.LOADING')}</span>
                </>
              )
              : (
                <MenuDropdown
                  menu={[
                    {
                      title: t('NEW_TRANSLATES.TOOLTIPS.REPORTS.UNLIMITED_ROWS'),
                      text: t('CONTROLS.DOWNLOAD_FORMAT', { format: 'CSV' }),
                      action: () => onPressDownloadFile('csv'),
                    },
                    {
                      title: t('NEW_TRANSLATES.TOOLTIPS.REPORTS.MAX_ROWS', { number: 1040000 }),
                      text: t('CONTROLS.DOWNLOAD_FORMAT', { format: 'XLSX' }),
                      action: () => onPressDownloadFile('xlsx'),
                    },
                  ]}
                  disabled={!downloadPermission}
                >
                  <img
                    className={dropdownClass}
                    src={dotsIcon}
                    alt=""
                    title={downloadPermission ? undefined : 'Not enough permissions'}
                  />
                </MenuDropdown>
              )
          }
        </>
          )}
    />
  );
};

export default ReportBlock;
