import React from 'react';
/*eslint-disable */
import { DefaultLinkFactory } from '@projectstorm/react-diagrams';

import LinkModel from './LinkModel';
import LinkWidget from './LinkWidget';
import './style.scss';

export default class AdvancedLinkFactory extends DefaultLinkFactory {
  constructor() {
    super('default');
  }

  generateReactWidget(event) {
    return <LinkWidget link={event.model} diagramEngine={this.engine} />;
  }

  generateLinkSegment(model, selected, path) {
    return (
      <path
        className={`link-segment${selected ? ' link-segment_selected' : ''}`}
        strokeWidth={model.getOptions().width}
        d={path}
      />
    );
  }

  generateModel(event) {
    return new LinkModel();
  }
}
