import endpoints from 'const/endpoints';

export default ({ id, ...body }) => ({
  url: endpoints.getUpdateGroupUrl(id),
  queryKey: endpoints.getUpdateGroupUrl(),
  body,
  options: {
    method: 'PATCH',
  },
  meta: {
    authToken: true,
  },
  transform: response => ({ senderGroups: response }),
  update: {
    senderGroups: (oldValue, newValue = {}) => oldValue.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          ...newValue,
          ...body,
        };
      }
      return item;
    }),
  },
});
