import React, { useState, useEffect, useMemo } from 'react';

const initThemeContext = {
  isDarkTheme: /true/i.test(localStorage.getItem('isDarkTheme')),
};

export const ThemeContext = React.createContext(initThemeContext);
const htmlClassList = document.querySelector('html').classList;

const ThemeProvider = ({ children }) => {
  const [isDarkTheme, setDarkTheme] = useState(initThemeContext.isDarkTheme);

  const toggleTheme = () => setDarkTheme(!isDarkTheme);

  const contextValue = useMemo(() => ({
    isDarkTheme,
    setDarkTheme,
    toggleTheme,
  }), [isDarkTheme, setDarkTheme, toggleTheme]);

  useEffect(() => {
    localStorage.setItem('isDarkTheme', isDarkTheme);

    htmlClassList.remove(`theme_${isDarkTheme ? 'light' : 'dark'}`);
    htmlClassList.add(`theme_${isDarkTheme ? 'dark' : 'light'}`);

    if (isDarkTheme) {
      document.documentElement.classList.add('dark-theme');
    } else {
      document.documentElement.classList.remove('dark-theme');
    }
  }, [isDarkTheme]);

  return (
    <ThemeContext.Provider
      value={contextValue}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
