import isFunction from 'lodash/isFunction';
import { actionTypes } from 'redux-query';
import toastRef from 'mkit-front-ds/dist/helpers/toast';

export default () => next => (action) => {
  const getMessage = (responseBody = {}) => {
    if (responseBody?.key) {
      return { key: responseBody.key, values: responseBody?.args };
    }
    return responseBody?.message
      || responseBody?.data?.message
      || 'An error occurred while executing the request.';
  };

  if (action.type === actionTypes.REQUEST_FAILURE
      || action.type === actionTypes.MUTATE_FAILURE) {
    switch (action.status) {
      case 401: {
        const message = 'Authorization error! You will be redirected to the login page.';

        if (!toastRef.current?.getToasts()?.find(item => item.message === message)) {
          toastRef.current?.show({
            message,
            intent: 'danger',
          });

          setTimeout(async () => {
            await localStorage.setItem('mKitToken', null);
            await localStorage.setItem('mKitTokenExpiresIn', null);
            await localStorage.setItem('mKitRefreshTokenExpiresIn', null);
            await localStorage.setItem('mKitIdToken', null);
            window.location.reload();
          }, 5000);
        }
        break;
      }
      case 403: {
        const errorCallback = action?.meta?.errorCallback;

        toastRef.current.show({ message: 'Not enough permissions' });

        if (errorCallback && isFunction(errorCallback)) {
          errorCallback(action.responseBody, action.status);
        }

        break;
      }
      default: {
        const errorCallback = action?.meta?.errorCallback;

        if (!action?.meta?.hideToast) {
          toastRef?.current?.showMessage({
            message: getMessage(action?.responseBody),
          });
        }
        if (errorCallback && isFunction(errorCallback)) {
          errorCallback(action.responseBody, action.status);
        }

        break;
      }
    }
  }

  return next(action);
};
