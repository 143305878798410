/* eslint-disable react/destructuring-assignment */
import React from 'react';

import './style.scss';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <span>SYSTEM: Cannot display report params</span>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
