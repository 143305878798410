const LANC_HOST = 'msg.lancktele.com';
const LANC_HOST_RU = 'msg.qntk.ru';

const DEV_HOST = 'mkitdev2.alarislabs.com';
const LOCAL_HOST = 'localhost:3000';
// eslint-disable-next-line no-undef
const IS_LANC_HOST = window.location.origin.includes(LANC_HOST) || window.location.origin.includes(LANC_HOST_RU);
// eslint-disable-next-line no-undef
const IS_DEV_HOST = window.location.origin.includes(DEV_HOST) || window.location.origin.includes(LOCAL_HOST);

export {
  IS_LANC_HOST,
  IS_DEV_HOST,
};
