import React, { useMemo } from 'react';

import { useTheme } from 'hooks';

import './style.scss';

const ImageArrayCellRenderer = ({
  value,
  valueFormatted,
  style,
  supportLightTheme,
  lightThemeIcon,
}) => {
  const { isDarkTheme } = useTheme();

  const iconsArray = useMemo(() => {
    if (supportLightTheme) {
      return value.map(I => lightThemeIcon(I, isDarkTheme));
    }

    return valueFormatted || value;
  }, [isDarkTheme]);

  return (
    <div className="image-array-cell-renderer">
      {
        iconsArray.map(I => (
          <img
            src={I}
            alt=""
            className="image-array-cell-renderer__image"
            style={style}
          />
        ))
      }
    </div>
  );
};

export default ImageArrayCellRenderer;
