import endpoints from 'const/endpoints';

export default ({
  id,
  successCallback,
  errorCallback,
} = {}) => ({
  url: endpoints.getFlowsNewByIdUrl(id),
  queryKey: endpoints.getFlowsNewByIdUrl(),
  options: {
    method: 'DELETE',
  },
  meta: {
    authToken: true,
    successCallback,
    errorCallback,
  },
});
