import { useState, useEffect, useMemo } from 'react';
import moment from 'moment';

import { useAccountsIdApi } from 'hooks/api';

const formatters = {
  default: (from, to) => `${from.format('MMMM DD, YYYY')} - ${to.format('MMMM DD, YYYY')}`,
  monthly: m => m.format('MMMM, YYYY'),
  quarterly: m => `${m.format('YYYY, Qo')} Quarter`,
  yearly: m => m.format('YYYY'),
};

const periods = ['Weekly', 'Monthly', 'Quarterly', 'Yearly'];

const formats = {
  Weekly: 'ddd',
  Monthly: 'DD',
  Quarterly: 'MMM DD, YYYY',
  Yearly: 'MMM YYYY',
  Custom: 'MMM DD, YYYY',
};

const initialDateRange = [
  moment(moment()).startOf('month'),
  moment(moment()).endOf('month'),
];

export default () => {
  const {
    isPendingGetAccountsId,
    lastUpdatedGetAccountsId,
    accountsId,
    getAccountsId,
  } = useAccountsIdApi();

  const [period, setPeriod] = useState(periods[1]);
  const [isVisiblePeriod, setIsVisiblePeriod] = useState(false);
  const [dateRange, setDateRange] = useState(initialDateRange);
  const [selectedAccount, setSelectedAccount] = useState();

  const accountInfo = useMemo(
    () =>
      accountsId.find(I => I.id === selectedAccount),
    [selectedAccount, accountsId],
  );

  const dropdownItems = useMemo(() => accountsId.map(I => ({
    id: I.id,
    title: `${I.name} (${I.currency})`,
  })), [accountsId]);

  const getFormat = (periodKey, from, to) => (
    formatters[String(periodKey).toLowerCase()] || formatters.default
  )(from, to);

  const formatCurrency = (amount, currency) => {
    amount = Number(amount);
    const res = Math.abs(amount).toLocaleString('en', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    })
      .replace('$', currency === 'USD' ? '$' : `${currency || ''} `)
      .replace('NaN', '0');

    return amount < 0 ? res.replace(/([^0-9]\s)/, '$1-') : res;
  };

  const getDateRangeCourusel = (range, periodKey, isSubtract) => {
    const startDate = range[0].clone();
    const endDate = range[1].clone();
    switch (periodKey) {
      case 'Weekly':
        startDate[isSubtract ? 'subtract' : 'add'](7, 'days');
        endDate[isSubtract ? 'subtract' : 'add'](7, 'days');
        break;
      case 'Monthly':
        startDate[isSubtract ? 'subtract' : 'add'](1, 'month');
        endDate[isSubtract ? 'subtract' : 'add'](1, 'month');
        break;
      case 'Quarterly':
        startDate[isSubtract ? 'subtract' : 'add'](3, 'month');
        endDate[isSubtract ? 'subtract' : 'add'](3, 'month');
        break;
      case 'Yearly':
        startDate[isSubtract ? 'subtract' : 'add'](1, 'year');
        endDate[isSubtract ? 'subtract' : 'add'](1, 'year');
        break;
      default:
        break;
    }

    if (!isSubtract && startDate.valueOf() - Date.now() > 0) {
      return range;
    }

    return [startDate, endDate];
  };

  const getAlignedDateRangeByInterval = (range, interval) => {
    let startDate;
    let endDate;

    if (range) {
      [startDate, endDate] = range;
    } else {
      startDate = moment(moment()).startOf('month');
      endDate = moment(moment()).endOf('month');
    }

    switch (interval.toLowerCase()) {
      case 'weekly':
        return [endDate.clone().startOf('week'), endDate.clone().endOf('week')];
      case 'monthly':
        return [endDate.clone().startOf('month'), endDate.clone().endOf('month')];
      case 'quarterly':
        return [endDate.clone().startOf('quarter'), endDate.clone().endOf('quarter')];
      case 'yearly':
        return [endDate.clone().startOf('year'), endDate.clone().endOf('year')];
      default: return [startDate.clone(), endDate.clone()];
    }
  };

  const changeDateRange = (range, interval = period) => {
    setDateRange(getAlignedDateRangeByInterval(range, interval));
  };

  const onSelectPeriod = ({ key }) => {
    setIsVisiblePeriod(false);
    setPeriod(key);

    let range;

    switch (key.toLowerCase()) {
      case 'weekly':
        range = [moment(moment()).startOf('month'), moment(moment()).endOf('week')];
        break;
      case 'monthly':
        range = [moment(moment()).startOf('month'), moment(moment()).endOf('month')];
        break;
      case 'quarterly':
        range = [moment(moment()).startOf('month'), moment(moment()).endOf('quarter')];
        break;
      case 'yearly':
        range = [moment(moment()).startOf('month'), moment(moment()).endOf('year')];
        break;
      default: break;
    }
    changeDateRange(range, key);
  };
  const onNextDate = () => {
    changeDateRange(getDateRangeCourusel(dateRange, period));
  };
  const onPrevDate = () => {
    changeDateRange(getDateRangeCourusel(dateRange, period, true));
  };

  useEffect(() => {
    if (!isPendingGetAccountsId && !accountsId?.length) {
      getAccountsId();
    }
  }, []);

  useEffect(() => {
    setSelectedAccount(accountsId[0]?.id);
  }, [accountsId]);

  return {
    period,
    formats,
    dateRange,
    formatCurrency,
    periods,
    onSelectPeriod,
    setIsVisiblePeriod,
    isVisiblePeriod,
    changeDateRange,
    getFormat,
    onPrevDate,
    onNextDate,
    selectedAccount,
    setSelectedAccount,
    accountInfo,
    dropdownItems,
    isPendingGetAccountsId,
    lastUpdatedGetAccountsId,
  };
};
