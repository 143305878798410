import React from 'react';
import NewEdrsScreen from 'mkit-front-ds/dist/screens/EdrsScreen';

import { useUsersApi } from 'hooks/api';
import { useToken } from 'hooks';

import './style.scss';

const EdrScreen = () => {
  const { currentUser } = useUsersApi();
  const { token, idToken } = useToken();

  return (
    <div className="edr-layout">
      <NewEdrsScreen
        externalToken={token}
        externalIdToken={idToken}
        userPermissions={currentUser?.roles || []}
      />
    </div>
  );
};

export default EdrScreen;
