import React from 'react';
import { Col, Row } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Field, FormSpy } from 'react-final-form';

import uikit from 'components/UIKit';

import CampaignCreateLaunchTimeRecurrent from './CampaignEditLaunchTimeRecurrent';
import CampaignCreateLaunchTimeDefault from './CampaignEditLaunchTimeDefault';
import CampaignCreateControlledOutside from './CampaignEditControlledOutside';
import './CampaignEditLaunchTime.scss';

const { CardTitle, Switch, Card } = uikit;

const CampaignEditLaunchTime = ({ externalTrigger, disabled }) => {
  const { t } = useTranslation();

  return (
    <Card className={classNames('campaign-create-launch-time campaign-create__card', {
      'campaign-create__card--controlled-outside': externalTrigger,
    })}
    >
      <div>
        <CardTitle>
          <Row type="flex" justify="space-between">
            <Col>
              {t('INSTANCES.CAMPAIGNS.LAUNCH_TIME')}
            </Col>
            {!externalTrigger && (
              <Col>
                <div className="campaign-create-launch-time__switch">
                  <span className="campaign-create-launch-time__switch__label">
                    {t('INSTANCES.CAMPAIGNS.RECURRENT_CAMPAIGN')}
                  </span>
                  <Field name="recurrentCompany">
                    {({ input, ...props }) => (
                      <Switch
                        {...input}
                        {...props}
                        checked={input.value}
                        disabled={disabled}
                      />
                    )}
                  </Field>
                </div>
              </Col>
            )}
          </Row>
        </CardTitle>
        {!externalTrigger ? (
          <FormSpy subscription={{ values: true }}>
            {({ values }) =>
              (values.recurrentCompany
                ? <CampaignCreateLaunchTimeRecurrent />
                : <CampaignCreateLaunchTimeDefault disabled={disabled} />)}
          </FormSpy>
        ) : <CampaignCreateControlledOutside />}
      </div>
    </Card>
  );
};

export default CampaignEditLaunchTime;
