import { isBoolean } from 'lodash';

import actions from 'const/actions';
/**
 *
 * @type {{menuExpand: boolean}}
 */
const initialState = {
  menuExpand: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.TOGGLE_MENU_EXPAND:
      return {
        ...state,
        menuExpand: isBoolean(action.state) ? action.state : !state.menuExpand,
      };
    default:
      return state;
  }
};
