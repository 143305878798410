import endpoints from 'const/endpoints';

export default ({
  body,
  successCallback,
  id,
}) => ({
  url: endpoints.getFlowsResourceUrl(id),
  options: {
    method: 'GET',
  },
  body,
  meta: {
    authToken: true,
    successCallback,
  },
  transform: response => ({ flowResource: response }),
  update: {
    flowResource: (oldValue, newValue) => ({
      ...oldValue,
      [id]: newValue,
    }),
  },
});
