import { useState } from 'react';
import { useLocalStorage } from 'react-use';

import {
  MENU_KEY,
  HINTS_KEY,
  DASHBOARD_KEY,
  CAMPAIGN_KEY,
  RECIPIENTS_KEY,
} from 'const';

export default ({
  hints = {},
  hintsKey = '',
}) => {
  const [currentHints, setCurrentHints] = useLocalStorage(HINTS_KEY, {});
  const [activeHint, setActiveHint] = useState(
    currentHints?.[hintsKey] || Object.keys(hints)[0],
  );

  const setNextHint = () => {
    const { nextHint } = hints[activeHint];

    if (hintsKey === MENU_KEY) {
      setCurrentHints({ ...JSON.parse(localStorage.getItem(HINTS_KEY)), [hintsKey]: nextHint });
    } else {
      setCurrentHints({ ...currentHints, [hintsKey]: nextHint });
    }
    setActiveHint(nextHint);
  };

  const skipAll = () => {
    setActiveHint('complete');
    setCurrentHints({
      [MENU_KEY]: 'complete',
      [DASHBOARD_KEY]: 'complete',
      [CAMPAIGN_KEY]: 'complete',
      [RECIPIENTS_KEY]: 'complete',
    });
  };

  return {
    activeHint,
    setNextHint,
    skipAll,
  };
};
