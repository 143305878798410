import React from 'react';

import tags from 'components/Tags';

const TagsCellRenderer = ({
  getValue,
  api,
  node,
  eGridCell,
}) => {
  const onDisplayAllTags = () => {
    // hack, stop propagation
    node.gridOptionsWrapper.gridOptions.isPropagationStopped = true;
    setTimeout(() => {
      node.gridOptionsWrapper.gridOptions.isPropagationStopped = false;
    });

    setTimeout(() => {
      node.setRowHeight(eGridCell.offsetHeight + 10);
      api.onRowHeightChanged();
    }, 50);
  };

  return (
    <tags.ViewTags
      tags={getValue()}
      maxTagsToDisplay={1}
      onDisplayAllTags={onDisplayAllTags}
    />
  );
};

export default TagsCellRenderer;
