import React from 'react';
import ConversationsScreen from 'mkit-front-ds/dist/screens/ConversationsScreen';

import { useUsersApi } from 'hooks/api';
import { useToken } from 'hooks';

import './style.scss';

const DialogScreen = () => {
  const { currentUser } = useUsersApi();
  const { token } = useToken();

  return (
    <div className="dialog-screen">
      <ConversationsScreen
        externalToken={token}
        userPermissions={currentUser?.roles || []}
      />
    </div>
  );
};

export default DialogScreen;
