import endpoints from 'const/endpoints';

export default ({
  id,
  successCallback,
}) => ({
  url: endpoints.getFlowLinksByIdUrl(id),
  options: {
    method: 'DELETE',
  },
  meta: {
    authToken: true,
    successCallback,
  },
});
