/* eslint-disable */
import React, { useContext, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import errorIcon from 'assets/icons/error.svg';
import ModalContext from './ModalContext';
import { IconButton } from 'components/buttons';
import CrossIcon from '../../assets/icons/cross-dark.svg';

import './style.scss';

const Modal = ({
  onClose: onCloseExternal,
  children,
  withoutButton,
  contentStyle,
  overlayStyle,
  withoutOverflow,
  withWarning,
  title,
}) => {
  const { t } = useTranslation();

  const modalNode = useContext(ModalContext);
  const shouldClose = useRef(null);

  useEffect(() => {
    if (modalNode.childNodes.length === 1 && !withoutOverflow) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const onClose = () => {
    if (shouldClose.current === null) {
      shouldClose.current = true;
    }

    if (shouldClose.current) {
      if (modalNode.childNodes.length <= 1) {
        document.body.style.overflow = 'unset';
      }
      onCloseExternal();
    }

    shouldClose.current = null;
  };
  const onClickDialog = () => {
    shouldClose.current = false;
  };

  return modalNode
    ? ReactDOM.createPortal(
      <div
        role="presentation"
        className="mkit-modal"
        onClick={onClose}
        style={overlayStyle}
      >
        <div
          role="presentation"
          onClick={onClickDialog}
          className="mkit-modal__dialog"
          style={contentStyle}
        >
          <div className="mkit-modal__header">
          {
            withWarning && (
              <div className="mkit-modal__dialog__warning">
                <img src={errorIcon} className="mkit-modal__dialog__warning__icon" alt="" />
                {title || t('TOAST.WARNING')}
              </div>
            )
          }
          {
            !withoutButton && (
              <IconButton
                filterType="white"
                onClick={onClose}
                icon={CrossIcon}
                style={{ marginLeft: 'auto' }}
              />
            )
          }
          </div>
          {children}
        </div>
      </div>,
      modalNode,
    )
    : null;
};

export default Modal;
