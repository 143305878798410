import React from 'react';
/*eslint-disable */
import { DefaultLinkWidget } from '@projectstorm/react-diagrams';

const CustomLinkArrowWidget = ({ point, previousPoint }) => {
  const angle =
      90 +
      (Math.atan2(
        point.getPosition().y - previousPoint.getPosition().y,
        point.getPosition().x - previousPoint.getPosition().x,
      ) * 180) / Math.PI;

  return (
    <g className="arrow" transform={`translate(${point.getPosition().x}, ${point.getPosition().y})`}>
      <g style={{ transform: `rotate(${angle}deg)` }}>
        <g transform="translate(0, -3)">
          <polygon
            points="0,10 8,30 -8,30"
            data-id={point.getID()}
            data-linkid={point.getLink().getID()}
          />
        </g>
      </g>
    </g>
  );
};

export default class AdvancedLinkWidget extends DefaultLinkWidget {
  generateArrow(point, previousPoint) {
    return (
      <CustomLinkArrowWidget
        key={`${point.getID()}-arrow`}
        point={point}
        previousPoint={previousPoint}
      />
    );
  }

  render() {
    // ensure id is present for all points on the path
    const points = this.props.link.getPoints();
    const paths = [];
    this.refPaths = [];

    // if (points.length === 2) {
    //   paths.push(
    //     this.generateLink(
    //       this.props.link.getSVGPath(),
    //       {
    //         onMouseDown: (event) => {
    //           this.addPointToLink(event, 1);
    //         },
    //       },
    //       '0',
    //     ),
    //   );
    //
    //   // draw the link as dangeling
    //   if (this.props.link.getTargetPort() == null) {
    //     paths.push(this.generatePoint(points[1]));
    //     paths.push(this.generateArrow(points[points.length - 1], points[points.length - 2]));
    //   }
    // }
    //
    // draw the multiple anchors and complex line instead
    paths.push(
      this.generateLink(
        this.props.link.getSVGPath(),
        {
          'data-linkid': this.props.link.getID(),
          'data-point': 0,
          onMouseDown: (event) => {
            this.addPointToLink(event, 1);
          },
        },
        0,
      ),
    );

    // render the circles
    paths.push(this.generatePoint(points[0]));
    paths.push(this.generatePoint(points[1]));

    paths.push(this.generateArrow(points[1], points[0]));

    return <g>{paths}</g>;
  }
}
