import React from 'react';
import classNames from 'classnames';

import magnifyingGlassIcon from 'assets/icons/magnifying-glass.svg';
import './style.scss';

const SearchField = ({
  value: externalValue,
  onChange: externalOnChange,
  input: {
    value = externalValue,
    onChange = externalOnChange,
    name,
    ...input
  } = {},
  type,
  placeholder,
  styleWrapper,
  styleInput,
  styleLabel,
  label,
  isDisabled = false,
  autoFocus = false,
  readOnly,
}) => {
  const basicFieldClass = classNames({
    'search-field': true,
    'search-field_disabled': isDisabled,
    [`search-field_${type}`]: type,
  });

  const onInputChange = ({ currentTarget }) => {
    if (onChange) {
      onChange(currentTarget.value);
    }
  };

  return (
    <div
      className={basicFieldClass}
      style={styleWrapper}
      readOnly={readOnly}
    >
      {
        label && (
          <label htmlFor={name} className="search-field__label" style={styleLabel}>
            {label}
          </label>
        )
      }
      <div className="search-field__input-wrapper" style={styleInput}>
        <input
          {...input}
          value={value}
          id={name}
          onChange={onInputChange}
          placeholder={placeholder}
          type="text"
          className="search-field__input-wrapper__input"
          disabled={isDisabled}
          autoFocus={autoFocus}
          readOnly={readOnly}
        />
        <img
          alt=""
          src={magnifyingGlassIcon}
          className="search-field__input-wrapper__icon"
        />
      </div>
    </div>
  );
};

export default SearchField;
