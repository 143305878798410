import endpoints from 'const/endpoints';

export default ({ body, id, successCallback }) => ({
  url: endpoints.getFiltersByIdUrl(id),
  body,
  options: {
    method: 'PUT',
  },
  meta: {
    authToken: true,
    successCallback,
  },
  transform: response => ({ filters: response }),
  update: {
    filters: (oldValue, newValue = {}) => oldValue.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          ...newValue,
          ...body,
        };
      }
      return item;
    }),
  },
});
