import { DEFAULT_LANGUAGES_OPTIONS, LANGUAGES_DICTIONARY } from 'mkit-front-ds/dist/consts';

const initialState = {
  selectedAttributes: {},
  languagesOptions: DEFAULT_LANGUAGES_OPTIONS,
};

export default (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'rewriteSelectedAttributes':
      return {
        ...state,
        selectedAttributes: {
          ...rest,
        },
      };
    case 'setSelectedAttributes':
      return {
        ...state,
        selectedAttributes: {
          ...state.selectedAttributes,
          ...rest,
        },
      };
    case 'clearSelectedAttributes':
      return {
        ...state,
        selectedAttributes: {},
      };
    case 'setAvailableLanguages':
      return {
        ...state,
        languagesOptions: rest?.languages?.length
          ? rest.languages.map(languageKey => ({
            value: languageKey,
            label: LANGUAGES_DICTIONARY[languageKey],
          }))
          : DEFAULT_LANGUAGES_OPTIONS,
      };
    default:
      return state;
  }
};
