import React from 'react';
import classNames from 'classnames';

import './style.scss';

const SuggestionButton = ({
  type,
  text,
  onClick,
  style,
  axis,
  disabled,
}) => {
  const className = classNames('mkit-suggestion-button', {
    [`mkit-suggestion-button_${type}`]: type,
    [`mkit-suggestion-button_${axis?.toLowerCase()}`]: !type && axis,
    'mkit-suggestion-button_disabled': disabled,
  });

  return (
    <button
      className={className}
      onClick={onClick}
      style={style}
      type="button"
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export default SuggestionButton;
