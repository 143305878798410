import endpoints from 'const/endpoints';

export default () => ({
  url: endpoints.getWhatsAppLanguagesUrl(),
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
  },
  transform: response => ({
    whatsAppLanguages: response,
  }),
  update: {
    whatsAppLanguages: (oldValue, newValue) => newValue,
  },
});
