/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';

import ThemeProvider from './context/ThemeProvider';
import TokenProvider from './context/TokenProvider';

import './index.scss';
import { Provider } from 'react-redux';
import { Provider as ReduxQueryProvider } from 'redux-query-react';

import VersionsScreen from 'screens/VersionsScreen';
import '@blueprintjs/core/lib/css/blueprint.css';
import 'mkit-front-ds/dist/@setproduct-ui/styles/setproduct.css';

import configureStore from './configureStore';
import 'antd/dist/antd.css';
import './uikit.less';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';

const getQueries = state => state.queries;
const store = configureStore();

if (window.location.pathname === '/versions') {
  ReactDOM.render(<VersionsScreen />, document.getElementById('root'));
} else {
  ReactDOM.render(<Provider store={store}>
    <ReduxQueryProvider queriesSelector={getQueries}>
      <TokenProvider>
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </TokenProvider>
    </ReduxQueryProvider>
  </Provider>, document.getElementById('root'));
}

serviceWorker.unregister();
