import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';

import {
  postAddAsset as postAddAssetApi,
  getAssets as getAssetsApi,
  deleteAsset as deleteAssetApi,
} from 'queries/assets';

import {
  getObject,
  getArray,
  useISESelector,
} from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetAssets,
      lastUpdated: lastUpdatedGetAssets,
      isFinished: isFinishedGetAssets,
    },
    getAssets,
  ] = useMutation(getAssetsApi);

  const [
    {
      isPending: isPendingAddAsset,
    },
    postAddAsset,
  ] = useMutation(postAddAssetApi);

  const [
    {
      isPending: isPendingDeleteAsset,
    },
    deleteAsset,
  ] = useMutation(deleteAssetApi);

  const selector = useCallback(state => ({
    assets: getArray(state, 'assets'),
    assetsMeta: getObject(state, 'assetsMeta'),
  }), []);
  const data = useISESelector(selector);

  return {
    deleteAsset,
    getAssets,
    postAddAsset,
    isPendingDeleteAsset,
    isPendingGetAssets,
    lastUpdatedGetAssets,
    isPendingAddAsset,
    isFinishedGetAssets,
    ...data,
  };
};
