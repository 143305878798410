import React, { useState, useMemo } from 'react';
import { orderBy } from 'lodash';

import HeaderCell from './HeaderCell';
import Row from './Row';
import styles from './Table.module.scss';

const COLUMNS = [
  {
    headerName: 'Service name',
    field: 'serviceName',
  },
  {
    headerName: 'Version',
    field: 'version',
  },
  {
    headerName: 'Build date',
    field: 'build.date',
  },
  {
    headerName: 'Commit date',
    field: 'commit.date',
  },
  {
    headerName: 'Build branch',
    field: 'build.branch',
  },
  {
    headerName: 'Build number',
    field: 'build.number',
  },
  {
    headerName: 'Health',
    field: 'health',
  },
];

const Table = ({
  list,
  referenceBuildBranch,
}) => {
  const [sort, setSort] = useState({});

  const sortedList = useMemo(() => {
    if (sort.field) {
      return orderBy(list, sort.field, sort.order);
    }

    return list;
  }, [sort, list]);

  return (
    <div className={styles.table}>
      <div className={styles.header}>
        {COLUMNS.map(column => (
          <HeaderCell
            {...column}
            key={column.field}
            sort={sort}
            setSort={setSort}
          />
        ))}
        <div />
        <div />
      </div>
      {sortedList.map(row => (
        <Row row={row} key={row.serviceName} referenceBuildBranch={referenceBuildBranch} />
      ))}
    </div>
  );
};

export default Table;
