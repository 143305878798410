import React, {
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import toastRef from 'mkit-front-ds/dist/helpers/toast';

import {
  useCompaniesApi,
  useFlowsNewApi,
  useUsersApi,
} from 'hooks/api';
import {
  useTable,
  useModalLogic,
  useBoolean,
  useTheme,
  useFilteredColumns,
} from 'hooks';
import { uikit, Table, buttons } from 'components';
import { BasicButton, IconButton } from 'components/buttons';
import { CustomColumnsView, DialogueScriptBlock } from 'components/blocks';
import { ConfirmModal, DeleteWarningModal } from 'components/modals';
import { EditScenarioForm } from 'components/forms';
import { SCENARIOS_COLUMNS } from 'const';
import fetchFile from 'helpers/fetchFile';
import endpoints from 'const/endpoints';
import treeIcon from 'assets/icons/scenario-tree.svg';
import pencilIcon from 'assets/icons/pencil.svg';
import trashIcon from 'assets/icons/trash.svg';
import addIcon from 'assets/icons/add.svg';
import crossDarkIcon from 'assets/icons/cross-grey.svg';
import copyIcon from 'assets/icons/copy.svg';
import toModeration from 'assets/icons/moderation/inline-moderation.svg';
import cancelModeration from 'assets/icons/moderation/inline-cancel-moderation.svg';
import exportIcon from 'assets/icons/export.svg';
import importIcon from 'assets/icons/import.svg';

import './style.scss';

const chatbotsPermissionMap = {
  'scenarios-create': 'createPermission',
  'scenarios-update': 'editPermission',
  'scenarios-delete': 'deletePermission',
};

const reducer = (state, { payload, action }) => {
  switch (action) {
    case 'add': {
      if (!state.find(item => item.id === payload.id)) {
        return [...state, payload];
      }
      break;
    }
    case 'remove': {
      return state.filter(item => item.id !== payload.id);
    }
    default: break;
  }
  return state;
};

const ScenariosScreen = () => {
  const iconStyle = { width: 'auto', height: 18 };

  const { t } = useTranslation();
  const { isDarkTheme } = useTheme();
  const isFormPristine = useRef(false);
  const fileInputRef = useRef();
  const [selectedScenarios, setSelectedScenarios] = useReducer(reducer, []);
  const [activeTab, setActiveTab] = useState('controlCenter');
  const [idToDelete, setIdToDelete] = useState();
  const [recordToEdit, setRecordToEdit] = useState();
  const tableRef = useRef(null);
  const [columns, setColumns] = useState([]);
  const [columnsNames, setColumnsNames] = useState([]);

  const [isImportPending, setIsImportPending] = useState(false);
  const [
    isOpenEditDrawer,
    openEditDrawer,
    closeEditDrawer,
  ] = useBoolean(false);
  const [isOpenModal, { openModal, closeModal }] = useModalLogic('');
  const { currentUser } = useUsersApi();
  const {
    flows,
    flowsMeta,
    getFlows,
    getFlowsAll,
    deleteFlow,
    createFlow,
    putFlow,
    postCloneFlow,
    postImportFlow,
    isPendingPutFlow,
    isPendingCreateFlow,
    isPendingDeleteFlow,
    isPendingGetFlows,
    lastUpdatedGetFlows,
    isPendingPostCloneFlow,
  } = useFlowsNewApi();
  const {
    sendQuery,
    setLimit,
    setOffset,
    limit,
    offset,
    onFilterChanged,
    onSortChanged,
  } = useTable({
    sendQuery: getFlows,
  });
  const {
    isOpenCustomViewDrawer,
    closeCustomViewDrawer,
    openCustomViewDrawer,
    onSaveCustomView,
    filteredColumns,
    setFilteredColumns,
  } = useFilteredColumns();
  const {
    getFlowToggleModerate,
  } = useFlowsNewApi();
  const {
    getOwnCompany,
    isPendingGetOwnCompany,
  } = useCompaniesApi();
  const {
    createPermission,
    editPermission,
    deletePermission,
  } = useMemo(() => currentUser?.roles?.reduce?.((acc, permissionKey) => {
    const permissionProperty = chatbotsPermissionMap[permissionKey];

    if (permissionProperty) {
      acc[permissionProperty] = true;
    }

    return acc;
  }, {}) || {}, []);

  const onCloseEditDrawer = () => {
    setRecordToEdit(null);
    closeEditDrawer();
  };

  const onClose = () => {
    if (isFormPristine.current) {
      onCloseEditDrawer();
    } else {
      openModal('confirm');
    }
  };

  const onConfirmClose = () => {
    setRecordToEdit(null);
    closeModal();
    closeEditDrawer();
  };

  const onDeleteConfirm = ({ id }) => {
    if (selectedScenarios.find(I => I.id === id || I.id === idToDelete)) {
      closeModal();
      onCloseEditDrawer();
      toastRef?.current?.show({ message: t('TOAST.DELETE_ERROR') });
    } else {
      deleteFlow({
        id: id || idToDelete,
        successCallback: () => {
          sendQuery();
          closeModal();
          onCloseEditDrawer();
        },
        errorCallback: () => {
          closeModal();
          onCloseEditDrawer();
        },
      });
    }
  };
  const onSubmit = (body) => {
    (recordToEdit ? putFlow : createFlow)({
      id: recordToEdit?.id,
      body,
      successCallback: () => {
        sendQuery();
        onCloseEditDrawer();
      },
    });
  };
  const onCloseTab = ({ e, id, index }) => {
    setSelectedScenarios({ action: 'remove', payload: { id } });
    if (index) {
      setActiveTab(
        selectedScenarios[index - 1].id.toString(),
      );
    } else {
      setActiveTab('controlCenter');
    }
    e.stopPropagation();
  };
  const onDeleteClick = ({ id }) => {
    setIdToDelete(id);
    openModal('delete');
  };
  const onImportChatbotClick = () => fileInputRef?.current?.click?.();
  const handleFileChange = ({ target }) => {
    const file = target.files?.[0];

    if (file) {
      if (file.type !== 'application/zip' && file.type !== 'application/x-zip-compressed') {
        toastRef.current.show({ message: t('NEW_TRANSLATES.TOAST_ERRORS.UNSUPPORTED_FILE_FORMAT'), intent: 'danger' });
        return;
      }
      const body = new FormData();
      body.append('file', file);

      setIsImportPending(true);
      postImportFlow({
        body,
        successCallback: () => {
          sendQuery();
          setIsImportPending(false);
          getFlowsAll();
        },
        errorCallback: () => setIsImportPending(false),
      });
    }
    target.value = '';
  };

  useEffect(() => {
    // Обнуляем фильтр колонок т к при включенной (или выключенной) модерации отображаемые колонки меняются, а фильт колонок нет
    setFilteredColumns({});
    getOwnCompany({
      successCallback: (ownCompany) => {
        const nextColumns = [...SCENARIOS_COLUMNS(t, isDarkTheme)];
        const moderatedButtons = [];

        moderatedButtons.push({
          id: 'moderation',
          icon: ({ status }) => (status === 2 ? toModeration : cancelModeration),
          filterType: 'white',
          onClick: ({ id }) => {
            getFlowToggleModerate({
              id,
              successCallback: () => sendQuery(),
            });
          },
          hidden: ({ status }) => (!ownCompany?.isModerated || (status !== 2 && status !== 4)),
          iconStyle,
          disabled: !deletePermission,
          title: !deletePermission && 'Not enough permissions',
        });
        nextColumns.splice(5, 0, {
          field: 'status',
          headerName: t('INSTANCES.STATUS'),
          cellRenderer: 'chatBotStatusRenderer',
          valueFormatter: ({ value }) => value,
          width: 200,
          hide: !ownCompany?.isModerated,
        });

        nextColumns.push({
          headerName: t('INSTANCES.ACTIONS'),
          cellClass: 'appearing-cell',
          cellRenderer: 'cellRendererActions',
          pinned: 'right',
          width: 200,
          minWidth: 200,
          resizable: false,
          cellRendererParams: {
            buttons: [
              ...moderatedButtons,
              {
                id: 'clone',
                icon: copyIcon,
                filterType: 'white',
                onClick: ({ id }) => {
                  postCloneFlow({
                    id,
                    successCallback: () => sendQuery(),
                  });
                },
                iconStyle,
                disabled: !createPermission,
                title: t(`NEW_TRANSLATES.TOOLTIPS.${!createPermission ? 'NOT_ENOUGH_PERMISSIONS' : 'CHATBOTS.DUPLICATE'}`),
              },
              {
                id: 'export',
                icon: exportIcon,
                filterType: 'white',
                onClick: ({ name, id }) => {
                  toastRef?.current?.show({ message: t('NEW_TRANSLATES.TOASTS.WAIT_DOWNLOAD'), intent: 'success' });
                  fetchFile({
                    url: endpoints.getExportFlowUrl(id),
                    fileName: name,
                  });
                },
                iconStyle,
                title: t('NEW_TRANSLATES.TOOLTIPS.CHATBOTS.EXPORT_CHATBOT'),
              },
              {
                id: 'pencil',
                icon: pencilIcon,
                filterType: 'white',
                onClick: (value) => {
                  setRecordToEdit(value);
                  openEditDrawer();
                },
                iconStyle,
                title: t('NEW_TRANSLATES.TOOLTIPS.EDIT'),
              },
              {
                id: 'tree',
                icon: treeIcon,
                filterType: 'white',
                onClick: ({ name, id }) => {
                  setSelectedScenarios({ action: 'add', payload: { name, id } });
                  setActiveTab(id.toString());
                },
                iconStyle,
                title: t('NEW_TRANSLATES.TOOLTIPS.CHATBOTS.OPEN_SCENARIOS'),
              },
              {
                id: 'trash',
                icon: trashIcon,
                filterType: 'white',
                onClick: ({ id }) => {
                  setIdToDelete(id);
                  openModal('delete');
                },
                iconStyle,
                disabled: !deletePermission,
                title: t(`NEW_TRANSLATES.TOOLTIPS.${!deletePermission ? 'NOT_ENOUGH_PERMISSIONS' : 'DELETE'}`),
              },
            ],
          },
          cellStyle: { justifyContent: 'flex-end' },
          lockVisible: true,
        });

        setColumns(nextColumns);
        setColumnsNames(ownCompany?.isModerated ? nextColumns.slice(0, -1) : [...SCENARIOS_COLUMNS(t)]);
      },
    });
  }, []);

  return (
    <div className="scenarios-screen">
      <div className="scenarios-screen__header">
        {t('SCREEN_TITLES.SCENARIOS')}
        {activeTab === 'controlCenter' && (
          <buttons.BasicButton
            onClick={openEditDrawer}
            text={t('SCENARIOS_CONSTRUCTOR.CONTROLS.NEW_SCENARIO')}
            icon={addIcon}
            filterType="white"
            type={BasicButton.types.ACCENT}
            disabled={!createPermission}
            title={createPermission ? undefined : 'Not enough permissions'}
          />
        )}
      </div>
      <uikit.Tabs
        activeKey={activeTab}
        onTabClick={setActiveTab}
        animated={false}
      >
        <uikit.TabPane
          tab={t('INSTANCES.CONTROL_CENTER')}
          key="controlCenter"
        >
          <Table
            title={t('SCENARIOS_CONSTRUCTOR.INSTANCES.SCENARIOS_LIST')}
            rowData={flows}
            columnDefs={columns}
            filteredColumns={filteredColumns}
            resizableColDef
            loading={isPendingGetFlows || !lastUpdatedGetFlows || isPendingPostCloneFlow || isPendingGetOwnCompany}
            limit={limit}
            total={flowsMeta.size}
            offset={offset}
            onChangeLimit={setLimit}
            onChangePage={setOffset}
            onCustomViewPress={openCustomViewDrawer}
            onRefreshPress={sendQuery}
            tableRef={tableRef}
            onFilterChanged={onFilterChanged}
            onSortChanged={onSortChanged}
            rightComponent={(
              <>
                <buttons.BasicButton
                  onClick={onImportChatbotClick}
                  text={t('NEW_TRANSLATES.CONTROLS.CHATBOTS.IMPORT_CHATBOT')}
                  icon={importIcon}
                  filterType="white"
                  disabled={!createPermission}
                  title={createPermission ? undefined : 'Not enough permissions'}
                  loading={isImportPending}
                />
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                  accept=".zip"
                />
              </>
            )}
          />
          <uikit.Drawer
            visible={isOpenCustomViewDrawer}
            onClose={closeCustomViewDrawer}
            width={360}
          >
            <CustomColumnsView
              initialValues={filteredColumns}
              onSubmit={onSaveCustomView}
              columnsNames={columnsNames}
              onCancel={closeCustomViewDrawer}
            />
          </uikit.Drawer>
        </uikit.TabPane>
        {
          selectedScenarios.map(({ name, id }, index) => (
            <uikit.TabPane
              tab={(
                <div className="scenarios-screen__tab">
                  {name}
                  <IconButton
                    icon={crossDarkIcon}
                    onClick={e => onCloseTab({ e, id, index })}
                    filterType="white"
                  />
                </div>
              )}
              key={id}
            >
              <DialogueScriptBlock
                campaignName={name}
                flowId={id}
                readOnlyAlways={!editPermission}
                sendQuery={sendQuery}
              />
            </uikit.TabPane>
          ))
        }
      </uikit.Tabs>
      {
        isOpenModal === 'delete' && (
          <DeleteWarningModal
            closeModal={closeModal}
            onDelete={onDeleteConfirm}
            isLoading={isPendingDeleteFlow}
            descriptionKey={t('TABLE.RECORD')}
          />
        )
      }
      {
        isOpenModal === 'confirm' && (
          <ConfirmModal
            onConfirm={onConfirmClose}
            closeModal={closeModal}
            description={t('TOAST.CLOSE_CONFIRM_DESC')}
          />
        )
      }
      <uikit.Drawer
        visible={isOpenEditDrawer}
        onClose={onClose}
        width={630}
        destroyOnClose
      >
        <EditScenarioForm
          onDelete={onDeleteClick}
          onSubmit={onSubmit}
          isLoading={isPendingDeleteFlow || isPendingCreateFlow || isPendingPutFlow}
          initialValues={recordToEdit}
          onCancel={onClose}
          isFormPristine={isFormPristine}
          editAllowed={editPermission}
          deleteAllowed={deletePermission}
        />
      </uikit.Drawer>
    </div>
  );
};

export default ScenariosScreen;
