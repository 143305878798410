import React from 'react';

import {
  EditorTextarea,
} from 'components/constructor';

import './style.scss';

const WhatsAppText = ({
  rcsParams: {
    message,
  },
  updateRcsParams,
  readOnly,
}) => {
  const setMessage = (newMessage) => {
    updateRcsParams({
      message: newMessage,
    });
  };

  return (
    <div className="mkit-whats-app-text">
      <div className="mkit-whats-app-text__input-wrapper">
        <EditorTextarea
          value={message}
          onChange={setMessage}
          type="mkit-whats-app-text"
          readOnly={readOnly}
        />
      </div>
    </div>
  );
};

export default WhatsAppText;
