import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DropdownField } from 'components/fields';
import chevronIcon from 'assets/icons/chevron.svg';

import './Pagination.scss';

const Pagination = ({
  limit = 1,
  total,
  page = 1,
  onChangePage,
  onChangeLimit,
  keyName,
}) => {
  const { t } = useTranslation();

  const { pagesAmount, pagesAmountArray } = useMemo(() => {
    const nextPagesAmount = Math.ceil(total / limit);
    return {
      pagesAmount: nextPagesAmount,
      pagesAmountArray: Array(nextPagesAmount).fill().map((_, idx) => 1 + idx),
    };
  }, [limit, total]);

  const onClickPrevPage = () => {
    if (page !== 1) {
      onChangePage(page - 1);
    }
  };
  const onClickNextPage = () => {
    if (pagesAmount > page) {
      onChangePage(page + 1);
    }
  };

  return (
    <div className="mkit-pagination">
      <div className="mkit-pagination__limit-label">{t('TABLE.TO_SHOW', { keyName: keyName || t('TABLE.LINES') })}</div>
      <div className="mkit-pagination__limit-field-wrapper">
        <DropdownField
          onChange={onChangeLimit}
          value={limit}
          values={[10, 50, 100, 200, 500]}
          placeholder={10}
          width={60}
          type="white"
          withoutCallbackDestroy
        />
      </div>
      <div className="mkit-pagination__page-info">
        {`${(page - 1) * limit + 1} - ${(page * limit) > total ? total : page * limit} ${t('TABLE.OF')} ${total}`}
      </div>
      <div className="mkit-pagination__page-control">
        <img
          role="presentation"
          className="mkit-pagination__page-control__button"
          src={chevronIcon}
          alt="left"
          onClick={onClickPrevPage}
        />
        <div className="mkit-pagination__page-control__page-field-wrapper">
          <DropdownField
            onChange={onChangePage}
            value={page}
            values={pagesAmountArray}
            placeholder={1}
            width={60}
            type="white"
            withoutCallbackDestroy
          />
        </div>
        <img
          role="presentation"
          className="mkit-pagination__page-control__button mkit-pagination__page-control__button_right"
          src={chevronIcon}
          alt="right"
          onClick={onClickNextPage}
        />
      </div>
    </div>
  );
};

export default Pagination;
