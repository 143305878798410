import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';

import {
  getTemplates as getTemplatesApi,
  postCreateTemplate as postCreateTemplateApi,
  patchUpdateTemplate as patchUpdateTemplateApi,
  deleteTemplate as deleteTemplateApi,
} from 'queries/templates';

import {
  getObject,
  getArray,
  useISESelector,
} from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetTemplates,
      lastUpdated: lastUpdatedGetTemplates,
    },
    getTemplates,
  ] = useMutation(getTemplatesApi);
  const [
    {
      isPending: isPendingPostTemplate,
      lastUpdated: lastUpdatedPostTemplate,
    },
    postCreateTemplate,
  ] = useMutation(postCreateTemplateApi);
  const [
    {
      isPending: isPendingPatchTemplate,
      lastUpdated: lastUpdatedPatchTemplate,
    },
    patchUpdateTemplate,
  ] = useMutation(patchUpdateTemplateApi);
  const [
    {
      isPending: isPendingDeleteTemplate,
      lastUpdated: lastUpdatedDeleteTemplate,
    },
    deleteTemplate,
  ] = useMutation(deleteTemplateApi);

  const selector = useCallback(state => ({
    templates: getArray(state, 'templates'),
    templatesPagination: getObject(state, 'templatesPagination'),
  }), []);
  const data = useISESelector(selector);

  return {
    getTemplates,
    postCreateTemplate,
    patchUpdateTemplate,
    deleteTemplate,
    isPendingGetTemplates,
    lastUpdatedGetTemplates,
    isPendingPostTemplate,
    lastUpdatedPostTemplate,
    isPendingPatchTemplate,
    lastUpdatedPatchTemplate,
    isPendingDeleteTemplate,
    lastUpdatedDeleteTemplate,
    ...data,
  };
};
