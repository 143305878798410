import React from 'react';
import shortid from 'shortid';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';

import { BasicButton } from 'components/buttons';
import addIcon from 'assets/icons/add.svg';

import ResponseFieldArray from '../ResponseFieldArray';

import './style.scss';

const ResponseGroupFieldArray = ({
  fields,
  styles = {},
  buttonText = 'ADD VALUE',
  readOnly = false,
  reportFields,
}) => {
  const { t } = useTranslation();
  const onAddField = () => {
    fields.push([{ conditionType: 1, id: shortid.generate() }]);
  };
  return (
    <div className="response-group-field-array" style={styles.wrapper}>
      <div className="response-group-field-array__fields">
        {
          fields.map((name, index) => (
            <FieldArray
              key={name}
              name={name}
              label={`${t('SCENARIOS_CONSTRUCTOR.INSTANCES.RESPONSE_GROUP')} #${index + 1}`}
              component={ResponseFieldArray}
              removeGroup={() => fields.remove(index)}
              readOnly={readOnly}
              fieldName={name}
              reportFields={reportFields}
            />
          ))
        }
      </div>
      {
        !readOnly && (
          <BasicButton
            text={buttonText}
            type={BasicButton.types.PRIMARY}
            icon={addIcon}
            filterType="white"
            onClick={onAddField}
            isNewStyle
          />
        )
      }
    </div>
  );
};

export default ResponseGroupFieldArray;
