import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { useReportsApi } from 'hooks/api';
import { TabsWrapper, TabsPane } from 'components/tabs';
import { ReportBlock } from 'components/blocks';
import { blocks } from 'components';

import './style.scss';

const ReportsScreen = () => {
  const { t } = useTranslation();

  const {
    getGroups,
    groups,
    isPendingGetGroups,
    lastUpdatedGetGroups,
    getParameters,
    isPendingGetParameters,
    reportParameters,
    postCreateReport,
    reports,
    deleteReport,
    isPendingPostCreateReport,
    isSuccessGetParameters,
  } = useReportsApi();

  const [activeTab, setActiveTab] = useState('control');
  const [editableReport, setEditableReport] = useState({});

  const onSelectReport = (templateId) => {
    getParameters({
      templateId,
    });
  };

  const onDeleteReport = (reportName) => {
    const name = reportName.replace(/\s+/g, '').toLowerCase();
    sessionStorage.removeItem(`columns_${window.location.pathname}_${name}`);
    sessionStorage.removeItem(`filters_${window.location.pathname}_${name}`);
    deleteReport(reportName);
    if (activeTab === reportName) {
      setActiveTab('control');
    }
  };

  const getFilterType = (type) => {
    switch (type) {
      case 'Number field':
        return 'numberColumnFilter';
      case 'Text field':
        return 'maskColumnFilter';
      case 'Date':
        return 'dateColumnFilter';
      default:
        return false;
    }
  };

  const modifyReports = useMemo(() => reports.map(report => ({
    name: report.name,
    templateId: report.templateId,
    columns: report.attributes?.map((attribute) => {
      const columnParams = attribute.name.split('|');
      const columnName = columnParams.shift();
      return {
        columnParams,
        headerName: columnName,
        field: attribute.name,
        cellRenderer: 'cellRendererReport',
        filter: getFilterType(attribute.type),
        sortable: true,
        flex: 1,
        valueFormatter: attribute.type === 'Date'
          ? ({ value }) => moment.utc(value).format('DD.MM.YYYY HH:mm:ss')
          : ({ colDef, data }) => data[colDef.field],
      };
    }),
    size: report.size,
    offset: report.offset,
    limit: report.limit,
    list: report.values,
    initialParameters: report.parameters,
    parameters: report.parameters.reduce((acc, item) => ({
      ...acc,
      [item.bindName]: item.parameterType === 'Date' && item.value
        ? moment(item.value) : item.value,
    }), {}),
  })), [reports]);

  useEffect(() => {
    getGroups();
  }, []);

  return (
    <>
      <h1 className="reports-layout__title" data-testid={`${window.location.pathname}/title`}>
        {t('SCREEN_TITLES.REPORTS')}
      </h1>
      <TabsWrapper externalActiveTab={activeTab} onChangeTab={setActiveTab}>
        <TabsPane label={t('INSTANCES.CONTROL_CENTER')} tabKey="control">
          <blocks.CreateReportBlock
            list={groups}
            isListLoading={isPendingGetGroups || !lastUpdatedGetGroups}
            reportParameters={reportParameters}
            isParamsLoading={isPendingGetParameters}
            onSelectReport={onSelectReport}
            isPendingPostCreateReport={isPendingPostCreateReport}
            postCreateReport={postCreateReport}
            reports={reports}
            setActiveTab={setActiveTab}
            editableReport={editableReport}
            isSuccessGetParameters={isSuccessGetParameters}
          />
        </TabsPane>
        {
          modifyReports.map(report => (
            <TabsPane
              label={report.name}
              tabKey={report.name}
              onClose={onDeleteReport}
            >
              <ReportBlock
                report={report}
                setEditableReport={setEditableReport}
                setActiveTab={setActiveTab}
                postCreateReport={postCreateReport}
                isLoading={isPendingPostCreateReport}
              />
            </TabsPane>
          ))
        }
      </TabsWrapper>
    </>
  );
};

export default ReportsScreen;
