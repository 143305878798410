import React from 'react';
import classNames from 'classnames';

import { IconButton } from 'components/buttons';
import crossIcon from 'assets/icons/cross-dark.svg';

import './style.scss';

const TabsPane = ({
  label,
  tabKey,
  onClickTab,
  activeTab,
  onClose,
}) => {
  const className = classNames({
    'tab-list-item': true,
    'tab-list-item_active': activeTab === tabKey,
  });

  const buttonStyle = { width: 15, height: 15, marginTop: 2 };
  const iconStyle = { width: 15 };

  const onTabPress = () => onClickTab(tabKey);

  return (
    <div
      className={className}
      onClick={onTabPress}
      role="presentation"
      data-testid={`${window.location.pathname}/tabs-pane/${tabKey}`}
    >
      {label}
      {
        !!onClose && (
          <IconButton
            icon={crossIcon}
            onClick={(e) => {
              e.stopPropagation();
              onClose(tabKey);
            }}
            style={buttonStyle}
            iconStyle={iconStyle}
            filterType="white"
          />
        )
      }
    </div>
  );
};

export default TabsPane;
