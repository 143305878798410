import React from 'react';

import './CampaignEditControlledOutside.scss';
import img from 'assets/imgs/octo.svg';

const CampaignEditControlledOutSide = () => (
  <div className="campaign-create-controlled-outside">
    <img src={img} alt="Controlled from the outside" className="campaign-create-controlled-outside__img" />
    <div className="campaign-create-controlled-outside__text">Controlled from the outside</div>
  </div>
);

export default CampaignEditControlledOutSide;
