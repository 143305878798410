import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';

import {
  getFlowResource as getFlowResourceApi,
  postFlowNode as postFlowNodeApi,
  putFlowNode as putFlowNodeApi,
  deleteFlowNode as deleteFlowNodeApi,
  postFlowLink as postFlowLinkApi,
  putFlowLink as putFlowLinkApi,
  deleteFlowLink as deleteFlowLinkApi,
  getValidators as getValidatorsApi,
  postCloneNode as postCloneNodeApi,
} from 'queries/flows';

import {
  getArray,
  defaultArray,
  useISESelector,
  getObject,
} from './selectors';

export default (
  flowId,
) => {
  const [
    {
      isPending: isPendingGetFlowResource,
      lastUpdated: lastUpdatedGetFlowResource,
    },
    getFlowResource,
  ] = useMutation(getFlowResourceApi);
  const [
    {
      isPending: isPendingGetValidators,
      lastUpdated: lastUpdatedGetValidators,
    },
    getValidators,
  ] = useMutation(getValidatorsApi);
  const [, postFlowNode] = useMutation(postFlowNodeApi);
  const [, putFlowNode] = useMutation(putFlowNodeApi);
  const [, deleteFlowNode] = useMutation(deleteFlowNodeApi);
  const [, postFlowLink] = useMutation(postFlowLinkApi);
  const [, putFlowLink] = useMutation(putFlowLinkApi);
  const [, deleteFlowLink] = useMutation(deleteFlowLinkApi);
  const [, postCloneNode] = useMutation(postCloneNodeApi);

  const selector = useCallback(state => ({
    validators: getArray(state, 'validators') || defaultArray,
    flowResource: flowId ? getObject(state, 'flowResource')[flowId] : getObject(state, 'flowResource'),
  }), [flowId]);
  const data = useISESelector(selector);

  return {
    getFlowResource,
    isPendingGetFlowResource,
    lastUpdatedGetFlowResource,
    postFlowNode,
    putFlowNode,
    deleteFlowNode,
    postFlowLink,
    putFlowLink,
    deleteFlowLink,
    getValidators,
    postCloneNode,
    isPendingGetValidators,
    lastUpdatedGetValidators,
    ...data,
  };
};
