import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useDropdownLogic, useTheme } from 'hooks';
import chevronIcon from 'assets/icons/chevron.svg';

import Option from './Option';
import './style.scss';

const DropdownField = ({
  type: externalType,
  onChange: externalOnChange,
  value: externalValue,
  name: externalName,
  input: {
    value = externalValue,
    onChange = externalOnChange,
    type = externalType,
    name = externalName,
  } = {},
  meta: {
    error,
  } = {},
  label,

  withSearch,
  withEmpty,
  styles = {},
  disabled = false,
  isRequired = false,
  readOnly = false,
  dropdownContentStyle,
  placeholder,
  options,
  parent,
  overlayPlacement = 'auto',
  customTextField = null,
  withTranslate = true,
  className,
}) => {
  const { t } = useTranslation();
  const { isDarkTheme } = useTheme();
  const {
    buttonRef,
    dropdownRef,
    isDropdownOpen,
    setDropdownOpen,
    toggleDropdown,
  } = useDropdownLogic();
  const [isDirectionTop, setIsDirectionTop] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isAll, setIsAll] = useState(true);

  const fieldRef = useRef();

  const dropdownFieldClass = classNames('mkit-dropdown-field', {
    [`mkit-dropdown-field_${type}`]: !!type,
    'mkit-dropdown-field_active': isDropdownOpen,
    'mkit-dropdown-field_direction-top': isDirectionTop,
    [className]: className,
  });
  const valueClass = classNames('mkit-dropdown-field__control__value', {
    'mkit-dropdown-field__control__value_placeholder': value === '',
  });

  const selectedOption = useMemo(() => {
    const foundItem = options.find(item => item.value === value);
    if (foundItem) {
      return foundItem;
    }

    return {};
  }, [options, value]);

  const optionsModify = useMemo(() => {
    const search = searchValue ? searchValue.trim().toLowerCase() : '';

    if (search && !isAll) {
      return options.filter(I => I.label?.toLowerCase()?.includes(search));
    }

    return options;
  }, [options, searchValue, isAll]);

  const handleSearch = ({ target }) => {
    setIsAll(false);
    setSearchValue(target.value);
  };

  const onSelectOption = (val) => {
    onChange(val);
    setDropdownOpen(!isDropdownOpen);
    if (withSearch) {
      setSearchValue(options.find(item => item.value === val)?.label);
      setIsAll(true);
    }
  };

  const onClear = () => {
    onChange(undefined);
    setDropdownOpen(!isDropdownOpen);
    if (withSearch) {
      setSearchValue('');
      setIsAll(true);
    }
  };

  const onClick = () => {
    if (readOnly) {
      return;
    }

    if (parent) {
      const parentBounding = document.querySelector(`[ref="${parent}"]`).getBoundingClientRect();
      const overlayBounding = fieldRef.current.getBoundingClientRect();
      let overlayHeight = (options.length >= 4 ? 4 : options.length) * 36;
      if (parentBounding.height < 180 && options.length > 2) {
        overlayHeight = 72;
      }

      if (parentBounding.bottom - overlayBounding.top - overlayHeight < 0) {
        setIsDirectionTop(true);
      } else if (isDirectionTop) {
        setIsDirectionTop(false);
      }
    } else if (overlayPlacement === 'auto') {
      if (((window.innerHeight - fieldRef.current.offsetTop) < 180) && !isDirectionTop) {
        setIsDirectionTop(true);
      } else if (((window.innerHeight - fieldRef.current.offsetTop) > 180) && isDirectionTop) {
        setIsDirectionTop(false);
      }
    }

    toggleDropdown();
  };

  useEffect(() => {
    if (isDropdownOpen && parent) {
      const parentBounding = document.querySelector(`[ref="${parent}"]`).getBoundingClientRect();
      if (parentBounding.height < 180) {
        fieldRef.current.children[0].children[1].style.height = '72px';
      } else if (fieldRef.current.children[0].children[1].style.height) {
        fieldRef.current.children[0].children[1].style.removeProperty('height');
      }
    }
  }, [isDropdownOpen]);

  useEffect(() => {
    if (!isDropdownOpen && withSearch && searchValue !== selectedOption?.label) {
      setSearchValue(selectedOption?.label || '');
    }
  }, [isDropdownOpen, selectedOption, searchValue]);

  return (
    <div
      ref={fieldRef}
      style={styles.container}
      className={dropdownFieldClass}
      data-tip={error}
      data-for={`${name}-mkit-dropdown-field`}
    >
      {
        label && (
          <label className="mkit-dropdown-field__label" style={styles.label}>
            {label}
            {isRequired && ' *'}
          </label>
        )
      }
      <div className="mkit-dropdown-field__content" style={styles.content}>
        {
          withSearch
            ? (
              <input
                ref={buttonRef}
                type="text"
                className="mkit-dropdown-field__control"
                disabled={disabled}
                onFocus={onClick}
                style={styles.control}
                placeholder={placeholder}
                value={searchValue}
                onChange={handleSearch}
              />
            )
            : (
              <button
                ref={buttonRef}
                className="mkit-dropdown-field__control"
                type="button"
                onClick={onClick}
                disabled={disabled}
                style={styles.control}
              >
                {
                  selectedOption.icon && (
                    <img
                      src={(!isDarkTheme && selectedOption.lightThemeIcon)
                        ? selectedOption.lightThemeIcon
                        : selectedOption.icon}
                      alt={selectedOption.value}
                      className={classNames('mkit-dropdown-field__control__icon', {
                        [`mkit-dropdown-field__control__icon_filter-${selectedOption.filterType}`]: selectedOption.filterType,
                      })}
                    />
                  )
                }
                <span className={valueClass} style={styles.controlValue}>
                  {withTranslate ? t(selectedOption.label) : selectedOption.label || placeholder}
                </span>
              </button>
            )
        }
        <img
          className="mkit-dropdown-field__content__dropdown-indicator"
          src={chevronIcon}
          alt="chevron-down"
        />
        {
          isDropdownOpen && (
            <div
              ref={dropdownRef}
              className="mkit-dropdown-field__overlay"
              style={dropdownContentStyle}
            >
              {
                withEmpty && value && (
                  <Option
                    label="{ Empty }"
                    onSelectOption={onClear}
                    isItalic
                  />
                )
              }
              {
                optionsModify.map(option => (
                  <Option
                    key={option.value}
                    value={option.value}
                    label={withTranslate ? t(option.label) : option.label}
                    icon={(!isDarkTheme && option.lightThemeIcon)
                      ? option.lightThemeIcon
                      : option.icon}
                    filterType={option.filterType}
                    isItalic={option.isItalic}
                    onSelectOption={onSelectOption}
                  />
                ))
              }
              {customTextField}
            </div>
          )
        }
      </div>
    </div>
  );
};

export default DropdownField;
