import endpoints from '../../const/endpoints';

export default () => ({
  url: endpoints.getDeliveryConditions(),
  queryKey: endpoints.getDeliveryConditions(),
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
  },
  transform: response => ({
    deliveryConditions: response,
  }),
  update: {
    deliveryConditions: (oldValue, newValue) => newValue,
  },
});
