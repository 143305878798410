import React from 'react';
import classNames from 'classnames';

import './style.scss';

const LoadingSpinner = ({
  fullSize,
  style,
  small,
  type,
  isPageLoader,
}) => {
  const spinnerClass = classNames('spinner', {
    'spinner_full-size': fullSize,
    'spinner_page-loader': isPageLoader,
  });
  const innerClass = classNames('spinner__inner', {
    [`spinner__inner_${type}`]: type,
    spinner__inner_small: small,
  });

  return (
    <div
      className={spinnerClass}
      style={style}
    >
      <div className={innerClass} />
    </div>
  );
};

export default LoadingSpinner;
