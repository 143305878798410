import React, { useEffect } from 'react';
import classNames from 'classnames';

import rollbackIcon from 'assets/icons/rollback.svg';
import errorIcon from 'assets/icons/error.svg';
import './style.scss';

const BasicField = ({
  value: externalValue,
  type: externalType,
  onChange: externalOnChange,
  input: {
    value = externalValue,
    onChange = externalOnChange,
    name,
    type = externalType,
    ...input
  } = {},
  meta: {
    pristine,
    initial,
    error,
    active,
    touched,
  } = {},
  placeholder,
  isResetable,
  styleWrapper = {},
  styleInput = {},
  styleLabel = {},
  label,
  isDisabled = false,
  isRequired = false,
  autoFocus = false,
  readOnly,
  icon,
  withoutError = false,
  pattern,
  inputRef,
  styles = {},
  withoutCallbackDestroy = false,
  fieldType = 'text',
  autoComplete = 'off',
  maxLength,
  fieldId,
}) => {
  const basicFieldClass = classNames({
    'basic-field': true,
    'basic-field_disabled': isDisabled,
    'basic-field_error': error && touched && !active,
    'basic-field_without-error': withoutError,
    [`basic-field_${type}`]: type,
  });

  const handleChange = ({ target }) => {
    onChange(target.validity.valid ? target.value : value);
  };
  const resetValue = () => {
    onChange(initial);
  };

  useEffect(() => () => {
    if (!withoutCallbackDestroy && onChange) {
      onChange(undefined);
    }
  }, []);

  return (
    <div
      className={basicFieldClass}
      style={{ ...styleWrapper, ...styles.container }}
    >
      {
        label && (
          <label
            htmlFor={name}
            className="basic-field__label"
            style={{ ...styleLabel, ...styles.label }}
          >
            {label}
            {isRequired && ' *'}
          </label>
        )
      }
      <div
        className="basic-field__input-wrapper"
        style={{ ...styleInput, ...styles.inputContainer }}
      >
        { touched && error && !active && !withoutError && (
          <img
            src={errorIcon}
            className="basic-field__input-wrapper__error"
            alt=""
            title={error}
          />
        )}
        <input
          {...input}
          ref={inputRef}
          value={value}
          id={name}
          onChange={handleChange}
          placeholder={placeholder}
          type={fieldType}
          className="basic-field__input-wrapper__input"
          disabled={isDisabled}
          autoFocus={autoFocus}
          readOnly={readOnly}
          pattern={pattern}
          autoComplete={autoComplete}
          maxLength={maxLength}
          data-testid={`${window.location.pathname}/basic-field/${label?.toLowerCase().replace(/\s+/g, '') || fieldId}`}
        />
        {
          icon && (
            <img
              src={icon}
              alt=""
              className="basic-field__input-wrapper__icon"
            />
          )
        }
      </div>
      {
        isResetable && (
          <div className="basic-field__reset-button">
            {
              isResetable && !pristine && (
                <img src={rollbackIcon} onClick={resetValue} alt="" role="presentation" />
              )
            }
          </div>
        )
      }
    </div>
  );
};

export default BasicField;
