import React from 'react';

import { CAMPAIGN_CHANNEL_ACTION_IMAGES } from 'const';

import CampaignChannelBase from './CampaignChannelBase';

const CampaignChannelAction = ({ type, ...props }) => (
  <CampaignChannelBase {...props} img={CAMPAIGN_CHANNEL_ACTION_IMAGES[type]} type={type} />
);

export default CampaignChannelAction;
