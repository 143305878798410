import endpoints from 'const/endpoints';

export default ({
  id,
  successCallback,
  errorCallback,
}) => ({
  url: endpoints.getRecipientsByIdUrl(id),
  queryKey: `get${endpoints.getRecipientsByIdUrl()}`,
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
    successCallback,
    errorCallback,
  },
  transform: response => ({ recipient: response }),
  update: {
    recipient: (oldValue, newValue) => newValue,
  },
});
