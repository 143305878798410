import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { SearchField } from 'components/fields';
import { useDropdownLogic } from 'hooks';

import './style.scss';

const MultiSelectField = ({
  input: {
    onChange,
    value = [],
    name,
  },
  placeholder,
  style = {},
  styleButton = {},
  label,
  items,
  disabled,
  // meta: {
  //   error,
  //   active,
  //   touched,
  // } = {},
  labelStyle,
  overlayStyle = {},
  styles = {},
  withSearch = true,
  fieldId,
}) => {
  const {
    isDropdownOpen,
    setDropdownOpen,
    dropdownRef,
    buttonRef,
  } = useDropdownLogic();

  const { t } = useTranslation();

  const dropdownClass = classNames({
    'multi-select-field': true,
    'multi-select-field_disabled': disabled,
    'multi-select-field_open': isDropdownOpen,
  });

  const [searchValue, setSearchValue] = useState('');

  const handleChange = (id) => {
    onChange([...value, id]);
  };

  const onButtonClick = () => {
    if (!disabled) {
      setDropdownOpen(!isDropdownOpen);
    }
  };

  const displayedItems = useMemo(() => {
    const itemsModify = items.filter(I => !value.includes(I.value || I));

    if (searchValue.trim()) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      return itemsModify.filter(I => (I.label?.toLowerCase() || I?.toLowerCase())
        .includes(searchValue.trim()?.toLowerCase()));
    }

    return itemsModify;
  }, [searchValue, value]);

  const onElementClick = (e, element) => {
    e.stopPropagation();

    onChange(value.filter(V => V !== element));
  };

  return (
    <div
      style={{ ...style, ...styles.container }}
      className={dropdownClass}
    >
      {
        label && (
          <label
            className="multi-select-field__label"
            htmlFor={name}
            style={labelStyle}
          >
            {label}
          </label>
        )
      }
      <div
        role="presentation"
        onClick={onButtonClick}
        ref={buttonRef}
        style={{ ...styleButton, ...styles.button }}
        className="multi-select-field__button"
        data-testid={`${window.location.pathname}/multi-select-field/${label?.toLowerCase().replace(/\s+/g, '') || fieldId}`}
      >
        {
          value.length
            ? value.map(item => (
              <button
                type="button"
                onClick={e => onElementClick(e, item.value || item)}
                className="multi-select-field__button__item"
                key={item.value || item}
              >
                {items.find(I => I.value === item)?.label || item}
              </button>
            ))
            : <span>{placeholder}</span>
        }
      </div>
      <div
        ref={dropdownRef}
        className="multi-select-field__overlay"
        style={{ ...overlayStyle, ...styles.overlay }}
      >
        {
          withSearch && (
            <SearchField
              input={{
                onChange: setSearchValue,
                name: 'search',
              }}
              styleWrapper={{ padding: '0 10px 10px' }}
              placeholder={t('PLACEHOLDERS.SEARCH_SOMETHING')}
            />
          )
        }
        {
          displayedItems.map(item => (
            <button
              onClick={() => handleChange(item.value || item)}
              key={item.value || item}
              type="button"
              className="multi-select-field__overlay__item"
            >
              <span className="multi-select-field__overlay__item__text">
                {item.label || item}
              </span>
            </button>
          ))
        }
      </div>
    </div>
  );
};

export default MultiSelectField;
