/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import classNames from 'classnames';

import Tooltip from 'components/Tooltip';

import './style.scss';

const IconButton = ({
  onClick,
  icon,
  filterType = '',
  type = '',
  style = null,
  isDisabled = false,
  iconStyle = null,
  styles = {},
  dataTip,
  dataFor,
  title,
}) => {
  const buttonClass = classNames({
    'mkit-icon-button': true,
    [`mkit-icon-button_${type}`]: type,
  });
  const buttonIconClass = classNames('mkit-icon-button__image', {
    [`mkit-icon-button__image_filter-${filterType}`]: filterType,
  });

  return (
    <>
      <button
        className={buttonClass}
        onClick={onClick}
        disabled={isDisabled}
        style={{ ...style, ...styles.container }}
        type="button"
        data-tip={dataTip}
        data-for={dataFor}
        title={title || ''}
      >
        <img src={icon} alt="" className={buttonIconClass} style={{ ...iconStyle, ...styles.icon }} />
      </button>
      {
        dataFor && (
          <Tooltip id={dataFor} place="bottom" />
        )
      }
    </>
  );
};

export default IconButton;
