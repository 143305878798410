import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { Col, Row } from 'antd';
import { Form, FormSpy } from 'react-final-form';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import {
  useAccountApi, useCampaignApi, useCompaniesApi, useUsersApi,
} from 'hooks/api';
import { useHint } from 'hooks';
import { buttons, campaignEdit, Hint } from 'components';
import LoadingSpinner from 'components/LoadingSpinner';
import {
  catchApiError,
  convertApiDataToData,
  convertFormDataToApiData,
  toast,
  validateCampaignEdit,
} from 'helpers';
import { CAMPAIGN_HINTS, CAMPAIGN_KEY } from 'const';
import playIcon from 'assets/icons/play_white.svg';
import sendToModeration from 'assets/icons/send-to-moderation.svg';
import crossIcon from 'assets/icons/cross-grey.svg';

import './CampaignEdit.scss';

const {
  CampaignEditGeneral,
  CampaignEditFallback,
  CampaignEditTargets,
  CampaignEditLaunchTime,
} = campaignEdit;

const { BasicButton } = buttons;

const relativeHints = ['campaignFilter', 'campaignLaunch', 'addFallback'];

const CampaignEdit = () => {
  const {
    activeHint,
    setNextHint,
    skipAll,
  } = useHint({ hints: CAMPAIGN_HINTS, hintsKey: CAMPAIGN_KEY });

  const history = useHistory();

  const { t } = useTranslation();

  const [shortLinks, setShortLinks] = useState([]);

  const {
    ownCompany,
    getOwnCompany,
  } = useCompaniesApi();
  const { currentUser } = useUsersApi();

  const {
    id, runId, rerun,
  } = useParams();
  const filterId = (new URLSearchParams(window.location.search)).get('filterId');

  const {
    createCampaign,
    updateCampaign,
    getCampaign,
    getRunInfo,
    getRerunInfo,
    run,
    postRun,
    postRerun,
    clearCampaign,
    clearRun,
    campaign,
    isPendingGetRun,
    isPendingPostRun,
    isPendingGetCampaign,
    isPendingCreateCampaign,
    isPendingUpdateCampaign,
    isPendingGetRerun,
    isPendingPostRerun,
  } = useCampaignApi();
  const {
    getTimezones,
  } = useAccountApi();

  const launchPermission = useMemo(() =>
    currentUser?.roles?.includes?.('broadcasts-launch'), []);

  useEffect(() => {
    if (runId) {
      if (rerun) {
        getRerunInfo({ campaignId: id, runId });
      } else {
        getRunInfo({ campaignId: id, runId });
      }
    } else if (id) {
      getCampaign({ id });
    }
  }, [id, runId]);
  useEffect(() => {
    getTimezones();
    getOwnCompany();

    return () => {
      if (runId) {
        clearRun();
      } else {
        clearCampaign();
      }
    };
  }, []);
  const saveDisabled = isPendingCreateCampaign || isPendingUpdateCampaign
    || isPendingGetCampaign || isPendingPostRun || isPendingPostRerun;

  const initialValue = useMemo(() => {
    if (!!id && !isEmpty(campaign)) {
      return convertApiDataToData(campaign);
    }
    if (!!runId && !isEmpty(run)) {
      return convertApiDataToData(run);
    }

    return ({
      externalTrigger: false,
      recurrentCompany: false,
      name: '',
      description: '',
      time: {
        hours: `${moment().hours() < 10 ? '0' : ''}${moment().hours()}`,
        minutes: `${moment().minutes() < 10 ? '0' : ''}${moment().minutes()}`,
        seconds: `${moment().seconds() < 10 ? '0' : ''}${moment().seconds()}`,
        gmt: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      date: moment(),
      filterIds: filterId ? [+filterId] : [],
      flowSteps: [],
    });
  }, [id, campaign, runId, run]);

  if (isPendingGetCampaign || isPendingGetRun || isPendingGetRerun) {
    return (
      <LoadingSpinner fullSize />
    );
  }

  return (
    <>
      <h1 className="campaign-create__title">
        {campaign?.name || run?.name || t('INSTANCES.CAMPAIGNS.CREATE_NEW_CAMPAIGN')}
      </h1>
      <div>
        <Form
          initialValues={initialValue}
          onSubmit={(values) => {
            if (values.isScript ? !values?.flowId || !values?.senderId : !values?.flowSteps?.length) {
              toast.error(t('NEW_TRANSLATES.TOAST_ERRORS.SELECT_MESSAGE_TYPE_FILL_MESSAGE'));
            } else {
              const data = convertFormDataToApiData(values, !!runId, rerun);
              if (shortLinks.length) {
                const newShortCodes = shortLinks.map(item => item.shortCode);
                data.shortLinkCodes = [...(data?.shortLinkCodes ? data.shortLinkCodes : []), ...newShortCodes];
              }
              if (runId) {
                (rerun ? postRerun : postRun)({
                  campaignId: id,
                  runId,
                  body: data,
                  successCallback: () => {
                    toast.success(rerun ? 'Rerun started!' : 'Run created!');
                    history.push('/campaigns');
                  },
                  errorCallback: res => toast.error(t(res?.key) || res?.message || t('TOAST.UNKNOWN_ERROR')),
                });
              } else if (id) {
                updateCampaign(id, data).then(() => {
                  toast.success(t('TOAST.CAMPAIGN_CREATED'));
                  history.push('/campaigns');
                }).catch(catchApiError);
              } else {
                createCampaign(data).then((response) => {
                  if (response && response.body && response.body.id) {
                    toast.success(t('TOAST.CAMPAIGN_CREATED'));
                    history.push('/campaigns');
                  }
                }).catch(catchApiError);
              }
            }
          }}
          validate={values => validateCampaignEdit(values, {}, t)}
          render={({
            handleSubmit,
            form: {
              change,
            },
            values: formValues,
          }) => (
            <Row gutter={[24, 24]} className="campaign-create__row" type="flex">
              <FormSpy
                onChange={(props) => {
                  if (props.values.campId !== formValues.campId) {
                    change('topicId', undefined);
                  }
                  if (props.values.flowId !== formValues.flowId) {
                    change('senderId', undefined);
                  }
                }}
              />
              <Col span={8} className="campaign-create__col">
                <CampaignEditGeneral initialValues={initialValue} disabled={!!runId || !launchPermission} />
              </Col>
              <FormSpy subscription={{ values: true }}>
                {({ values }) => (
                  <>
                    <Col span={8} className="campaign-create__col">
                      <CampaignEditTargets
                        externalTrigger={values.externalTrigger}
                        filterIds={initialValue.filterIds}
                        broadcastName={run?.name}
                        runDate={run?.createdAt}
                        readOnly={!launchPermission}
                        {...run?.rerunCount}
                      />
                      {
                        activeHint === 'campaignFilter' && !id && (
                          <Hint
                            setNextHint={setNextHint}
                            {...CAMPAIGN_HINTS[activeHint]}
                            onSkip={skipAll}
                          />
                        )
                      }
                    </Col>
                    <Col span={8} className="campaign-create__col">
                      <CampaignEditLaunchTime
                        externalTrigger={values.externalTrigger}
                        disabled={!!runId || !launchPermission}
                      />
                      {
                        activeHint === 'campaignLaunch' && !id && (
                          <Hint
                            setNextHint={setNextHint}
                            {...CAMPAIGN_HINTS[activeHint]}
                            onSkip={skipAll}
                          />
                        )
                      }
                    </Col>
                    <Col span={24}>
                      <CampaignEditFallback
                        activeHint={activeHint}
                        setNextHint={setNextHint}
                        skipAll={skipAll}
                        disabled={!launchPermission || (!!runId && run?.status === 'COMPLETE')}
                        setShortLinks={setShortLinks}
                      />
                    </Col>
                  </>
                )}
              </FormSpy>
              <div className="campaign-create__actions">
                <BasicButton
                  icon={crossIcon}
                  onClick={() => {
                    history.replace('/campaigns');
                  }}
                  text={t('CONTROLS.CANCEL')}
                  type={BasicButton.types.SECONDARY_CANCEL}
                  isNewStyle
                />
                <BasicButton
                  loading={saveDisabled}
                  disabled={!launchPermission || (!!runId && run?.status === 'COMPLETE')}
                  icon={(ownCompany?.isModerated && !formValues?.isScript) ? sendToModeration : playIcon}
                  onClick={handleSubmit}
                  text={(ownCompany?.isModerated && !formValues?.isScript) ? t('CONTROLS.BROADCASTS.SEND_TO_MODERATION') : t('CONTROLS.SEND')}
                  type={BasicButton.types.ACCENT}
                  title={launchPermission ? undefined : 'Not enough permissions'}
                />
              </div>
            </Row>
          )}
        />
      </div>
      {
        activeHint !== 'complete' && !relativeHints.includes(activeHint) && !id && (
          <Hint
            setNextHint={setNextHint}
            onSkip={skipAll}
            {...CAMPAIGN_HINTS[activeHint]}
          />
        )
      }
    </>
  );
};

export default CampaignEdit;
