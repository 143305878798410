import transformMessage from './transformMessage';

export default ({ contentPattern }) => {
  if (contentPattern) {
    return ({
      ...contentPattern,
      message: transformMessage(contentPattern),
    });
  }

  return null;
};
