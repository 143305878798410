import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import InputMask from 'react-input-mask';
import { useTranslation } from 'react-i18next';

import { useDropdownLogic } from 'hooks';
import { useAccountApi } from 'hooks/api';
import clockSmallBlueIcon from 'assets/icons/clock-small_blue.svg';

import TimeDropdown from './TimeDropdown';
import './style.scss';

const fillTime = (item, index) => {
  const number = `${index < 10 ? '0' : ''}${index}`;
  return {
    value: number,
    label: number,
  };
};
const hoursOptions = new Array(24).fill().map(fillTime);
const minutesOptions = new Array(60).fill().map(fillTime);

const TimePickerField = ({
  input: {
    value,
    onChange,
  },
  label,
  typeOverlay,
  styles = {},
  withTimezone = true,
  disabled,
}) => {
  const {
    buttonRef,
    dropdownRef,
    isDropdownOpen,
    openDropdown,
  } = useDropdownLogic();
  const {
    timezones,
    getTimezones,
  } = useAccountApi();
  const { t } = useTranslation();
  const containerClassName = classNames('time-picker-field', {
    'time-picker-field_open': isDropdownOpen,
    'time-picker-field_disabled': disabled,
  });
  const overlayClassName = classNames('time-picker-field__input__overlay', {
    [`time-picker-field__input__overlay_${typeOverlay}`]: typeOverlay,
    'time-picker-field__input__overlay_without-timezone': !withTimezone,
  });

  const timezonesOptions = useMemo(() => timezones.map(({ areaOffset, areaName }) => ({
    value: areaName,
    label: `${areaOffset} (${areaName})`,
  })), [timezones]);
  const formatChars = useMemo(() => ({
    2: '[0-2]',
    5: '[0-5]',
    9: '[0-9]',
  }), []);
  const mask = useMemo(() => {
    if (withTimezone) {
      const foundTimezone = timezones.find(({ areaName }) => areaName === value.gtm);
      return `29:59:59 (GTM ${foundTimezone ? `${foundTimezone.areaOffset.replace(/2|5|9/gi, matched => `\\${matched}`)}` : '0:00'})`;
    }

    return '29:59:59';
  }, [timezones, value.gtm]);

  const onChangeHours = (hours) => {
    onChange({
      ...value,
      hours,
    });
  };
  const onChangeMinutes = (minutes) => {
    onChange({
      ...value,
      minutes,
    });
  };
  const onChangeSeconds = (seconds) => {
    onChange({
      ...value,
      seconds,
    });
  };
  const onChangeGmt = (gtm) => {
    onChange({
      ...value,
      gtm,
    });
  };
  const onChangeInputMask = (event) => {
    if (event.target.value) {
      const [hours, minutes, seconds] = event.target.value.split(':');

      if (hours > 23) {
        return;
      }

      onChange({
        ...value,
        minutes,
        hours,
        seconds: seconds.slice(0, 2),
      });
    }
  };

  useEffect(() => {
    if (!timezones.length && withTimezone) {
      getTimezones();
    }
  }, []);

  return (
    <div className={containerClassName} style={styles.container}>
      {
        label && (
          <span className="time-picker-field__label" style={styles.label}>
            {label}
          </span>
        )
      }
      <div
        className="time-picker-field__input"
        style={styles.input}
        ref={buttonRef}
      >
        <InputMask
          mask={mask}
          maskChar="0"
          value={`${value.hours}:${value.minutes}:${value.seconds}`}
          onChange={onChangeInputMask}
          alwaysShowMask
          onFocus={openDropdown}
          formatChars={formatChars}
          disabled={disabled}
        />
        <img
          className="time-picker-field__input__icon"
          alt="clock"
          src={clockSmallBlueIcon}
        />
        <div
          role="presentation"
          className={overlayClassName}
          style={styles.overlay}
          ref={dropdownRef}
          onClick={e => e.stopPropagation()}
        >
          <div className="time-picker-field__input__overlay__time">
            <TimeDropdown
              label={t('NEW_TRANSLATES.CONTROLS.TIME.HRS')}
              value={value.hours}
              options={hoursOptions}
              onChange={onChangeHours}
            />
            <div className="time-picker-field__input__overlay__time-border" />
            <TimeDropdown
              label={t('NEW_TRANSLATES.CONTROLS.TIME.MIN')}
              options={minutesOptions}
              value={value.minutes}
              onChange={onChangeMinutes}
            />
            <div className="time-picker-field__input__overlay__time-border" />
            <TimeDropdown
              label={t('NEW_TRANSLATES.CONTROLS.TIME.SEC')}
              value={value.seconds}
              options={minutesOptions}
              onChange={onChangeSeconds}
            />
          </div>
          {
            withTimezone && (
              <TimeDropdown
                label="GMT"
                value={value.gtm}
                options={timezonesOptions}
                onChange={onChangeGmt}
                withSearch
              />
            )
          }
        </div>
      </div>
    </div>
  );
};

export default TimePickerField;
