import { getParams } from 'helpers';

const rootHost = process.env.NODE_ENV === 'production' ? window.location.origin : 'https://mkitdev2.alarislabs.com';
const recipientsHost = `${rootHost}/recipient-service`;
const assetsHost = `${rootHost}/content-service`;
const campaignsHost = `${rootHost}/campaign-service`;
const referenceHost = `${rootHost}/reference-service`;
const authHost = `${rootHost}/auth-service`;
const edrHost = `${rootHost}/edrs-restapi-service`;
const importRecipientsHost = `${rootHost}/import-service`;
const reportsHost = `${rootHost}/report-service`;
const transitHost = 'https://web.mkit.alarislabs.com/rest';
const sendersHost = `${rootHost}/sender-id-service`;
const conversionHost = `${rootHost}/conversation-service/api/v1`;
const conversionWsHost = `${rootHost.replace('https', 'wss')}/conversation-service/ws/v1`;
const templatesHost = `${rootHost}/content-template-service`;
const accountHost = `${rootHost}/account-service`;
const balancesHost = `${rootHost}/balance-updater-service`;
const flowHost = `${rootHost}/flow-service`;
const responderHost = `${rootHost}/responder-service/api/public`;
const backOfficeHost = `${rootHost}/backoffice-service`;
const linkHost = `${rootHost}/link-service`;

export default {
  rootHost,
  getAuthUrl: () => `${authHost}/auth`,
  getCaptchaUrl: () => `${transitHost}/captcha`,
  getCountriesUrl: () => `${transitHost}/country`,
  getCurrenciesUrl: () => `${transitHost}/currency`,
  getRegisterUrl: () => `${transitHost}/register_user`,
  getActivateUserUrl: () => `${transitHost}/activate_user`,
  getResetPasswordUrl: () => `${transitHost}/reset_user_password`,
  getUpdatePasswordUrl: () => `${transitHost}/update_user_password`,

  getRecipientsUrl: (params = {}) => `${recipientsHost}/recipients${getParams(params)}`,
  postRecipientUrl: () => `${recipientsHost}/recipients`,
  getTagsUrl: () => `${recipientsHost}/recipients/tags`,
  getRecipientsByIdUrl: id => `${recipientsHost}/recipients/${id}`,
  getAttributesUrl: () => `${recipientsHost}/attributes`,
  getStatsByFiltersUrl: (params = {}) => `${recipientsHost}/recipients/stat-by-filters${getParams(params)}`,
  getRecipientsAttributesUrl: () => `${recipientsHost}/attributes`,
  getRecipientsAttributeUrl: name => `${recipientsHost}/attributes/${name}`,
  getRecipientsAttributesTypesUrl: () => `${recipientsHost}/attributes/types`,
  getFiltersUrl: () => `${recipientsHost}/filters`,
  getFiltersByIdUrl: id => `${recipientsHost}/filters/${id}`,
  getRecipientsInfoUrl: () => `${recipientsHost}/recipients/info`,
  getDeleteAllRecipientsUrl: () => `${recipientsHost}/recipients/delete-all`,

  getWordsUrl: () => `${backOfficeHost}/opt-out/words`,
  getWordsByIdUrl: id => `${backOfficeHost}/opt-out/words/${id}`,

  getDraftsUrl: () => `${importRecipientsHost}/drafts`,
  getDraftByIdUrl: id => `${importRecipientsHost}/drafts/${id}`,
  getDraftByIdSelectDataUrl: id => `${importRecipientsHost}/drafts/${id}/select-data`,
  getDraftByIdSelectAttributesUrl: id => `${importRecipientsHost}/drafts/${id}/select-attributes`,
  getDictionariesDbActionsUrl: () => `${importRecipientsHost}/dictionaries/db-actions`,
  getDictionariesFileActionsUrl: () => `${importRecipientsHost}/dictionaries/file-actions`,
  getExportErrorsDraftUrl: id => `${importRecipientsHost}/drafts/${id}/export-errors`,
  getDraftRunImportUrl: id => `${importRecipientsHost}/drafts/${id}/run-import`,
  getDraftErrorsListUrl: id => `${importRecipientsHost}/drafts/${id}/errors-list`,
  getDraftImportListUrl: id => `${importRecipientsHost}/drafts/${id}/import-list`,
  getPresetsUrl: () => `${importRecipientsHost}/presets`,
  getPresetByIdUrl: id => `${importRecipientsHost}/presets/${id}`,

  getCampaignByIdUrl: id => `${campaignsHost}/campaigns/${id}`,
  getCampaignWithRunsByIdUrl: id => `${campaignsHost}/campaigns/${id}/one-with-processes`,
  getCampaignProcessesUrl: id => `${campaignsHost}/campaigns/${id}/processes`,
  getCampaignsUrl: params => `${campaignsHost}/campaigns${getParams(params)}`,
  createCampaignsUrl: () => `${campaignsHost}/campaigns`,
  updateCampaignUrl: id => `${campaignsHost}/campaigns/${id}`,
  getAccountStatisticsUrl: params => `${campaignsHost}/campaigns/dashboard${getParams(params)}`,
  getAccountsIdUrl: () => `${campaignsHost}/campaigns/dashboard/accounts`,
  getThreadStatisticsUrl: () => `${campaignsHost}/campaigns/dashboard/threads`,
  getSubscribersStatisticsUrl: () => `${campaignsHost}/campaigns/dashboard/subscribers`,
  getRunInfoUrl: (campaignId, runId) => `${campaignsHost}/campaigns/${campaignId}/processes/${runId}`,
  getRerunInfoUrl: (campaignId, runId) => `${campaignsHost}/campaigns/${campaignId}/processes/${runId}/rerun`,

  getAddAssetUrl: () => `${assetsHost}/files/upload/drop`,
  getAssetsUrl: () => `${assetsHost}/files/metainfo`,
  getDeleteAssetUrl: (params = {}) => `${assetsHost}/files/delete${getParams(params)}`,
  getEdrUrl: () => `${edrHost}/edrs`,
  getEdrDownloadUrl: (params = {}) => `${edrHost}/edrs/download${getParams(params)}`,

  getRatePlansUrl: () => `${backOfficeHost}/rate-plans`,
  getSmsRateUrl: () => `${backOfficeHost}/rates`,
  getRcsRateUrl: () => `${backOfficeHost}/rates`,
  getAccountsUrl: () => `${referenceHost}/accounts`,
  getBoAccountsUrl: () => `${backOfficeHost}/accounts`,
  getBalanceUrl: () => `${balancesHost}/balance`,
  getInvoicesUrl: () => `${referenceHost}/invoices/filter`,
  getPaymentsUrl: () => `${backOfficeHost}/payments-mkit`,
  getExportPaymentsUrl: (params = {}) => `${referenceHost}/payments/export${getParams(params)}`,
  getExportInvoicesUrl: (params = {}) => `${referenceHost}/invoices/export${getParams(params)}`,
  getDeliveryConditions: () => `${campaignsHost}/flow/delivery-conditions`,

  getGroupsUrl: () => `${reportsHost}/groups`,
  getReportParametersUrl: () => `${reportsHost}/parameters`,
  getCreateReportUrl: params => `${reportsHost}/report${getParams(params)}`,
  getReportFileUrl: () => `${reportsHost}/report/file`,

  getSenderGroupsUrl: () => `${sendersHost}/groups`,
  getCreateGroupUrl: () => `${sendersHost}/group`,
  getUpdateGroupUrl: id => `${sendersHost}/group/update/${id}`,
  getDeleteGroupUrl: id => `${sendersHost}/group/delete/${id}`,
  getUpdateApiKeyUrl: id => `${sendersHost}/group/update/${id}/apikey`,

  getStatsUrl: (params = {}) => `${conversionHost}/pivot-table${getParams(params)}`,
  getConversationsListUrl: (params = {}) => `${conversionHost}/conversations/list${getParams(params)}`,
  getConversationUrl: (params = {}) => `${conversionHost}/conversations${getParams(params)}`,
  getCategoriesUrl: (params = {}) => `${conversionHost}/meta-info${getParams(params)}`,
  getSendMessageUrl: () => `${conversionHost}/messages`,
  getSocketsUrl: (params = {}) => `${conversionWsHost}/messages/subscribe${getParams(params)}`,

  getTemplatesUrl: () => `${templatesHost}/templates`,
  getUpdateTemplateUrl: id => `${templatesHost}/templates/${id}/update`,
  getDeleteTemplateUrl: id => `${templatesHost}/templates/${id}/delete`,

  getTimezones: () => `${accountHost}/timezones`,
  getUserInfo: () => `${accountHost}/user/info`,
  getUserInfoNew: (id = '') => `${accountHost}/users/${id}`,
  getApiCallsUrl: () => `${accountHost}/audit/api-calls`,
  getOperationsUrl: () => `${accountHost}/audit/operations`,
  getCurrentUserUrl: () => `${accountHost}/users/me`,

  getValidatorsUrl: () => `${flowHost}/validators`,
  getFlowsNewAllUrl: () => `${flowHost}/flows-new/all`,
  getFlowsNewUrl: () => `${flowHost}/flows-new`,
  getFlowsNewByIdUrl: id => `${flowHost}/flows-new/${id}`,
  getFlowsModerateUrl: id => `${flowHost}/flows-new/toggle-moderate/${id}`,
  getFlowNodesUrl: () => `${flowHost}/flow-nodes`,
  getFlowNodesByIdUrl: id => `${flowHost}/flow-nodes/${id}`,
  getCloneNodeUrl: id => `${flowHost}/flow-nodes/${id}/clone`,
  getFlowLinksUrl: () => `${flowHost}/flow-links`,
  getFlowLinksByIdUrl: id => `${flowHost}/flow-links/${id}`,
  getFlowsResourceUrl: id => `${flowHost}/flows/resource/${id}`,
  getCloneFlowUrl: id => `${flowHost}/flows-new/${id}/clone`,
  getExportFlowUrl: id => `${flowHost}/flows-new/export-flow/${id}`,
  getImportFlowUrl: () => `${flowHost}/flows-new/import-flow`,

  getFinishThreadUrl: id => `${responderHost}/stop-thread/${id}`,
  getChangeThreadUrl: () => `${responderHost}/change-flow-step`,
  getCreateThreadUrl: () => `${responderHost}/create-thread`,

  getUsersUrl: (id = '') => `${backOfficeHost}/users/${id}`,
  getAuthoritiesUrl: (id = '') => `${backOfficeHost}/users/${id}/authorities`,
  getRolesUrl: (id = '') => `${backOfficeHost}/roles/${id}`,
  getPermissionsUrl: (id = '') => `${backOfficeHost}/permissions/${id}`,

  getApprovedTemplatesUrl: () => `${backOfficeHost}/whatsapp-dialog360-templates`,
  getWhatsAppCategoriesUrl: () => `${backOfficeHost}/whatsapp-templates/categories`,
  getWhatsAppLanguagesUrl: () => `${backOfficeHost}/whatsapp-templates/languages`,
  getWhatsAppUrl: () => `${backOfficeHost}/whatsapp-templates`,
  getWhatsAppDeleteUrl: id => `${backOfficeHost}/whatsapp-templates/${id}`,

  getAccountsByClientUrl: () => `${backOfficeHost}/accounts/by-client`,

  getPaymentProvidersUrl: () => `${backOfficeHost}/payments/providers`,
  getCreateOrderUrl: () => `${backOfficeHost}/payments/external`,

  getSendersUrl: () => `${backOfficeHost}/senders-mkit`,

  getAvailableChannelsUrl: () => `${backOfficeHost}/get-available-channels`,

  getBuildInfoUrl: () => `${rootHost}/version.json`,

  getOwnCompanyUrl: () => `${backOfficeHost}/companies/own-company`,

  getSignInUrl: () => `${accountHost}/auth/mkit/sign-in${process.env.NODE_ENV === 'production' ? '' : '-lh'}`,
  getRefreshTokenUrl: () => `${accountHost}/auth/mkit/refresh-token${process.env.NODE_ENV === 'production' ? '' : '-lh'}`,
  getLogoutUrl: () => `${accountHost}/auth/mkit/logout${process.env.NODE_ENV === 'production' ? '' : '-lh'}`,

  getPortalAssetsUrl: filename => `${rootHost}/assets/${filename}`,

  getShortLinkUrl: (params = {}) => `${linkHost}${getParams(params)}`,
};
