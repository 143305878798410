import React from 'react';

/*eslint-disable */
import { CAMPAIGN_CHANNEL_TYPE } from 'const';
import {
  getTransformedRcs,
  getTransformedViber,
  getTransformedSms,
  getTransformedWhatsApp,
  convertSecondsToObjectTime,
} from 'helpers';
import { RcsBlock, SmsBlock, WhatsAppBlock, EmailBlock } from 'components/blocks';
import Drawer from '../UIKit/Drawer/Drawer';
// import { ChannelRcsEditor } from '../ChannelEditor';
// import ChannelSmsEditor from '../ChannelEditor/ChannelSmsEditor';

function channelEditorFabric(type, props) {
  switch (type) {
    case CAMPAIGN_CHANNEL_TYPE.rcs:
      return (
        <RcsBlock
          {...props}
          channel={getTransformedRcs(props.channel)}
          senderId={props.channel?.senderId}
          deliveryCondition={props.channel?.deliveryCondition}
          timeout={convertSecondsToObjectTime(props.channel?.timeout)}
          withShortLink
          isNewDesign
        />
      );
    case CAMPAIGN_CHANNEL_TYPE.sms:
      return (
        <SmsBlock
          {...props}
          channel={getTransformedSms(props.channel)}
          senderId={props.channel?.senderId}
          deliveryCondition={props.channel?.deliveryCondition}
          timeout={convertSecondsToObjectTime(props.channel?.timeout)}
          withShortLink
          isNewDesign
        />
      );
    case CAMPAIGN_CHANNEL_TYPE.viber:
      return (
        <RcsBlock
          {...props}
          channel={getTransformedViber(props.channel)}
          senderId={props.channel?.senderId}
          deliveryCondition={props.channel?.deliveryCondition}
          timeout={convertSecondsToObjectTime(props.channel?.timeout)}
          isViber
          withShortLink
          isNewDesign
        />
      );
    case CAMPAIGN_CHANNEL_TYPE.whatsapp:
      return (
        <WhatsAppBlock
          {...props}
          channel={getTransformedWhatsApp(props.channel)}
          senderId={props.channel?.senderId}
          deliveryCondition={props.channel?.deliveryCondition}
          timeout={convertSecondsToObjectTime(props.channel?.timeout)}
          constructorType='template'
          withShortLink
          isNewDesign
        />
      );
    case CAMPAIGN_CHANNEL_TYPE.email:
      return (
        <EmailBlock
          {...props}
          senderId={props.channel?.senderId}
          deliveryCondition={props.channel?.deliveryCondition}
          timeout={convertSecondsToObjectTime(props.channel?.timeout)}
        />
      );
    default:
      return <div>This channel type not support yet</div>;
  }
}

const CampaignEditChannel = ({
  channel,
  onSave,
  onClose,
  index,
  templates,
  postCreateTemplate,
  deleteTemplate,
  patchUpdateTemplate,
  readOnly,
  setShortLinks,
}) =>  (
  <Drawer
    open
    visible
    width={533}
    closable={channel.channelType === 6}
    onClose={onClose}
    zIndex={10}
    destroyOnClose
  >
    {channelEditorFabric(channel.channelType, {
      onCancel: onClose,
      onChange: onSave,
      channel,
      index,
      templates,
      postCreateTemplate,
      deleteTemplate,
      patchUpdateTemplate,
      readOnly,
      setShortLinks,
    })}
  </Drawer>
);

export default CampaignEditChannel;
