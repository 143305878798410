import endpoints from 'const/endpoints';

export default body => ({
  url: endpoints.getUserInfo(),
  queryKey: `put${endpoints.getUserInfo()}`,
  body,
  options: {
    method: 'PATCH',
  },
  meta: {
    authToken: true,
  },
});
