import endpoints from 'const/endpoints';

export default ({
  body: {
    id,
    ...body
  },
  successCallback = () => {},
  errorCallback = () => {},
}) => ({
  body,
  url: endpoints.getAuthoritiesUrl(id),
  options: {
    method: 'PATCH',
  },
  meta: {
    authToken: true,
    successCallback,
    errorCallback,
  },
});
