import React from 'react';
import { MediaScreen as NewMediaScreen } from 'mkit-front-ds/dist/screens';

import { useUsersApi } from 'hooks/api';

import './style.scss';

const MediaScreen = () => {
  const { currentUser } = useUsersApi();

  return (
    <div className="media-screen-container">
      <NewMediaScreen userPermissions={currentUser?.roles || []} />
    </div>
  );
};

export default MediaScreen;
