import React from 'react';
import { Drawer as DrawerSource } from 'antd';

import { useTheme } from 'hooks';

import './Drawer.scss';

const Drawer = ({
  children,
  width,
  style,
  zIndex = 100,
  ...props
}) => {
  const { isDarkTheme } = useTheme();

  const maskStyle = {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  };

  // todo: fix with uikit.less
  const bodyStyle = {
    background: isDarkTheme ? '#2b313e' : '#fff',
    padding: '24px',
    ...style,
  };

  return (
    <DrawerSource
      maskStyle={maskStyle}
      bodyStyle={bodyStyle}
      width={width || '400px'}
      title=""
      zIndex={zIndex}
      maskClosable
      {...props}
    >
      {children}
    </DrawerSource>
  );
};

export default Drawer;
