import moment from 'moment';

import {
  VALIDATION_MESSAGE_REQUIRED,
  VALIDATION_MESSAGE_TAG_LIST_REQUIRED,
  VALIDATION_MESSAGE_DATE,
  VALIDATION_MESSAGE_ARRAY,
} from 'const';

export const required = (value, t) => (value ? undefined : t(VALIDATION_MESSAGE_REQUIRED));

export const requiredString = (value, t) =>
  (value && value.trim() ? undefined : t(VALIDATION_MESSAGE_REQUIRED));

export const tagListRequired = (value, t) => (Array.isArray(value) && value.length
  ? undefined
  : t(VALIDATION_MESSAGE_TAG_LIST_REQUIRED));

export const date = (value, t, format = undefined) =>
  (moment(value, format).isValid() ? undefined : t(VALIDATION_MESSAGE_DATE));

export const dateWithDefaultFormat = (value, t) =>
  (moment(value).isValid() ? undefined : t(VALIDATION_MESSAGE_DATE));

export const isArray = (value, t) =>
  (Array.isArray(value) ? undefined : t(VALIDATION_MESSAGE_ARRAY));
export const isArrayRequired = (value, t) =>
  (Array.isArray(value) && value.length ? undefined : t('VALIDATORS.FILTERS_CANNOT_BE_EMPTY'));

export const minLength = min => (value, t) => {
  if (!value) {
    return undefined;
  }
  return value.trim().length < min ? t('VALIDATORS.NOT_LESS_THAN', { what: min }) : undefined;
};

export const email = (value, t) => (
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? t('VALIDATORS.INVALID_EMAIL')
    : undefined
);

export const composeValidators = (validators, t) => value =>
  validators.reduce((error, validator) => error || validator(value, t), undefined);
