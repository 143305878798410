import endpoints from 'const/endpoints';

export default ({
  successCallback,
} = {}) => ({
  url: endpoints.getValidatorsUrl(),
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
    successCallback,
  },
  transform: response => ({ validators: response.validators }),
  update: {
    validators: (oldValue, newValue) => newValue,
  },
});
