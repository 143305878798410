import React from 'react';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { BasicField, DropdownFieldNew } from 'components/fields';
import { BasicButton, IconButton } from 'components/buttons';
import addIcon from 'assets/icons/add.svg';
import trashIcon from 'assets/icons/trash.svg';

import './style.scss';

const DropdownFieldArray = ({
  fields,
  styles = {},
  buttonText,
  dropdownPlaceholder,
  options,
  type,
  dropdownName = 'type',
  fieldName = 'value',
  initialValues = {},
  disabled,
  // todo времено пока не запилят options
  replaceDropdownOnField,
}) => {
  const { t } = useTranslation();
  const iconButtonStyle = { width: 40, height: 37 };
  const basicButtonStyle = { minWidth: 175, marginTop: 10, alignSelf: 'baseline' };

  const fieldClass = classNames('dropdown-field-array', {
    [`dropdown-field-array_${type}`]: type,
  });

  const onAddField = () => {
    fields.push(initialValues);
  };

  return (
    <div className={fieldClass} style={styles.wrapper}>
      {
        fields.map((name, index) => (
          <div className="dropdown-field-array__field" key={name}>
            {
              replaceDropdownOnField ? (
                <Field
                  type="with-border"
                  name={`${name}.${dropdownName}`}
                  component={BasicField}
                  placeholder={dropdownPlaceholder}
                  isDisabled={disabled}
                />
              ) : (
                <Field
                  type="with-border"
                  name={`${name}.${dropdownName}`}
                  component={DropdownFieldNew}
                  options={options}
                  placeholder={dropdownPlaceholder}
                  disabled={disabled}
                />
              )
            }
            {
              type === 'equivalent' && <span>as</span>
            }
            <div className="dropdown-field-array__field__right">
              <Field
                type="with-border"
                name={`${name}.${fieldName}`}
                component={BasicField}
                isDisabled={disabled}
              />
              <IconButton
                filterType="white"
                icon={trashIcon}
                onClick={() => fields.remove(index)}
                style={iconButtonStyle}
                isDisabled={disabled}
                title={t('NEW_TRANSLATES.TOOLTIPS.DELETE')}
              />
            </div>
          </div>
        ))
      }
      <BasicButton
        text={buttonText}
        type={BasicButton.types.PRIMARY}
        icon={addIcon}
        filterType="white"
        style={basicButtonStyle}
        onClick={onAddField}
        disabled={disabled}
      />
    </div>
  );
};

export default DropdownFieldArray;
