import actions from 'const/actions';

const toggleMenuExpandAction = state => ({
  type: actions.TOGGLE_MENU_EXPAND,
  state,
});

export default {
  toggleMenuExpandAction,
};
