import React, { useEffect } from 'react';
import Switch from 'react-switch';
import classNames from 'classnames';

import { useTheme } from 'hooks';

import './style.scss';

const SwitchField = ({
  name,
  label,
  onChange: externalOnChange,
  value: externalValue,
  input: {
    value = externalValue,
    onChange = externalOnChange,
  } = {},
  styles = {},
  labelStyle,
  switchProps,
  wrapperStyle,
  isDisabled,
  mirrorLabel = false,
}) => {
  const { isDarkTheme } = useTheme();

  const switchClass = classNames({
    'mkit-switch-field': true,
    'mkit-switch-field_off': !value,
  });

  useEffect(() => () => onChange(undefined), []);

  return (
    <div className={switchClass} style={{ ...wrapperStyle, ...styles.wrapper }}>
      {!!label && !mirrorLabel && (
        <label
          htmlFor={name}
          className="mkit-switch-field__label"
          style={{ ...labelStyle, ...styles.label }}
        >
          {label}
        </label>
      )}
      <Switch
        id={name}
        checked={value}
        onChange={onChange}
        width={44}
        height={22}
        handleDiameter={16}
        offHandleColor="#aaadb2"
        offColor={isDarkTheme ? '#2b313e' : '#fff'}
        onColor={isDarkTheme ? '#0C91C0' : '#4D8AA0'}
        checkedIcon={false}
        uncheckedIcon={false}
        activeBoxShadow=""
        disabled={isDisabled}
        {...switchProps}
      />
      {
        mirrorLabel && (
          <label
            htmlFor={name}
            className="mkit-switch-field__label"
            style={{ ...labelStyle, ...styles.label }}
          >
            {label}
          </label>
        )
      }
    </div>
  );
};

export default SwitchField;
