import endpoints from '../../const/endpoints';

export default ({ errorCallback, templateId } = {}) => ({
  url: endpoints.getReportParametersUrl(),
  queryKey: endpoints.getReportParametersUrl(),
  body: {
    templateId,
  },
  meta: {
    errorCallback,
    authToken: true,
  },
  options: {
    method: 'GET',
  },
  transform: response => ({ reportParameters: response }),
  update: {
    reportParameters: (oldValue, newValue) => newValue,
  },
});
