import React from 'react';
import SignUpScreenSource from 'mkit-front-ds/dist/screens/SignUpScreen';

import { useToken, useTheme } from 'hooks';

const SignUpScreen = () => (
  <SignUpScreenSource useTokenHook={useToken} useThemeHook={useTheme} />
);

export default SignUpScreen;
