import React from 'react';
import classNames from 'classnames';
import { Select as SelectSource } from 'antd';
import './Select.scss';

const Select = ({
  className, dropdownClassName, children, ...props
}) =>
  (
    <SelectSource
      className={classNames(className, 'select')}
      dropdownClassName={classNames(dropdownClassName, 'select-dropdown')}
      {...props}
    >
      {children}
    </SelectSource>
  );

export default Select;
