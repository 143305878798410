import React from 'react';
import classNames from 'classnames';

import LoadingSpinner from 'components/LoadingSpinner';

import './style.scss';

const BasicButton = ({
  icon,
  text,
  disabled = false,
  onClick,
  className,
  style,
  type,
  iconStyle,
  styles = {},
  isNewStyle,
  filterType,
  loading,
  title,
  dataTestId,
}) => {
  const buttonClass = classNames(className, {
    'basic-button': true,
    [`basic-button_${type}`]: type,
    'basic-button_new-style': isNewStyle,
  });
  const buttonIconClass = classNames('basic-button__img', {
    [`basic-button__img_filter-${filterType}`]: filterType,
  });

  return (
    <button
      type="button"
      className={buttonClass}
      onClick={onClick}
      style={{ ...style, ...styles.container }}
      disabled={disabled || loading}
      title={title}
      data-testid={dataTestId}
    >
      <div className="basic-button__content">
        {
          (icon && !loading) && (
            <img
              src={icon}
              alt=""
              className={buttonIconClass}
              style={{ ...iconStyle, ...styles.icon }}
            />
          )
        }
        {
          loading && (
            <LoadingSpinner small type="white" style={{ marginLeft: 0, marginRight: 12 }} />
          )
        }
        <div className="basic-button__text">
          {text}
        </div>
      </div>
    </button>
  );
};

BasicButton.types = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  WARN: 'warn',
  ACCENT: 'accent',
  CANCEL: 'cancel',
  SECONDARY_CANCEL: 'secondary-cancel',
};

export default BasicButton;
