import actions from '../const/actions';

const toggleCampaignExternalTriggerAction = state => ({
  type: actions.TOGGLE_CAMPAIGN_EXTERNAL_TRIGGER,
  state,
});

const addChannelToCampaignAction = (channelType, text) => ({
  type: actions.ADD_CHANNEL_TO_CAMPAIGN,
  channelType,
  text,
});

const stopCampaignChannelsAction = () => ({
  type: actions.STOP_CAMPAIGN_CHANNELS,
});

export default {
  toggleCampaignExternalTriggerAction,
  addChannelToCampaignAction,
  stopCampaignChannelsAction,
};
