import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { getParams } from 'helpers';

const DEFAULT_LIMIT = 100;
const DEFAULT_OFFSET = 1;

const getSearchParams = (search) => {
  const urlParams = new URLSearchParams(search);

  const params = {};
  urlParams.forEach((value, key) => {
    params[key] = value;
  });
  return params;
};

const getLimitAndOffsetParams = (search, initialLimit) => {
  const { limit, offset } = getSearchParams(search);

  return {
    limit: +limit || initialLimit,
    offset: +offset || DEFAULT_OFFSET,
  };
};

export default ({
  initialLimit = DEFAULT_LIMIT,
} = {}) => {
  const history = useHistory();

  const { limit, offset } = useMemo(
    () => getLimitAndOffsetParams(history.location.search, initialLimit),
    [history.location.search],
  );

  const replacePath = ({ newLimit, newOffset }) => {
    const params = {};

    const {
      limit: oldLimit,
      offset: oldOffset,
    } = getLimitAndOffsetParams(history.location.search, initialLimit);
    const nextLimit = newLimit || oldLimit;
    const nextOffset = newOffset || oldOffset;

    if (nextLimit !== initialLimit) {
      if (nextLimit) {
        params.limit = nextLimit;
      } else {
        params.limit = oldLimit;
      }
    }

    if (nextOffset !== 1) {
      if (nextOffset) {
        params.offset = nextOffset;
      } else {
        params.offset = oldOffset;
      }
    }

    // обновить роут только если параметры изменились
    if ((oldLimit !== nextLimit) || (oldOffset !== nextOffset)) {
      history.replace(`${history.location.pathname}${getParams(params)}`);
    }
  };
  const onChangeLimit = (newLimit) => {
    replacePath({ newLimit, newOffset: DEFAULT_OFFSET });
  };
  const onChangePage = (newOffset) => {
    replacePath({ newOffset });
  };
  const resetPagination = () => {
    replacePath({ newOffset: DEFAULT_OFFSET });
  };

  return {
    limit,
    offset,
    onChangeLimit,
    onChangePage,
    resetPagination,
  };
};
