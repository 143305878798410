import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { entitiesReducer, queriesReducer } from 'redux-query';

import layout from './layout';
import campaigns from './campaigns';
import selectMedia from './selectMedia';
import dashboard from './dashboard';
import changeState from './changeState';

export default combineReducers({
  form: formReducer,
  entities: entitiesReducer,
  queries: queriesReducer,
  states: changeState,
  layout,
  campaigns,
  selectMedia,
  dashboard,
});
