import React from 'react';
import './Card.scss';
import classNames from 'classnames';

const Card = ({ children, className }) =>
  (
    <div className={classNames('card', className)}>
      {children}
    </div>
  );

export default Card;
