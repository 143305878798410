import endpoints from 'const/endpoints';

export default ({
  id,
  body, successCallback = Function.prototype, errorCallback = Function.prototype,
}) => ({
  url: endpoints.getWhatsAppDeleteUrl(id),
  queryKey: `put${endpoints.getWhatsAppDeleteUrl(id)}`,
  body,
  options: {
    method: 'PUT',
  },
  meta: {
    authToken: true,
    successCallback,
    errorCallback,
  },
});
