import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  SuggestionItem,
  ImageElement,
  EditorTextarea,
} from 'components/constructor';

import './style.scss';

const ViberPictureCard = ({
  rcsParams: {
    message,
    buttonCaption,
    ...rcsParams
  },
  updateRcsParams,
  openUrlButtonConfig,
  openMediaSelect,
  openUploadMedia,
}) => {
  const { t } = useTranslation();

  const setMessage = (newMessage) => {
    updateRcsParams({
      message: newMessage,
    });
  };
  const setButtonCaption = (newCaption) => {
    updateRcsParams({
      buttonCaption: newCaption,
    });
  };

  return (
    <div className="mkit-viber-picture-card">
      <ImageElement
        rcsParams={rcsParams}
        updateRcsParams={updateRcsParams}
        openMediaSelect={openMediaSelect}
        openUploadMedia={openUploadMedia}
        type="square-border"
      />
      <div className="mkit-viber-picture-card__input-wrapper">
        <EditorTextarea
          value={message}
          onChange={setMessage}
          type="viber-text"
        />
        <SuggestionItem
          type="purple"
          text={buttonCaption || t('MESSAGE_CONSTRUCTOR.CONTROLS.BUTTON')}
          onChangeText={setButtonCaption}
          onEdit={openUrlButtonConfig}
        />
      </div>
    </div>
  );
};

export default ViberPictureCard;
