import React, { useMemo } from 'react';

const ReportCellRenderer = ({ colDef, valueFormatted, value }) => {
  const { alignPosition, textColor, valueFormat } = useMemo(() => {
    let position = 'left';
    let color = '';
    let cellValue = valueFormatted !== undefined && valueFormatted !== null ? valueFormatted : value;
    if (colDef?.columnParams?.length) {
      colDef.columnParams.forEach((param) => {
        if (param.startsWith('A')) {
          const positionFlag = param.split('')[1];
          switch (positionFlag) {
            case 'L':
              position = 'left';
              break;
            case 'C':
              position = 'center';
              break;
            case 'R':
              position = 'right';
              break;
            default:
              break;
          }
        } else if (param.startsWith('N')) {
          if (param.includes('R') && cellValue < 0) {
            color = 'red';
          }
          if (param.match(/\d/g)) {
            const roundCount = param.match(/\d/g);
            cellValue = cellValue.toFixed(roundCount);
          }
          if (param.includes('S')) {
            cellValue = parseFloat(cellValue);
            cellValue = cellValue.toLocaleString('us-US', { maximumFractionDigits: 10 });
          }
        }
      });
    }
    return {
      alignPosition: position,
      textColor: color,
      valueFormat: cellValue,
    };
  }, [value]);

  return (
    <div style={{
      display: 'flex',
      flex: 1,
      justifyContent: alignPosition,
      color: textColor,
    }}
    >
      {valueFormat}
    </div>
  );
};

export default ReportCellRenderer;
