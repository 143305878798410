import endpoints from 'const/endpoints';

export default ({ id, ...body }) => ({
  url: endpoints.getUpdateTemplateUrl(id),
  queryKey: endpoints.getUpdateTemplateUrl(),
  body,
  options: {
    method: 'PATCH',
  },
  meta: {
    authToken: true,
  },
});
