import React, { useMemo } from 'react';

import { useTheme } from 'hooks';
import Tooltip from 'components/Tooltip';
import getChannelIcon from 'helpers/getChannelIcon';

import './style.scss';

const OptOutCellRenderer = ({
  value,
  style,
}) => {
  const { isDarkTheme } = useTheme();

  const iconsArray = useMemo(() => value.map(I => ({
    icon: getChannelIcon(I.channelType, isDarkTheme, I.isForAllChannel),
    senders: I.isForAllChannel ? 'Whole channel' : I.senderNames.toString().replace(/,/g, '<br />'),
    sendersCount: I.senderNames.length,
  })), [isDarkTheme]);

  return (
    <div className="opt-outs-cell-renderer">
      {
        iconsArray.map(I => (
          <div
            className="opt-outs-cell-renderer__wrapper"
            key={I.icon}
            data-tip={I.senders}
            data-for="senders"
          >
            <img
              src={I.icon}
              alt=""
              style={style}
            />
            {
              !!I.sendersCount && (
                <div className="opt-outs-cell-renderer__wrapper__senders-count">
                  {I.sendersCount}
                </div>
              )
            }
            <Tooltip id="senders" place="bottom" multiline />
          </div>
        ))
      }
    </div>
  );
};

export default OptOutCellRenderer;
