import React from 'react';

import { DropdownFieldNew } from 'components/fields';

import './styles.scss';

const styles = {
  container: {
    width: 273,
    flex: 'unset',
  },
  control: {
    padding: 0,
  },
};
const CellRendererDropdownField = ({
  onChange,
  value,
  placeholder,
  data,
}) => (
  <DropdownFieldNew
    styles={styles}
    options={data.options}
    placeholder={placeholder}
    onChange={nextValue => onChange(nextValue, data)}
    value={value}
    type="without-border"
    withEmpty
    parent="eBodyViewport"
  />
);

export default CellRendererDropdownField;
