import endpoints from 'const/endpoints';

export default ({ successCallback, errorCallback }) => ({
  url: endpoints.getDeleteAllRecipientsUrl(),
  options: {
    method: 'DELETE',
  },
  meta: {
    successCallback,
    errorCallback,
    authToken: true,
  },
});
