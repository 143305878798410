import React from 'react';

import { ImageElement } from 'components/constructor';

import './style.scss';

const ViberPicture = ({
  rcsParams,
  updateRcsParams,
  openMediaSelect,
  openUploadMedia,
}) => (
  <div className="mkit-viber-picture">
    <ImageElement
      rcsParams={rcsParams}
      updateRcsParams={updateRcsParams}
      openMediaSelect={openMediaSelect}
      openUploadMedia={openUploadMedia}
      type="square"
    />
  </div>
);

export default ViberPicture;
