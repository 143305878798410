import React, { useMemo } from 'react';

import { useTheme } from 'hooks';

import './styles.scss';

const ImageCellRenderer = ({
  value,
  valueFormatted,
  style,
  supportLightTheme,
  lightThemeIcon,
}) => {
  const { isDarkTheme } = useTheme();

  const icon = useMemo(() => {
    if (supportLightTheme) {
      return lightThemeIcon(value, isDarkTheme);
    }

    return valueFormatted || value;
  }, [isDarkTheme]);

  return (
    <img
      src={icon}
      alt=""
      className="image-cell-renderer"
      style={style}
    />
  );
};

export default ImageCellRenderer;
