import React, {
  useEffect,
  useRef,
  useImperativeHandle,
} from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import cx from 'classnames';

import '@ag-grid-community/core/dist/styles/ag-grid.css';

import {
  ListFilterForm,
  DateFilter,
  NewMaskFilter,
  NumberFilter,
} from 'components/forms';
import {
  TagsFilterFormWrapper,
  OperationsFilterFormWrapper,
  TemplatesFilterFormWrapper,
} from 'components/wrappers';

import AgColumnHeader from './AgColumnHeader';
import CellRendererDropdownField from './CellRendererDropdownField';
import ButtonsCellRenderer from './ButtonsCellRenderer';
import TagsCellRenderer from './TagsCellRenderer';
import ImageCellRenderer from './ImageCellRenderer';
import StatusCellRenderer from './StatusCellRenderer';
import ImageArrayCellRenderer from './ImageArrayCellRenderer';
import MediaCellRenderer from './MediaCellRenderer';
import OperationCellRenderer from './OperationCellRenderer';
import OptOutsCellRenderer from './OptOutsCellRenderer';
import RatesCellRenderer from './RatesCellRenderer';
import EdrBodyCellRenderer from './ErdBodyCellRenderer';
import ReportCellRenderer from './ReportCellRenderer';
import ChatBotStatusRenderer from './ChatBotStatusRenderer';

import './style.scss';

const areEqual = (prevProps, nextProps) => {
  // ререндер нужен если:
  if (prevProps.rowData !== nextProps.rowData
      || prevProps.loading !== nextProps.loading
      || prevProps.columnDefs !== nextProps.columnDefs
      || prevProps.filteredColumns !== nextProps.filteredColumns) {
    return false;
  }

  return true;
};

export default React.memo(({
  className,
  columnDefs,
  rowData = [],
  rowKey = 'id',
  onFilterChanged,
  onSortChanged,
  rowSelection,
  onSelectionChanged,
  loading,
  resizableColDef = false,
  tableRef,
  onRowClicked,
  filteredColumns = {},
  rowHeight = 36,
  agRef,
  ...props
}) => {
  console.count('re-render');
  const tableClassName = cx('mkit-ag-table', {
    [className]: className,
  });
  const agGridRef = agRef || useRef();

  const defaultColDef = {
    // autoHeight: true,
    // cellStyle: { display: 'flex', alignItems: 'center' },
    resizable: resizableColDef,
    minWidth: 105,
    lockPosition: true,
    comparator: Function.prototype,
    // maxWidth: window.innerWidth / 3,
  };
  const frameworkComponents = {
    maskColumnFilter: NewMaskFilter,
    tagsColumnFilter: TagsFilterFormWrapper,
    listColumnFilter: ListFilterForm,
    dateColumnFilter: DateFilter,
    numberColumnFilter: NumberFilter,
    operationsColumnFilter: OperationsFilterFormWrapper,
    templatesColumnFilter: TemplatesFilterFormWrapper,
    cellRendererActions: ButtonsCellRenderer,
    cellRendererTags: TagsCellRenderer,
    cellRendererImage: ImageCellRenderer,
    cellRendererImageArray: ImageArrayCellRenderer,
    cellRendererStatus: StatusCellRenderer,
    cellRendererDropdownField: CellRendererDropdownField,
    cellRendererMedia: MediaCellRenderer,
    cellRendererOperation: OperationCellRenderer,
    cellRendererOptOuts: OptOutsCellRenderer,
    cellRendererRates: RatesCellRenderer,
    cellRendererEdrBody: EdrBodyCellRenderer,
    cellRendererReport: ReportCellRenderer,
    agColumnHeader: AgColumnHeader,
    chatBotStatusRenderer: ChatBotStatusRenderer,
  };

  // const onGridReady = (params) => {
  //   // const panel = params.api.gridPanel;
  //   // const columns = panel.columnController.getAllDisplayedColumns();
  //   // const usedWidth = panel.columnController.getWidthOfColsInList(columns);
  //   //
  //   // if (usedWidth < panel.eBodyViewport.clientWidth) {
  //   //   panel.sizeColumnsToFit();
  //   // }
  // };
  const handleFilterChanged = ({ api }) => {
    onFilterChanged(api.getFilterModel());
  };
  const handleSortChanged = ({ api }) => {
    onSortChanged(api.getSortModel());
  };
  const handleRowClicked = ({ node, ...params }) => {
    if (onRowClicked) {
      if (!node.gridOptionsWrapper.gridOptions.isPropagationStopped) {
        onRowClicked({ node, ...params });
      }
    }
  };
  const getRowNodeId = row => row[rowKey];

  useImperativeHandle(tableRef, () => ({
    setFilters(filters) {
      agGridRef.current.api.setFilterModel(filters);
    },
    redrawRows() {
      agGridRef.current.api.redrawRows();
    },
  }));

  useEffect(() => {
    if (loading) {
      agGridRef.current.api.showLoadingOverlay();
    } else if (!rowData.length) {
      agGridRef.current.api.showNoRowsOverlay();
    } else {
      agGridRef.current.api.hideOverlay();
    }
  }, [loading, rowData]);
  useEffect(() => {
    const hiddenColumns = [];
    const visibleColumns = [];

    const allColumns = agGridRef.current.columnApi.getAllColumns();
    if (allColumns) {
      allColumns.forEach(({ colId, visible, colDef }) => {
        if (filteredColumns[colId] === false && visible && !colDef.lockVisible) {
          hiddenColumns.push(colId);
        } else if (filteredColumns[colId] === true && !visible) {
          visibleColumns.push(colId);
        }
      });
    }

    if (hiddenColumns.length) {
      agGridRef.current.columnApi.setColumnsVisible(hiddenColumns, false);
    }

    if (visibleColumns.length) {
      agGridRef.current.columnApi.setColumnsVisible(visibleColumns, true);
      // обновление строк, что бы не было случаев
      // когда ячейки с данными находятся не под своим заголовком
      agGridRef.current.api.redrawRows();
      // agGridRef.current.api.setColumnDefs(columnDefs);
    }
  }, [filteredColumns, agGridRef.current]);
  useEffect(() => {
    // fix это нужно потому-что при одновременой установки колонок и строк, располрожение строк едет
    if (!loading && rowData.length) {
      setTimeout(() => {
        if (agGridRef.current) {
          agGridRef.current.api.setRowData(rowData);
          agGridRef.current.api.redrawRows();
        }
      }, 100);
    }
  }, [rowData, loading]);

  return (
    <div className={tableClassName}>
      <AgGridReact
        {...props}
        ref={agGridRef}
        headerHeight={36}
        rowHeight={rowHeight}
        onRowClicked={handleRowClicked}
        getRowNodeId={getRowNodeId}
        rowData={rowData}
        // getRowHeight={() => 42}
        rowSelection={rowSelection}
        onSelectionChanged={onSelectionChanged}
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        frameworkComponents={frameworkComponents}
        // onGridReady={onGridReady}
        onSortChanged={handleSortChanged}
        onFilterChanged={handleFilterChanged}
        suppressColumnVirtualisation
        enableBrowserTooltips
      />
    </div>
  );
}, areEqual);
