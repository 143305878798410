import React, { useState, useMemo, useEffect } from 'react';
import { isEqual } from 'lodash';
import ScrollMenu from 'react-horizontal-scrolling-menu';

import arrowIcon from 'assets/icons/arrow-right.svg';

import './style.scss';

const TabsWrapper = ({
  children,
  defaultActiveKey,
  onChangeTab,
  externalActiveTab,
}) => {
  const childrenModify = useMemo(() => {
    if (children?.length) {
      return children.reduce((acc, item) => {
        if (!isEqual(item, []) && item) {
          if (Array.isArray(item)) {
            acc.push(...item);
          } else if (typeof item === 'object') {
            acc.push(item);
          }
        }

        return acc;
      }, []);
    }

    return [children];
  }, [children]);

  const [activeTab, setActiveTab] = useState(defaultActiveKey || childrenModify[0].props.tabKey);

  const onClickTabItem = (tab) => {
    if (onChangeTab) {
      onChangeTab(tab);
    }

    setActiveTab(tab);
  };

  const menuItems = useMemo(() => childrenModify.map(child => React.cloneElement(child, {
    activeTab,
    onClickTab: onClickTabItem,
    key: child.props?.tabKey,
  })), [activeTab, childrenModify]);

  useEffect(() => {
    if (externalActiveTab) {
      setActiveTab(externalActiveTab);
    }
  }, [externalActiveTab]);

  return (
    <div className="tabs">
      <ScrollMenu
        data={menuItems}
        menuClass="tabs__list"
        arrowLeft={<img src={arrowIcon} alt="" className="tabs__arrow-left" />}
        arrowRight={<img src={arrowIcon} alt="" className="tabs__arrow-right" />}
        selected={activeTab}
        alignCenter={false}
        wheel={false}
        dragging={false}
        hideArrows
        scrollToSelected
      />
      <div className="tabs__content">
        {
          childrenModify.map(child => (
            child.props.tabKey !== activeTab
              ? null
              : child.props.children
          ))
        }
      </div>
    </div>
  );
};

export default TabsWrapper;
