import React from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import shortid from 'shortid';

import { BasicField } from 'components/fields';
import { BasicButton, IconButton } from 'components/buttons';
import addIcon from 'assets/icons/add.svg';
import trashIcon from 'assets/icons/trash.svg';

import './style.scss';

import ReportField from '../ReportField';

const ConditionFieldArray = ({
  reportFields,
  fields,
  styles = {},
  buttonText = 'ADD VALUE',
  readOnly = false,
}) => {
  const iconButtonStyle = { width: 40, height: 37 };
  const stylesBasicField = {
    label: { flex: 3, marginRight: 28 },
    inputContainer: { flex: 7 },
  };
  const onAddField = () => {
    fields.push({ id: shortid.generate(), condition: '' });
  };
  const { t } = useTranslation();

  return (
    <div className="condition-field-array" style={styles.wrapper}>
      <div className="condition-field-array__fields">
        {
          fields.map((name, index) => (
            <div className="condition-field-array__fields__field" key={name}>
              <Field
                name={`${name}.label`}
                type="border"
                label={t('SCENARIOS_CONSTRUCTOR.INSTANCES.LABEL')}
                component={BasicField}
                styles={{
                  label: { flex: 3 },
                  inputContainer: { flex: 7 },
                  container: { marginBottom: 12 },
                }}
                readOnly={readOnly}
                defaultValue=""
              />
              <Field
                name={`${name}.reportField`}
                type="border"
                label=""
                component={ReportField}
                readOnly={readOnly}
                showCustomReportField={false}
                options={reportFields}
              />
              <div className="condition-field-array__fields__field__condition">
                <Field
                  name={`${name}.condition`}
                  component={BasicField}
                  styles={stylesBasicField}
                  label={t('NEW_TRANSLATES.SCENARIOS_CONSTRUCTOR.INSTANCES.CONDITION', { number: index + 1 })}
                  placeholder={t('NEW_TRANSLATES.PLACEHOLDERS.CHATBOTS.CONDITION')}
                  type="border"
                  readOnly={readOnly}
                />
                <IconButton
                  filterType="white"
                  icon={trashIcon}
                  onClick={() => fields.remove(index)}
                  style={iconButtonStyle}
                  isDisabled={readOnly}
                />
              </div>
            </div>
          ))
        }
      </div>
      {
        !readOnly && (
          <BasicButton
            text={buttonText}
            type={BasicButton.types.PRIMARY}
            icon={addIcon}
            filterType="white"
            onClick={onAddField}
            isNewStyle
          />
        )
      }
    </div>
  );
};

export default ConditionFieldArray;
