import React, {
  useEffect,
  useRef,
  useState,
} from 'react';

import ModalContext from './ModalContext';

const ModalProvider = ({ children }) => {
  const modalRef = useRef();
  const [context, setContext] = useState();

  // make sure re-render is triggered after initial
  // render so that modalRef exists
  useEffect(() => {
    setContext(modalRef.current);
  }, []);

  return (
    <>
      <ModalContext.Provider value={context}>
        {children}
      </ModalContext.Provider>
      <div ref={modalRef} />
    </>
  );
};

export default ModalProvider;
