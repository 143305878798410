import actions from 'const/actions';

const setDashboardHint = state => ({
  type: actions.SET_DASHBOARD_HINT,
  state,
});

export default {
  setDashboardHint,
};
