/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import classNames from 'classnames';

import { getButtonsArray } from 'helpers';
import { IconButton } from 'components/buttons';
import Tooltip from 'components/Tooltip';
import crossIcon from 'assets/icons/cross-grey.svg';
import smallIcon from 'assets/icons/rcs/small.svg';
import smallActiveIcon from 'assets/icons/rcs/small-active.svg';
import mediumIcon from 'assets/icons/rcs/medium.svg';
import mediumActiveIcon from 'assets/icons/rcs/medium-active.svg';
import heightIcon from 'assets/icons/rcs/height.svg';
import heightActiveIcon from 'assets/icons/rcs/height-active.svg';
import addIcon from 'assets/icons/add-grey.svg';
import placeholderIcon from 'assets/icons/rcs/image-placeholder.svg';
import placeholderStapleIcon from 'assets/icons/staple-dark.svg';

import './style.scss';

const ImageElement = ({
  axis,
  rcsParams: {
    image,
    imageHeight,
  },
  updateRcsParams,
  openMediaSelect,
  type,
  readOnly,
  onImageClick,
  openUploadMedia,
  withThumbnailSelect,
}) => {
  const iconButtonStyle = {
    width: 20,
    minWidth: 20,
    height: 'auto',
  };

  const elementClass = classNames({
    'mkit-image-element': true,
    [`mkit-image-element_${axis?.toLowerCase()}`]: axis,
    [`mkit-image-element_${imageHeight}`]: axis === 'VERTICAL' && imageHeight,
    'mkit-image-element_border': !image && (type === 'square' || type === 'square-border'),
    [`mkit-image-element_${type}`]: type,
    'mkit-image-element_with-cursor': !!onImageClick,
  });

  const removeImage = (e) => {
    e.stopPropagation();
    updateRcsParams({ image: undefined });
  };

  const onSelectImageClick = () => {
    if (onImageClick) {
      onImageClick();

      return;
    }
    if (!readOnly) {
      openMediaSelect();
    }
  };

  const onThumbnailClick = (e) => {
    e.stopPropagation();

    if (!readOnly) {
      openMediaSelect(true);
    }
  };

  const onAddIconClick = (e) => {
    e.stopPropagation();
    openUploadMedia();
  };

  const heightButtons = getButtonsArray({
    keyValue: 'imageHeight',
    value: imageHeight,
    onClick: updateRcsParams,
    buttonsConfig: [
      {
        activeKey: 'small',
        activeIcon: smallActiveIcon,
        icon: smallIcon,
      },
      {
        activeKey: 'medium',
        activeIcon: mediumActiveIcon,
        icon: mediumIcon,
      },
      {
        activeKey: 'height',
        activeIcon: heightActiveIcon,
        icon: heightIcon,
      },
    ],
  });

  return (
    <div
      role="presentation"
      className={elementClass}
      onClick={onSelectImageClick}
    >
      {
        !image && (
          <div className="mkit-image-element__icons-row">
            <img
              src={
                type === 'any-file' || type === 'any-file-in-template'
                  ? placeholderStapleIcon
                  : placeholderIcon
              }
              alt=""
            />
            {
              !!openUploadMedia && (
                <img
                  src={addIcon}
                  alt=""
                  role="presentation"
                  onClick={onAddIconClick}
                  className="mkit-image-element__add-icon"
                />
              )
            }
          </div>
        )
      }
      {
        axis === 'VERTICAL' && !readOnly && (
          <div className="mkit-image-element__height-tools">
            {
              heightButtons.map(I => (
                <IconButton
                  icon={I.icon}
                  onClick={I.onClick}
                  style={iconButtonStyle}
                />
              ))
            }
          </div>
        )
      }
      {
        image && (
          <>
            <img
              src={
                image?.fileMimeType?.includes('video')
                  ? image?.x_thumbnailUri || image?.thumbnailUri
                  : image?.fileUrl
              }
              alt=""
              className="mkit-image-element__image"
            />
            {
              image?.fileMimeType?.includes('video') && withThumbnailSelect && (
                <>
                  <img
                    role="presentation"
                    src={image?.thumbnailUri}
                    alt=""
                    className="mkit-image-element__thumbnail"
                    data-tip="Select thumbnail"
                    data-for="thumbnail"
                    onClick={onThumbnailClick}
                    style={{ cursor: readOnly ? 'initial' : 'cursor' }}
                  />
                  <Tooltip id="thumbnail" place="right" />
                </>
              )
            }
            {
              !readOnly && (
                <button
                  type="button"
                  onClick={removeImage}
                  className="mkit-image-element__cross-button"
                >
                  <img src={crossIcon} alt="" />
                </button>
              )
            }
          </>
        )
      }
    </div>
  );
};

export default ImageElement;
