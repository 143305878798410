import React from 'react';
import { useTranslation } from 'react-i18next';

import { BasicButton, IconButton } from 'components/buttons';
import { MenuDropdown } from 'components/dropdowns';
import Pagination from 'components/Pagination';
import AntdTable from 'components/AntTable';
import AgTable from 'components/AgTable';
import eyeIcon from 'assets/icons/eye.svg';
import dotsIcon from 'assets/icons/dots.svg';
import refreshIcon from 'assets/icons/refresh.svg';

import './style.scss';

const Table = ({
  withPagination = true,
  limit,
  total,
  offset,
  onChangeLimit,
  onChangePage,
  title,
  onCustomViewPress,
  style,
  leftComponent,
  rightComponent,
  antTable = false,
  menuItems,
  onRefreshPress,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <div className="mkit-table" style={style}>
      <div className="mkit-table__header">
        <div className="mkit-table__header__left">
          {
            title && (
              <h2 className="mkit-table__header__left__title">
                {title}
              </h2>
            )
          }
          {
            leftComponent && (
              <div className="mkit-table__header__left__component">
                {leftComponent}
              </div>
            )
          }
        </div>
        <div className="mkit-table__header__right">
          {rightComponent}
          {
            onCustomViewPress && (
              <BasicButton
                text={t('CONTROLS.CUSTOM_VIEW')}
                icon={eyeIcon}
                filterType="white"
                onClick={onCustomViewPress}
              />
            )
          }
          {
            menuItems && (
              <MenuDropdown
                menu={menuItems}
              >
                <img className="mkit-table__header__right__dropdown" src={dotsIcon} alt="" />
              </MenuDropdown>
            )
          }
        </div>
      </div>
      {
        antTable ? (
          <AntdTable {...props} />
        ) : (
          <AgTable {...props} />
        )
      }
      {
        withPagination && (
          <div className="mkit-table__pagination">
            {
              onRefreshPress && (
                <IconButton
                  icon={refreshIcon}
                  filterType="white"
                  onClick={onRefreshPress}
                  styles={{
                    container: {
                      marginRight: 10,
                    },
                  }}
                />
              )
            }
            <Pagination
              limit={limit}
              total={total || 1}
              page={offset}
              onChangeLimit={onChangeLimit}
              onChangePage={onChangePage}
            />
          </div>
        )
      }
    </div>
  );
};

export default Table;
