import { useMutation } from 'redux-query-react';
import { useCallback } from 'react';

import { getTags as getTagsApi } from 'queries/recipients';

import { getArray, useISESelector } from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetTags,
      // lastUpdated: lastUpdatedGetFilters,
    },
    getTags,
  ] = useMutation(getTagsApi);

  const selector = useCallback(state => ({
    tags: getArray(state, 'tags'),
  }), []);

  const data = useISESelector(selector);

  return {
    isPendingGetTags,
    // lastUpdatedGetFilters,
    getTags,
    ...data,
  };
};
