export default (body) => {
  if (body?.RCSMessage) {
    return 2;
  }
  if (body?.recipient_type || body?.type === 'template' || body?.selectedType) {
    return 4;
  }
  if (body?.text || body?.imageUrl) {
    return 3;
  }
  if (body?.MsgType || body?.Content) {
    return 6;
  }

  return 1;
};
