const VALIDATION_MESSAGE_REQUIRED = 'VALIDATORS.FIELD_REQUIRED';
const VALIDATION_MESSAGE_TAG_LIST_REQUIRED = 'VALIDATORS.AT_LEAST_ONE';
const VALIDATION_MESSAGE_DATE = 'VALIDATORS.DATE_IS_INVALID';
const VALIDATION_MESSAGE_ARRAY = 'VALIDATORS.ARRAY_TYPE';

export {
  VALIDATION_MESSAGE_REQUIRED,
  VALIDATION_MESSAGE_TAG_LIST_REQUIRED,
  VALIDATION_MESSAGE_DATE,
  VALIDATION_MESSAGE_ARRAY,
};
