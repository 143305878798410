const STEP_TYPES = {
  STATIC_MESSAGE: 1,
  DYNAMIC_MESSAGE: 2,
  ASK_QUESTION: 3,
  SET_VARIABLE: 4,
  SET_CONTACT_ATTRIBUTE: 5,
  MAKE_WEBHOOK_CALL: 6,
  EXECUTE_CODE: 7,
  JUMP_TO: 8,
  RESET_CHAT_AND_START_OVER: 9,
  HANDOVER_TO_HUMAN: 10,
  OPT_OUT_USER: 11,
};

const CONDITION_TYPES = {
  1: 'Regexp',
  2: 'Keyphrase',
  3: 'Intent',
  5: 'Else',
};

const NODE_TYPE = {
  ACTION: 0,
  CONDITION: 1,
};

const CONDITION_NODE_TYPE = {
  USER_RESPONSE: 1,
  CONDITION: 2,
};

const TRIGGER_TYPES = {
  START: 0,
  FAILOVER: 1,
  RESPONSE_TIMEOUT: 2,
  STUCK_TIMEOUT: 3,
  OPT_OUT: 4,
};

const CONDITION_TYPE_OPTIONS = [
  {
    label: 'SCENARIOS_CONSTRUCTOR.INSTANCES.USER_RESPONSE',
    value: 1,
  },
  {
    label: 'SCENARIOS_CONSTRUCTOR.INSTANCES.CONDITION',
    value: 2,
  },
];

const ACTION_FAILURE_OPTIONS = [
  {
    label: 'SCENARIOS_CONSTRUCTOR.INSTANCES.CONTINUE',
    value: 1,
  },
  {
    label: 'SCENARIOS_CONSTRUCTOR.INSTANCES.END_CHAT',
    value: 2,
  },
  {
    label: 'SCENARIOS_CONSTRUCTOR.INSTANCES.FAILOVER',
    value: 3,
  },
  {
    label: 'SCENARIOS_CONSTRUCTOR.INSTANCES.HANDOVER',
    value: 4,
  },
  {
    label: 'SCENARIOS_CONSTRUCTOR.INSTANCES.DEFAULT',
    value: 'null',
  },
];

const TRIGGER_TYPE_OPTIONS = [
  {
    label: 'SCENARIOS_CONSTRUCTOR.INSTANCES.START_NODE',
    value: 0,
  },
  {
    label: 'SCENARIOS_CONSTRUCTOR.INSTANCES.FAILOVER_NODE',
    value: 1,
  },
  {
    label: 'SCENARIOS_CONSTRUCTOR.PLACEHOLDERS.RESPONSE_TIMEOUT_NODE',
    value: 2,
  },
  {
    label: 'SCENARIOS_CONSTRUCTOR.INSTANCES.STUCK_TIMEOUT_NODE',
    value: 3,
  },
  {
    label: 'SCENARIOS_CONSTRUCTOR.INSTANCES.OPT_OUT_NODE',
    value: 4,
  },
  {
    label: 'SCENARIOS_CONSTRUCTOR.INSTANCES.REGULAR_NODE',
    value: 'null',
  },
];

const FIRST_TRIGGER_TYPE_OPTIONS = [
  {
    label: 'SCENARIOS_CONSTRUCTOR.INSTANCES.START_NODE',
    value: 0,
  },
];

const REPORT_TYPES = [
  {
    label: 'SCENARIOS_CONSTRUCTOR.INSTANCES.NO_PUBLIC',
    value: 'NO + PUBLIC',
  },
  {
    label: 'SCENARIOS_CONSTRUCTOR.INSTANCES.PRESSED_YES',
    value: 'Pressed YES',
  },
  {
    label: 'SCENARIOS_CONSTRUCTOR.INSTANCES.PRESSED_NO',
    value: 'Pressed NO',
  },
];

export {
  NODE_TYPE,
  CONDITION_NODE_TYPE,
  STEP_TYPES,
  CONDITION_TYPES,
  TRIGGER_TYPES,
  ACTION_FAILURE_OPTIONS,
  TRIGGER_TYPE_OPTIONS,
  CONDITION_TYPE_OPTIONS,
  FIRST_TRIGGER_TYPE_OPTIONS,
  REPORT_TYPES,
};
