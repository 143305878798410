import React from 'react';
import ApisScreen from 'mkit-front-ds/dist/screens/ApisScreen';

import './style.scss';

const ApiAccesses = () => (
  <div className="api-accesses-screen-container">
    <ApisScreen />
  </div>
);

export default ApiAccesses;
