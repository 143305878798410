import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useMutation } from 'redux-query-react';

import { useModalLogic } from 'hooks';
import { QueryErrorModal } from 'components/modals';

import './style.scss';

const CheckQueryItem = ({
  queryKey,
  activeQuery,
  text,
  setNextQuery,
  url,
  body,
  method,
}) => {
  const [error, setError] = useState({ status: '', message: '' });

  const [isOpenModal, { openModal, closeModal }] = useModalLogic();

  const [
    {
      isPending,
      isFinished,
    },
    sendQuery,
  ] = useMutation(({ successCallback, errorCallback }) => ({
    url,
    body,
    queryKey: url,
    options: {
      method,
    },
    meta: {
      authToken: true,
      hideToast: true,
      successCallback,
      errorCallback,
    },
  }));

  const circleClass = classNames('check-query-item__circle', {
    'check-query-item__circle_success': !error.status,
    'check-query-item__circle_failure': error.status === 'error',
    'check-query-item__circle_warning': error.status === 'warning',
  });

  const spinnerClass = classNames('check-query-item__spinner__inner', {
    'check-query-item__spinner__inner_in-progress': isPending,
  });

  useEffect(() => {
    if (queryKey === activeQuery) {
      sendQuery({
        successCallback: () => {
          setNextQuery();
        },
        errorCallback: (res, code) => {
          let errorText = 'Unknown error';

          if (res) {
            if (res.data) {
              errorText = res.data.error || res.data.message;
            } else {
              errorText = res.message || res.error;
            }
          }

          setNextQuery();
          setError({
            status: code === 0 || code === 500 || code === 502 ? 'error' : 'warning',
            message: `${errorText} //ERROR CODE: ${code}`,
          });
        },
      });
    }
  }, [queryKey, activeQuery]);

  const onCircleClick = () => {
    if (error.status) {
      openModal();
    }
  };

  return (
    <div className="check-query-item">
      {
        !isFinished
          ? (
            <div className="check-query-item__spinner">
              <div className={spinnerClass} />
            </div>
          )
          : (
            <div
              className={circleClass}
              onClick={onCircleClick}
              role="presentation"
            />
          )
      }
      <span className="check-query-item__text">
        {text}
      </span>
      {
        isOpenModal && (
          <QueryErrorModal
            closeModal={closeModal}
            text={error.message}
          />
        )
      }
    </div>
  );
};

export default CheckQueryItem;
