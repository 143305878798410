import React from 'react';
import BroadcastScreenSource from 'mkit-front-ds/dist/screens/BroadcastScreen';

import styles from './BroadcastScreen.module.scss';

const BroadcastScreen = ({ history }) => (
  <div className={styles.screen}>
    <BroadcastScreenSource history={history} />
  </div>
);

export default BroadcastScreen;
