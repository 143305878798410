import React, { useEffect, useState } from 'react';

import Table from './Table';
import styles from './VersionScreen.module.scss';

const ROOT = process.env.NODE_ENV === 'production' ? window.location.origin : 'https://mkitdev2.alarislabs.com';
const SERVICES_LIST = [
  'account-service',
  'backoffice-service',
  'balance-updater-service',
  'billing-agent-service',
  'cache-service',
  'campaign-service',
  'content-service',
  'content-template-service',
  'conversation-service',
  'debezium-connect',
  'dictionary-service',
  'document-service',
  'edr',
  'edrs-restapi-service',
  'emulator',
  'finance-service',
  'flow-service',
  'http-vendor-service',
  'import-service',
  'integration-service',
  'js-script-executor',
  'keycloak',
  'mdn',
  'moderation-service',
  'notification-service',
  'gateway-service',
  'ocs',
  'omni-api',
  'omni-fallback',
  'omni-webhook',
  'pipeline-service',
  'psp-service',
  'recipient-service',
  'reference-service',
  'report-service',
  'reporting-service',
  'responder-service',
  'smpp-service',
  'tariffication-manager-service',
  'smppapi-crud-service',
  'link-service',
];

const VersionsScreen = () => {
  const [referenceBuildBranch, setReferenceBuildBranch] = useState();
  const [list, setList] = useState([]);

  const getServiceVersion = ({ serviceName, url }) => {
    fetch(url || `${ROOT}/${serviceName}/version`)
      .then(res => res.json())
      .then((res) => {
        if (serviceName === 'gateway-service' && res['build.branch']) {
          setReferenceBuildBranch(res['build.branch']);
        }

        fetch(`${ROOT}/${serviceName}/health`)
          .then((healthRes) => {
            setList(prev => [
              ...prev,
              {
                serviceName,
                ...(!res.error && res),
                health: healthRes.status === 200 ? 'UP' : healthRes?.statusText,
              },
            ]);
          });
      })
      .catch(() => {
        setList(prev => [
          ...prev,
          {
            serviceName,
          },
        ]);
      });
  };

  useEffect(() => {
    SERVICES_LIST.forEach(serviceName => getServiceVersion({ serviceName }));
    getServiceVersion({ serviceName: 'mkit-front', url: `${ROOT}/version.json` });
    getServiceVersion({ serviceName: 'backoffice-front', url: `${ROOT}/bo/version.json` });
  }, []);

  return (
    <div className={styles.screen}>
      <Table
        list={list}
        referenceBuildBranch={referenceBuildBranch}
      />
    </div>
  );
};

export default VersionsScreen;
