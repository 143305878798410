import { isNaN } from 'lodash';

const CRON_MINUTES_INDEX = 0;
const CRON_HOURS_INDEX = 1;
const CRON_DAYS_OF_MONTHS_INDEX = 2;
const CRON_MONTHS_INDEX = 3;
const CRON_DAYS_OF_WEEK_INDEX = 4;
const CRON_YEARS_INDEX = 5;

function range(start, end) {
  return Array(end - start + 1).fill().map((_, idx) => start + idx);
}

/**
 * @param items {number[]}
 * @return string
 */
const numbersToRanges = (items) => {
  const ranges = [];
  let rangeStart;
  let rangeEnd;
  const sortedItems = items.sort();
  for (let i = 0; i < sortedItems.length; i += 1) {
    rangeStart = sortedItems[i];
    rangeEnd = rangeStart;
    while (sortedItems[i + 1] - sortedItems[i] === 1) {
      rangeEnd = sortedItems[i + 1];
      i += 1;
    }
    ranges.push(rangeStart === rangeEnd ? `${rangeStart}` : `${rangeStart}-${rangeEnd}`);
  }
  return ranges.join(',');
};

/**
 *
 * @param ranges {string}
 * @return {number[]}
 */
const rangesToNumbers = (ranges) => {
  const result = [];
  ranges?.split(',').forEach((item) => {
    if (item.indexOf('-') !== -1) {
      const rangeBorders = item?.split('-');
      result.push(...range(+rangeBorders[0], +rangeBorders[1]));
    } else {
      result.push(+item);
    }
  });
  return result;
};

/**
 *
  * @param value {string}
 * @return {number[]|undefined}
 */
const parseCronRanges = value => (value === '*' || value === '?' ? undefined : rangesToNumbers(value));

/**
 *
 * @param value {number[]|undefined}
 * @return {string}
 */
const generateCronRangesFromNumbers = value => (value ? numbersToRanges(value) : '*');
/**
 *
 * @param time {Moment}
 * @param daysOfWeek {number[]|undefined}
 * @param daysOfMonths {number[]|undefined}
 * @param months {number[]|undefined}
 * @param years {number[]|undefined}
 * @returns {string}
 */
const generateCron = ({
  time,
  daysOfWeek,
  daysOfMonths,
  months,
  years,
}) => {
  const daysOfWeekStr = generateCronRangesFromNumbers(daysOfWeek);
  const daysOfMothsStr = generateCronRangesFromNumbers(daysOfMonths);
  const monthsStr = generateCronRangesFromNumbers(months);
  const yearsStr = generateCronRangesFromNumbers(years);
  return `${+time.minutes} ${+time.hours} ${daysOfMothsStr} ${monthsStr} ${daysOfWeekStr} ${yearsStr}`;
};

/**
 *
 * @param cron {string}
 */
const parsCron = (cron, timezone) => {
  const cronItems = cron.trim().split(' ');
  const minutes = +cronItems[CRON_MINUTES_INDEX];
  const hours = +cronItems[CRON_HOURS_INDEX];
  let time = {
    minutes: '00',
    hours: '00',
    seconds: '00',
  };
  if (!isNaN(minutes) && !isNaN(hours)) {
    time = {
      minutes: `${minutes < 10 ? '0' : ''}${minutes}`,
      hours: `${hours < 10 ? '0' : ''}${hours}`,
      seconds: '00',
      gtm: timezone,
    };
  }
  const daysOfWeek = parseCronRanges(cronItems[CRON_DAYS_OF_WEEK_INDEX]);
  const daysOfMonth = parseCronRanges(cronItems[CRON_DAYS_OF_MONTHS_INDEX]);
  const months = parseCronRanges(cronItems[CRON_MONTHS_INDEX]);
  const years = parseCronRanges(cronItems[CRON_YEARS_INDEX]);
  return {
    time,
    daysOfWeek,
    daysOfMonth,
    months,
    years,
  };
};

export {
  generateCron,
  parsCron,
};
