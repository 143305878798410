/* eslint-disable */
import React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';

import ConditionNodeModel from './ConditionNodeModel';
import ConditionNodeWidget from './ConditionNodeWidget';
import ErrorBoundary from './ErrorBoundary';

export default class ConditionNodeFactory extends AbstractReactFactory {
  constructor() {
    super('condition-node');
  }

  generateModel(event) {
    return new ConditionNodeModel();
  }

  generateReactWidget(event) {
    return (
      <ErrorBoundary>
        <ConditionNodeWidget engine={this.engine} node={event.model} />
      </ErrorBoundary>
    )
  }
}
