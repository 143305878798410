import React from 'react';
import ContentTemplatesScreen from 'mkit-front-ds/dist/screens/ContentTemplatesScreen';

import { useUsersApi } from 'hooks/api';

import './style.scss';

const TemplatesScreen = () => {
  const { currentUser } = useUsersApi();

  return (
    <div className="templates-screen">
      <ContentTemplatesScreen userPermissions={currentUser?.roles || []} />
    </div>
  );
};

export default TemplatesScreen;
