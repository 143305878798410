/* eslint-disable */
import React, { useMemo, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { toast as toastClass } from 'react-toastify';

import { useVariables } from 'hooks';
import { toast, setCaretAtEnd } from 'helpers';
import boldIcon from 'assets/icons/rcs/bold.svg';
import boldActiveIcon from 'assets/icons/rcs/bold-active.svg';
import italicIcon from 'assets/icons/rcs/italic.svg';
import italicActiveIcon from 'assets/icons/rcs/italic-active.svg';
import underlineIcon from 'assets/icons/rcs/underline.svg';
import underlineActiveIcon from 'assets/icons/rcs/underline-active.svg';

import './style.scss';

const EditorTextarea = ({
  value,
  onChange,
  type,
  fontStyle,
  onChangeFontStyle,
  styleWrapper,
  isTitle,
  cardIndex,
  axis,
  isSms,
  readOnly,
  withVariables = true,
  placeholderValue,
  maxLength,
}) => {
  const { t } = useTranslation();

  const inputRef = useRef();
  const inputId = isTitle ? 'titleEditor' : 'editor';

  const {
    onItemClick,
    onButtonClick,
    handleChange,
    isDropdownOpen,
    buttonRef,
    dropdownRef,
    variables,
  } = useVariables({
    element: inputRef,
    value,
    cardIndex,
    onChange,
    isSms,
    highlightingVariables: !withVariables,
  });

  const textareaClass = classNames('mkit-editor-textarea', {
    [`mkit-editor-textarea_${type}`]: type,
    [`mkit-editor-textarea_${fontStyle}`]: fontStyle,
    [`mkit-editor-textarea_${axis?.toLowerCase()}`]: axis,
    'mkit-editor-textarea_open': isDropdownOpen,
  });
  const fontButtonClass = selectedType => classNames('mkit-editor-textarea__tools__button', {
    'mkit-editor-textarea__tools__button_selected': fontStyle === selectedType,
  });

  const placeholder = useMemo(() => {
    if (placeholderValue) {
      return placeholderValue;
    }
    if (type === 'viber-text' || type === 'whats-app-text') {
      return t('MESSAGE_CONSTRUCTOR.INSTANCES.ENTER_MESSAGE');
    }

    return t('MESSAGE_CONSTRUCTOR.PLACEHOLDERS.ENTER_OR_LEAVE_MESSAGE');
  }, [type, placeholderValue]);

  const fontButtons = useMemo(() => ([
    {
      icon: fontStyle === 'bold' ? boldActiveIcon : boldIcon,
      onClick: () => onChangeFontStyle('bold'),
      type: 'bold',
    },
    {
      icon: fontStyle === 'italic' ? italicActiveIcon : italicIcon,
      onClick: () => onChangeFontStyle('italic'),
      type: 'italic',
    },
    {
      icon: fontStyle === 'underline' ? underlineActiveIcon : underlineIcon,
      onClick: () => onChangeFontStyle('underline'),
      type: 'underline',
    },
  ]), [fontStyle, onChangeFontStyle]);

  const onMouseUp = (e) => {
    if (window.getSelection().anchorNode.parentNode.className === 'mkit-editor-textarea__tag'
      && e.keyCode !== 8) {
      if (!inputRef.current.childNodes[inputRef.current.childNodes?.length - 1]?.className) {
        setCaretAtEnd(inputRef.current);
      } else {
        setCaretAtEnd(inputRef.current);
        document.execCommand(
          'insertHTML',
          false,
          '<span> </span>',
        );
      }
    }
  };

  const onKeyDown = (e) => {
    onMouseUp(e);

    if (maxLength && value?.length >= maxLength && e.keyCode !== 8
      && !(e.metaKey && e.keyCode === 65)) {
      e.preventDefault();
    }
    if (e.keyCode === 13 && !e.shiftKey && !e.ctrlKey) {
      e.preventDefault();
      document.execCommand('insertLineBreak', false, null);
    }
  };

  const handlePaste = (e) => {
    if (maxLength) {
      e.preventDefault();
      const fieldValue = inputRef.current.innerText;
      const text = e.clipboardData.getData('text/plain');

      if ((fieldValue?.length || 0) + text?.length > maxLength) {
        if (!toastClass.isActive('maxLength')) {
          toast.error(
            t('MESSAGE_CONSTRUCTOR.INSTANCES.EXCEEDED_MAX_LENGTH', { count: maxLength }),
            { toastId: 'maxLength', position: 'top-right' },
          );
        }
      } else {
        document.execCommand('insertHTML', false, text);
      }
    }
  };

  useEffect(() => {
    const item = document.getElementById(inputId);
    item.addEventListener('paste', handlePaste);

    return () => item.removeEventListener('paste', handlePaste, false);
  }, []);

  return (
    <div
      className={textareaClass}
      style={styleWrapper}
    >
      {
        !readOnly && withVariables && (
          <div className="mkit-editor-textarea__tools">
            {
              variables && (
                <button
                  className="mkit-editor-textarea__tools__variable"
                  type="button"
                  onClick={onButtonClick}
                  ref={buttonRef}
                >
                  (x)
                  <div
                    className="mkit-editor-textarea__tools__variable__block"
                    ref={dropdownRef}
                  >
                    {
                      variables.map(item => (
                        <button
                          type="button"
                          onClick={() => onItemClick(item)}
                          className="mkit-editor-textarea__tools__variable__block__item"
                          key={item}
                        >
                          {item}
                        </button>
                      ))
                    }
                  </div>
                </button>
              )
            }
            {
              !!onChangeFontStyle && fontButtons.map(I => (
                <button
                  className={fontButtonClass(I.type)}
                  type="button"
                  onClick={I.onClick}
                  key={I.type}
                >
                  <img src={I.icon} alt="" />
                </button>
              ))
            }
          </div>
        )
      }
      {
        readOnly && <div className="mkit-editor-textarea__disabler" />
      }
      {/* eslint-disable-next-line max-len */}
      {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus,jsx-a11y/role-supports-aria-props */}
      <div
        className="mkit-editor-textarea__input"
        ref={inputRef}
        id={inputId}
        onInput={handleChange}
        data-placeholder={placeholder}
        onKeyDown={onKeyDown}
        onMouseUp={onMouseUp}
        contentEditable
        role="textbox"
        dir="auto"
        data-gramm="false"
        aria-multiline="true"
        aria-autocomplete="list"
        aria-expanded="false"
        aria-owns="text-editor"
        spellCheck="true"
      />
    </div>
  );
};

export default EditorTextarea;
