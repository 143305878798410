import React from 'react';
import './FormControl.scss';

const FormControl = ({ meta, children }) => (
  <span className="form-control">
    {children}
    {meta && meta.touched && meta.error && (
    <span className="form-control__error">
      <span className="form-control__error-message">
        {meta.error}
      </span>
    </span>
    )}
  </span>
);

export default FormControl;
