import endpoints from 'const/endpoints';

export default ({
  body,
  successCallback,
  id,
}) => ({
  url: endpoints.getFlowNodesByIdUrl(id),
  options: {
    method: 'PUT',
  },
  body,
  meta: {
    authToken: true,
    successCallback,
  },
});
