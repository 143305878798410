import React, { useMemo, useState } from 'react';
import classNames from 'classnames';

import { useDropdownLogic } from 'hooks';
import './style.scss';

const DropdownSpecifyField = ({
  input: {
    onChange,
    value,
    name,
    ...input
  },
  placeholder,
  style,
  styleInput,
  styleButton,
  label,
  items,
  disabled,
  meta: {
    error,
    active,
    touched,
  } = {},
  pattern = '^.*$',
}) => {
  const [isAll, setIsAll] = useState();
  const {
    isDropdownOpen,
    setDropdownOpen,
    dropdownRef,
    buttonRef,
  } = useDropdownLogic();

  const dropdownClass = classNames({
    'dropdown-specify-field': true,
    'dropdown-specify-field_disabled': disabled,
    'dropdown-specify-field_open': isDropdownOpen,
  });

  const onDropdownSelect = (selectedValue) => {
    setDropdownOpen(false);
    if (value.value !== selectedValue.id) {
      onChange({
        value: selectedValue.id,
        isCustom: false,
      });
    }
  };
  const onChangeInput = ({ target }) => {
    if (isAll) {
      setIsAll(false);
    }

    onChange({
      value: target.validity.valid ? target.value : value.value,
      isCustom: true,
    });
  };
  const onButtonClick = () => {
    if (!disabled) {
      setDropdownOpen(!isDropdownOpen);
      setIsAll(true);
    }
  };
  const onFocus = () => {
    if (!isDropdownOpen) {
      setIsAll(true);
      setDropdownOpen(true);
    }
  };

  const displayedValue = useMemo(() => {
    if (!value.isCustom) {
      const foundItem = items.find(item => item.id === value.value);
      if (foundItem) {
        return foundItem.title;
      }

      return '';
    }

    return value.value || '';
  }, [items, value]);
  const foundItems = useMemo(() => {
    if (value.value && !isAll) {
      const desiredValue = displayedValue.toLowerCase();

      return items.filter(item => item.title.toLowerCase().includes(desiredValue));
    }

    return items;
  }, [items, value, isAll]);

  return (
    <div
      style={style}
      className={dropdownClass}
    >
      {
        label && (
          <label
            className="dropdown-specify-field__label"
            htmlFor={name}
          >
            {label}
          </label>
        )
      }
      <div
        ref={buttonRef}
        className="dropdown-specify-field__control"
        style={styleButton}
      >
        <input
          {...input}
          type="text"
          id={name}
          style={styleInput}
          className="dropdown-specify-field__control__input"
          value={displayedValue}
          placeholder={placeholder}
          onFocus={onFocus}
          onChange={onChangeInput}
          pattern={pattern}
          disabled={disabled}
        />
        <button
          type="button"
          aria-label="open"
          onClick={onButtonClick}
          className="dropdown-specify-field__control__button"
        />
      </div>
      <div
        ref={dropdownRef}
        className="dropdown-specify-field__overlay"
      >
        {
          foundItems.map(item => (
            <button
              onClick={() => onDropdownSelect(item)}
              key={item.id}
              type="button"
              className="dropdown-specify-field__overlay__item"
            >
              <span className="dropdown-specify-field__overlay__item__text">
                {item.title}
              </span>
            </button>
          ))
        }
      </div>
      {!active && touched && error && (
        <div className="dropdown-specify-field__error-text">
          {error}
        </div>
      )}
    </div>
  );
};

export default DropdownSpecifyField;
