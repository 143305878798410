import moment from 'moment';

import {
  required, requiredString, tagListRequired, date, isArrayRequired,
} from './index';

import { generateCron, parsCron } from './cron';

/**
 *
 * @param values {Object}
 * @param errors {Object}
 * @param validateFunction {Function|Function[]}
 * @param field {string}
 * @param t {Function}
 */
const validateField = (
  values,
  errors,
  validateFunction,
  field,
  t,
) => {
  let message;
  if (Array.isArray(validateFunction)) {
    validateFunction.forEach((validateFunctionItem) => {
      if (!message) {
        message = validateFunctionItem(values[field], t);
      }
    });
  } else {
    message = validateFunction(values[field], t);
  }
  if (message) {
    errors[field] = message;
  }
};

const validateLaunchTimeDefault = (values, errors = {}, t) => {
  if (values.isInstantly) {
    return errors;
  }
  validateField(values, errors, [required, date], 'time', t);
  validateField(values, errors, [required, date], 'date', t);
  const dateFormatted = values.date?.format('YYYY-MM-DD');
  const timeFormatted = moment(values.time)?.format('HH:mm:ss');
  const dateTime = moment(`${dateFormatted} ${timeFormatted}`);
  if (dateTime < moment()) {
    errors.date = t('NEW_TRANSLATES.ERRORS.BROADCAST_PAST_DATE');
  }
  return errors;
};

const validateLaunchTimeRecurrent = (values, errors = {}, t) => {
  validateField(values, errors, tagListRequired, 'launchTimeMonths', t);
  validateField(values, errors, [required, date], 'launchTimeStart', t);
  validateField(values, errors, [required, date], 'launchTimeEnd', t);
  validateField(values, errors, [required, date], 'time', t);
  if (values.launchTimeDayOfMonth) {
    validateField(values, errors, tagListRequired, 'daysOfMonths', t);
  } else {
    validateField(values, errors, tagListRequired, 'daysOfWeek', t);
  }
  return errors;
};

const validateCampaignEditLaunchTime = (values, errors = {}, t) => {
  if (values.externalTrigger) {
    return errors;
  }
  return values.recurrentCompany
    ? validateLaunchTimeRecurrent(values, errors, t)
    : validateLaunchTimeDefault(values, errors, t);
};

const validationTargets = (values, errors = {}, t) => {
  validateField(values, errors, [required, isArrayRequired], 'filterIds', t);
};

export const validateCampaignEdit = (values, errors = {}, t) => {
  if (values.draft) {
    return errors;
  }
  validateField(values, errors, requiredString, 'name', t);
  validateField(values, errors, requiredString, 'name', t);
  validationTargets(values, errors, t);
  return validateCampaignEditLaunchTime(values, errors, t);
};

const convertLaunchTimeDataToApiData = (values, result) => {
  result.fRecurrent = values.recurrentCompany;
  if (values.recurrentCompany) {
    result.startDateAt = values.launchTimeStart.format('YYYY-MM-DDTHH:mm:ssZ');
    result.endDateAt = values.launchTimeEnd.format('YYYY-MM-DDTHH:mm:ssZ');
    result.schedule = generateCron({
      time: values.time,
      daysOfWeek: !values.launchTimeDayOfMonth && values.daysOfWeek,
      daysOfMonths: values.launchTimeDayOfMonth && values.daysOfMonths,
      months: values.launchTimeMonths,
    });
    result.timezone = values.time.gtm;
  } else if (!values.isInstantly) {
    result.schedule = generateCron({
      time: values.time,
      daysOfMonths: values.date ? [values.date.date()] : undefined,
      months: values.date ? [values.date.get('month')] : undefined,
      years: values.date ? [values.date.year()] : undefined,
    });
    result.timezone = values.time.gtm;
  } else {
    result.schedule = '*/2 * * * * *';
  }
};

export const convertFormDataToApiData = (values, isRun, isRerun) => {
  const result = {};

  if (!isRun) {
    result.name = values.name;
    result.description = values.description;
    result.frecurrent = values.recurrentCompany;
    result.ftrigger = values.externalTrigger;
    result.fTrigger = values.externalTrigger || false;
    result.ftrigger = values.externalTrigger || false;
    result.campId = values.campId;
    result.topicId = values.topicId;
    result.fRecurrent = values.recurrentCompany || false;
    result.shortLinkCodes = values.shortLinkCodes;
  }
  if (!isRerun) {
    result.filterIds = values.filterIds;
  }
  if (!result.externalTrigger) {
    convertLaunchTimeDataToApiData(values, result);
  }
  if (values.isScript) {
    result.scenario = {
      senderId: values.senderId?.split('/')[0],
      // eslint-disable-next-line no-unsafe-optional-chaining
      channelId: +values.senderId?.split('/')[1],
      flowId: values.flowId,
    };
  } else {
    result.flowSteps = values.flowSteps;
  }
  return result;
};

const convertApiDataToLaunchTime = (apiData) => {
  const parsedCron = parsCron(apiData.schedule, apiData.timezone);
  const result = {};
  result.isInstantly = false;
  if (apiData.frecurrent) {
    result.launchTimeStart = moment(apiData.startDateAt);
    result.launchTimeEnd = moment(apiData.endDateAt);
    result.time = parsedCron.time;
    result.daysOfWeek = parsedCron.daysOfWeek;
    result.daysOfMonths = parsedCron.daysOfMonth;
    result.launchTimeMonths = parsedCron.months;
    result.launchTimeDayOfMonth = !!parsedCron.daysOfMonth;
  } else if (apiData.schedule === '*/2 * * * * *' || apiData.schedule === '*/2 * * * * ?') {
    result.isInstantly = true;
    result.time = {
      hours: `${moment().hours() < 10 ? '0' : ''}${moment().hours()}`,
      minutes: `${moment().minutes() < 10 ? '0' : ''}${moment().minutes()}`,
      seconds: `${moment().seconds() < 10 ? '0' : ''}${moment().seconds()}`,
      gmt: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
  } else if (parsedCron.daysOfMonth && parsedCron.months) {
    result.time = parsedCron.time;

    if (parsedCron.daysOfMonth.length) {
      result.date = moment().date(parsedCron.daysOfMonth[0]).set('month', parsedCron.months[0]);
    }
  }
  return result;
};

export const convertApiDataToData = (apiData) => {
  let formData = ({
    externalTrigger: apiData.ftrigger,
    recurrentCompany: apiData.frecurrent,
    name: apiData.name,
    campId: apiData.campId,
    topicId: apiData.topicId,
    description: apiData.description,
    filterIds: apiData.filterIds,
    flowSteps: apiData.flowSteps,
    shortLinkCodes: apiData.shortLinkCodes,
    ...convertApiDataToLaunchTime(apiData),
  });

  if (!apiData.flowSteps.length) {
    formData = {
      ...formData,
      isScript: true,
      flowId: apiData.scenario.flowId,
      senderId: `${apiData.scenario.senderId}/${apiData.scenario.channelId}`,
    };
  }

  return formData;
};

export const convertMomentTimeToSeconds = (time) => {
  const timeObj = time?.toObject() || {};
  return timeObj.seconds + timeObj.minutes * 60 + timeObj.hours * 3600;
};

export const convertObjectTimeToSeconds = time =>
  // eslint-disable-next-line radix
  parseInt(time.seconds) + parseInt(time.minutes) * 60 + parseInt(time.hours) * 3600;

export const convertSecondsToObjectTime = (seconds) => {
  if (!seconds) {
    return { hours: '00', minutes: '10', seconds: '00' };
  }

  const formattedTime = moment.utc(seconds * 1000).format('HH:mm:ss').split(':');

  return { hours: formattedTime[0], minutes: formattedTime[1], seconds: formattedTime[2] };
};

export const convertMomentTimeToStringTime = (time) => {
  const timeObj = time.toObject();
  return `${timeObj.hours ? `${timeObj.hours} h` : ''} 
    ${timeObj.minutes ? `${timeObj.minutes} m` : ''} 
    ${timeObj.seconds ? `${timeObj.seconds} s` : ''}`.trim();
};
