import React from 'react';
import { Tag } from 'antd';
import classNames from 'classnames';
import './CheckableTag.scss';

const { CheckableTag: CheckableTagSource } = Tag;

const CheckableTag = ({ className, ...props }) =>
  (<CheckableTagSource className={classNames(className, 'checkable-tag')} {...props} />);

export default CheckableTag;
