import React from 'react';
import { TimePicker as TimePickerSource } from 'antd';
import classNames from 'classnames';
import './TimePicker.scss';

const TimePicker = ({ className, popupClassName, ...props }) => (
  <TimePickerSource
    popupClassName={classNames(popupClassName, 'time-picker__popup')}
    className={classNames(className, 'time-picker')}
    {...props}
  />
);

export default TimePicker;
