import React from 'react';
import classNames from 'classnames';

import './style.scss';

const Buttons = ({
  buttons,
  type,
  componentsParams,
  className,
}) => {
  const itemClass = buttonType => classNames('whats-app-template-card__buttons__item', {
    'whats-app-template-card__buttons__item_reply': buttonType === 'quick_reply',
  });

  const getDisplayText = (text) => {
    if (componentsParams[type]) {
      return text.replace(/{{\d}}/g, (string) => {
        const variableValue = componentsParams[type][string.replace(/{{/g, '').replace(/}}/g, '')];

        return variableValue || string;
      });
    }
    return text;
  };

  return (
    <div className={className}>
      {
       buttons.map(({ text, type: buttonType }) => (
         <div className={itemClass(buttonType)} key={text}>
           {getDisplayText(text)}
         </div>
       ))
     }
    </div>
  );
};

export default Buttons;
