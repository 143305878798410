import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useDropdownLogic } from 'hooks';

import './style.scss';

const RcsReplyDropdown = ({
  type,
  text,
  onSelectControl,
  style,
  parentRef,
  axis,
  disabled,
}) => {
  const { t } = useTranslation();

  const {
    buttonRef,
    dropdownRef,
    isDropdownOpen,
    toggleDropdown,
  } = useDropdownLogic();

  const [isDirectionTop, setIsDirectionTop] = useState(false);

  const className = classNames('rcs-reply-dropdown', {
    'rcs-reply-dropdown_open': isDropdownOpen,
    [`rcs-reply-dropdown_${type}`]: type,
    'rcs-reply-dropdown_direction-top': isDirectionTop,
    [`rcs-reply-dropdown_${axis?.toLowerCase()}`]: !type && axis,
    'rcs-reply-dropdown_disabled': disabled,
  });

  const onClick = () => {
    if (!disabled) {
      if (parentRef) {
        const { offsetHeight } = parentRef.current;

        if (((offsetHeight - buttonRef.current.offsetTop) < -144) && !isDirectionTop) {
          setIsDirectionTop(true);
        } else if (((offsetHeight - buttonRef.current.offsetTop) > -144) && isDirectionTop) {
          setIsDirectionTop(false);
        }
      }
      toggleDropdown();
    }
  };

  return (
    <div
      className={className}
      ref={buttonRef}
      onClick={onClick}
      style={style}
      role="presentation"
    >
      {text}
      <div className="rcs-reply-dropdown__block" ref={dropdownRef}>
        <button
          className="rcs-reply-dropdown__block__item"
          type="button"
          onClick={() => onSelectControl('reply')}
        >
          {t('MESSAGE_CONSTRUCTOR.INSTANCES.REPLY')}
        </button>
        <button
          className="rcs-reply-dropdown__block__item"
          type="button"
          onClick={() => onSelectControl('action')}
        >
          {t('MESSAGE_CONSTRUCTOR.ACTION')}
        </button>
      </div>
    </div>
  );
};

export default RcsReplyDropdown;
