import convertTemplateToConstructorFormat from 'mkit-front-ds/dist/helpers/converterTemplateToConstructorFormat';

export default ({ contentPattern }) => {
  if (contentPattern) {
    switch (true) {
      case contentPattern.type === 'location':
        return ({
          selectedType: 'whatsAppGetGeolocation',
          location: {
            longitude: contentPattern.location.longitude,
            latitude: contentPattern.location.latitude,
            name: contentPattern.location.name,
            address: contentPattern.location.address,
          },
        });
      case contentPattern.type === 'template': {
        return ({
          ...contentPattern.template,
          ...convertTemplateToConstructorFormat({
            components: contentPattern.template.templateComponents,
            useEmptyValues: false,
          }),
          components: [...(contentPattern.template.templateComponents || [])],
        });
      }
      case contentPattern.type === 'text':
      case contentPattern.selectedType === 'whatsAppText': {
        const channelCopy = { ...contentPattern };
        return ({ selectedType: 'whatsAppText', ...channelCopy });
      }
      case contentPattern.type === 'image':
      case contentPattern.type === 'document':
      case contentPattern.type === 'audio':
      case contentPattern.type === 'video':
      case contentPattern.selectedType === 'whatsAppFile':
        return ({ selectedType: 'whatsAppFile', ...contentPattern });
      case contentPattern.interactive
      && contentPattern.interactive.selectedType === 'whatsAppMenu':
        return ({ ...contentPattern.interactive });
      case contentPattern.interactive
      && contentPattern.interactive.selectedType === 'whatsAppSendGeolocation':
      case contentPattern.interactive
      && contentPattern.interactive.selectedType === 'whatsAppCard': {
        const channelCopy = { ...contentPattern.interactive };
        channelCopy.suggestionsInside = channelCopy.action?.buttons?.map(I => ({
          id: I.reply.id,
          text: I.reply.title,
          suggestionType: I.type,
        }));
        delete channelCopy.action;
        return ({ ...channelCopy });
      }
      default:
        return {};
    }
  }

  return null;
};
