import { useEffect } from 'react';
import ReconnectingWebSocket from 'reconnecting-websocket';

import { useToken } from 'hooks';
import endpoints from 'const/endpoints';

import useOnMessageSocket from './useOnMessageSocket';

let ws;

export default ({
  selectedNumber,
}) => {
  const { token } = useToken();
  const { onMessage } = useOnMessageSocket({ selectedNumber });

  useEffect(() => {
    ws = new ReconnectingWebSocket(
      endpoints.getSocketsUrl({ token }),
      [],
      {
        debug: process.env.NODE_ENV !== 'production',
      },
    );
    ws.onmessage = onMessage;
    ws.onopen = () => ws.send('{"type":"page","payload":"afk"}');
    window.wsObject = ws;
    return () => ws.close();
  }, []);

  useEffect(() => {
    if (window.wsObject) {
      window.wsObject.onmessage = onMessage;
    }
  }, [onMessage]);
};
