import React from 'react';
import classNames from 'classnames';

import { IconButton } from 'components/buttons';
import urlIcon from 'assets/icons/url.svg';
import downloadIcon from 'assets/icons/download.svg';
import trashIcon from 'assets/icons/trash.svg';

import './style.scss';

const MiniatureItem = ({
  preview,
  fileName,
  format,
  size,
  weight,
  copyInClipboard,
  saveAs,
  deleteAsset,
  fileLinkId,
  type,
  selected,
  onSelectItem,
  mediaType,
  onDoubleClick,
  fileUrl,
}) => {
  const iconButtonStyle = {
    width: 20,
    height: 22,
  };

  const itemClass = classNames({
    'mkit-media-miniature-block__item': true,
    [`mkit-media-miniature-block__item_${type}`]: type,
    'mkit-media-miniature-block__item_selected': selected,
    'mkit-media-miniature-block__item_border': mediaType !== 'Image' && mediaType !== 'Video',
  });

  const onCopyUrl = (e) => {
    e.stopPropagation();
    copyInClipboard(fileUrl);
  };
  const onDownload = (e) => {
    e.stopPropagation();
    saveAs(fileUrl, fileName);
  };
  const onDelete = (e) => {
    e.stopPropagation();
    deleteAsset(fileLinkId);
  };

  return (
    <div
      className={itemClass}
      onClick={onSelectItem}
      onDoubleClick={onDoubleClick}
      role="presentation"
    >
      <img src={preview} alt="" className="mkit-media-miniature-block__item__image" />
      <div className="mkit-media-miniature-block__item__control-wrapper">
        <span className="mkit-media-miniature-block__item__control-wrapper__text">
          {fileName}
        </span>
        <span className="mkit-media-miniature-block__item__control-wrapper__text">
          {format}
        </span>
        {
          (mediaType === 'Image' || mediaType === 'Video') && (
            <span className="mkit-media-miniature-block__item__control-wrapper__text">
              {size}
            </span>
          )
        }
        <span className="mkit-media-miniature-block__item__control-wrapper__text">
          {weight}
        </span>
        <div className="mkit-media-miniature-block__item__control-wrapper__buttons-wrapper">
          <IconButton
            onClick={onCopyUrl}
            icon={urlIcon}
            style={{ ...iconButtonStyle, marginRight: 15 }}
            filterType="only-white"
          />
          <IconButton
            onClick={onDownload}
            icon={downloadIcon}
            style={{ ...iconButtonStyle, marginRight: 15 }}
            filterType="only-white"
          />
          <IconButton
            onClick={onDelete}
            icon={trashIcon}
            style={iconButtonStyle}
            filterType="only-white"
          />
        </div>
      </div>
    </div>
  );
};

export default MiniatureItem;
