import endpoints from 'const/endpoints';

export default body => ({
  url: endpoints.getCreateGroupUrl(),
  queryKey: endpoints.getCreateGroupUrl(),
  body,
  options: {
    method: 'POST',
  },
  meta: {
    authToken: true,
  },
  // transform: response => ({ senderGroups: response }),
  // update: {
  //   senderGroups: (oldValue = [], newValue) => [...oldValue, newValue],
  // },
});
