import React from 'react';
import { useKey } from 'react-use';
import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal';
import { BasicButton } from 'components/buttons';
import crossIcon from 'assets/icons/cross-grey.svg';
import deleteIcon from 'assets/icons/delete.svg';

import './style.scss';

const DeleteWarningModal = ({
  closeModal,
  onDelete,
  descriptionKey,
  description,
  isLoading,
  many,
}) => {
  const { t } = useTranslation();

  useKey('Enter', onDelete);
  useKey('Escape', closeModal);

  return (
    <Modal
      onClose={closeModal}
      contentStyle={{
        minHeight: '10vh',
        minWidth: '30vw',
      }}
      withWarning
    >
      <div className="delete-warning-modal__description">
        {many ? (
          description || t('TOAST.MANY_DELETE_CONFIRM', { descriptionKey })
        ) : (
          description || t('TOAST.DELETE_CONFIRM', { descriptionKey })
        )}
      </div>
      <div className="delete-warning-modal__buttons">
        <BasicButton
          text={t('CONTROLS.DELETE')}
          type={BasicButton.types.WARN}
          icon={deleteIcon}
          onClick={onDelete}
          loading={isLoading}
        />
        <BasicButton
          text={t('CONTROLS.CANCEL')}
          type={BasicButton.types.CANCEL}
          icon={crossIcon}
          onClick={closeModal}
          isNewStyle
        />
      </div>
    </Modal>
  );
};

export default DeleteWarningModal;
