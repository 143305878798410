/*eslint-disable */
import React, { useMemo } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { DELIVERY_CONDITIONS_TITLE } from 'const';
import { convertMomentTimeToStringTime } from 'helpers';
import closeIcon from 'assets/icons/cross.svg';
import './CampaignChannel.scss';

export default (props) => {
  const {
    channelType,
    img,
    deliveryCondition,
    timeout,
    onClick,
    onClose,
  } = props;
  const containerClassName = classNames('campaign-create-channel', `campaign-create-channel--${channelType}`);
  const timeStr = useMemo(() => {
    const time = moment('00:00:00', 'HH:mm:ss');
    time.set('seconds', timeout);
    return convertMomentTimeToStringTime(time);
  }, [timeout]);
  return (
    <div className={containerClassName}>
      {onClose && (
        <div className="campaign-create-channel__remove" onClick={onClose}>
          <img src={closeIcon} alt="" />
        </div>
      )}
      <div
        className="campaign-create-channel__content-wrapper"
        onClick={() => {
          if (onClick) {
            onClick(props);
          }
        }}
      >
        <div className="campaign-create-channel__arrow" />
        <div className="campaign-create-channel__img" style={{ backgroundImage: `url(${img})` }} />
        <div className="campaign-create-channel__content">
          <div>{DELIVERY_CONDITIONS_TITLE[deliveryCondition] || deliveryCondition}</div>
          <div>{timeStr}</div>
        </div>
      </div>
    </div>
  );
};
