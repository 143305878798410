import React from 'react';
import moment from 'moment';

import checkIcon from 'assets/icons/check-green.svg';
import crossIcon from 'assets/icons/red-cross.svg';
import getChannelIcon from 'helpers/getChannelIcon';

import invStatus from './inviteStatus';
import { CONDITION_TYPES } from './scenario';

const formatDate = (value, format = 'DD.MM.YYYY') => (value ? moment(value).format(format) : '');
const parseDate = ({ value }) => formatDate(value);
const parseDateTime = ({ value }) => formatDate(value, 'DD.MM.YYYY HH:mm:ss');
const getChannelDistribution = value =>
  value.map(item => `${item.perStatusCount.sent} ${item.channel}`).join(' - ');
const getBooleanIcon = value => (value ? checkIcon : crossIcon);

const ASSETS_COLUMNS = t => [
  {
    headerName: t('INSTANCES.ASSETS.FILENAME'),
    field: 'fileName',
    colId: 'fileName',
    flex: 1,
    filter: 'maskColumnFilter',
    sortable: true,
    headerComponentParams: {
      withoutEmpty: true,
    },
  },
  {
    headerName: t('INSTANCES.ASSETS.MEDIA_TYPE'),
    field: 'mediaType',
    colId: 'mediaType',
    flex: 1,
    sortable: true,
    filter: 'listColumnFilter',
  },
  {
    headerName: t('INSTANCES.ASSETS.FORMAT'),
    field: 'format',
    colId: 'format',
    sortable: true,
    filter: 'listColumnFilter',
    flex: 1,
  },
  {
    headerName: t('INSTANCES.ASSETS.SIZE'),
    sortable: true,
    field: 'resolution',
    colId: 'resolution',
    flex: 1,
  },
  {
    headerName: t('INSTANCES.ASSETS.WEIGHT'),
    sortable: true,
    field: 'weight',
    colId: 'weight',
    flex: 1,
  },
  {
    headerName: t('INSTANCES.ASSETS.DATE'),
    filter: 'dateColumnFilter',
    sortable: true,
    headerComponentParams: {
      withoutEmpty: true,
    },
    field: 'date',
    colId: 'date',
    flex: 1,
  },
];

const SENDERS_COLUMNS = t => [
  {
    headerName: t('INSTANCES.SENDERS.ID'),
    field: 'displayName',
    flex: 1,
  },
  {
    headerName: t('INSTANCES.CHANNEL'),
    field: 'channel',
    cellRenderer: 'cellRendererImage',
    flex: 1,
    valueFormatter: ({ value }) => getChannelIcon(value),
    cellRendererParams: {
      style: { width: 30, height: 30 },
      supportLightTheme: true,
      lightThemeIcon: (value, isDark) => getChannelIcon(value, isDark),
    },
  },
  {
    headerName: t('INSTANCES.COUNTRY'),
    field: 'countryList',
    flex: 1,
  },
  {
    headerName: t('INSTANCES.STATUS'),
    field: 'isActive',
    cellRenderer: 'cellRendererStatus',
    flex: 1,
    valueFormatter: ({ value }) => (value ? t('INSTANCES.ACTIVE') : t('INSTANCES.SENDERS.INACTIVE')),
  },
  {
    headerName: t('INSTANCES.SENDERS.REGISTERED_FROM'),
    field: 'effectiveFrom',
    flex: 1,
    valueFormatter: parseDateTime,
  },
  {
    headerName: t('INSTANCES.SENDERS.REGISTERED_TILL'),
    field: 'effectiveTill',
    flex: 1,
    valueFormatter: parseDateTime,
  },
  {
    headerName: t('INSTANCES.SENDERS.RATE_PLAN'),
    field: 'ratePlanName',
    flex: 1,
  },
  {
    headerName: t('INSTANCES.SENDERS.KEYWORD'),
    field: 'keyword',
    flex: 1,
  },
  {
    headerName: t('INSTANCES.SENDERS.GUID'),
    field: 'extGuid',
    flex: 1,
  },
  {
    headerName: t('INSTANCES.SENDERS.WEBHOOK'),
    field: 'webhookUrl',
    flex: 1,
  },
];

const SENDER_GROUPS_COLUMNS = t => [
  {
    headerName: t('INSTANCES.SENDER_GROUPS.ID'),
    field: 'id',
    flex: 1,
  },
  {
    headerName: t('INSTANCES.SENDER_GROUPS.NAME'),
    field: 'name',
    flex: 1,
  },
  {
    headerName: t('INSTANCES.SENDER_GROUPS.CHANNELS'),
    field: 'channels',
    cellRenderer: 'cellRendererImageArray',
    flex: 1,
    valueFormatter: ({ value }) => value.map(item => getChannelIcon(item)),
    cellRendererParams: {
      style: { width: 30, height: 30 },
      supportLightTheme: true,
      lightThemeIcon: (value, isDark) => getChannelIcon(value, isDark),
    },
  },
  {
    headerName: t('INSTANCES.LOGIN'),
    field: 'login',
    flex: 1,
  },
  {
    headerName: t('INSTANCES.API_KEY'),
    field: 'apiKey',
    flex: 1,
  },
  {
    headerName: t('INSTANCES.SENDER_GROUPS.WEBHOOK'),
    field: 'webhookUrl',
    flex: 1,
  },
];

const TEMPLATES_COLUMNS = (t, isDarkTheme) => [
  {
    headerName: t('INSTANCES.TEMPLATES.NAME'),
    field: 'name',
    flex: 1,
    sortable: true,
    filter: 'maskColumnFilter',
  },
  {
    headerName: t('INSTANCES.CHANNEL'),
    field: 'channel',
    cellRenderer: 'cellRendererImage',
    flex: 1,
    valueFormatter: ({ value }) => getChannelIcon(value),
    cellRendererParams: {
      style: { width: 30, height: 30 },
      supportLightTheme: true,
      lightThemeIcon: (value, isDark) => getChannelIcon(value, isDark),
    },
    filter: 'listColumnFilter',
    headerComponentParams: {
      items: [
        {
          id: 1,
          title: 'SMS',
          icon: getChannelIcon(1, isDarkTheme),
        },
        {
          id: 2,
          title: 'RCS',
          icon: getChannelIcon(2, isDarkTheme),
        },
        {
          id: 3,
          title: 'Viber',
          icon: getChannelIcon(3, isDarkTheme),
        },
        {
          id: 4,
          title: 'WhatsApp',
          icon: getChannelIcon(4, isDarkTheme),
        },
      ],
    },
  },
  {
    headerName: t('INSTANCES.TYPE'),
    field: 'type',
    flex: 1,
    filter: 'listColumnFilter',
    headerComponentParams: {
      items: [
        {
          id: 'Richcard',
          title: 'Richcard',
        },
        {
          id: 'Carousel',
          title: 'Carousel',
        },
        {
          id: 'File',
          title: 'File',
        },
        {
          id: 'Text',
          title: 'Text',
        },
      ],
    },
  },
  {
    headerName: t('INSTANCES.TEMPLATES.LAST_MODIFIED'),
    field: 'updatedAt',
    flex: 1,
    valueFormatter: parseDateTime,
    sortable: true,
    filter: 'dateColumnFilter',
  },
];

const RATE_PLANS_COLUMNS = t => [
  {
    field: 'countryName',
    headerName: t('INSTANCES.COUNTRY'),
    filter: 'maskColumnFilter',
    sortable: true,
    flex: 1,
  },
  {
    field: 'netName',
    headerName: t('INSTANCES.NETWORK'),
    filter: 'maskColumnFilter',
    sortable: true,
    flex: 1,
  },
  {
    field: 'mccMnc',
    headerName: t('INSTANCES.RATES.MCCMNC'),
    filter: 'numberColumnFilter',
    sortable: true,
    flex: 1,
  },
  {
    field: 'rate',
    headerName: t('INSTANCES.RATE'),
    sortable: true,
    cellRenderer: 'cellRendererRates',
  },
  {
    field: 'currencyCode',
    headerName: t('INSTANCES.RATES.CURRENCY_CODE'),
    sortable: true,
    flex: 1,
  },
  {
    field: 'effectiveFrom',
    headerName: t('INSTANCES.RATES.FROM'),
    filter: 'dateColumnFilter',
    sortable: true,
    valueFormatter: parseDateTime,
    flex: 1,
  },
  {
    field: 'effectiveTill',
    headerName: t('INSTANCES.RATES.TILL'),
    filter: 'dateColumnFilter',
    sortable: true,
    valueFormatter: parseDateTime,
    flex: 1,
  },
];

const RCS_RATE_PLANS_COLUMNS = t => [
  {
    field: 'entity',
    headerName: t('INSTANCES.ENTITY'),
    sortable: true,
    flex: 1,
    valueFormatter: ({ value }) => {
      switch (value) {
        case 0: return t('INSTANCES.RATES.UNIQUE_USERS');
        case 1: return t('INSTANCES.MESSAGE');
        case 2: return t('INSTANCES.RATES.SESSION');
        default: return '';
      }
    },
    headerComponentParams: {
      items: [
        {
          id: 0,
          title: t('INSTANCES.RATES.UNIQUE_USERS'),
        },
        {
          id: 1,
          title: t('INSTANCES.MESSAGE'),
        },
        {
          id: 2,
          title: t('INSTANCES.RATES.SESSION'),
        },
      ],
    },
    filter: 'listColumnFilter',
  },
  {
    field: 'direction',
    headerName: t('INSTANCES.RATES.DIRECTION'),
    sortable: true,
    flex: 1,
    valueFormatter: ({ value }) => {
      switch (value) {
        case 1: return 'MT';
        case 2: return 'MO';
        default: return t('INSTANCES.RATES.ANY');
      }
    },
    headerComponentParams: {
      items: [
        {
          id: 1,
          title: 'MT',
        },
        {
          id: 2,
          title: 'MO',
        },
        {
          id: 'null',
          title: t('INSTANCES.RATES.ANY'),
        },
      ],
    },
    filter: 'listColumnFilter',
  },
  {
    field: 'messageType',
    headerName: t('INSTANCES.MESSAGE_TYPE'),
    sortable: true,
    flex: 1,
    valueFormatter: ({ value }) => {
      switch (value) {
        case 1: return t('INSTANCES.RATES.TEXT');
        case 2: return t('INSTANCES.FILE');
        case 3: return t('INSTANCES.RATES.AUDIO');
        case 4: return t('INSTANCES.GEOLOCATION');
        case 5: return t('INSTANCES.RATES.RICH_CARD');
        case 6: return t('INSTANCES.RATES.ANY_MESSAGE');
        default: return t('INSTANCES.RATES.ANY');
      }
    },
    headerComponentParams: {
      items: [
        {
          id: 1,
          title: t('INSTANCES.RATES.TEXT'),
        },
        {
          id: 2,
          title: t('INSTANCES.FILE'),
        },
        {
          id: 3,
          title: t('INSTANCES.RATES.AUDIO'),
        },
        {
          id: 4,
          title: t('INSTANCES.GEOLOCATION'),
        },
        {
          id: 5,
          title: t('INSTANCES.RATES.RICH_CARD'),
        },
        {
          id: 6,
          title: t('INSTANCES.RATES.ANY_MESSAGE'),
        },
        {
          id: 'null',
          title: t('INSTANCES.RATES.ANY'),
        },
      ],
    },
    filter: 'listColumnFilter',
  },
  {
    field: 'messageStatus',
    headerName: t('INSTANCES.RATES.MESSAGE_STATUS'),
    valueFormatter: () => t('INSTANCES.RATES.ANY'),
    flex: 1,
  },
  {
    field: 'rateNetName',
    headerName: t('INSTANCES.NETWORK'),
    filter: 'maskColumnFilter',
    sortable: true,
    flex: 1,
  },
  {
    field: 'mccMnc',
    headerName: t('INSTANCES.RATES.MCCMNC'),
    filter: 'maskColumnFilter',
    sortable: true,
    flex: 1,
  },
  {
    field: 'rate',
    headerName: t('INSTANCES.RATE'),
    filter: 'numberColumnFilter',
    sortable: true,
    flex: 1,
  },
  {
    field: 'startDate',
    headerName: t('INSTANCES.RATES.FROM'),
    filter: 'dateColumnFilter',
    sortable: true,
    valueFormatter: parseDateTime,
    flex: 1,
  },
  {
    field: 'endDate',
    headerName: t('INSTANCES.RATES.TILL'),
    filter: 'dateColumnFilter',
    sortable: true,
    valueFormatter: parseDateTime,
    flex: 1,
  },
];

const EDR_COLUMNS = t => [
  {
    field: 'time',
    headerName: t('INSTANCES.SENT'),
    valueFormatter: parseDateTime,
    filter: 'dateColumnFilter',
    sortable: true,
    flex: 1,
  },
  {
    field: 'messageId',
    headerName: t('INSTANCES.EDR.MESSAGE_ID'),
    sortable: true,
    flex: 1,
    filter: 'maskColumnFilter',
  },
  {
    field: 'sessionId',
    headerName: t('INSTANCES.EDR.SESSION_ID'),
    sortable: true,
    filter: 'maskColumnFilter',
    flex: 1,
  },
  {
    field: 'accountName',
    headerName: t('INSTANCES.EDR.ACCOUNT_NAME'),
    sortable: true,
    filter: 'maskColumnFilter',
    flex: 1,
  },
  {
    field: 'channelType',
    headerName: t('INSTANCES.CHANNEL'),
    sortable: true,
    flex: 1,
    valueFormatter: ({ value }) => {
      switch (value) {
        case 1: return 'SMS';
        case 2: return 'RCS';
        case 3: return 'Viber';
        case 4: return 'WhatsApp';
        default: return '';
      }
    },
    filter: 'listColumnFilter',
    headerComponentParams: {
      items: [
        {
          id: 1,
          title: 'SMS',
        },
        {
          id: 2,
          title: 'RCS',
        },
        {
          id: 3,
          title: 'Viber',
        },
        {
          id: 4,
          title: 'WhatsApp',
        },
      ],
    },
  },
  {
    field: 'messageType',
    headerName: t('INSTANCES.TYPE'),
    sortable: true,
    withFilter: true,
    filter: 'listColumnFilter',
    valueFormatter: ({ value }) => {
      switch (value) {
        case 1: return 'MT';
        case 2: return 'MO';
        default: return '';
      }
    },
    headerComponentParams: {
      items: [
        {
          id: 1,
          title: 'MT',
        },
        {
          id: 2,
          title: 'MO',
        },
      ],
    },
    flex: 1,
  },
  {
    field: 'mdnStatus',
    headerName: t('INSTANCES.EDR.MDN_STATUS'),
    sortable: true,
    filter: 'listColumnFilter',
    headerComponentParams: {
      items: [
        {
          id: 'sent',
          title: 'Sent',
        },
        {
          id: 'seen',
          title: 'Seen',
        },
        {
          id: 'delivered',
          title: 'Delivered',
        },
        {
          id: 'failed-sending',
          title: 'Failed Sending',
        },
      ],
    },
    flex: 1,
  },
  {
    field: 'senderId',
    headerName: t('INSTANCES.SENDER'),
    sortable: true,
    filter: 'maskColumnFilter',
    flex: 1,
  },
  {
    field: 'incomingDestNumber',
    headerName: t('INSTANCES.EDR.RECIPIENT'),
    sortable: true,
    filter: 'maskColumnFilter',
    flex: 1,
  },
  {
    field: 'country',
    headerName: t('INSTANCES.COUNTRY'),
    sortable: true,
    filter: 'maskColumnFilter',
    flex: 1,
  },
  {
    field: 'network',
    headerName: t('INSTANCES.NETWORK'),
    sortable: true,
    filter: 'maskColumnFilter',
    flex: 1,
  },
  {
    field: 'broadcast',
    headerName: t('INSTANCES.BROADCAST'),
    sortable: true,
    filter: 'maskColumnFilter',
    flex: 1,
  },
  {
    field: 'body',
    headerName: t('INSTANCES.BODY'),
    sortable: true,
    filter: 'maskColumnFilter',
    flex: 1,
  },
  {
    field: 'body',
    headerName: t('INSTANCES.EDR.CONSTRUCTOR'),
    cellRenderer: 'cellRendererEdrBody',
    valueFormatter: ({ data }) => data?.body,
    minWidth: 160,
    flex: 1,
  },
  {
    field: 'rate',
    headerName: t('INSTANCES.RATE'),
    sortable: true,
    filter: 'numberColumnFilter',
    flex: 1,
  },
  {
    field: 'finalCost',
    headerName: t('INSTANCES.EDR.FINAL_COST'),
    sortable: true,
    filter: 'numberColumnFilter',
    flex: 1,
  },
  {
    field: 'isBillable',
    headerName: t('INSTANCES.EDR.IS_BILLABLE'),
    sortable: true,
    filter: 'listColumnFilter',
    headerComponentParams: {
      items: [
        {
          id: 'true',
          title: 'True',
        },
        {
          id: 'false',
          title: 'False',
        },
      ],
      withoutEmpty: true,
    },
    flex: 1,
  },
];

const INVOICES_COLUMNS = t => [
  {
    field: 'invType',
    headerName: t('INSTANCES.TYPE'),
    flex: 1,
    valueFormatter: ({ data }) => {
      if (data.invDueAmount >= 0) {
        return t('INSTANCES.INVOICES.INVOICE');
      }
      return t('INSTANCES.INVOICES.CREDIT_NOTE');
    },
    sortable: true,
    filter: 'listColumnFilter',
    headerComponentParams: {
      items: [
        {
          id: 'invoice',
          title: t('INSTANCES.INVOICES.INVOICE'),
        },
        {
          id: 'creditNote',
          title: t('INSTANCES.INVOICES.CREDIT_NOTE'),
        },
      ],
    },
    resizable: true,
  },
  {
    field: 'invRefCode',
    headerName: t('INSTANCES.INVOICES.REFERENCE_NUMBER'),
    flex: 1,
    sortable: true,
    filter: 'maskColumnFilter',
    resizable: true,
  },
  {
    field: 'invIssueDate',
    headerName: t('INSTANCES.DATE'),
    flex: 1,
    valueFormatter: parseDate,
    sortable: true,
    filter: 'dateColumnFilter',
    resizable: true,
  },
  {
    field: 'invBillingPeriod',
    headerName: t('INSTANCES.INVOICES.BILLING_PERIOD'),
    flex: 1,
    valueFormatter: ({ data }) =>
      `${formatDate(data.invStartDate)} - ${formatDate(data.invEndDate)}`,
    filter: 'dateColumnFilter',
    headerComponentParams: {
      withoutEmpty: true,
      onlyRange: true,
    },
    resizable: true,
  },
  {
    field: 'invDueAmount',
    headerName: t('INSTANCES.AMOUNT'),
    sortable: true,
    filter: 'numberColumnFilter',
    resizable: true,
  },
  {
    field: 'invCurrencyCode',
    headerName: t('INSTANCES.CURRENCY'),
    flex: 1,
    sortable: true,
    resizable: true,
  },
  {
    field: 'invDueDate',
    headerName: t('INSTANCES.INVOICES.DUE_DATE'),
    valueFormatter: parseDate,
    flex: 1,
    sortable: true,
    filter: 'dateColumnFilter',
    resizable: true,
  },
  {
    field: 'invStatusId',
    headerName: t('INSTANCES.STATUS'),
    valueFormatter: ({ value }) => invStatus[value],
    flex: 1,
    sortable: true,
    filter: 'listColumnFilter',
    headerComponentParams: {
      items: Object.keys(invStatus).map(id => ({ id, title: invStatus[id] })),
    },
    resizable: true,
  },
  {
    field: 'unpaidAmount',
    headerName: t('INSTANCES.INVOICES.UNPAID_AMOUNT'),
    flex: 1,
    sortable: true,
    filter: 'numberColumnFilter',
    resizable: true,
  },
  // {
  //   dataIndex: 'pdf',
  //   type: 'string',
  //   title: 'PDF',
  // },
  // {
  //   dataIndex: 'usageDetails',
  //   type: 'string',
  //   title: 'Usage Details',
  // },
];

const PAYMENTS_COLUMNS = t => [
  {
    field: 'referenceCode',
    headerName: t('INSTANCES.PAYMENTS.REFERENCE_NUMBER'),
    flex: 1,
    sortable: true,
    filter: 'maskColumnFilter',
  },
  {
    field: 'createdAt',
    headerName: t('INSTANCES.DATE'),
    valueFormatter: parseDateTime,
    flex: 1,
    sortable: true,
    filter: 'dateColumnFilter',
  },
  {
    field: 'date',
    headerName: t('INSTANCES.PAYMENTS.PAY_DAY'),
    valueFormatter: parseDateTime,
    flex: 1,
    sortable: true,
    filter: 'dateColumnFilter',
  },
  {
    field: 'paymentCurrencyAmount',
    headerName: t('INSTANCES.AMOUNT'),
    flex: 1,
    sortable: true,
    filter: 'numberColumnFilter',
  },
  {
    field: 'paymentCurrencyCode',
    headerName: t('INSTANCES.CURRENCY'),
    flex: 1,
    sortable: true,
  },
];

// const CAMPAIGNS_COLUMNS = [
//   {
//     title: 'Campaign Name',
//     dataIndex: 'name',
//
//     // flex: 1,
//     // sortable: true,
//     // filter: 'maskColumnFilter',
//     // headerComponentParams: {
//     //   withoutEmpty: true,
//     // },
//   },
//   {
//     title: 'Status',
//     dataIndex: 'status',
//
//     // flex: 1,
//     // filter: 'dateColumnFilter',
//     // sortable: true,
//     // valueFormatter: parseDateTime,
//     // headerComponentParams: {
//     //   withoutEmpty: true,
//     // },
//   },
//   {
//     title: 'Recepients',
//     onCell: () => ({ cellRenderer: 'cellRefresh' }),
//     // dataIndex: 'createdAt',
//   },
//   {
//     title: '(Aproximate) Cost, $',
//     // dataIndex: 'createdAt',
//   },
//   {
//     title: 'Launch Time',
//     dataIndex: 'createdAt',
//     render: value => ({ children: parseDateTime({ value }) }),
//   },
//   {
//     title: 'Avg. Steps Conversion',
//     // dataIndex: 'createdAt',
//   },
//   {
//     title: 'Channel Distribution',
//     // dataIndex: 'createdAt',
//   },
// ];

const CAMPAIGNS_COLUMNS = t => [
  {
    title: t('INSTANCES.CAMPAIGNS.BROADCAST_NAME'),
    dataIndex: 'name',
    width: 250,
    sortable: true,
    filter: 'maskColumnFilter',
    headerComponentParams: {
      withoutEmpty: true,
    },
    // ellipsis: true,

    // flex: 1,
    // sortable: true,
    // filter: 'maskColumnFilter',
    // headerComponentParams: {
    //   withoutEmpty: true,
    // },
  },
  {
    title: t('INSTANCES.CAMPAIGNS.PROGRESS'),
    dataIndex: 'progress',
    width: 262,
  },
  {
    title: t('INSTANCES.TYPE'),
    dataIndex: 'type',
  },
  {
    title: t('INSTANCES.STATUS'),
    dataIndex: 'status',
  },
  {
    title: t('INSTANCES.CAMPAIGNS.RECIPIENTS'),
    dataIndex: 'recipients',
    render: (value, row) => ({
      children: (
        <>
          {value}
          {row.children && (
            <sup className="ant-table-cell-sup">
              {t('NEW_TRANSLATES.SCREENS.BROADCASTS.STATUSES_BROADCASTS.LAST_RUN')}
            </sup>
          )}
        </>
      ),
    }),
  },
  // {
  //   title: '(Aproximate) Cost, $',
  //   dataIndex: 'temp-2',
  //   // dataIndex: 'createdAt',
  // },
  {
    title: t('INSTANCES.CAMPAIGNS.LAUNCH_TIME'),
    dataIndex: 'dtStartExecution',
    // sortable: true,
    filter: 'dateColumnFilter',
    headerComponentParams: {
      withoutEmpty: true,
    },
    render: (value, row) => ({
      children: (
        <>
          {parseDateTime({ value })}
          {row.children && (
            <sup className="ant-table-cell-sup">
              {t('NEW_TRANSLATES.SCREENS.BROADCASTS.STATUSES_BROADCASTS.LAST_RUN')}
            </sup>
          )}
        </>
      ),
    }),
  },
  // {
  //   title: 'Avg. Steps Conversion',
  //   dataIndex: 'temp-3',
  //   // dataIndex: 'createdAt',
  // },
  {
    title: t('INSTANCES.CAMPAIGNS.CHANNEL_ENGAGEMENT'),
    dataIndex: 'channelDistribution',
    render: (value, row) => ({
      children: (
        <>
          {getChannelDistribution(value)}
          {row.children && (
            <sup className="ant-table-cell-sup">
              {t('NEW_TRANSLATES.SCREENS.BROADCASTS.STATUSES_BROADCASTS.IN_TOTAL')}
            </sup>
          )}
        </>
      ),
    }),
  },
];

const CAMPAIGN_LAUNCHES_COLUMNS = [
  {
    title: 'Progress',
    dataIndex: 'progress',
    width: 315,
  },
  {
    title: 'Type',
    dataIndex: 'type',
  },
  {
    title: 'Status',
    dataIndex: 'status',
  },

  {
    title: 'Recepients',
    dataIndex: 'recipients',
  },
  // {
  //   headerName: '(Aproximate) Cost, $',
  //   field: 'temp-2',
  //   // dataIndex: 'createdAt',
  // },
  {
    title: 'Launch Time',
    dataIndex: 'dtStartExecution',
    render: value =>
      ({ children: parseDateTime({ value }) }),
  },
  // {
  //   headerName: 'Avg. Steps Conversion',
  //   field: 'temp-3',
  //   // dataIndex: 'createdAt',
  // },
  {
    title: 'Channel Engagement',
    dataIndex: 'channelDistribution',
    render: value => ({ children: getChannelDistribution(value) }),
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
  },
];

const SCENARIOS_COLUMNS = (t, isDarkTheme) => [
  {
    field: 'channel',
    headerName: t('INSTANCES.CHANNEL'),
    cellRenderer: 'cellRendererImage',
    width: 110,
    valueFormatter: ({ value }) => getChannelIcon(value),
    cellRendererParams: {
      style: { width: 30, height: 30 },
      supportLightTheme: true,
      lightThemeIcon: (value, isDark) => getChannelIcon(value, isDark),
    },
    filter: 'listColumnFilter',
    headerComponentParams: {
      withoutEmpty: true,
      items: [
        {
          id: 1,
          title: 'SMS',
          icon: getChannelIcon(1, isDarkTheme),
        },
        {
          id: 2,
          title: 'RCS',
          icon: getChannelIcon(2, isDarkTheme),
        },
        {
          id: 3,
          title: 'Viber',
          icon: getChannelIcon(3, isDarkTheme),
        },
        {
          id: 4,
          title: 'WhatsApp',
          icon: getChannelIcon(4, isDarkTheme),
        },
      ],
    },
  },
  {
    field: 'name',
    headerName: t('NEW_TRANSLATES.INSTANCES.NAME'),
    flex: 1,
    sortable: true,
    filter: 'maskColumnFilter',
  },
  // {
  //   field: 'campaignName',
  //   headerName: t('INSTANCES.CAMPAIGN'),
  //   flex: 1,
  // },
  // {
  //   field: 'topicName',
  //   headerName: t('INSTANCES.TOPIC'),
  //   flex: 1,
  // },
  {
    field: 'triggerCondition',
    headerName: t('INSTANCES.SCENARIOS.TRIGGER'),
    valueFormatter: ({ value }) => {
      if (value && value.length) {
        return `${value[0].condition} ${value.length > 1 ? '...' : ''}`;
      }

      return '';
    },
    tooltip: ({ value }) => {
      if (value && value.length > 1) {
        return value.map(({ condition, conditionType }) => `${CONDITION_TYPES[conditionType]}: ${condition}`).join('\n');
      }

      return null;
    },
    flex: 1,
    sortable: true,
    filter: 'maskColumnFilter',
  },
  {
    field: 'resetCondition',
    headerName: t('INSTANCES.SCENARIOS.RESET'),
    valueFormatter: ({ value }) => {
      if (value && value.length) {
        return `${value[0].condition} ${value.length > 1 ? '...' : ''}`;
      }

      return '';
    },
    tooltip: ({ value }) => {
      if (value && value.length > 1) {
        return value.map(({ condition, conditionType }) => `${CONDITION_TYPES[conditionType]}: ${condition}`).join('\n');
      }

      return null;
    },
    flex: 1,
    sortable: true,
    filter: 'maskColumnFilter',
  },
  {
    field: 'stopCondition',
    headerName: t('INSTANCES.SCENARIOS.STOP'),
    valueFormatter: ({ value }) => {
      if (value && value.length) {
        return `${value[0].condition} ${value.length > 1 ? '...' : ''}`;
      }

      return '';
    },
    tooltip: ({ value }) => {
      if (value && value.length > 1) {
        return value.map(({ condition, conditionType }) => `${CONDITION_TYPES[conditionType]}: ${condition}`).join('\n');
      }

      return null;
    },
    flex: 1,
    sortable: true,
    filter: 'maskColumnFilter',
  },
  {
    field: 'optOutCondition',
    headerName: t('INSTANCES.SCENARIOS.OPT_OUT'),
    valueFormatter: ({ value }) => {
      if (value && value.length) {
        return `${value[0].condition} ${value.length > 1 ? '...' : ''}`;
      }

      return '';
    },
    tooltip: ({ value }) => {
      if (value && value.length > 1) {
        return value.map(({ condition, conditionType }) => `${CONDITION_TYPES[conditionType]}: ${condition}`).join('\n');
      }

      return null;
    },
    flex: 1,
    sortable: true,
    filter: 'maskColumnFilter',
  },
  {
    field: 'isDefault',
    headerName: t('INSTANCES.IS_DEFAULT'),
    cellRenderer: 'cellRendererImage',
    valueFormatter: ({ value }) => getBooleanIcon(value),
    width: 80,
  },
];

const USERS_COLUMNS = t => [
  {
    field: 'firstName',
    headerName: t('INSTANCES.FIRST_NAME'),
    flex: 1,
    sortable: true,
    filter: 'maskColumnFilter',
  },
  {
    field: 'middleName',
    headerName: t('INSTANCES.MIDDLE_NAME'),
    flex: 1,
    sortable: true,
    filter: 'maskColumnFilter',
  },
  {
    field: 'lastName',
    headerName: t('INSTANCES.LAST_NAME'),
    flex: 1,
    sortable: true,
    filter: 'maskColumnFilter',
  },
  {
    field: 'username',
    headerName: t('INSTANCES.LOGIN'),
    flex: 1,
    sortable: true,
    filter: 'maskColumnFilter',
  },
  {
    field: 'email',
    headerName: t('INSTANCES.USERS.EMAIL'),
    flex: 1,
    sortable: true,
    filter: 'maskColumnFilter',
  },
  {
    field: 'phoneNumber',
    headerName: t('INSTANCES.CONTACT_PHONE'),
    flex: 1,
    sortable: true,
    filter: 'maskColumnFilter',
  },
  {
    field: 'createdAt',
    headerName: t('INSTANCES.USERS.CREATED_AT'),
    valueFormatter: parseDateTime,
    flex: 1,
    sortable: true,
    filter: 'dateColumnFilter',
  },
  {
    field: 'updatedAt',
    headerName: t('INSTANCES.USERS.UPDATED_AT'),
    valueFormatter: parseDateTime,
    flex: 1,
    sortable: true,
    filter: 'dateColumnFilter',
  },
];

const AUDIT_COLUMNS = t => [
  {
    field: 'time',
    headerName: t('INSTANCES.AUDIT.DATE_TIME'),
    flex: 1,
    valueFormatter: parseDateTime,
    filter: 'dateColumnFilter',
    sortable: true,
    headerComponentParams: {
      withoutEmpty: true,
    },
  },
  {
    field: 'login',
    headerName: t('INSTANCES.AUDIT.USER'),
    flex: 1,
    filter: 'maskColumnFilter',
    sortable: true,
    headerComponentParams: {
      withoutEmpty: true,
    },
  },
  {
    field: 'operation',
    headerName: t('INSTANCES.AUDIT.LOGGED_ACTION'),
    flex: 1,
    filter: 'operationsColumnFilter',
    sortable: true,
    headerComponentParams: {
      withoutEmpty: true,
    },
    cellRenderer: 'cellRendererOperation',
    valueFormatter: ({ data }) => {
      const { operation, rowCount } = data;
      let entriesCount = 0;

      if (rowCount) {
        entriesCount = Object.values(rowCount).reduce((acc, item) => {
          acc += item;
          return acc;
        }, 0);
      }

      return { operation: t(`SCREENS.AUDIT.${operation}`), entriesCount: `${entriesCount} ${t('SCREENS.AUDIT.ENTRIES')}` };
    },
  },
  // {
  //   field: 'entity',
  //   headerName: t('INSTANCES.ENTITY'),
  //   flex: 1,
  // },
  // {
  //   field: 'actionType',
  //   headerName: t('INSTANCES.AUDIT.ACTION_TYPE'),
  //   flex: 1,
  // },
  // {
  //   field: 'actionName',
  //   headerName: t('INSTANCES.AUDIT.ACTION_NAME'),
  //   flex: 1,
  // },
];

const WHATS_APP_TEMPLATES_COLUMNS = t => [
  {
    field: 'registeredName',
    headerName: t('INSTANCES.WHATS_APP_TEMPLATES.REGISTERED_NAME'),
    flex: 1,
    sortable: true,
    filter: 'maskColumnFilter',
  },
  {
    field: 'serviceCategory',
    headerName: t('INSTANCES.WHATS_APP_TEMPLATES.SERVICE_CATEGORY'),
    flex: 1,
    filter: 'templatesColumnFilter',
    sortable: true,
    headerComponentParams: {
      withoutEmpty: true,
      instance: 'category',
    },
  },
  {
    field: 'senderId',
    headerName: t('INSTANCES.WHATS_APP_TEMPLATES.SENDER_ID'),
    flex: 1,
    filter: 'templatesColumnFilter',
    sortable: true,
    headerComponentParams: {
      withoutEmpty: true,
      instance: 'sender',
    },
  },
  {
    field: 'isApproved',
    headerName: t('INSTANCES.WHATS_APP_TEMPLATES.APPROVED'),
    flex: 1,
    cellRenderer: 'cellRendererImage',
    valueFormatter: ({ value }) => getBooleanIcon(value),
    filter: 'listColumnFilter',
    headerComponentParams: {
      withoutEmpty: true,
      items: [
        {
          id: 1,
          icon: checkIcon,
        },
        {
          id: 0,
          icon: crossIcon,
        },
      ],
    },
  },
  {
    field: 'language',
    headerName: t('INSTANCES.LANGUAGE'),
    flex: 1,
    filter: 'templatesColumnFilter',
    sortable: true,
    headerComponentParams: {
      withoutEmpty: true,
      instance: 'language',
    },
  },
  {
    field: 'createdAt',
    headerName: t('INSTANCES.WHATS_APP_TEMPLATES.CREATED'),
    valueFormatter: parseDateTime,
    flex: 1,
    sortable: true,
    filter: 'dateColumnFilter',
  },
  // {
  //   field: 'Modified',
  //   headerName: t('INSTANCES.WHATS_APP_TEMPLATES.MODIFIED'),
  //   flex: 1,
  // },
];

export {
  ASSETS_COLUMNS,
  RATE_PLANS_COLUMNS,
  RCS_RATE_PLANS_COLUMNS,
  EDR_COLUMNS,
  INVOICES_COLUMNS,
  PAYMENTS_COLUMNS,
  SENDERS_COLUMNS,
  CAMPAIGNS_COLUMNS,
  CAMPAIGN_LAUNCHES_COLUMNS,
  SENDER_GROUPS_COLUMNS,
  TEMPLATES_COLUMNS,
  SCENARIOS_COLUMNS,
  USERS_COLUMNS,
  AUDIT_COLUMNS,
  WHATS_APP_TEMPLATES_COLUMNS,
  getChannelIcon,
};
