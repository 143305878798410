import endpoints from 'const/endpoints';

export default ({
  id,
  successCallback = Function.prototype,
} = {}) => ({
  url: endpoints.getUserInfoNew(id),
  queryKey: endpoints.getUserInfoNew(id),
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
    successCallback,
  },
  transform: response => ({ userInfo: response }),
  update: {
    userInfo: (oldValue, newValue) => newValue,
  },
});
