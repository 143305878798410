import React from 'react';
import TwoFactorVoiceScreen from 'mkit-front-ds/dist/screens/TwoFactorVoiceScreen';

import './style.scss';

const TwoFAVoiceScreen = () => (
  <div className="two-fa-voice-screen-container">
    <TwoFactorVoiceScreen />
  </div>
);

export default TwoFAVoiceScreen;
