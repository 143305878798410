import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { BasicButton } from 'components/buttons';

import './style.scss';

const Hint = ({
  alignment,
  text,
  placement,
  setNextHint,
  circlePlacement,
  onSkip,
}) => {
  const buttonStyle = {
    container: {
      position: 'absolute',
      left: 0,
      bottom: -45,
    },
  };

  const { t } = useTranslation();

  const tooltipClass = classNames('hint', {
    [`hint_${alignment}`]: alignment,
  });

  const onSkipClick = (e) => {
    e.stopPropagation();
    onSkip();
  };

  return (
    <>
      <div
        className={tooltipClass}
        style={placement}
        role="presentation"
        onClick={setNextHint}
      >
        {t(text)}
        <BasicButton
          text={t('HINTS.SKIP_ALL')}
          onClick={onSkipClick}
          type={BasicButton.types.ACCENT}
          styles={buttonStyle}
        />
      </div>
      <div className="hint__circle" style={circlePlacement} />
    </>
  );
};

export default Hint;
