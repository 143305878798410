import endpoints from 'const/endpoints';

export default id => ({
  url: endpoints.getUpdateApiKeyUrl(id),
  queryKey: endpoints.getUpdateApiKeyUrl(),
  options: {
    method: 'PATCH',
  },
  meta: {
    authToken: true,
  },
});
