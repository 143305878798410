/*eslint-disable */
import React from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { SuggestionItem } from 'components/constructor';
import { SuggestionButton } from 'components/buttons';

import './style.scss';

const DnDSuggestionsInside = ({
  suggestionsInside,
  updateRcsParams,
  readOnly,
  openCreateSuggestion,
  onSuggestionChange,
  onRemoveSuggestion,
  cardIndex,
  axis,
  onAddSuggestion,
}) => {
  const { t } = useTranslation();

  const onDragEnd = ({ destination, source }) => {
    if (destination && source) {
      const result = Array.from(suggestionsInside);
      const [removed] = result.splice(source.index, 1);
      result.splice(destination.index, 0, removed);

      updateRcsParams({ suggestionsInside: result });
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId="suggestionsInside"
        direction="vertical"
      >
        {(provided, snapshot) => (
          <div
            className="mkit-dnd-suggestions-inside"
            ref={provided.innerRef}
            style={{ minHeight: 42 * suggestionsInside.length + 42 }}
          >
            {
              suggestionsInside.map((I, index) => (
                <Draggable
                  draggableId={I.suggestionType + index + I.text}
                  index={index}
                  key={I.suggestionType + index + I.text}
                  isDragDisabled={readOnly}
                >
                  {(itemProvided) => (
                    <SuggestionItem
                      text={I.text}
                      icon={I.icon}
                      id={I.id}
                      onChangeText={value => onSuggestionChange(value, I.id, 'inside')}
                      type="inside"
                      axis={axis}
                      isLast={
                        index === suggestionsInside.length - 1
                        && (suggestionsInside.length === 4 || readOnly)
                      }
                      onRemove={() => onRemoveSuggestion('inside', I.id)}
                      onEdit={() => openCreateSuggestion({
                        place: 'inside',
                        initialValues: I,
                        isReply: I.type === 'reply',
                      })}
                      cardIndex={cardIndex}
                      readOnly={readOnly}
                      wrapperRef={itemProvided.innerRef}
                      {...itemProvided.draggableProps}
                      {...itemProvided.dragHandleProps}
                    />
                  )}
                </Draggable>
              ))
            }
            {
              suggestionsInside.length < 4 && !readOnly && (
                <SuggestionButton
                  text={t('MESSAGE_CONSTRUCTOR.CONTROLS.REPLY_OR_ACTION')}
                  onClick={() => onAddSuggestion('inside')}
                  axis={axis}
                  style={snapshot.isDraggingOver ? { paddingTop: 54 } : {}}
                />
              )
            }
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DnDSuggestionsInside;
