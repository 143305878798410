import endpoints from 'const/endpoints';

export default ({ id, successCallback }) => ({
  url: endpoints.getFiltersByIdUrl(id),
  options: {
    method: 'DELETE',
  },
  meta: {
    authToken: true,
    successCallback,
  },
  update: {
    filters: oldValue => oldValue.filter(item => item.id !== id),
  },
});
