import React from 'react';
import { Menu } from 'antd';
import classNames from 'classnames';
import './DropdownItem.scss';

const DropdownItem = ({
  menuValue: {
    text,
    icon,
    filterType,
    action,
    styleIcon,
  },
  ...props
}) => (
  <Menu.Item key={text} {...props} onClick={action}>
    {icon && (
      <img
        src={icon}
        alt=""
        style={styleIcon}
        className={classNames('menu-item__img', {
          [`menu-item__img_filter-${filterType}`]: filterType,
        })}
      />
    )}
    <div className="menu-item__text">{text}</div>
  </Menu.Item>
);

export default DropdownItem;
