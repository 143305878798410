import React, {
  useState,
  useMemo,
  useReducer,
  useEffect,
} from 'react';
import { useToggle } from 'react-use';
import moment from 'moment';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ConstructorEditor as ConstructorEditorNew } from 'mkit-front-ds/dist/components/constructor';
import Button from 'mkit-front-ds/dist/@setproduct-ui/core/Button';

import {
  toast,
  convertObjectTimeToSeconds,
} from 'helpers';
import {
  RCS_ALL_MIME_TYPES,
  VIBER_ALL_MIME_TYPES,
  VIBER_IMAGE_MIME_TYPES,
} from 'const';
import {
  useSendersApi,
  useRecipientsApi,
  useTemplatesApi,
} from 'hooks/api';
import { DropdownSpecifyField, DropdownField, TimePickerField } from 'components/fields';
import { BasicButton, IconButton } from 'components/buttons';
import { CreateActionForm, ConfigButtonForm } from 'components/forms';
import SelectMedia from 'components/SelectMedia/SelectMedia';
import Drawer from 'components/UIKit/Drawer/Drawer';
import { ConstructorEditor, SendersDropdown } from 'components/constructor';
import LoadingSpinner from 'components/LoadingSpinner';
import trashIcon from 'assets/icons/trash.svg';
import disketteDarkIcon from 'assets/icons/diskette-dark.svg';
import disketteIcon from 'assets/icons/diskette.svg';
import crossIcon from 'assets/icons/cross-grey.svg';

import './style.scss';

const initialRcsParams = {
  selectedType: '',
  axis: 'VERTICAL',
  titleFontStyle: 'bold',
  imageHeight: 'small',
  suggestionsInside: [],
  suggestionsOutside: [],
};

const RcsBlock = ({
  channel,
  onChange,
  onCancel,
  index,
  senderId,
  isViber,
  withTools = true,
  withTemplates,
  initialParams,
  canChooseType = true,
  readOnly = false,
  reportFields,
  deliveryCondition = 'DELIVERY_SUCCESS',
  timeout = { hours: '00', minutes: '10', seconds: '00' },
  isNewDesign = false,
  withShortLink,
  setShortLinks,
  channelNumber,
}) => {
  const timeFieldStyle = {
    container: {
      width: '100%',
    },
    overlay: {
      top: 'unset',
      bottom: 36,
    },
  };
  const headerButtonStyle = { minWidth: 20 };

  const blockClass = classNames('rcs-block', {
    'rcs-block_viber': isViber,
  });

  const wrapperClass = classNames('rcs-block__content-wrapper', {
    'rcs-block__content-wrapper_new-design': isNewDesign,
    alwaysLightTheme: true,
  });

  const wrapperHeaderClass = classNames('rcs-block__content-wrapper__header', {
    'rcs-block__content-wrapper__header_new-design': isNewDesign,
  });

  const { t } = useTranslation();
  const {
    getTemplates,
    templates,
    postCreateTemplate,
    deleteTemplate,
    patchUpdateTemplate,
    isPendingGetTemplates,
  } = useTemplatesApi();
  const {
    getSenders,
    senders,
    isPendingGetSenders,
  } = useSendersApi();
  const { getAttributes, isPendingGetAttributes } = useRecipientsApi();
  const [sender, setSender] = useState(senderId || '');
  const [template, setTemplate] = useState('');
  const [waitFor, setWaitFor] = useState(deliveryCondition);
  const [isThumbnailSelect, setIsThumbnailSelect] = useState(false);
  const [waitingTime, setWaitingTime] = useState(timeout);
  const [selectMediaOpen, toggleSelectOpenMedia] = useToggle(false);
  const [createSuggestion, setCreateSuggestion] = useState({ open: false, place: '' });
  const [selectedCard, setSelectedCard] = useState(0);
  const [buttonConfigOpen, setButtonConfigOpen] = useState(false);
  const [showError, setShowError] = useState(false);
  const reducer = (state, { isReset, ...newValue }) => {
    if (isReset) {
      return newValue;
    }

    return ({
      ...state,
      ...newValue,
    });
  };
  const [rcsParams, updateRcsParams] = useReducer(reducer, initialParams || {});

  const formatByChannel = useMemo(() => {
    if (isViber) {
      if (rcsParams.selectedType === 'viberImageCard') {
        return VIBER_IMAGE_MIME_TYPES;
      }
      return VIBER_ALL_MIME_TYPES;
    }
    return rcsParams.selectedType === 'fileMessage' ? RCS_ALL_MIME_TYPES : RCS_ALL_MIME_TYPES.filter(i => i !== 'application/pdf');
  }, [isViber, rcsParams]);

  const rcsTemplates = useMemo(() => templates
    ?.filter(I => I.channel === (isViber ? 3 : 2))
    ?.map(I => ({
      id: I.id,
      title: I.name,
    })), [templates]);
  const sendersOptions = useMemo(() => senders.filter(I => I.channel === (isViber ? 3 : 2) && I.status === 2)
    .map(I => ({
      id: I.senderId,
      title: I.displayName,
    })), [senders]);

  const resetType = () => {
    if (initialParams) {
      updateRcsParams({ isReset: true, ...initialParams });
    } else {
      updateRcsParams({ isReset: true, ...initialRcsParams });
    }
  };
  const updateCarouselRcsParams = (newValue) => {
    const cardsModify = rcsParams.cards;

    cardsModify[selectedCard] = ({
      ...cardsModify[selectedCard],
      ...newValue,
    });
    updateRcsParams({ cards: cardsModify });
  };
  const selectMedia = (image) => {
    if (rcsParams.selectedType === 'carousel') {
      if (isThumbnailSelect) {
        updateCarouselRcsParams({
          image: {
            ...rcsParams.cards[selectedCard]?.image,
            thumbnailUri: image.fileUrl,
            x_thumbnailUri: rcsParams.cards[selectedCard]?.image.x_thumbnailUri
              || rcsParams.cards[selectedCard]?.image.thumbnailUri,
          },
        });

        setIsThumbnailSelect(false);
      } else {
        updateCarouselRcsParams({ image });
      }
    } else if (isThumbnailSelect) {
      updateRcsParams({
        image: {
          ...rcsParams.image,
          thumbnailUri: image.fileUrl,
          x_thumbnailUri: rcsParams.image.x_thumbnailUri || rcsParams.image.thumbnailUri,
        },
      });

      setIsThumbnailSelect(false);
    } else {
      updateRcsParams({ image });
    }
  };
  const closeButtonConfig = () => {
    setButtonConfigOpen(false);
  };
  const openUrlButtonConfig = () => {
    setButtonConfigOpen(true);
  };
  const onSaveButtonConfig = ({ buttonUrl, buttonCaption }) => {
    updateRcsParams({ buttonUrl, buttonCaption });
    closeButtonConfig();
  };
  const closeCreateSuggestion = () => {
    setCreateSuggestion({ ...createSuggestion, open: false });
  };
  const openCreateSuggestion = ({ place, initialValues, isReply }) => {
    if (initialValues?.category === 'mapAction' && initialValues?.query) {
      initialValues.by = true;
    }

    setCreateSuggestion({
      open: true,
      place,
      initialValues,
      isReply,
    });
  };
  const onCreateSuggestion = ({
    isEdit,
    id,
    suggestionType,
    ...values
  }) => {
    const updateRcsParamsModify = rcsParams.selectedType === 'carousel' ? updateCarouselRcsParams : updateRcsParams;
    const rcsParamsModify = rcsParams.selectedType === 'carousel' ? rcsParams.cards[selectedCard] : rcsParams;
    const text = suggestionType === 'reply' ? 'Reply' : 'Action';
    if (createSuggestion.place === 'inside') {
      if (id !== undefined) {
        const editableSuggestionIndex = rcsParamsModify.suggestionsInside.indexOf(
          rcsParamsModify.suggestionsInside.find(I => I.id === id),
        );

        rcsParamsModify.suggestionsInside[editableSuggestionIndex] = ({
          id,
          text,
          suggestionType,
          ...values,
        });
      } else {
        updateRcsParamsModify({
          suggestionsInside: [
            ...rcsParamsModify.suggestionsInside,
            {
              id: rcsParamsModify.suggestionsInside.length,
              text,
              suggestionType,
              ...values,
            },
          ],
        });
      }
    } else if (id !== undefined) {
      const editableActionIndex = rcsParams.suggestionsOutside.indexOf(
        rcsParams.suggestionsOutside.find(I => I.id === id),
      );
      rcsParams.suggestionsOutside[editableActionIndex] = ({
        id,
        text,
        suggestionType,
        ...values,
      });
    } else {
      updateRcsParams({
        suggestionsOutside: [
          ...rcsParams.suggestionsOutside,
          {
            id: rcsParams.suggestionsOutside.length,
            text,
            suggestionType,
            ...values,
          },
        ],
      });
    }
    closeCreateSuggestion();
  };
  const onApply = () => {
    if (!sender && withTools) {
      setShowError(true);
    } else {
      setShowError(false);

      switch (rcsParams.selectedType) {
        case 'viberText':
          if (!rcsParams?.message) {
            toast.error(t('TOAST.ENTER_TEXT'));
            return;
          }
          break;
        case 'viberImage':
          if (!rcsParams?.image) {
            toast.error(t('TOAST.COMPLETE_FILE'));
            return;
          }
          break;
        case 'viberTextCard':
          if (!rcsParams?.message || !rcsParams?.buttonCaption || !rcsParams?.buttonUrl) {
            toast.error(t('TOAST.COMPLETE_VIBER_TEXT_CARD'));
            return;
          }
          break;
        case 'viberImageCard':
          if (!rcsParams?.message || !rcsParams?.buttonCaption || !rcsParams?.buttonUrl || !rcsParams?.image) {
            toast.error(t('TOAST.COMPLETE_VIBER_IMAGE_CARD'));
            return;
          }
          break;
        case 'richcardMessage':
          if (!rcsParams.image && !rcsParams.title && !rcsParams.message) {
            toast.error(t('TOAST.COMPLETE_BLOCK'));
            return;
          }
          break;
        case 'carousel': {
          let emptyCardIndex;
          rcsParams.cards.forEach((item, cardIndex) => {
            if (!item.image && !item.title && !item.message) {
              emptyCardIndex = cardIndex + 1;
            }
          });

          if (emptyCardIndex) {
            toast.error(t('TOAST.EMPTY_CARD', { index: emptyCardIndex }));
            return;
          }
          break;
        }
        case 'textMessage':
          if (!rcsParams.message) {
            toast.error(t('TOAST.ENTER_MESSAGE'));
            return;
          }
          break;
        default: break;
      }

      const formAnSuggestions = rawSuggestions => rawSuggestions.reduce((acc, item) => {
        if (item.suggestionType === 'reply') {
          acc.push({
            reply: {
              displayText: item.text,
              reportField: item.reportField,
              postback: {
                data: item.postback ? JSON.stringify(item.postback) : 'test',
              },
            },
          });
        } else {
          delete item.icon;
          delete item.id;

          const {
            category,
            text,
            type,
            fallbackUrl,
            postback,
            suggestionType,
            ...body
          } = item;

          let typeBody;

          switch (type) {
            case 'openUrl': {
              typeBody = {
                url: body.url,
                application: body.application,
                viewMode: body.application === 'webview' ? body.viewMode : undefined,
              };
              break;
            }
            case 'dialPhoneNumber':
            case 'dialVideoCall': {
              typeBody = {
                phoneNumber: body.phoneNumber,
                fallbackUrl,
              };
              break;
            }
            case 'dialEnrichedCall': {
              typeBody = {
                phoneNumber: body.phoneNumber,
                subject: body.subject,
                fallbackUrl,
              };
              break;
            }
            case 'requestLocationPush': {
              typeBody = {};
              break;
            }
            case 'showLocation': {
              typeBody = {
                location: body.by ? {
                  query: body.query,
                  label: body.label,
                } : {
                  latitude: +body.latitude,
                  longitude: +body.longitude,
                  label: body.label,
                },
                fallbackUrl,
              };
              break;
            }
            case 'createCalendarEvent':
              typeBody = {
                startTime: `${moment(body.startTime).format('YYYY-MM-DDTHH:mm:ss')}Z`,
                endTime: `${moment(body.endTime).format('YYYY-MM-DDTHH:mm:ss')}Z`,
                title: body.title,
                description: body.description,
                fallbackUrl,
              };
              break;
            default:
              typeBody = {
                ...body,
                fallbackUrl,
              };
              break;
          }
          delete typeBody.reportField;

          acc.push({
            action: {
              displayText: text,
              reportField: item.reportField,
              postback: {
                data: item.postback ? JSON.stringify(postback) : 'test',
              },
              [category]: {
                [type]: typeBody,
              },
            },
          });
        }

        return acc;
      }, []);

      const getCardAlignment = () => {
        if (rcsParams.axis === 'VERTICAL') {
          return { cardOrientation: rcsParams.axis };
        }

        return {
          cardOrientation: 'HORIZONTAL',
          imageAlignment: rcsParams.axis,
        };
      };

      const getImageHeight = (imageHeight) => {
        switch (imageHeight) {
          case 'small': return 'SHORT_HEIGHT';
          case 'medium': return 'MEDIUM_HEIGHT';
          case 'height': return 'TALL_HEIGHT';
          default: return undefined;
        }
      };

      let body;

      switch (rcsParams.selectedType) {
        case 'richcardMessage':
          body = {
            [rcsParams.selectedType]: {
              message: {
                generalPurposeCard: {
                  content: {
                    description: rcsParams.message || undefined,
                    title: rcsParams.title || undefined,
                    media: rcsParams.image
                      ? {
                        mediaContentType: rcsParams.image.fileMimeType,
                        mediaFileSize: rcsParams.image.fileSize,
                        mediaUrl: rcsParams.image.fileUrl,
                        thumbnailContentType: rcsParams.image.fileMimeType,
                        thumbnailFileSize: rcsParams.image.thumbnailSize,
                        thumbnailUrl: rcsParams.image.thumbnailUri,
                        height: getImageHeight(rcsParams.imageHeight),
                        x_thumbnailUri: rcsParams.image.x_thumbnailUri,
                      }
                      : undefined,
                    suggestions: rcsParams.suggestionsInside.length
                      ? formAnSuggestions(rcsParams.suggestionsInside)
                      : undefined,
                  },
                  layout: {
                    descriptionFontStyle: rcsParams.messageFontStyle
                      ? [rcsParams.messageFontStyle]
                      : [],
                    titleFontStyle: rcsParams.titleFontStyle
                      ? [rcsParams.titleFontStyle]
                      : [],
                    ...getCardAlignment(),
                  },
                },
              },
            },
            suggestedChipList: rcsParams.suggestionsOutside.length
              ? {
                suggestions: formAnSuggestions(rcsParams.suggestionsOutside),
              }
              : undefined,
          };
          break;
        case 'textMessage':
          body = {
            textMessage: rcsParams.message,
            suggestedChipList: rcsParams.suggestionsOutside.length
              ? {
                suggestions: formAnSuggestions(rcsParams.suggestionsOutside),
              }
              : undefined,
          };
          break;
        case 'fileMessage':
          body = {
            fileMessage: {
              fileMIMEType: rcsParams.image.fileMimeType,
              fileName: rcsParams.image.fileName,
              fileSize: rcsParams.image.fileSize,
              fileUrl: rcsParams.image.fileUrl,
              thumbnailFileName: rcsParams.image.fileName,
              thumbnailFileSize: rcsParams.image.thumbnailSize,
              thumbnailMIMEType: rcsParams.image.fileMimeType,
              thumbnailUrl: rcsParams.image.thumbnailUri,
              x_thumbnailUri: rcsParams.image.x_thumbnailUri,
            },
            suggestedChipList: rcsParams.suggestionsOutside.length
              ? {
                suggestions: formAnSuggestions(rcsParams.suggestionsOutside),
              }
              : undefined,
          };
          break;
        case 'carousel':
          body = {
            richcardMessage: {
              message: {
                generalPurposeCardCarousel: {
                  content: rcsParams.cards.map(item => ({
                    description: item.message || undefined,
                    title: item.title || undefined,
                    media: item.image
                      ? {
                        mediaContentType: item.image.fileMimeType,
                        mediaFileSize: item.image.fileSize,
                        mediaUrl: item.image.fileUrl,
                        thumbnailContentType: item.image.fileMimeType,
                        thumbnailFileSize: item.image.thumbnailSize,
                        thumbnailUrl: item.image.thumbnailUri,
                        height: getImageHeight(item.imageHeight),
                        x_thumbnailUri: item.image.x_thumbnailUri,
                      }
                      : undefined,
                    suggestions: item.suggestionsInside.length
                      ? formAnSuggestions(item.suggestionsInside)
                      : undefined,
                  })),
                  layout: {
                    cardOrientation: 'VERTICAL',
                    cardWidth: rcsParams.cardWidth === 'small' ? 'SMALL_WIDTH' : 'MEDIUM_WIDTH',
                    descriptionFontStyle: rcsParams.messageFontStyle
                      ? [rcsParams.messageFontStyle]
                      : [],
                    titleFontStyle: rcsParams.titleFontStyle
                      ? [rcsParams.titleFontStyle]
                      : [],
                  },
                },
              },
            },
            suggestedChipList: rcsParams.suggestionsOutside.length
              ? {
                suggestions: formAnSuggestions(rcsParams.suggestionsOutside),
              }
              : undefined,
          };
          break;
        case 'viberText':
          body = { text: rcsParams.message };
          break;
        case 'viberImage':
          body = {
            imageUrl: rcsParams.image?.fileUrl,
            fileType: rcsParams?.image?.mediaType,
            fileName: rcsParams?.image?.fileName,
            fileSize: rcsParams?.image?.fileSize,
          };
          break;
        case 'viberTextCard':
          body = {
            text: rcsParams.message,
            buttonCaption: rcsParams.buttonCaption,
            buttonUrl: rcsParams.buttonUrl,
          };
          break;
        case 'viberImageCard':
          body = {
            text: rcsParams.message,
            buttonCaption: rcsParams.buttonCaption,
            buttonUrl: rcsParams.buttonUrl,
            imageUrl: rcsParams.image?.fileUrl,
          };
          break;
        default: break;
      }

      onChange({
        index,
        channelType: isViber ? 3 : 2,
        contentPattern: isViber ? body : {
          RCSMessage: body,
        },
        deliveryCondition: waitFor,
        order: 0,
        senderId: sender,
        timeout: convertObjectTimeToSeconds(waitingTime),
      });
    }
  };
  const onSaveTemplate = () => {
    let templateType;

    switch (rcsParams.selectedType) {
      case 'richcardMessage': templateType = 'Richcard';
        break;
      case 'carousel': templateType = 'Carousel';
        break;
      case 'textMessage': templateType = 'Text';
        break;
      default: templateType = 'File';
        break;
    }
    const templateBody = {
      name: template.value,
      channel: isViber ? 3 : 2,
      content: JSON.stringify(rcsParams),
      type: templateType,
    };

    if (template.isCustom) {
      postCreateTemplate(templateBody)
        .then(() => {
          getTemplates({ limit: 100, offset: 0 });
        });
    } else {
      patchUpdateTemplate({
        ...templateBody,
        name: rcsTemplates.find(I => I.id === template.value).title,
        id: template.value,
      })
        .then(() => {
          getTemplates({ limit: 100, offset: 0 });
        });
    }
  };
  const onDeleteTemplate = () => {
    deleteTemplate(template.value)
      .then(() => {
        getTemplates({ limit: 100, offset: 0 });
      });
  };

  const openMediaSelect = (isThumbnail) => {
    if (isThumbnail && !isViber) {
      setIsThumbnailSelect(true);
    }

    toggleSelectOpenMedia();
  };
  const closeMediaSelect = () => {
    if (isThumbnailSelect) {
      setIsThumbnailSelect(false);
    }
    toggleSelectOpenMedia();
  };

  useEffect(() => {
    const selectedItem = templates?.find(I => I.id === template.value);

    if (selectedItem) {
      updateRcsParams({
        isReset: true,
        ...JSON.parse(selectedItem.content),
      });
    }
  }, [template]);
  useEffect(() => {
    if (!isEmpty(template)) {
      const foundItem = rcsTemplates.find(I => I.title === template.value
        || I.id === template.value);

      if (foundItem) {
        setTemplate({ value: foundItem.id, isCustom: false });
      } else {
        setTemplate({});
      }
    }
  }, [rcsTemplates]);
  useEffect(() => {
    if (channel && !rcsParams.message) {
      updateRcsParams({ isReset: true, ...channel });
    }
  }, [channel]);
  useEffect(() => {
    getSenders({ limit: 1000, onlyActiveSenders: false });
    getAttributes({ variablesOnly: true });
    getTemplates({ limit: 100, offset: 0 });
  }, []);

  if ((isPendingGetTemplates
    || isPendingGetSenders
    || isPendingGetAttributes
  ) && !createSuggestion.open) {
    return <LoadingSpinner fullSize />;
  }

  return (
    <div className={blockClass}>
      <h2 className="rcs-block__header">
        {t(`SCREENS.BROADCASTS.${isViber ? 'VIBER' : 'RCS'}_MESSAGE_SETTINGS`)}
      </h2>
      {
        (withTools || withTemplates) && (
          <div className="rcs-block__tools">
            <span className="rcs-block__label" style={{ minWidth: 'unset' }}>
              {t('SCREENS.BROADCASTS.TEMPLATE')}
            </span>
            <DropdownSpecifyField
              input={{
                value: template,
                onChange: setTemplate,
              }}
              placeholder={t('PLACEHOLDERS.ENTER_NAME')}
              items={rcsTemplates}
              style={{ width: 329 }}
              disabled={readOnly}
            />
            <IconButton
              icon={disketteDarkIcon}
              onClick={onSaveTemplate}
              isDisabled={isEmpty(template) || readOnly}
              filterType="white"
            />
            <IconButton
              icon={trashIcon}
              onClick={onDeleteTemplate}
              isDisabled={isEmpty(template) || template.isCustom || readOnly}
              filterType="white"
            />
          </div>
        )
      }
      <div className={wrapperClass}>
        <div className={wrapperHeaderClass}>
          {
            canChooseType && (
              <Button
                type="icon"
                view="flat"
                iconColor={isViber ? 'var(--componentBg)' : '#787885'}
                icon="chevron-left"
                onClick={resetType}
                style={headerButtonStyle}
                disabled={readOnly}
              />
              // <BasicButton
              //   onClick={resetType}
              //   style={headerButtonStyle}
              //   icon={rcsParams.selectedType && arrowLeftIcon}
              // />
            )
          }
          {
            withTools && (
              <SendersDropdown
                values={sendersOptions}
                value={sender}
                onChange={setSender}
                showError={showError}
                disabled={readOnly}
                channel={isViber ? 'viber' : 'rcs'}
                isNewDesign
              />
            )
          }
        </div>
        {isNewDesign ? (
          <ConstructorEditorNew
            rcsParams={rcsParams}
            updateRcsParams={updateRcsParams}
            openMediaSelect={openMediaSelect}
            openCreateSuggestion={openCreateSuggestion}
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
            channel={channelNumber}
            openUrlButtonConfig={openUrlButtonConfig}
            readOnly={readOnly}
            withShortLink={withShortLink}
            setShortLinks={setShortLinks}
          />
        ) : (
          <ConstructorEditor
            rcsParams={rcsParams}
            updateRcsParams={updateRcsParams}
            openMediaSelect={openMediaSelect}
            openCreateSuggestion={openCreateSuggestion}
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
            constructorType={isViber ? 'viber' : 'rcs'}
            openUrlButtonConfig={openUrlButtonConfig}
            readOnly={readOnly}
          />
        )}
      </div>
      {
        withTools && (
          <>
            <div className="rcs-block__time-tools" style={{ marginTop: 24 }}>
              <span className="rcs-block__label">
                {t('SCREENS.BROADCASTS.WAIT_FOR')}
              </span>
              <DropdownField
                input={{
                  value: waitFor,
                  onChange: setWaitFor,
                }}
                type="grey-border"
                values={[
                  {
                    id: 'DELIVERY_SUCCESS',
                    title: t('NEW_TRANSLATES.INSTANCES.MESSAGE_CONSTRUCTOR.DELIVERY_SUCCESS'),
                  },
                  {
                    id: 'SUBMIT_SUCCESS',
                    title: t('NEW_TRANSLATES.INSTANCES.MESSAGE_CONSTRUCTOR.SUBMIT_SUCCESS'),
                  },
                  {
                    id: 'DISPLAY_SUCCESS',
                    title: t('NEW_TRANSLATES.INSTANCES.MESSAGE_CONSTRUCTOR.DISPLAY_SUCCESS'),
                  },
                ]}
                isDisabled={readOnly}
                withoutCallbackDestroy
              />
            </div>
            <div className="rcs-block__time-tools">
              <span className="rcs-block__label">
                {t('SCREENS.BROADCASTS.WAITING_TIME')}
              </span>
              <TimePickerField
                input={{
                  value: waitingTime,
                  onChange: setWaitingTime,
                }}
                styles={timeFieldStyle}
                typeOverlay="primary-color"
                withTimezone={false}
                disabled={readOnly}
              />
            </div>
          </>
        )
      }
      <div className="rcs-block__buttons">
        <BasicButton
          text={t('CONTROLS.CANCEL')}
          type={BasicButton.types.CANCEL}
          icon={crossIcon}
          onClick={onCancel}
          isNewStyle
        />
        <BasicButton
          text={t('CONTROLS.SAVE')}
          type={BasicButton.types.ACCENT}
          icon={disketteIcon}
          onClick={onApply}
          disabled={!rcsParams.selectedType || readOnly}
        />
      </div>
      <SelectMedia
        open={selectMediaOpen}
        onClose={closeMediaSelect}
        onSelected={selectMedia}
        width={555}
        mediaFormat={isThumbnailSelect ? 'image' : undefined}
        format={formatByChannel}
        fileMaxSize={isViber ? 200 : 500}
        channel={isViber ? 3 : 2}
        allowedMimeTypes={formatByChannel}
        withUploadButton
      />
      {
        createSuggestion.open && (
          <Drawer
            visible
            onClose={closeCreateSuggestion}
            width={500}
          >
            <CreateActionForm
              onClose={closeCreateSuggestion}
              onSubmit={onCreateSuggestion}
              initialValues={createSuggestion.initialValues}
              isReply={createSuggestion.isReply}
              reportFields={reportFields}
            />
          </Drawer>
        )
      }
      <Drawer
        visible={buttonConfigOpen}
        onClose={closeButtonConfig}
        width={480}
        destroyOnClose
      >
        <ConfigButtonForm
          onClose={closeButtonConfig}
          onSubmit={onSaveButtonConfig}
          readOnly={readOnly}
          initialValues={{
            buttonUrl: rcsParams.buttonUrl || 'https://',
            buttonCaption: rcsParams.buttonCaption || '',
          }}
        />
      </Drawer>
    </div>
  );
};

export default RcsBlock;
