import moment from 'moment';

import urlActiveIcon from 'assets/icons/rcs/url-active.svg';
import callActiveIcon from 'assets/icons/rcs/call-active.svg';
import locationActiveIcon from 'assets/icons/rcs/location-active.svg';
import pushActiveIcon from 'assets/icons/rcs/push-active.svg';
import calendarActiveIcon from 'assets/icons/calendar-active.svg';

const initialRcsParams = {
  selectedType: '',
  axis: 'VERTICAL',
  titleFontStyle: 'bold',
  imageHeight: 'small',
  suggestionsInside: [],
  suggestionsOutside: [],
};

const getImageHeight = (imageHeight) => {
  switch (imageHeight) {
    case 'SHORT_HEIGHT': return 'small';
    case 'MEDIUM_HEIGHT': return 'medium';
    case 'TALL_HEIGHT': return 'height';
    default: return 'small';
  }
};

const getActionIcon = (category, type) => {
  switch (category) {
    case 'urlAction': return urlActiveIcon;
    case 'dialerAction': return callActiveIcon;
    case 'mapAction': return type === 'requestLocationPush' ? pushActiveIcon : locationActiveIcon;
    case 'calendarAction': return calendarActiveIcon;
    default: return urlActiveIcon;
  }
};

const getPostback = (data) => {
  if (data && (data.includes('scenarioId') || data.includes('actionType'))) {
    return JSON.parse(data);
  }

  return undefined;
};

const getTransformedSuggestions = (suggestions = []) => suggestions?.reduce((acc, item, index) => {
  if (Object.keys(item)[0] === 'reply') {
    acc.push({
      id: index,
      suggestionType: 'reply',
      text: item.reply.displayText,
      reportField: item.reply.reportField,
      postback: getPostback(item.reply.postback?.data),
    });
  } else {
    const category = Object.keys(item.action).find(
      I => I !== 'displayText' && I !== 'postback' && I !== 'reportField',
    );
    const type = Object.keys(item.action[category])[0];
    const {
      location = {},
      startTime,
      endTime,
      ...typeBody
    } = item.action[category][type];

    const startTimeModify = startTime ? moment.utc(startTime) : undefined;
    const endTimeModify = endTime ? moment.utc(endTime) : undefined;

    acc.push({
      id: index,
      category,
      text: item.action.displayText,
      reportField: item.action.reportField,
      icon: getActionIcon(category, type),
      suggestionType: 'action',
      postback: getPostback(item.action.postback?.data),
      type,
      startTime: startTimeModify,
      endTime: endTimeModify,
      ...typeBody,
      ...location,
    });
  }

  return acc;
}, []) || [];

export default (channel) => {
  const rcsMessageKeys = Object.keys(channel.contentPattern?.RCSMessage ?? {});
  let rcsType = '';

  if (rcsMessageKeys.length) {
    switch (true) {
      case rcsMessageKeys.includes('richcardMessage'):
        rcsType = 'richcardMessage';
        break;
      case rcsMessageKeys.includes('fileMessage'):
        rcsType = 'fileMessage';
        break;
      case rcsMessageKeys.includes('textMessage'):
        rcsType = 'textMessage';
        break;
      default: break;
    }
  }

  if (rcsType === 'richcardMessage'
    && channel.contentPattern.RCSMessage.richcardMessage.message.generalPurposeCardCarousel) {
    rcsType = 'carousel';
  }

  const suggestionsOut = channel.contentPattern?.RCSMessage?.suggestedChipList?.suggestions;
  switch (rcsType) {
    case 'fileMessage': {
      const { fileMessage } = channel.contentPattern.RCSMessage;

      return ({
        selectedType: rcsType,
        image: {
          fileMimeType: fileMessage.fileMIMEType || fileMessage.fileMimeType,
          fileName: fileMessage.fileName,
          fileSize: fileMessage.fileSize,
          fileUrl: fileMessage.fileUrl || fileMessage.fileUri,
          thumbnailSize: fileMessage.thumbnailFileSize,
          thumbnailUri: fileMessage.thumbnailUrl,
          x_thumbnailUri: fileMessage.x_thumbnailUri,
        },
        suggestionsOutside: getTransformedSuggestions(suggestionsOut),
      });
    }
    case 'textMessage': {
      const { textMessage } = channel.contentPattern.RCSMessage;

      return ({
        selectedType: rcsType,
        message: textMessage,
        suggestionsOutside: getTransformedSuggestions(suggestionsOut),
      });
    }
    case 'richcardMessage': {
      const {
        content,
        layout,
        // eslint-disable-next-line no-unsafe-optional-chaining
      } = channel.contentPattern.RCSMessage.richcardMessage?.message?.generalPurposeCard;

      return ({
        selectedType: rcsType,
        message: content.description,
        title: content.title,
        imageHeight: getImageHeight(content.media?.height) || 'small',
        image: content.media ? {
          fileMimeType: content.media.mediaContentType,
          fileSize: content.media.mediaFileSize,
          fileUrl: content.media.mediaUrl,
          thumbnailSize: content.media.thumbnailFileSize,
          thumbnailUri: content.media.thumbnailUrl,
          x_thumbnailUri: content.media.x_thumbnailUri,
        } : undefined,
        suggestionsInside: getTransformedSuggestions(content.suggestions),
        messageFontStyle: layout.descriptionFontStyle?.toString(),
        titleFontStyle: layout.titleFontStyle?.toString(),
        axis: layout.cardOrientation === 'VERTICAL' ? layout.cardOrientation : layout.imageAlignment,
        suggestionsOutside: getTransformedSuggestions(suggestionsOut),
      });
    }
    case 'carousel': {
      const {
        content,
        layout,
        // eslint-disable-next-line no-unsafe-optional-chaining
      } = channel.contentPattern.RCSMessage.richcardMessage?.message?.generalPurposeCardCarousel;

      return ({
        selectedType: rcsType,
        cardWidth: layout.cardWidth === 'SMALL_WIDTH' ? 'small' : 'medium',
        messageFontStyle: layout.descriptionFontStyle?.toString(),
        titleFontStyle: layout.titleFontStyle?.toString(),
        suggestionsOutside: getTransformedSuggestions(suggestionsOut),
        cards: content.map(item => ({
          message: item.description,
          title: item.title,
          image: item.media ? {
            fileMimeType: item.media.mediaContentType,
            fileSize: item.media.mediaFileSize,
            fileUrl: item.media.mediaUrl,
            thumbnailSize: item.media.thumbnailFileSize,
            thumbnailUri: item.media.thumbnailUrl,
            x_thumbnailUri: item.media.x_thumbnailUri,
          } : undefined,
          imageHeight: getImageHeight(item.media?.height),
          suggestionsInside: getTransformedSuggestions(item.suggestions),
          axis: 'VERTICAL',
        })),
      });
    }
    default: return initialRcsParams;
  }
};
