import { DASHBOARD_KEY, HINTS_KEY } from 'const';
import actions from 'const/actions';

const initialState = {
  dashboardHint: JSON.parse(localStorage.getItem(HINTS_KEY))?.[DASHBOARD_KEY] || '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_DASHBOARD_HINT:
      return {
        ...state,
        dashboardHint: action.state,
      };
    default:
      return state;
  }
};
