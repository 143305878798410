export const DASHBOARD_KEY = 'dashboardHints';
export const CAMPAIGN_KEY = 'campaignHints';
export const RECIPIENTS_KEY = 'recipientsHints';
export const MENU_KEY = 'menuHints';
export const HINTS_KEY = 'hints';

export const DASHBOARD_HINTS = {
  dashboardAddWidget: {
    text: 'HINTS.WELCOME_DASHBOARD',
    nextHint: 'complete',
    alignment: 'right',
    placement: {
      top: 17,
      right: 170,
    },
    circlePlacement: {
      top: -20,
      right: 33,
      width: 125,
      height: 125,
    },
  },
};

export const CAMPAIGN_HINTS = {
  campaignGeneral: {
    text: 'HINTS.BROADCAST_DESIGNER',
    nextHint: 'campaignFilter',
    alignment: 'left',
    placement: {
      left: 220,
      top: 60,
      maxWidth: 230,
    },
    circlePlacement: {
      top: 55,
      left: 103,
    },
  },
  campaignFilter: {
    text: 'HINTS.CONTACTS_SECTION',
    nextHint: 'campaignLaunch',
    alignment: 'left',
    placement: {
      left: 108,
      bottom: 10,
      maxWidth: 260,
    },
    circlePlacement: {
      top: 'unset',
      bottom: -2,
      left: -3,
    },
  },
  campaignLaunch: {
    text: 'HINTS.MANIPULATIONS_WITH_CALENDAR',
    nextHint: 'addFallback',
    alignment: 'right',
    placement: {
      left: -265,
      top: 13,
      maxWidth: 260,
    },
    circlePlacement: {
      top: -26,
      left: 10,
      width: 150,
      height: 150,
    },
  },
  addFallback: {
    text: 'HINTS.SEQUENCE_OF_CHANNELS',
    nextHint: 'complete',
    alignment: 'left',
    placement: {
      right: -285,
      maxWidth: 260,
    },
    circlePlacement: {
      top: -25,
      right: -10,
    },
  },
};

export const RECIPIENTS_HINTS = {
  campaignGeneral: {
    text: 'HINTS.TARGET_FUTURE_BROADCASTS',
    nextHint: 'complete',
    alignment: 'left',
    placement: {
      left: 215,
      top: 76,
      maxWidth: 230,
    },
    circlePlacement: {
      top: 61,
      left: 105,
    },
  },
};

export const MENU_HINTS = {
  addContacts: {
    text: 'HINTS.TARGET_AMONG_RECIPIENTS',
    nextHint: 'addAssets',
    alignment: 'left',
    placement: {
      left: 95,
      top: 172,
      minWidth: 240,
    },
    circlePlacement: {
      top: 150,
      left: -15,
    },
  },
  addAssets: {
    text: 'HINTS.UPLOAD_CONTENT_MESSAGES',
    nextHint: 'addBroadcast',
    alignment: 'left',
    placement: {
      left: 95,
      top: 355,
      minWidth: 200,
    },
    circlePlacement: {
      top: 330,
      left: -15,
    },
  },
  addBroadcast: {
    text: 'HINTS.FINALLY_START_BROADCAST',
    nextHint: 'checkConversations',
    alignment: 'left',
    placement: {
      left: 95,
      top: 125,
      minWidth: 200,
    },
    circlePlacement: {
      top: 100,
      left: -15,
    },
  },
  checkConversations: {
    text: 'HINTS.CONVERSATION_MODULE',
    nextHint: 'complete',
    alignment: 'left',
    placement: {
      left: 95,
      top: 215,
      minWidth: 260,
    },
    circlePlacement: {
      top: 190,
      left: -15,
    },
  },
};
