import React from 'react';

import {
  GeolocationElement,
} from 'components/constructor';

import './style.scss';

const WhatsAppGeolocation = ({
  rcsParams,
  openGeolocation,
  readOnly,
}) => (
  <div className="mkit-whats-app-geolocation">
    <GeolocationElement
      rcsParams={rcsParams}
      openGeolocation={openGeolocation}
      readOnly={readOnly}
    />
  </div>
);

export default WhatsAppGeolocation;
