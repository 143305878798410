import vkIcon from 'assets/socials/vk.svg';
import weChatIcon from 'assets/socials/wechat.svg';
import emailIcon from 'assets/socials/email.svg';
import facebookIcon from 'assets/socials/facebook.svg';
import odnoklasnikiIcon from 'assets/socials/odnoklassniki.svg';
import whatsappIcon from 'assets/socials/whatsapp.svg';
import viberIcon from 'assets/socials/viber.svg';
import telegramIcon from 'assets/socials/telegram.svg';
import smsIcon from 'assets/socials/sms.svg';
import smsDarkIcon from 'assets/socials/sms-dark.svg';
import rcsIcon from 'assets/socials/rcs.svg';
import rcsDarkIcon from 'assets/socials/rcs-dark.svg';
import addWhiteIcon from 'assets/icons/add_white.svg';
import stopIcon from 'assets/icons/stop.svg';
import startIcon from 'assets/icons/play.svg';

const CAMPAIGN_CHANNEL_TYPE = {
  vk: 'vk',
  facebook: 'facebook',
  odnoklasniki: 'odnoklasniki',
  whatsapp: 4,
  viber: 3,
  telegram: 'telegram',
  sms: 1,
  rcs: 2,
  wechat: 6,
  email: 7,
};

const CAMPAIGN_CHANNEL_ACTION_TYPE = {
  start: 'start',
  add: 'add',
  stop: 'stop',
};

const CAMPAIGN_CHANNEL_ACTION_IMAGES = {
  [CAMPAIGN_CHANNEL_ACTION_TYPE.start]: startIcon,
  [CAMPAIGN_CHANNEL_ACTION_TYPE.add]: addWhiteIcon,
  [CAMPAIGN_CHANNEL_ACTION_TYPE.stop]: stopIcon,
};

const CAMPAIGN_CHANNEL_IMAGES = {
  [CAMPAIGN_CHANNEL_TYPE.vk]: () => vkIcon,
  [CAMPAIGN_CHANNEL_TYPE.wechat]: () => weChatIcon,
  [CAMPAIGN_CHANNEL_TYPE.email]: () => emailIcon,
  [CAMPAIGN_CHANNEL_TYPE.facebook]: () => facebookIcon,
  [CAMPAIGN_CHANNEL_TYPE.odnoklasniki]: () => odnoklasnikiIcon,
  [CAMPAIGN_CHANNEL_TYPE.whatsapp]: () => whatsappIcon,
  [CAMPAIGN_CHANNEL_TYPE.viber]: () => viberIcon,
  [CAMPAIGN_CHANNEL_TYPE.telegram]: () => telegramIcon,
  [CAMPAIGN_CHANNEL_TYPE.sms]: isDark => (isDark ? smsIcon : smsDarkIcon),
  [CAMPAIGN_CHANNEL_TYPE.rcs]: isDark => (isDark ? rcsIcon : rcsDarkIcon),
};

export {
  CAMPAIGN_CHANNEL_TYPE,
  CAMPAIGN_CHANNEL_ACTION_TYPE,
  CAMPAIGN_CHANNEL_IMAGES,
  CAMPAIGN_CHANNEL_ACTION_IMAGES,
};
