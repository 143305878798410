import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';
import { updateEntities, cancelQuery } from 'redux-query';
import { useDispatch } from 'react-redux';

import {
  getRecipients as getRecipientsApi,
  getAttributes as getAttributesApi,
  putRecipients as putRecipientsApi,
  deleteRecipient as deleteRecipientApi,
  postRecipient as postRecipientApi,
  patchRecipients as patchRecipientsApi,
  getStatsByFilters as getStatsByFiltersApi,
  postAttribute as postAttributeApi,
  postAttributeType as postAttributeTypeApi,
  postRecipientsInfo as postRecipientsInfoApi,
  deleteAllRecipients as deleteAllRecipientsApi,
  getRecipientById as getRecipientByIdApi,
} from 'queries/recipients';

import {
  getObject,
  getArray,
  getNumber,
  useISESelector,
} from './selectors';

export default () => {
  const dispatch = useDispatch();
  const [
    {
      isPending: isPendingGetRecipients,
      lastUpdated: lastUpdatedGetRecipients,
    },
    getRecipients,
  ] = useMutation(getRecipientsApi);
  const [
    {
      isPending: isPendingPostRecipient,
      lastUpdated: lastUpdatedPostRecipient,
    },
    postRecipient,
  ] = useMutation(postRecipientApi);
  const [
    {
      isPending: isPendingGetAttributes,
    },
    getAttributes,
  ] = useMutation(getAttributesApi);
  const [
    {
      isPending: isPendingGetStatsByFilters,
    },
    getStatsByFilters,
  ] = useMutation(getStatsByFiltersApi);
  const [
    {
      isPending: isPendingPostRecipientsInfo,
    },
    postRecipientsInfo,
  ] = useMutation(postRecipientsInfoApi);
  const [
    {
      isPending: isPendingPutRecipients,
    },
    putRecipients,
  ] = useMutation(putRecipientsApi);
  const [
    {
      isPending: isPendingDeleteAllRecipients,
    },
    deleteAllRecipients,
  ] = useMutation(deleteAllRecipientsApi);
  const [
    {
      isPending: isPendingGetRecipient,
    },
    getRecipientById,
  ] = useMutation(getRecipientByIdApi);
  const [, patchRecipients] = useMutation(patchRecipientsApi);
  const [, postAttribute] = useMutation(postAttributeApi);
  const [, postAttributeType] = useMutation(postAttributeTypeApi);
  const [
    {
      isPending: isPendingDeleteRecipient,
    },
    deleteRecipient,
  ] = useMutation(deleteRecipientApi);

  const clearRecipients = () => {
    dispatch(
      updateEntities({ recipients: () => [] }),
    );
  };
  const clearRecipient = () => {
    dispatch(
      updateEntities({ recipient: () => {} }),
    );
  };
  const clearStatsByFilters = () => {
    dispatch(
      updateEntities({ statsByFilters: () => 0 }),
    );
  };
  const cancelStatsByFiltersQuery = () => {
    dispatch(
      cancelQuery('getStatsByFilters'),
    );
  };

  const selector = useCallback(state => ({
    recipients: getArray(state, 'recipients'),
    attributes: getArray(state, 'attributes'),
    recipientsPagination: getObject(state, 'recipientsPagination'),
    recipientsInfo: getObject(state, 'recipientsInfo'),
    filters: getArray(state, 'filters'),
    statsByFilters: getNumber(state, 'statsByFilters'),
    recipient: getObject(state, 'recipient'),
  }), []);
  const data = useISESelector(selector);

  return {
    getRecipients,
    isPendingGetRecipients,
    lastUpdatedGetRecipients,
    postRecipient,
    isPendingPostRecipient,
    lastUpdatedPostRecipient,
    getAttributes,
    isPendingGetAttributes,
    putRecipients,
    deleteRecipient,
    deleteAllRecipients,
    patchRecipients,
    isPendingGetStatsByFilters,
    getStatsByFilters,
    clearRecipients,
    cancelStatsByFiltersQuery,
    postAttribute,
    postAttributeType,
    clearStatsByFilters,
    isPendingPostRecipientsInfo,
    postRecipientsInfo,
    isPendingDeleteRecipient,
    isPendingDeleteAllRecipients,
    getRecipientById,
    isPendingGetRecipient,
    clearRecipient,
    isPendingPutRecipients,
    ...data,
  };
};
