import endpoints from 'const/endpoints';

export default fileLinkId => ({
  url: endpoints.getDeleteAssetUrl({ fileLinkId }),
  queryKey: endpoints.getDeleteAssetUrl(),
  options: {
    method: 'DELETE',
  },
  meta: {
    authToken: true,
  },
  update: {
    assets: oldValue => oldValue.filter(item => item.fileLinkId !== fileLinkId),
  },
});
