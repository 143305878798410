import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { IconButton } from 'components/buttons';
import {
  ImageElement,
  EditorTextarea,
  DnDSuggestionsInside,
  DnDSuggestionsOutside,
} from 'components/constructor';
import { getButtonsArray } from 'helpers';
import horizontalLeftIcon from 'assets/icons/rcs/horizontal-left.svg';
import horizontalLeftActiveIcon from 'assets/icons/rcs/horizontal-left-active.svg';
import horizontalRightIcon from 'assets/icons/rcs/horizontal-right.svg';
import horizontalRightActiveIcon from 'assets/icons/rcs/horizontal-right-active.svg';
import verticalIcon from 'assets/icons/rcs/vertical.svg';
import verticalActiveIcon from 'assets/icons/rcs/vertical-active.svg';
import crossIcon from 'assets/icons/cross-grey.svg';

import './style.scss';

const RichCard = ({
  rcsParams: {
    axis,
    cardWidth,
    title,
    message,
    titleFontStyle,
    messageFontStyle,
    suggestionsInside,
    suggestionsOutside,
    cards,
    ...rcsParams
  },
  updateRcsParams,
  openMediaSelect,
  openCreateSuggestion,
  isCarousel,
  updateGeneralRcsParams,
  removeCard,
  cardIndex,
  readOnly,
  openUploadMedia,
  isOpenInTab,
}) => {
  const iconButtonStyle = {
    width: 20,
    minWidth: 20,
    height: 16,
  };
  const outsideSuggestionsStyles = {
    wrapper: {
      padding: '0 24px',
    },
    button: {
      width: cardWidth === 'small' ? 270 : 306,
      margin: isCarousel ? '10px auto 0' : '24px auto 0',
    },
  };

  const richCardClass = classNames('mkit-rich-card', {
    'mkit-rich-card_in-carousel': isCarousel,
    'mkit-rich-card_small-width': cardWidth === 'small',
  });
  const textClass = classNames({
    'mkit-rich-card__text': true,
    [`mkit-rich-card__text_${axis?.toLowerCase()}`]: axis,
  });

  const { t } = useTranslation();

  const setTitle = (newTitle) => {
    updateRcsParams({
      title: newTitle,
    });
  };

  const setTitleFontStyle = (newFontStyle) => {
    const updateFunction = isCarousel ? updateGeneralRcsParams : updateRcsParams;

    updateFunction({
      titleFontStyle: newFontStyle,
    });
  };

  const setMessage = (newMessage) => {
    updateRcsParams({
      message: newMessage,
    });
  };

  const setMessageFontStyle = (newFontStyle) => {
    const updateFunction = isCarousel ? updateGeneralRcsParams : updateRcsParams;

    updateFunction({
      messageFontStyle: newFontStyle,
    });
  };

  const onFileClick = () => {
    window.open(rcsParams.image.fileUrl, '_blank');
  };

  const axisButtons = useMemo(() => getButtonsArray({
    keyValue: 'axis',
    value: axis,
    onClick: updateRcsParams,
    buttonsConfig: [
      {
        activeKey: 'LEFT',
        icon: horizontalLeftIcon,
        activeIcon: horizontalLeftActiveIcon,
      },
      {
        activeKey: 'VERTICAL',
        icon: verticalIcon,
        activeIcon: verticalActiveIcon,
      },
      {
        activeKey: 'RIGHT',
        icon: horizontalRightIcon,
        activeIcon: horizontalRightActiveIcon,
      },
    ],
  }), [axis]);

  const onAddSuggestion = (place) => {
    openCreateSuggestion({ place });
  };

  const onRemoveSuggestion = (place, id) => {
    if (place === 'outside') {
      updateRcsParams({ suggestionsOutside: suggestionsOutside.filter(I => I.id !== id) });
    } else {
      updateRcsParams({ suggestionsInside: suggestionsInside.filter(I => I.id !== id) });
    }
  };

  const onSuggestionChange = (text, id, place) => {
    let suggestionsModify;
    let suggestionKey = 'suggestionsInside';

    if (place === 'inside') {
      suggestionsModify = suggestionsInside;
    } else {
      suggestionsModify = suggestionsOutside;
      suggestionKey = 'suggestionsOutside';
    }

    const changedSuggestion = suggestionsModify.find(I => I.id === id);

    suggestionsModify[suggestionsModify.indexOf(changedSuggestion)] = {
      ...changedSuggestion,
      text,
    };

    updateRcsParams({
      [suggestionKey]: suggestionsModify,
    });
  };

  const onCrossClick = () => {
    if (isCarousel) {
      removeCard();
    }
  };

  return (
    <div className={richCardClass}>
      {
        !isCarousel && (
          <div className="mkit-rich-card__header-tools">
            <div className="mkit-rich-card__header-tools__axis">
              {
                !readOnly && axisButtons.map(I => (
                  <IconButton
                    icon={I.icon}
                    onClick={I.onClick}
                    style={iconButtonStyle}
                  />
                ))
              }
            </div>
            {
              !readOnly && (
                <IconButton
                  icon={crossIcon}
                  onClick={onCrossClick}
                  style={iconButtonStyle}
                />
              )
            }
          </div>
        )
      }
      <div className={`mkit-rich-card__${axis?.toLowerCase()}`}>
        <ImageElement
          axis={axis}
          rcsParams={rcsParams}
          updateRcsParams={updateRcsParams}
          openMediaSelect={openMediaSelect}
          readOnly={readOnly}
          openUploadMedia={openUploadMedia}
          onImageClick={isOpenInTab && onFileClick}
          withThumbnailSelect
        />
        <div className={textClass}>
          <EditorTextarea
            value={title}
            onChange={setTitle}
            fontStyle={titleFontStyle}
            onChangeFontStyle={setTitleFontStyle}
            styleWrapper={{ marginBottom: 10, minHeight: axis !== 'VERTICAL' && !title ? 40 : 'unset' }}
            cardIndex={cardIndex}
            axis={axis}
            readOnly={readOnly}
            placeholderValue={`${t('MESSAGE_CONSTRUCTOR.PLACEHOLDERS.ENTER_OR_LEAVE_TITLE')} ${t('MESSAGE_CONSTRUCTOR.PLACEHOLDERS.MAX_CHARS', { count: 200 })}`}
            maxLength={200}
            isTitle
          />
          {
            (!!message || !readOnly) && (
              <EditorTextarea
                value={message}
                onChange={setMessage}
                fontStyle={messageFontStyle}
                onChangeFontStyle={setMessageFontStyle}
                axis={axis}
                cardIndex={cardIndex}
                readOnly={readOnly}
                placeholderValue={`${t('MESSAGE_CONSTRUCTOR.PLACEHOLDERS.ENTER_OR_LEAVE_MESSAGE')} ${t('MESSAGE_CONSTRUCTOR.PLACEHOLDERS.MAX_CHARS', { count: 2000 })}`}
                maxLength={2000}
              />
            )
          }
        </div>
        <DnDSuggestionsInside
          suggestionsInside={suggestionsInside}
          updateRcsParams={updateRcsParams}
          readOnly={readOnly}
          openCreateSuggestion={openCreateSuggestion}
          onRemoveSuggestion={onRemoveSuggestion}
          cardIndex={cardIndex}
          axis={axis}
          onAddSuggestion={onAddSuggestion}
        />
      </div>
      {
        !isCarousel && (
          <DnDSuggestionsOutside
            updateRcsParams={updateRcsParams}
            suggestionsOutside={suggestionsOutside}
            onSuggestionChange={onSuggestionChange}
            onRemoveSuggestion={onRemoveSuggestion}
            openCreateSuggestion={openCreateSuggestion}
            cardIndex={cardIndex}
            readOnly={readOnly}
            styles={outsideSuggestionsStyles}
            onAddSuggestion={onAddSuggestion}
          />
        )
      }
    </div>
  );
};

export default RichCard;
