import React from 'react';
import classNames from 'classnames';

import './style.scss';

const MediaCellRenderer = ({
  value,
  style,
  data,
  onClick,
}) => {
  const className = classNames('media-cell-renderer', {
    'media-cell-renderer_pointer': onClick && data.mediaType === 'Image',
    'media-cell-renderer_invert': data.mediaType !== 'Image' && data.mediaType !== 'Video',
  });

  const onIconClick = () => {
    if (onClick && data.mediaType === 'Image') {
      onClick(data);
    }
  };

  return (
    <img
      src={value}
      className={className}
      alt=""
      style={style}
      onClick={onIconClick}
      role="presentation"
    />
  );
};

export default MediaCellRenderer;
