import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

import { ListFilterForm } from 'components/forms';
import { useWhatsAppTemplatesApi, useSendersApi } from 'hooks/api';

const TemplatesFilterFormWrapper = forwardRef(({
  onSubmit,
  initialValues,
  column,
  colDef,
  ...props
}, ref) => {
  const {
    getWhatsAppLanguages,
    getWhatsAppCategories,
    whatsAppLanguages,
    whatsAppCategories,
  } = useWhatsAppTemplatesApi();
  const {
    getSenders,
    senders,
  } = useSendersApi();
  const tagsFilterRef = useRef();

  useImperativeHandle(ref, () => ({
    getModel() {
      return tagsFilterRef.current.getModel();
    },
    setModel(values = { value: '' }) {
      tagsFilterRef.current.setModel(values);
    },
    afterGuiAttached() {
      getWhatsAppLanguages();
      getWhatsAppCategories();
      getSenders({ limit: 1000, onlyActiveSenders: false });
    },
    isFilterActive() {
      return tagsFilterRef.current.isFilterActive();
    },
    doesFilterPass() {
      return true;
    },
  }));

  const items = useMemo(() => {
    switch (colDef.headerComponentParams.instance) {
      case 'language':
        return whatsAppLanguages.map(({ code, nameEn }) => ({
          id: code,
          title: nameEn,
        }));
      case 'category':
        return whatsAppCategories.map(({ code }) => ({
          id: code,
          title: code,
        }));
      case 'sender':
        return senders.filter(I => I.channel === 4)
          .map(I => ({
            id: I.senderId,
            title: I.displayName,
          }));
      default: return [];
    }
  }, [
    whatsAppLanguages,
    whatsAppCategories,
    senders,
    colDef.headerComponentParams.instance,
  ]);

  return (
    <ListFilterForm
      {...props}
      colDef={colDef}
      column={column}
      ref={tagsFilterRef}
      items={items}
      onSubmit={onSubmit}
      withSearch
      withFilter
    />
  );
});

export default TemplatesFilterFormWrapper;
