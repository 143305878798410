import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import { Form, Field, FormSpy } from 'react-final-form';
import { createForm } from 'final-form';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { uikit } from 'components';
import { BasicButton } from 'components/buttons';
import { CheckboxField, SwitchField } from 'components/fields';
import saveIcon from 'assets/icons/ok.svg';
import cancelIcon from 'assets/icons/cross-dark.svg';

import './style.scss';

const DateFilter = forwardRef(({
  styles,
  onSubmit: onExternalSubmit,
  initialValues,
  colDef: {
    field,
    headerComponentParams: {
      withoutEmpty = false,
      onlyRange = false,
    } = {},
  } = {},
  filterChangedCallback,
  api,
}, ref) => {
  const paddingLeft = withoutEmpty ? 0 : 37;

  const { t } = useTranslation();

  const isApply = useRef();
  const formRef = useRef();
  if (!ref?.current) {
    const form = createForm({
      onSubmit: (values, actions) => {
        // todo delete after the introduction of new tables
        if (onExternalSubmit) {
          onExternalSubmit(values, actions);
        } else {
          isApply.current = true;
          filterChangedCallback({
            values,
            field,
          });

          if (api) {
            api.menuFactory.hidePopup();
          }
        }
      },
      initialValues: {
        range: onlyRange,
      },
    });
    form.pauseValidation();
    formRef.current = form;
  }

  useImperativeHandle(ref, () => ({
    getModel() {
      // eslint-disable-next-line react/no-this-in-sfc
      if (!this.isFilterActive()) {
        return null;
      }

      const { values } = formRef.current.getState();

      return {
        values,
        type: 'date',
        // field: colDef.field,
      };
    },
    setModel(data) {
      isApply.current = true;
      if (data) {
        const { values } = data;
        formRef.current.initialize(values);

        // todo нужно для таких кейсов, применен именной фильтр к одному атрибуту
        // а потом применяем именой фильтр к другому атрибуты и поля в этом фильтре не заполняются
        setTimeout(() => {
          formRef.current.initialize({});
          formRef.current.initialize(values);
        });
      } else {
        formRef.current.initialize({});
      }
    },
    isFilterActive() {
      const { values } = formRef.current.getState();

      if (!values || !isApply.current) {
        return false;
      }

      return Object.values(values).some(item => item);
    },
    doesFilterPass() {
      return true;
    },
  }));

  return (
    <Form
      keepDirtyOnReinitialize
      form={formRef.current}
      initialValues={initialValues}
      render={({
        handleSubmit,
        form,
        values,
        pristine,
      }) => (
        <div className="mkit-date-filter" style={styles}>
          {
            !withoutEmpty && (
              <>
                <Field
                  name="empty"
                  component={CheckboxField}
                  label={t('FILTERS.EMPTY_VALUE')}
                  wrapperStyle={{ marginBottom: 12, marginLeft: 5 }}
                />
                <Field
                  name="notempty"
                  component={CheckboxField}
                  label={t('FILTERS.NOT_EMPTY')}
                  wrapperStyle={{ marginBottom: 8, marginLeft: 5 }}
                  isDisabled={values.byValue}
                />
              </>
            )
          }
          <div className="mkit-date-filter__row">
            {
              !withoutEmpty && (
                <Field
                  name="byValue"
                  wrapperStyle={{ marginLeft: 5 }}
                  component={CheckboxField}
                  // isDisabled={values.notempty}
                />
              )
            }
            <Field
              name="fromValue"
              placeholder={values.range ? t('FILTERS.MORE_VALUE') : t('FILTERS.VALUE')}
              component={uikit.DatePicker}
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
              styleWrapper={{ width: '100%' }}
              // disabled={values.notempty || (!withoutEmpty && !values.byValue)}
              fieldType="underline"
            />
          </div>
          <Field
            name="range"
            component={SwitchField}
            label={t('FILTERS.BY_RANGE')}
            wrapperStyle={{
              marginTop: 15,
              paddingLeft,
            }}
            labelStyle={{ marginRight: 'auto' }}
            isDisabled={onlyRange || (!withoutEmpty && (values.notempty || !values.byValue))}
          />
          {values.range && (
            <Field
              name="toValue"
              placeholder={t('FILTERS.LESS_VALUE')}
              component={uikit.DatePicker}
              showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }}
              styleWrapper={{
                width: '100%',
                paddingLeft,
                marginTop: 6,
              }}
              disabled={!withoutEmpty && (values.notempty || !values.byValue)}
              fieldType="underline"
            />
          )}
          <FormSpy
            subscription={{ values: true }}
            onChange={(props) => {
              if (props.values.fromValue && props.values.fromValue !== values.fromValue) {
                if (!withoutEmpty && !values.byValue) {
                  form.change('byValue', true);
                }

                if (values.notempty) {
                  form.change('notempty', false);
                }
              }

              if (props.values.byValue !== values.byValue && values.notempty) {
                form.change('notempty', false);
              }
            }}
          />
          <div className="mkit-date-filter__actions-wrapper">
            <BasicButton
              text={t('CONTROLS.CLEAR')}
              type={BasicButton.types.SECONDARY}
              icon={cancelIcon}
              onClick={form.reset}
              filterType="white"
            />
            <BasicButton
              text={t('CONTROLS.APPLY')}
              type={BasicButton.types.ACCENT}
              icon={saveIcon}
              onClick={handleSubmit}
              disabled={pristine}
            />
          </div>
        </div>
      )}
    />
  );
});

export default DateFilter;
