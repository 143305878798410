import React, {
  useReducer,
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { BasicButton } from 'components/buttons';
import { ConstructorEditor } from 'components/constructor';
import Drawer from 'components/UIKit/Drawer/Drawer';
import { CreateActionForm } from 'components/forms';
import crossIcon from 'assets/icons/cross-grey.svg';

import './style.scss';

const initialRcsParams = {
  selectedType: '',
  axis: 'VERTICAL',
  titleFontStyle: 'bold',
  imageHeight: 'small',
  suggestionsInside: [],
  suggestionsOutside: [],
};

const CardDisplay = ({
  channel,
  onClose,
}) => {
  const closeButtonStyle = {
    margin: 'auto auto 0 0',
  };

  const { t } = useTranslation();

  const cardDisplayClass = classNames('card-display', {
    'card-display_whats-app': channel.channelType === 4,
  });

  const reducer = (state, { isReset, ...newValue }) => {
    if (isReset) {
      return newValue;
    }

    return ({
      ...state,
      ...newValue,
    });
  };

  const [rcsParams, updateRcsParams] = useReducer(reducer, initialRcsParams);
  const [selectedCard, setSelectedCard] = useState(0);
  const [createSuggestion, setCreateSuggestion] = useState({ open: false, place: '' });

  const openCreateSuggestion = ({ place, initialValues, isReply }) => {
    if (initialValues?.category === 'mapAction' && initialValues?.query) {
      initialValues.by = true;
    }

    setCreateSuggestion({
      open: true,
      place,
      initialValues,
      isReply,
    });
  };
  const closeCreateSuggestion = () => {
    setCreateSuggestion({ ...createSuggestion, open: false });
  };

  useEffect(() => {
    if (channel) {
      updateRcsParams({ isReset: true, ...channel });
    }
  }, [channel]);

  return (
    <div className={cardDisplayClass}>
      <h2 className="card-display__header">
        {t('CONVERSATIONS.INSTANCES.VIEW_MESSAGE')}
      </h2>
      <div className="card-display__content-wrapper">
        <div className="card-display__content-wrapper__header" />
        <ConstructorEditor
          rcsParams={rcsParams}
          updateRcsParams={updateRcsParams}
          selectedCard={selectedCard}
          setSelectedCard={setSelectedCard}
          openCreateSuggestion={openCreateSuggestion}
          constructorType={channel.channelType === 4 ? 'whatsApp' : 'rcs'}
          constructorStyle={{ height: 560 }}
          isOpenInTab
          readOnly
        />
      </div>
      <BasicButton
        text={t('CONTROLS.CLOSE')}
        type={BasicButton.types.CANCEL}
        icon={crossIcon}
        onClick={onClose}
        style={closeButtonStyle}
        isNewStyle
      />
      <div className="card-display__disabler" />
      {
        createSuggestion.open && (
          <Drawer
            visible
            onClose={closeCreateSuggestion}
            width={500}
          >
            <CreateActionForm
              onClose={closeCreateSuggestion}
              onSubmit={Function.prototype}
              initialValues={createSuggestion.initialValues}
              isReply={createSuggestion.isReply}
              readOnly
            />
          </Drawer>
        )
      }
    </div>
  );
};

export default CardDisplay;
