/* eslint-disable react/destructuring-assignment */
import React from 'react';

import './style.scss';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div
          className="condition-node"
          role="presentation"
          onClick={e => e.stopPropagation()}
        >
          <div className="condition-node__header condition-node__header_error" />
          <div className="condition-node__item">
            SYSTEM: Cannot display condition node
          </div>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
