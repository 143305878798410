import React from 'react';
import classNames from 'classnames';

import { useDropdownLogic } from 'hooks';

import './style.scss';

const ExpandButton = ({
  text,
  onClick,
  onItemClick,
  styles = {},
  options,
  disabled,
}) => {
  const {
    buttonRef,
    dropdownRef,
    isDropdownOpen,
    toggleDropdown,
  } = useDropdownLogic();

  const className = classNames('mkit-expand-button', {
    'mkit-expand-button_open': isDropdownOpen,
    'mkit-expand-button_disabled': disabled,
  });

  const handleClick = () => {
    if (!disabled) {
      if (onClick) {
        onClick();
      } else {
        toggleDropdown();
      }
    }
  };

  return (
    <div
      className={className}
      ref={buttonRef}
      onClick={handleClick}
      style={styles.container}
      role="presentation"
      disabled={disabled}
    >
      {text}
      <div className="mkit-expand-button__block" ref={dropdownRef}>
        {
          options.map(({ label, value }) => (
            <button
              className="mkit-expand-button__block__item"
              type="button"
              onClick={() => onItemClick(value)}
            >
              {label}
            </button>
          ))
        }
      </div>
    </div>
  );
};

export default ExpandButton;
