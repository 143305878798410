import endpoints from 'const/endpoints';

export default () => ({
  url: endpoints.getWhatsAppCategoriesUrl(),
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
  },
  transform: response => ({
    whatsAppCategories: response,
  }),
  update: {
    whatsAppCategories: (oldValue, newValue) => newValue,
  },
});
