/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useMemo } from 'react';

import { DEFAULT_CAROUSEL_CARD } from 'const';
import { RichCard, DnDSlides, DnDSuggestionsOutside } from 'components/constructor';
import { getButtonsArray } from 'helpers';
import { IconButton } from 'components/buttons';
import arrowIcon from 'assets/icons/arrow-right-dark.svg';
import smallWidth from 'assets/icons/rcs/small-width.svg';
import smallWidthActive from 'assets/icons/rcs/small-width-active.svg';
import mediumWidth from 'assets/icons/rcs/medium-width.svg';
import mediumWidthActive from 'assets/icons/rcs/medium-width-active.svg';
import crossIcon from 'assets/icons/cross-grey.svg';

import './style.scss';

const CarouselRichCard = ({
  rcsParams,
  updateRcsParams,
  openMediaSelect,
  openCreateSuggestion,
  editorRef,
  selectedCard,
  setSelectedCard,
  readOnly,
  openUploadMedia,
  isOpenInTab,
}) => {
  const iconButtonStyle = {
    width: 20,
    minWidth: 20,
    height: 16,
  };
  const outsideSuggestionsStyles = {
    wrapper: {
      marginTop: 10,
      padding: '0 24px',
    },
    button: {
      width: rcsParams.cardWidth === 'small' ? 270 : 306,
      margin: '10px auto 0',
    },
  };

  const widthButtons = useMemo(() => getButtonsArray({
    keyValue: 'cardWidth',
    value: rcsParams.cardWidth,
    onClick: updateRcsParams,
    buttonsConfig: [
      {
        activeKey: 'small',
        icon: smallWidth,
        activeIcon: smallWidthActive,
      },
      {
        activeKey: 'medium',
        icon: mediumWidth,
        activeIcon: mediumWidthActive,
      },
    ],
  }), [rcsParams.cardWidth]);

  const onLeftClick = () => {
    setSelectedCard(selectedCard - 1);
  };
  const onRightClick = () => {
    if (selectedCard === rcsParams.cards.length - 1) {
      setSelectedCard(0);
    } else {
      setSelectedCard(selectedCard + 1);
    }
  };

  const addCard = () => {
    updateRcsParams({
      ...rcsParams,
      cards: [
        ...rcsParams.cards,
        DEFAULT_CAROUSEL_CARD,
      ],
    });

    setSelectedCard(rcsParams.cards.length);
  };

  const onAddSuggestion = (place) => {
    openCreateSuggestion({ place });
  };

  const onRemoveSuggestion = (place, id) => {
    updateRcsParams({ suggestionsOutside: rcsParams.suggestionsOutside.filter(I => I.id !== id) });
  };

  const onSuggestionChange = (text, id) => {
    const changedSuggestion = rcsParams.suggestionsOutside.find(I => I.id === id);

    rcsParams.suggestionsOutside[rcsParams.suggestionsOutside.indexOf(changedSuggestion)] = {
      ...changedSuggestion,
      text,
    };

    updateRcsParams({
      suggestionsOutside: rcsParams.suggestionsOutside,
    });
  };

  const updateRcsParamsModify = (newValue) => {
    const cardsModify = rcsParams.cards;

    cardsModify[selectedCard] = ({
      ...cardsModify[selectedCard],
      ...newValue,
    });

    updateRcsParams({ cards: cardsModify });
  };

  const removeCard = () => {
    if (rcsParams.cards.length > 2) {
      if (selectedCard !== 0) {
        setSelectedCard(selectedCard - 1);
      }
      rcsParams.cards.splice(selectedCard, 1);
      updateRcsParams({ cards: rcsParams.cards });
    } else {
      const cardsModify = rcsParams.cards;

      cardsModify[selectedCard] = DEFAULT_CAROUSEL_CARD;

      updateRcsParams({ cards: cardsModify });
    }
  };

  return (
    <div className="mkit-carousel-rich-card">
      <div className="mkit-carousel-rich-card__tools">
        <div className="mkit-carousel-rich-card__tools__card-width">
          {
            !readOnly && widthButtons.map(I => (
              <IconButton
                icon={I.icon}
                onClick={I.onClick}
                style={iconButtonStyle}
              />
            ))
          }
        </div>
        {
          !readOnly && (
            <IconButton
              icon={crossIcon}
              onClick={removeCard}
              style={iconButtonStyle}
            />
          )
        }
      </div>
      <button
        type="button"
        className="mkit-carousel-rich-card__arrow-button mkit-carousel-rich-card__arrow-button_left"
        onClick={onLeftClick}
        disabled={selectedCard === 0}
      >
        <img src={arrowIcon} alt="" />
      </button>
      <RichCard
        rcsParams={{
          ...rcsParams.cards[selectedCard],
          suggestionsOutside: rcsParams.suggestionsOutside,
          messageFontStyle: rcsParams.messageFontStyle,
          titleFontStyle: rcsParams.titleFontStyle,
          cardWidth: rcsParams.cardWidth,
          cards: rcsParams.cards,
        }}
        updateRcsParams={updateRcsParamsModify}
        openMediaSelect={openMediaSelect}
        openCreateSuggestion={openCreateSuggestion}
        editorRef={editorRef}
        updateGeneralRcsParams={updateRcsParams}
        removeCard={removeCard}
        selectedCard={selectedCard}
        setSelectedCard={setSelectedCard}
        cardIndex={selectedCard}
        key={selectedCard}
        readOnly={readOnly}
        openUploadMedia={openUploadMedia}
        isOpenInTab={isOpenInTab}
        isCarousel
      />
      <DnDSlides
        cards={rcsParams.cards}
        updateRcsParams={updateRcsParams}
        selectedCard={selectedCard}
        setSelectedCard={setSelectedCard}
        readOnly={readOnly}
      />
      <DnDSuggestionsOutside
        updateRcsParams={updateRcsParams}
        suggestionsOutside={rcsParams.suggestionsOutside}
        onSuggestionChange={onSuggestionChange}
        onRemoveSuggestion={onRemoveSuggestion}
        openCreateSuggestion={openCreateSuggestion}
        cardIndex={selectedCard}
        readOnly={readOnly}
        styles={outsideSuggestionsStyles}
        onAddSuggestion={onAddSuggestion}
      />
      {
        selectedCard === rcsParams.cards.length - 1 && !readOnly
          ? (
            <button
              type="button"
              className="mkit-carousel-rich-card__arrow-button"
              onClick={addCard}
              disabled={rcsParams.cards.length === 12}
            >
              +
            </button>
          )
          : (
            <button
              type="button"
              className="mkit-carousel-rich-card__arrow-button"
              onClick={onRightClick}
            >
              <img src={arrowIcon} alt="" />
            </button>
          )
      }
    </div>
  );
};

export default CarouselRichCard;
