import React, { useState } from 'react';
import classNames from 'classnames';

import { IconButton } from 'components/buttons';
import crossIcon from 'assets/icons/cross-grey.svg';
import gearIcon from 'assets/icons/gear.svg';

import './style.scss';

const SuggestionItem = React.forwardRef(({
  type,
  text,
  icon,
  onChangeText,
  isLast,
  axis,
  onRemove,
  onEdit,
  cardIndex,
  readOnly,
  wrapperRef,
  ref,
  id = 1,
  ...props
}) => {
  const iconStyle = { width: 13, height: 13 };
  const iconButtonStyle = { minWidth: 13, width: 13, height: 13 };

  const className = classNames('mkit-suggestion-item', {
    [`mkit-suggestion-item_${type}`]: type,
    'mkit-suggestion-item_with-icon': !!icon,
    [`mkit-suggestion-item_last-${axis?.toLowerCase()}`]: isLast && axis,
    [`mkit-suggestion-item_${axis?.toLowerCase()}`]: axis,
  });

  const [isHover, setIsHover] = useState(false);

  // const maxLength = useMemo(() => {
  //   if (type === 'purple') {
  //     return 32;
  //   }
  //
  //   return 25;
  // }, [axis, type]);

  return (
    <div
      className={className}
      ref={wrapperRef || ref}
      {...props}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {
        icon && (
          <img src={icon} alt="" className="mkit-suggestion-item__icon" />
        )
      }
      <div
        className="mkit-suggestion-item__input"
        id={`suggestionEditor-${id}-${type}`}
      >
        {text}
      </div>
      {
        (type === 'inside' || isHover) && (
          <div className="mkit-suggestion-item__control-wrapper">
            <IconButton
              onClick={onEdit}
              icon={gearIcon}
              style={{ ...iconButtonStyle, marginRight: 5 }}
              iconStyle={iconStyle}
            />
            {
              type !== 'purple' && !readOnly && (
                <IconButton
                  onClick={onRemove}
                  icon={crossIcon}
                  style={iconButtonStyle}
                  iconStyle={{ ...iconStyle, width: 20, height: 20 }}
                />
              )
            }
          </div>
        )
      }
      {readOnly && <div className="mkit-suggestion-item__disabler" />}
    </div>
  );
});

export default SuggestionItem;
