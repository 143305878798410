import { DefaultPortModel } from '@projectstorm/react-diagrams';
import { isEmpty } from 'lodash';

import LinkModel from '../Link/LinkModel';

export default class PortInModel extends DefaultPortModel {
  constructor(options) {
    super({
      ...options,
      type: 'port-in',
    });
  }

  canLinkToPort(port) {
    if (this.options.in === port.getOptions().in) return false; // in->in / out->out
    if (this.getNode().getID() === port.getNode().getID()) return false; // node n2->node n2

    const out = this.options.in ? port : this; // one of the ports is necessarily out
    const outLinks = Object.values(out.getLinks()); // gets all links attached to this out port
    if (outLinks.length > 1) return false; // if there is any already, disallow linking

    return true;
  }

  link(port, options) {
    const link = this.createLinkModel(options);
    link.setSourcePort(this);
    link.setTargetPort(port);
    return link;
  }

  getTargetPortName() {
    if (!isEmpty(this.links)) {
      const key = Object.keys(this.links)?.[0];

      return this.links[key].getTargetPort().getOptions().name;
    }

    return null;
  }

  getTargetNodeId() {
    if (!isEmpty(this.links)) {
      const key = Object.keys(this.links)?.[0];

      return this.links[key].getTargetPort().getNode().getID();
    }

    return null;
  }

  isChangedState() {
    const currentState = JSON.stringify({
      id: this.getID(),
      fromNodeId: this.getNode().getID(),
      toNodeId: this.getTargetNodeId(),
      condition: this.options.condition,
      isFinal: this.options.isFinal,
    });
    const { prevState } = this.options;
    this.options.prevState = currentState;

    return prevState !== currentState;
  }

  removeLink(link) {
    super.removeLink(link);

    if (!this.options.in && this.isChangedState()) {
      const parentCanvasModel = this.getParentCanvasModel();
      // todo "!this.options.isRemove" костыль,
      //  что бы при удалениии линка вместе с портом, не вызывалось событие 'portUpdated'
      if (parentCanvasModel && !this.options.isRemove) {
        parentCanvasModel.fireEvent({ port: this }, 'portUpdated');
      }
    }
  }

  createLinkModel(options) {
    if (!this.options.in && !this.options.isFinal && Object.values(this.getLinks()).length < 1) {
      return new LinkModel({ id: this.options.id, ...options });
    }

    return undefined;
  }

  changeTypePort(isFinal) {
    this.options.isFinal = isFinal;

    if (this.isChangedState()) {
      const parentCanvasModel = this.getParentCanvasModel();
      if (parentCanvasModel) {
        parentCanvasModel.fireEvent({ port: this }, 'portUpdated');
      }
    }
  }

  updatePort(options) {
    this.options = {
      ...this.options,
      ...options,
    };

    if (this.isChangedState()) {
      const parentCanvasModel = this.getParentCanvasModel();
      if (parentCanvasModel) {
        parentCanvasModel.fireEvent({ port: this }, 'portUpdated');
      }
    }

    return this;
  }
}
