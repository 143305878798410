import locationIcon from 'assets/icons/location.svg';
import linkIcon from 'assets/icons/link.svg';
import phoneIcon from 'assets/icons/phone.svg';
import calendarIcon from 'assets/icons/calendar.svg';
import textIcon from 'assets/icons/text.svg';
import settingsIcon from 'assets/icons/settings_white.svg';
import locationIconDark from 'assets/icons/location_dark.svg';
import linkIconDark from 'assets/icons/link_dark.svg';
import phoneIconDark from 'assets/icons/phone_dark.svg';
import calendarIconDark from 'assets/icons/calendar_dark.svg';
import textIconDark from 'assets/icons/text_dark.svg';
import settingsIconDark from 'assets/icons/settings_dark.svg';

export const RICH_CARD_ORIENTATION_VERTICAL = 'VERTICAL';
export const RICH_CARD_ORIENTATION_HORIZONTAL = 'HORIZONTAL';

export const RICH_CARD_MEDIA_ALIGNMENT_LEFT = 'LEFT';
export const RICH_CARD_MEDIA_ALIGNMENT_RIGHT = 'RIGHT';

export const RICH_CARD_MEDIA_HEIGHT_SHORT = 'SHORT_HEIGHT';
export const RICH_CARD_MEDIA_HEIGHT_MEDIUM = 'MEDIUM_HEIGHT';
export const RICH_CARD_MEDIA_HEIGHT_TALL = 'TALL_HEIGHT';

export const RICH_CARD_TEXT_STYLE_BOLD = 'bold';
export const RICH_CARD_TEXT_STYLE_ITALIC = 'italic';
export const RICH_CARD_TEXT_STYLE_UNDERLINE = 'underline';

export const RICH_CARD_ACTION_CATEGORIES = {
  urlAction: 'urlAction',
  dialerAction: 'dialerAction',
  mapAction: 'mapAction',
  calendarAction: 'calendarAction',
  composeAction: 'composeAction',
  deviceAction: 'deviceAction',
  settingsAction: 'settingsAction',
};

export const RICH_CARD_ACTION_CATEGORIES_ICONS = {
  [RICH_CARD_ACTION_CATEGORIES.urlAction]: {
    light: linkIcon,
    dark: linkIconDark,
  },
  [RICH_CARD_ACTION_CATEGORIES.dialerAction]: {
    light: phoneIcon,
    dark: phoneIconDark,
  },
  [RICH_CARD_ACTION_CATEGORIES.mapAction]: {
    light: locationIcon,
    dark: locationIconDark,
  },
  [RICH_CARD_ACTION_CATEGORIES.calendarAction]: {
    light: calendarIcon,
    dark: calendarIconDark,
  },
  [RICH_CARD_ACTION_CATEGORIES.composeAction]: {
    light: textIcon,
    dark: textIconDark,
  },
  [RICH_CARD_ACTION_CATEGORIES.deviceAction]: {
    light: settingsIcon,
    dark: settingsIconDark,
  },
  [RICH_CARD_ACTION_CATEGORIES.settingsAction]: {
    light: settingsIcon,
    dark: settingsIconDark,
  },
};

export const RICH_CARD_ACTIONS = {
  openUrl: 'openUrl',
  dialPhoneNumber: 'dialPhoneNumber',
  dialEnrichedCall: 'dialEnrichedCall',
  dialVideoCall: 'dialVideoCall',
  showLocation: 'showLocation',
  requestLocationPush: 'requestLocationPush',
  createCalendarEvent: 'createCalendarEvent',
  composeTextMessage: 'composeTextMessage',
  composeRecordingMessage: 'composeRecordingMessage',
  requestDeviceSpecifics: 'requestDeviceSpecifics',
  disableAnonymization: 'disableAnonymization',
  enableDisplayedNotifications: 'enableDisplayedNotifications',
};

export const RICH_CARD_ACTION_URL_OPEN_APPLICATION = {
  browser: 'browser',
  webview: 'webview',
};

export const RICH_CARD_ACTION_URL_OPEN_VIEW_MODE = {
  full: 'full',
  half: 'half',
  tall: 'tall',
};

export const RICH_CARD_COMPOSE_RECORDING_MESSAGE = {
  audio: 'AUDIO',
  video: 'VIDEO',
};

export const RICH_CARD_DEFAULT_ACTION_CATEGORY = RICH_CARD_ACTION_CATEGORIES.urlAction;
export const RICH_CARD_DEFAULT_ACTION = RICH_CARD_ACTIONS.openUrl;
export const RICH_CARD_DEFAULT_ACTION_CONFIG = {
  url: 'http://',
  application: RICH_CARD_ACTION_URL_OPEN_APPLICATION.browser,
};

export const RICH_CARD_ACTION_CATEGORIES_LIST = [
  {
    value: RICH_CARD_ACTION_CATEGORIES.urlAction,
    title: 'url action',
    actions: [
      {
        value: RICH_CARD_ACTIONS.openUrl,
        title: 'Open url',
        defaultValues: {
          url: '',
          application: RICH_CARD_ACTION_URL_OPEN_APPLICATION.browser,
        },
      },
    ],
  },
  {
    value: RICH_CARD_ACTION_CATEGORIES.dialerAction,
    title: 'Dialer action',
    actions: [
      {
        value: RICH_CARD_ACTIONS.dialPhoneNumber,
        title: 'Dial phone number',
        defaultValues: {
          phoneNumber: '',
        },
      },
      {
        value: RICH_CARD_ACTIONS.dialEnrichedCall,
        title: 'Dial enriched call',
        defaultValues: {
          phoneNumber: '',
        },
      },
      {
        value: RICH_CARD_ACTIONS.dialVideoCall,
        title: 'Dial video call',
        defaultValues: {
          phoneNumber: '',
        },
      },
    ],
  },
  {
    value: RICH_CARD_ACTION_CATEGORIES.mapAction,
    title: 'map action',
    actions: [
      {
        value: RICH_CARD_ACTIONS.showLocation,
        title: 'Show location',
        defaultValues: {
          location: {},
        },
      },
      {
        value: RICH_CARD_ACTIONS.requestLocationPush,
        title: 'Request location push',
        defaultValues: {},
      },
    ],
  },
  {
    value: RICH_CARD_ACTION_CATEGORIES.calendarAction,
    title: 'calendar category',
    actions: [
      {
        value: RICH_CARD_ACTIONS.createCalendarEvent,
        title: 'Create calendar event',
        defaultValue: {
          startTime: '',
          endTime: '',
          title: '',
        },
      },
    ],
  },
  {
    value: RICH_CARD_ACTION_CATEGORIES.composeAction,
    title: 'compose actions',
    actions: [
      {
        value: RICH_CARD_ACTIONS.composeTextMessage,
        title: 'Compose text message',
        defaultValues: {
          phoneNumber: '',
          text: '',
        },
      },
      {
        value: RICH_CARD_ACTIONS.composeRecordingMessage,
        title: 'Compose recording message',
        defaultValues: {
          phoneNumber: '',
          type: '',
        },
      },
    ],
  },
  {
    value: RICH_CARD_ACTION_CATEGORIES.deviceAction,
    title: 'device action',
    actions: [
      {
        value: RICH_CARD_ACTIONS.requestDeviceSpecifics,
        title: 'Request device specifics',
        defaultValues: {},
      },
    ],
  },
  {
    value: RICH_CARD_ACTION_CATEGORIES.settingsAction,
    title: 'settings action',
    actions: [
      {
        value: RICH_CARD_ACTIONS.disableAnonymization,
        title: 'Disable anonymization',
        defaultValues: {},
      },
      {
        value: RICH_CARD_ACTIONS.enableDisplayedNotifications,
        title: 'Enable displayed notifications',
        defaultValues: {},
      },
    ],
  },
];

export const DEFAULT_CAROUSEL_CARD = {
  axis: 'VERTICAL',
  suggestionsInside: [],
  title: '',
  message: '',
  imageHeight: 'small',
};
