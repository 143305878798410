import endpoints from 'const/endpoints';

export default ({
  body,
  successCallback,
  errorCallback,
} = {}) => ({
  url: endpoints.getImportFlowUrl(),
  body,
  options: {
    method: 'POST',
  },
  meta: {
    authToken: true,
    successCallback,
    errorCallback,
  },
});
