import React, { useMemo } from 'react';

import { ImageElement, GeolocationElement } from 'components/constructor';
import { MIME_TYPES } from 'const';

import './style.scss';

const Header = ({
  format,
  text,
  type,
  componentsParams,
  className,
  rcsParams,
  updateRcsParams,
  openMediaSelect,
  openGeolocation,
  readOnly,
}) => {
  const filePrams = useMemo(() => {
    const {
      image,
    } = rcsParams || {};
    if (image && (!image?.thumbnailUri || !image?.fileMimeType || !image?.fileName)) {
      const fileExt = image?.fileUrl?.split('.')?.pop();
      const fileName = decodeURIComponent(image?.fileUrl?.split('/')?.pop());
      return {
        image: {
          ...image,
          fileName,
          thumbnailUri: image?.fileUrl,
          fileMimeType: MIME_TYPES[fileExt],
          fileUrl: image?.fileUrl,
        },
      };
    }
    return rcsParams;
  }, [rcsParams]);

  const displayText = useMemo(() => {
    if (componentsParams[type]) {
      return text.replace(/{{\d}}/g, (string) => {
        const variableValue = componentsParams[type][string.replace(/{{/g, '').replace(/}}/g, '')];

        return variableValue || string;
      });
    }
    return text;
  }, [text, componentsParams]);

  switch (format) {
    case 'TEXT':
      return (
        <div className={className}>
          {displayText}
        </div>
      );
    case 'IMAGE':
    case 'VIDEO':
      return (
        <div className={className}>
          <ImageElement
            rcsParams={filePrams}
            updateRcsParams={updateRcsParams}
            openMediaSelect={openMediaSelect}
            type="in-template"
            readOnly={readOnly}
          />
        </div>
      );
    case 'DOCUMENT':
      return (
        <div className={className}>

          {
            !filePrams.image || filePrams.image?.fileMimeType?.includes('image') || filePrams.image?.fileMimeType?.includes('video')
              ? (
                <ImageElement
                  rcsParams={filePrams}
                  updateRcsParams={updateRcsParams}
                  openMediaSelect={openMediaSelect}
                  type="any-file-in-template"
                  readOnly={readOnly}
                />
              )
              : (
                <div className="whats-app-file__file">
                  <div
                    role="presentation"
                    className="whats-app-file__file__image"
                    onClick={readOnly ? Function.prototype : openMediaSelect}
                  />
                  <div className="whats-app-file__file__info">
                    <span>{filePrams.image.fileName}</span>
                    {/* will be added in new constructor */}
                    {/* <span>{bytesToSize(filePrams.image.fileSize)}</span> */}
                  </div>
                </div>
              )
          }
        </div>
      );
    case 'LOCATION':
      return (
        <div className={className}>
          <GeolocationElement
            rcsParams={filePrams}
            openGeolocation={openGeolocation}
            type="in-template"
            readOnly={readOnly}
          />
        </div>
      );
    default: return <span>hello</span>;
  }
};

export default Header;
