import React from 'react';
import NewAuditScreen from 'mkit-front-ds/dist/screens/AuditScreen';

import './style.scss';

const AuditScreen = () => (
  <div className="audit-screen-container">
    <NewAuditScreen />
  </div>
);

export default AuditScreen;
