import { useReducer } from 'react';

const reducer = (state, { action }) => {
  switch (action) {
    case 'true': {
      if (!state) {
        return true;
      }
      break;
    }
    case 'false': {
      if (state) {
        return false;
      }
      break;
    }
    case 'toggle': {
      return !state;
    }
    default:
      break;
  }

  return state;
};

export default (initialValue) => {
  const [state, setState] = useReducer(reducer, initialValue);

  const setTrue = () => {
    setState({ action: 'true' });
  };
  const setFalse = () => {
    setState({ action: 'false' });
  };
  const toggle = () => {
    setState({ action: 'toggle' });
  };

  return [
    state,
    setTrue,
    setFalse,
    toggle,
  ];
};
