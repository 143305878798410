import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useDropdownLogic } from 'hooks';
import { ReactComponent as MessageIcon } from 'assets/icons/message.svg';
import { ReactComponent as MessageIconNew } from 'assets/icons/message_new.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/error.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow_down_new.svg';

import './style.scss';

const SendersDropdown = ({
  values,
  value,
  onChange,
  style,
  showError,
  disabled,
  isNewDesign = false,
  channel = 'sms',
}) => {
  const { t } = useTranslation();
  const {
    buttonRef,
    dropdownRef,
    isDropdownOpen,
    toggleDropdown,
  } = useDropdownLogic();

  const className = classNames('mkit-senders-dropdown', {
    'mkit-senders-dropdown_open': isDropdownOpen,
    'mkit-senders-dropdown_empty': showError && !value,
    'mkit-senders-dropdown_disabled': disabled,
    'mkit-senders-dropdown_new-design': isNewDesign,
    [`mkit-senders-dropdown_${channel}`]: channel,
  });

  const dropdownClass = classNames('mkit-senders-dropdown__block', {
    'mkit-senders-dropdown__block_new-design': isNewDesign,
  });

  const dropdownItemClass = classNames('mkit-senders-dropdown__block__item', {
    'mkit-senders-dropdown__block__item_new-design': isNewDesign,
  });

  const sortedValues = useMemo(
    () =>
      values.sort((a, b) => a.title.localeCompare(b.title)),
    [values],
  );

  const selectedItem = useMemo(() => values.find(I => I.id === value), [value, values]);

  return (
    <div
      className={className}
      ref={buttonRef}
      onClick={disabled ? Function.prototype : toggleDropdown}
      style={style}
      role="presentation"
    >
      <div className="mkit-senders-dropdown__value-wrapper">
        {isNewDesign ? (
          <MessageIconNew className="mkit-senders-dropdown__value-wrapper__icon" />
        ) : (
          <MessageIcon className="mkit-senders-dropdown__value-wrapper__icon" />
        )}
        {selectedItem?.title ? (
          <span className="mkit-senders-dropdown__value-wrapper__title">
            {selectedItem?.title}
          </span>
        ) : showError ? (
          <div className="mkit-senders-dropdown__value-wrapper__error">
            <ErrorIcon className="mkit-senders-dropdown__value-wrapper__error-icon" />
            {t('MESSAGE_CONSTRUCTOR.PLACEHOLDERS.PLEASE_SELECT')}
          </div>
        ) : (
          <span className="mkit-senders-dropdown__value-wrapper__placeholder">
            {t('CONVERSATIONS.INSTANCES.SELECT_SENDER')}
          </span>
        )}
        <ArrowDown className="mkit-senders-dropdown__arrow-icon" />
      </div>
      <div className={dropdownClass} ref={dropdownRef}>
        {
          sortedValues.filter(I => I.id !== value).map(I => (
            <button
              className={dropdownItemClass}
              type="button"
              onClick={() => onChange(I.id)}
            >
              {I.title}
            </button>
          ))
        }
      </div>
    </div>
  );
};

export default SendersDropdown;
