import React from 'react';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { uikit } from 'components';
import {
  BasicField,
  CheckboxField,
  MultiSelectField,
  DropdownField,
} from 'components/fields';
import { BasicButton } from 'components/buttons';
import { required } from 'helpers/validators';
import runIcon from 'assets/icons/run.svg';

import './style.scss';

const CreateReportForm = ({
  onSubmit,
  initialValues,
  params,
  description,
  isLoading,
}) => {
  const iconStyle = { marginRight: 8 };
  const fieldStyle = { marginBottom: 12 };
  const datePickerStyle = { flex: 7, paddingLeft: 24 };
  const buttonStyle = { marginTop: 'auto', marginLeft: 'auto', width: 180 };

  const { t } = useTranslation();

  const getFieldProps = (field) => {
    let fieldProps = {
      name: field.bindName,
      label: `${field.name}${field.mandatory ? ' *' : ''}`,
      key: field.id,
      validate: field.mandatory && (value => required(value, t)),
      fieldId: field.id,
    };

    switch (field.parameterType) {
      case 'Text field':
      case 'Number field':
        fieldProps = {
          ...fieldProps,
          component: BasicField,
          type: 'with-border',
          styleWrapper: fieldStyle,
          placeholder: 'Enter data',
        };
        break;
      case 'Date': {
        fieldProps = {
          ...fieldProps,
          component: uikit.DatePicker,
          style: datePickerStyle,
          dateFormat: 'YYYY-MM-DD',
          labelStyle: { display: 'flex', flex: 3 },
          styleWrapper: fieldStyle,
        };
        break;
      }
      case 'Date and time':
        fieldProps = {
          ...fieldProps,
          component: uikit.DatePicker,
          showTime: { defaultValue: moment('00:00:00', 'HH:mm:ss') },
          closeOnSelect: false,
          style: datePickerStyle,
          labelStyle: { display: 'flex', flex: 3 },
          styleWrapper: fieldStyle,
        };
        break;
      case 'Month and year':
        fieldProps = {
          ...fieldProps,
          component: uikit.DatePicker,
          mode: 'month',
          dateFormat: 'YYYY-MM',
          style: datePickerStyle,
          labelStyle: { display: 'flex', flex: 3 },
          styleWrapper: fieldStyle,
        };
        break;
      case 'Year':
        fieldProps = {
          ...fieldProps,
          component: uikit.DatePicker,
          mode: 'year',
          dateFormat: 'YYYY',
          style: datePickerStyle,
          labelStyle: { display: 'flex', flex: 3 },
          styleWrapper: fieldStyle,
        };
        break;
      case 'Checkbox':
        fieldProps = {
          ...fieldProps,
          component: CheckboxField,
          validate: undefined,
        };
        break;
      case 'Combobox':
        fieldProps = {
          ...fieldProps,
          component: DropdownField,
          styles: fieldStyle,
          values: field.value,
          type: 'border',
        };
        break;
      case 'Multiselect':
        fieldProps = {
          ...fieldProps,
          component: MultiSelectField,
          style: fieldStyle,
          labelStyle: { width: '25%', marginRight: 24 },
          items: field.value,
        };
        break;
      default:
        fieldProps = {
          ...fieldProps,
          label: 'Unknown field type',
          component: BasicField,
          type: 'with-border',
          styleWrapper: fieldStyle,
        };
        break;
    }

    return fieldProps;
  };

  return (
    <Form
      onSubmit={(values) => {
        onSubmit(params.reduce((acc, item) => ({
          ...acc,
          [item.bindName]: values[item.bindName] || '',
        }), {}));
      }}
      initialValues={initialValues}
      render={
        ({
          handleSubmit,
          invalid,
        }) => (
          <div className="create-report-form">
            <span className="create-report-form__description">
              {t('SCREENS.REPORTS.DESCRIPTION', { description })}
            </span>
            {
              params.map(field => (
                <Field {...getFieldProps(field)} />
              ))
            }
            <BasicButton
              text={t('CONTROLS.REPORTS.RUN_REPORT')}
              type={BasicButton.types.ACCENT}
              icon={runIcon}
              style={buttonStyle}
              onClick={handleSubmit}
              disabled={invalid}
              iconStyle={iconStyle}
              loading={isLoading}
              dataTestId={`${window.location.pathname}/report-params/button/run-report`}
            />
          </div>
        )
    }
    />
  );
};

export default CreateReportForm;
