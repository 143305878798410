import React from 'react';

import { useTheme } from 'hooks';
import { CAMPAIGN_CHANNEL_IMAGES } from 'const';

import CampaignChannelBase from './CampaignChannelBase';

const CampaignChannel = ({ channelType, ...props }) => {
  const { isDarkTheme } = useTheme();

  return (
    <CampaignChannelBase
      {...props}
      img={CAMPAIGN_CHANNEL_IMAGES[channelType]?.(isDarkTheme)}
      type={channelType}
    />
  );
};

export default CampaignChannel;
