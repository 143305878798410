export default ({
  url,
  fileName,
  callback,
}) => {
  fetch(url, {
    method: 'GET',
    headers: {
      // eslint-disable-next-line no-undef
      Authorization: `Bearer ${localStorage.getItem('mKitToken')}`,
    },
  })
    .then((response) => {
      if (response.status === 200) {
        return response.blob();
      }

      throw `${response.status} (${response.statusText})`;
    })
    .then((response) => {
      const objectURL = URL.createObjectURL(response);
      const a = document.createElement('a');
      a.href = objectURL;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();

      if (callback) {
        callback();
      }
    })
    .catch(err => console.error(err));
};
