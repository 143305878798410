import React, { useRef } from 'react';
import classNames from 'classnames';

import tagIcon from 'assets/icons/tag.svg';
import tagsIcon from 'assets/icons/tags.svg';
import closeIcon from 'assets/icons/cross.svg';
/*eslint-disable */
import './style.scss';

const Tag = ({
  tagText, // string - текст тега
  isEditable, // переключатель режима редактирования. В режиме редактирования тег может быть изменяем
  disabled,
  onRemove, // output event: удалить тег
  wrapperStyle,
  isMany,
  tooltipText,
}) => {
  const tagRef = useRef(null);

  const tagWrapperClass = classNames({
    'mkit-tag-wrapper': true,
  });
  const tagClass = classNames({
    'mkit-tag-wrapper__tag': true,
    'mkit-tag-wrapper__tag_editable': isEditable,
    'mkit-tag-wrapper__tag_disabled': disabled,
  });
  const previewTagText = tagText.length > 20 ? `${tagText.substr(0, 20)}...` : tagText;

  const removeHandler = () => {
    if (!disabled) {
      onRemove();
    }
  };
  return (
    <div className={tagWrapperClass} style={wrapperStyle} ref={tagRef} title={tooltipText || tagText}>
      <div className={tagClass}>
        <img src={isMany ? tagsIcon : tagIcon} className="mkit-tag-wrapper__tag__img" alt="" />
        <span>{previewTagText}</span>
        {isEditable && <img src={closeIcon} className="mkit-tag-wrapper__tag__close-img" alt="" onClick={removeHandler} />}
      </div>
    </div>
  );
};

export default Tag;
