import React from 'react';
import classNames from 'classnames';

import './style.scss';

const Option = ({
  onSelectOption,
  value,
  label,
  icon,
  filterType,
  isItalic = false,
}) => {
  const optionClass = classNames('option', {
    option_italic: isItalic,
  });

  const handleSelectOption = () => {
    onSelectOption(value);
  };

  const iconClassName = classNames('option__icon', {
    [`option__icon_filter-${filterType}`]: filterType,
  });

  return (
    <div
      className={optionClass}
      role="presentation"
      onClick={handleSelectOption}
    >
      {
        icon && (
          <img
            alt={value}
            src={icon}
            className={iconClassName}
          />
        )
      }
      {label}
    </div>
  );
};

export default Option;
