import React from 'react';
import TwoFactorSmsScreen from 'mkit-front-ds/dist/screens/TwoFactorSmsScreen';

import './style.scss';

const TwoFASMSScreen = () => (
  <div className="two-fa-screen-container">
    <TwoFactorSmsScreen />
  </div>
);

export default TwoFASMSScreen;
