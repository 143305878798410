import React, { useState, useEffect } from 'react';
import NewAccountScreen from 'mkit-front-ds/dist/screens/AccountScreen';

import endpoints from 'const/endpoints';
import { useTheme } from 'hooks';
import { useUsersApi } from 'hooks/api';

import './style.scss';

const AccountScreen = () => {
  const {
    isDarkTheme,
    setDarkTheme,
    toggleTheme,
  } = useTheme();
  const [buildInfo, setBuildInfo] = useState('');
  const { currentUser } = useUsersApi();

  useEffect(() => {
    fetch(endpoints.getBuildInfoUrl())
      .then(res => res.json())
      .then(res => setBuildInfo(Object.entries(res).reduce((acc, [key, value]) => {
        acc += `${acc ? '\n' : ''}${key.replace('.', ' ')}: ${value}`;
        return acc;
      }, '')));
  }, []);

  return (
    <div className="account-screen-container">
      <NewAccountScreen
        externalToggleTheme={toggleTheme}
        externalIsDarkTheme={isDarkTheme}
        externalSetDarkTheme={setDarkTheme}
        externalUserId={currentUser?.id}
      />
      <div className="account-screen-container__build-info">
        {buildInfo}
      </div>
    </div>
  );
};

export default AccountScreen;
