import React from 'react';

import { ImageElement } from 'components/constructor';
import { bytesToSize } from 'helpers';

import './style.scss';

const WhatsAppFile = ({
  rcsParams: {
    suggestionsOutside,
    ...rcsParams
  },
  updateRcsParams,
  openMediaSelect,
  openUploadMedia,
  readOnly,
  isOpenInTab,
}) => {
  const onFileClick = () => {
    window.open(rcsParams.image.fileUrl, '_blank');
  };

  return (
    <div className="mkit-whats-app-file">
      {
        !rcsParams.image || rcsParams.image?.fileMimeType.includes('image')
        || (rcsParams.image?.fileMimeType.includes('video') && rcsParams.image?.thumbnailUri)
          ? (
            <ImageElement
              rcsParams={rcsParams}
              updateRcsParams={updateRcsParams}
              openMediaSelect={openMediaSelect}
              type="any-file"
              readOnly={readOnly}
              onImageClick={isOpenInTab && onFileClick}
              openUploadMedia={openUploadMedia}
            />
          )
          : (
            <div className="mkit-whats-app-file__row">
              <div
                role="presentation"
                className="mkit-whats-app-file__staple-button"
                onClick={isOpenInTab ? onFileClick : openMediaSelect}
              />
              <div className="mkit-whats-app-file__info">
                <span>{rcsParams.image.fileName}</span>
                {
                  rcsParams.image.fileSize && (
                    <span>{bytesToSize(rcsParams.image.fileSize)}</span>
                  )
                }
              </div>
            </div>
          )
      }
    </div>
  );
};

export default WhatsAppFile;
