import endpoints from 'const/endpoints';

export default ({
  body,
  errorCallback,
} = {}) => ({
  url: endpoints.getRecipientsInfoUrl(),
  body,
  options: {
    method: 'POST',
  },
  meta: {
    authToken: true,
    errorCallback,
  },
  transform: recipientsInfo => ({ recipientsInfo }),
  update: {
    recipientsInfo: (oldValue, newValue) => newValue,
  },
});
