import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';
import { updateEntities } from 'redux-query';
import { useDispatch } from 'react-redux';

import {
  getSenderGroups as getSenderGroupsApi,
  getUpdateApiKey as getUpdateApiKeyApi,
  postCreateSenderGroup as postCreateSenderGroupApi,
  patchUpdateSenderGroup as patchUpdateSenderGroupApi,
  deleteSenderGroup as deleteSenderGroupApi,
  getSenders as getSendersApi,
} from 'queries/senders';

import {
  getArray,
  useISESelector,
} from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetSenders,
      lastUpdated: lastUpdatedGetSenders,
    },
    getSenders,
  ] = useMutation(getSendersApi);
  const [
    {
      isPending: isPendingGetSenderGroups,
      lastUpdated: lastUpdatedGetSenderGroups,
      isFinished: isFinishedGetSenderGroups,
    },
    getSenderGroups,
  ] = useMutation(getSenderGroupsApi);
  const [, getUpdateApiKey] = useMutation(getUpdateApiKeyApi);
  const [
    {
      isPending: isPendingCreateSenderGroup,
    },
    postCreateSenderGroup,
  ] = useMutation(postCreateSenderGroupApi);
  const [
    {
      isPending: isPendingPatchSenderGroup,
    },
    patchUpdateSenderGroup,
  ] = useMutation(patchUpdateSenderGroupApi);
  const [, deleteSenderGroup] = useMutation(deleteSenderGroupApi);

  const selector = useCallback(state => ({
    freeSenders: getArray(state, 'freeSenders'),
    senders: getArray(state, 'senders'),
    chatSenders: getArray(state, 'chatSenders'),
  }), []);
  const data = useISESelector(selector);

  const dispatch = useDispatch();

  const deleteSenderIds = () => {
    dispatch(
      updateEntities({
        senderIds: () => [],
        senderIdsPagination: () => {},
      }),
    );
  };

  return {
    getSenderGroups,
    getUpdateApiKey,
    postCreateSenderGroup,
    patchUpdateSenderGroup,
    deleteSenderGroup,
    getSenders,
    isPendingGetSenderGroups,
    isPendingPatchSenderGroup,
    isPendingCreateSenderGroup,
    isPendingGetSenders,
    lastUpdatedGetSenderGroups,
    lastUpdatedGetSenders,
    isFinishedGetSenderGroups,
    deleteSenderIds,
    ...data,
  };
};
