export default ({ contentPattern }) => {
  if (contentPattern.MsgType === 'text' && contentPattern.Content) {
    return {
      selectedType: 'weChatText',
      message: contentPattern.Content,
    };
  }
  if (contentPattern.MsgType === 'image' && contentPattern.PicUrl) {
    return {
      selectedType: 'weChatFile',
      image: {
        fileUrl: contentPattern.PicUrl,
        thumbnailUri: contentPattern.thumbnailUri,
      },
    };
  }

  return null;
};
