import React, {
  useRef,
  useEffect,
  useState,
  useMemo,
} from 'react';
import classNames from 'classnames';
import { usePrevious } from 'react-use';
import { List, AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css';

import { useDropdownLogic } from 'hooks';
import Tooltip from 'components/Tooltip';
import arrowDown from 'assets/icons/arrow_down.png';

import './style.scss';

const DropdownFieldOld = ({
  type: externalType,
  onChange: externalOnChange,
  value: externalValue,
  input: {
    value = externalValue,
    onChange = externalOnChange,
    type = externalType,
    name,
  } = {},
  meta: {
    error,
  } = {},
  values,
  label,
  placeholder,
  width,
  styles,
  styleInput = {},
  blockStyle = {},
  icon,
  isDisabled = false,
  hideArrow,
  withoutCallbackDestroy = false,
  fieldId,
}) => {
  const {
    buttonRef,
    dropdownRef,
    isDropdownOpen,
    setDropdownOpen,
  } = useDropdownLogic();

  const [bottom, setBottom] = useState(true);
  const prevDrOpen = usePrevious(isDropdownOpen);

  const fieldClass = classNames({
    'mkit-dropdown-field': true,
    [`mkit-dropdown-field_${type}`]: !!type,
    'mkit-dropdown-field_error': error && prevDrOpen && !isDropdownOpen && !value,
    'mkit-dropdown-field-active': isDropdownOpen,
    'mkit-dropdown-field_disabled': isDisabled,
  });

  const selectedValueClass = classNames({
    'mkit-dropdown-field__select-wrapper__selected-value': true,
    'mkit-dropdown-field__select-wrapper__selected-value_placeholder': !value,
  });

  const dropdownClass = classNames({
    'mkit-dropdown-field__block': true,
    'mkit-dropdown-field__block_open': isDropdownOpen,
    'mkit-dropdown-field__block_top': !bottom,
  });

  const fieldRef = useRef();

  const onButtonPress = () => {
    if (!isDisabled) {
      if (((window.innerHeight - fieldRef.current.offsetTop) < 300) && bottom) {
        setBottom(false);
      } else if (((window.innerHeight - fieldRef.current.offsetTop) > 300) && !bottom) {
        setBottom(true);
      }
      setDropdownOpen(!isDropdownOpen);
    }
  };

  const onItemPress = (val) => {
    onChange(val);
    setDropdownOpen(!isDropdownOpen);
  };

  const { displayedValue, displayedImage } = useMemo(() => {
    const foundItem = values.find(item => item.id === value);
    let textValue = '';
    let imageValue = '';

    if (foundItem) {
      textValue = foundItem.title;
      imageValue = foundItem.image;
    } else {
      textValue = value || placeholder;
    }

    return {
      displayedValue: textValue,
      displayedImage: imageValue,
    };
  }, [values, value]);

  useEffect(() => {
    if ((window.innerHeight - fieldRef.current.offsetTop) < 120) {
      setBottom(false);
    }

    return () => {
      if (!withoutCallbackDestroy) {
        onChange(undefined);
      }
    };
  }, []);

  const filteredItems = useMemo(() =>
    values.filter(val => val !== value), [values, value]);

  const itemRenderer = ({ index, key, style }) => (
    <div
      key={key}
      className="mkit-dropdown-field__block__value"
      role="presentation"
      onClick={() => onItemPress(filteredItems[index].id || filteredItems[index])}
      style={style}
    >
      {
        filteredItems[index].image && (
          <img
            alt=""
            src={filteredItems[index].image}
            className="mkit-dropdown-field__block__value__image"
          />
        )
      }
      {filteredItems[index].title || filteredItems[index]}
    </div>
  );

  return (
    <div
      ref={fieldRef}
      style={styles}
      className={fieldClass}
      data-tip={error}
      data-for={`${name}-mkit-dropdown-field`}
    >
      {
        label && (
          <div className="mkit-dropdown-field__label-wrapper">
            {
              icon && (
                <img src={icon} alt="" className="mkit-dropdown-field__label-wrapper__icon" />
              )
            }
            <label className="mkit-dropdown-field__label-wrapper__label">
              {label}
            </label>
          </div>
        )
      }
      <div
        ref={buttonRef}
        className="mkit-dropdown-field__select-wrapper"
        role="presentation"
        onClick={onButtonPress}
        style={{
          minWidth: width,
          ...styleInput,
        }}
        data-testid={`${window.location.pathname}/dropdown-field/${label?.toLowerCase().replace(/\s+/g, '') || fieldId}`}
      >
        <div className={selectedValueClass}>
          {
            displayedImage && (
              <img src={displayedImage} alt="" className="mkit-dropdown-field__select-wrapper__selected-value__image" />
            )
          }
          {displayedValue}
        </div>
        {!hideArrow && (
          <img src={arrowDown} className="mkit-dropdown-field__select-wrapper__img" alt="arrow-down" />
        )}
      </div>
      <div
        ref={dropdownRef}
        className={dropdownClass}
        style={{
          // width: width + 18,
          height: filteredItems.length > 9 ? 270 : filteredItems.length * 30,
          ...blockStyle,
          ...styleInput,
        }}
      >
        <AutoSizer>
          {({ height, width: containerWidth }) => (
            <List
              width={containerWidth}
              height={height}
              rowCount={filteredItems.length}
              rowHeight={30}
              rowRenderer={itemRenderer}
            />
          )}
        </AutoSizer>
      </div>
      {
        error && prevDrOpen && !isDropdownOpen && !value && (
          <Tooltip id={`${name}-mkit-dropdown-field`} place="right" multiline isError />
        )
      }
    </div>
  );
};

export default DropdownFieldOld;
