/*eslint-disable */
import React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';

import ActionNodeModel from './ActionNodeModel';
import ActionNodeWidget from './ActionNodeWidget';
import ErrorBoundary from './ErrorBoundary';

export default class ActionNodeFactory extends AbstractReactFactory {
  constructor() {
    super('action-node');
  }

  generateModel() {
    return new ActionNodeModel();
  }

  generateReactWidget(event) {
    return (
      <ErrorBoundary>
        <ActionNodeWidget engine={this.engine} node={event.model} />
      </ErrorBoundary>
    );
  }
}
