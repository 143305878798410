import React, { useCallback, useState } from 'react';

import CheckableTag from '../CheckableTag/CheckableTag';
import './CheckableTagList.scss';

const CheckableTagList = ({
  items, onBlur, onFocus, onChange = () => {}, value,
}) => {
  const [checkedItems, changeCheckedItems] = useState(value || []);
  const onChangeCallback = useCallback((state, key) => {
    let newValue = [];
    if (state && !checkedItems.includes(key)) {
      checkedItems.push(key);
      newValue = [...checkedItems];
    } else if (!state) {
      newValue = checkedItems.filter(item => item !== key);
    }
    changeCheckedItems(newValue);
    onChange(newValue);
  }, [checkedItems, changeCheckedItems, onChange]);

  return (
    <div className="checkable-tags-list">
      {items.map(item => (
        <CheckableTag
          onBlur={onBlur}
          onFocus={onFocus}
          checked={checkedItems.indexOf(item.value) !== -1}
          onChange={(state) => {
            onChangeCallback(state, item.value);
          }}
          key={item.value}
        >
          {item.label}
        </CheckableTag>
      ))}
    </div>
  );
};

export default CheckableTagList;
