import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  EditorTextarea,
  DnDSuggestionsOutside,
} from 'components/constructor';

import './style.scss';

const TextMessage = ({
  rcsParams: {
    message,
    suggestionsOutside,
  },
  openCreateSuggestion,
  updateRcsParams,
  withoutSuggestions,
  readOnly,
}) => {
  const outsideSuggestionsStyles = {
    button: {
      width: 306,
      margin: '24px auto 0',
    },
  };

  const { t } = useTranslation();

  const setMessage = (newMessage) => {
    updateRcsParams({
      message: newMessage,
    });
  };

  const onSuggestionTextChange = (text, id) => {
    const changedSuggestion = suggestionsOutside.find(I => I.id === id);

    suggestionsOutside[suggestionsOutside.indexOf(changedSuggestion)] = {
      ...changedSuggestion,
      text,
    };

    updateRcsParams({
      [suggestionsOutside]: suggestionsOutside,
    });
  };

  const onAddSuggestion = () => {
    openCreateSuggestion({ place: 'outside' });
    // if (type === 'action') {
    //   openCreateSuggestion({ place: 'outside' });
    // } else {
    //   updateRcsParams({
    //     suggestionsOutside: [
    //       ...suggestionsOutside,
    //       {
    //         id: Math.random(),
    //         text: 'Reply',
    //         type: 'reply',
    //       },
    //     ],
    //   });
    // }
  };

  const onRemoveSuggestion = (place, id) => {
    updateRcsParams({ suggestionsOutside: suggestionsOutside.filter(I => I.id !== id) });
  };

  return (
    <div className="mkit-text-message">
      <EditorTextarea
        value={message}
        onChange={setMessage}
        type="border"
        isSms={withoutSuggestions}
        placeholderValue={!withoutSuggestions && `${t('MESSAGE_CONSTRUCTOR.PLACEHOLDERS.ENTER_OR_LEAVE_MESSAGE')} ${t('MESSAGE_CONSTRUCTOR.PLACEHOLDERS.MAX_CHARS', { count: 2000 })}`}
        maxLength={!withoutSuggestions && 2000}
        readOnly={readOnly}
      />
      {
        !withoutSuggestions && (
          <DnDSuggestionsOutside
            updateRcsParams={updateRcsParams}
            suggestionsOutside={suggestionsOutside}
            onSuggestionChange={onSuggestionTextChange}
            onRemoveSuggestion={onRemoveSuggestion}
            openCreateSuggestion={openCreateSuggestion}
            readOnly={readOnly}
            styles={outsideSuggestionsStyles}
            onAddSuggestion={onAddSuggestion}
          />
        )
      }
    </div>
  );
};

export default TextMessage;
