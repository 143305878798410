import React from 'react';
import classNames from 'classnames';
import AceEditor from 'react-ace';
import { useTranslation } from 'react-i18next';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/theme-xcode';

import { useTheme } from 'hooks';

import './style.scss';

const CodeEditorField = ({
  input: {
    value,
    onChange,
  },
  styles = {},
  readOnly = false,
}) => {
  const buttonsClass = buttonKey => classNames('code-editor-field__header__buttons__button', {
    'code-editor-field__header__buttons__button_active': buttonKey === value.language,
  });

  const { t } = useTranslation();

  const { isDarkTheme } = useTheme();

  const buttonsArray = [
    {
      label: 'JS',
      value: 'javascript',
    },
    {
      label: 'Python',
      value: 'python',
    },
  ];

  const onChangeLanguage = (language) => {
    onChange({ ...value, language });
  };

  const handleChange = (code) => {
    onChange({ ...value, code });
  };

  return (
    <div className="code-editor-field" style={styles.container}>
      <div className="code-editor-field__header">
        <span className="code-editor-field__header__label">
          {t('SCENARIOS_CONSTRUCTOR.INSTANCES.LANGUAGES')}
        </span>
        <div className="code-editor-field__header__buttons">
          {
            buttonsArray.map(item => (
              <button
                key={item.label}
                type="button"
                className={buttonsClass(item.value)}
                onClick={() => onChangeLanguage(item.value)}
                disabled={readOnly}
              >
                {item.label}
              </button>
            ))
          }
        </div>
      </div>
      <AceEditor
        className="code-editor-field__input"
        mode={value.language}
        theme={isDarkTheme ? 'monokai' : 'xcode'}
        name="code-editor"
        value={value.code}
        onChange={handleChange}
        setOptions={{
          useWorker: false,
        }}
        readOnly={readOnly}
      />
    </div>
  );
};

export default CodeEditorField;
