import moment from 'moment';

export default (filters = {}) =>
  Object.keys(filters).reduce((acc, filterId) => {
    switch (filters[filterId].type) {
      case 'mask': {
        const values = [];

        if (filters[filterId].values.empty) {
          values.push('empty');
        }

        if (filters[filterId].values.notempty) {
          values.push('notempty');
        }

        if (values.length) {
          acc[`${filterId}[of]`] = values.toString();
          break;
        }

        if (filters[filterId].values.value) {
          if (filters[filterId].values.value.search(/[*_%]/g) < 0) {
            values.push(`*${filters[filterId].values.value}*`);
          } else {
            values.push(filters[filterId].values.value);
          }
        }

        if (values.length) {
          acc[`${filterId}[of]`] = values.toString();
        }
        break;
      }
      case 'list': {
        const values = Object.keys(filters[filterId].values).reduce((accJ, key) => {
          if (filters[filterId].values[key]) {
            accJ.push(key);
          }

          return accJ;
        }, []);

        acc[`${filterId}[of]`] = values.toString();
        break;
      }
      case 'sort': {
        const { name, sortType } = filters[filterId];

        acc[filterId] = `${sortType === 'desc' ? '-' : ''}${name}`;
        break;
      }
      case 'number': {
        const values = [];

        if (filters[filterId].values.empty) {
          values.push('empty');
        }

        if (filters[filterId].values.notempty) {
          values.push('notempty');
        }

        if (filters[filterId].values.range) {
          if (filters[filterId].values.fromValue) {
            acc[`${filterId}[gt]`] = filters[filterId].values.fromValue;
          }

          if (filters[filterId].values.toValue) {
            acc[`${filterId}[lt]`] = filters[filterId].values.toValue;
          }
        } else if (filters[filterId].values.fromValue) {
          values.push(filters[filterId].values.fromValue);
        }

        if (values.length) {
          acc[`${filterId}[of]`] = values.toString();
        }

        break;
      }
      case 'date': {
        const values = [];

        if (filters[filterId].values.empty) {
          values.push('empty');
        }

        if (filters[filterId].values.notempty) {
          values.push('notempty');
        }

        if (filters[filterId].values.range) {
          if (filters[filterId].values.fromValue) {
            acc[`${filterId}[gt]`] = `${moment(filters[filterId].values.fromValue).format('YYYY-MM-DDTHH:mm:ss')}Z`;
          }

          if (filters[filterId].values.toValue) {
            acc[`${filterId}[lt]`] = `${moment(filters[filterId].values.toValue).format('YYYY-MM-DDTHH:mm:ss')}Z`;
          }
        } else if (!moment.duration(moment(filters[filterId].values.fromValue).format('HH:mm:ss')).asSeconds()) {
          // все записи за день
          acc[`${filterId}[gt]`] = `${moment(filters[filterId].values.fromValue).format('YYYY-MM-DD')}T00:00:00Z`;
          acc[`${filterId}[lt]`] = `${moment(filters[filterId].values.fromValue).format('YYYY-MM-DD')}T23:59:59Z`;
        } else if (filters[filterId].values.fromValue) {
          values.push(`${moment(filters[filterId].values.fromValue).format('YYYY-MM-DDTHH:mm:ss')}Z`);
        }

        if (values.length) {
          acc[`${filterId}[of]`] = values.toString();
        }
        break;
      }
      default: break;
    }
    return acc;
  }, {});
