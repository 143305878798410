import React, { useMemo } from 'react';
import classNames from 'classnames';

import { EditorTextarea } from 'components/constructor';

import Header from './Header';
import TextBlock from './TextBlock';
import Buttons from './Buttons';

import './style.scss';

const WhatsAppTemplateCard = ({
  rcsParams: {
    components,
    componentsParams = {},
    ...rcsParams
  },
  updateRcsParams,
  openMediaSelect,
  openGeolocation,
  readOnly,
}) => {
  const fieldStyle = { backgroundColor: '#fff', minHeight: 'auto', width: '100%' };

  const findVariables = component => Object.values(component).reduce((paramsAcc, objValue) => {
    if (typeof objValue === 'string') {
      const foundVariables = objValue.match(/{{\d}}/g);
      if (foundVariables && !paramsAcc.some(I => foundVariables.includes(I))) {
        paramsAcc.push(...foundVariables);
      }
    }
    return paramsAcc;
  }, []);

  const {
    header,
    body,
    footer,
    buttons,
    variables,
  } = useMemo(() => components?.reduce((acc, item) => {
    let componentVariables;
    item.type = item.type.toLowerCase();

    if (item.type === 'buttons') {
      item.buttons.forEach((B) => {
        componentVariables = findVariables(B);
      });
    } else {
      componentVariables = findVariables(item);
    }

    acc[item.type] = {
      ...item,
      // variables: componentVariables,
    };
    if (componentVariables.length) {
      if (acc.variables) {
        acc.variables.push({
          type: item.type,
          list: componentVariables,
        });
      } else {
        acc.variables = [({
          type: item.type,
          list: componentVariables,
        })];
      }
    }

    return acc;
  }, {}), [components]);
  // const buttons = {
  //   type: 'buttons',
  //   buttons: [
  //     {
  //       text: 'Hello QUICK REPLY',
  //       type: 'quick_reply',
  //     },
  //     {
  //       text: 'Bye QUICK REPLY',
  //       type: 'quick_reply',
  //     },
  //   ],
  // };

  const onVariableChange = (value, type, index) => {
    updateRcsParams({
      componentsParams: {
        ...componentsParams,
        [type]: {
          ...componentsParams[type],
          [index]: value,
        },
      },
    });
  };

  const headerClass = classNames('mkit-whats-app-template-card__header', {
    'mkit-whats-app-template-card__header_open-bottom': !!body || !!footer || (!!buttons && buttons?.buttons[0]?.type !== 'quick_reply'),
  });
  const textBlockClass = classNames('mkit-whats-app-template-card__text-block', {
    'mkit-whats-app-template-card__text-block_open-bottom': (!!footer || (!!buttons && buttons?.buttons[0]?.type !== 'quick_reply')) && !header,
    'mkit-whats-app-template-card__text-block_open-top': !!header && !footer,
    'mkit-whats-app-template-card__text-block_open': !!header && !!footer,
  });

  const footerClass = classNames('mkit-whats-app-template-card__text-block mkit-whats-app-template-card__footer', {
    'mkit-whats-app-template-card__text-block_open-bottom': !header && !body && buttons && buttons?.buttons[0]?.type !== 'quick_reply',
    'mkit-whats-app-template-card__text-block_open-top': (!!header || !!body) && (!buttons || buttons?.buttons[0]?.type === 'quick_reply'),
    'mkit-whats-app-template-card__text-block_open': (!!header || !!body) && buttons && buttons?.buttons[0]?.type !== 'quick_reply',
  });

  const buttonsClass = classNames('mkit-whats-app-template-card__buttons', {
    'mkit-whats-app-template-card__buttons_reply': buttons?.buttons[0]?.type === 'quick_reply',
  });

  return (
    <div className="mkit-whats-app-template-card">
      {
        header && (
          <Header
            className={headerClass}
            componentsParams={componentsParams}
            rcsParams={rcsParams}
            updateRcsParams={updateRcsParams}
            openMediaSelect={openMediaSelect}
            openGeolocation={openGeolocation}
            {...header}
          />
        )
      }
      {
        body && (
          <TextBlock
            componentsParams={componentsParams}
            className={textBlockClass}
            readOnly={readOnly}
            {...body}
          />
        )
      }
      {
        footer && (
          <TextBlock
            componentsParams={componentsParams}
            className={footerClass}
            {...footer}
          />
        )
      }
      {
        buttons && (
          <Buttons
            componentsParams={componentsParams}
            className={buttonsClass}
            {...buttons}
          />
        )
      }
      {
        !!variables && variables.map(({ type, list }) => (
          <>
            <span className="mkit-whats-app-template-card__title">{type}</span>
            {
              list.map((I, index) => (
                <div className="mkit-whats-app-template-card__row">
                  <span className="mkit-whats-app-template-card__row__label">{I}</span>
                  <EditorTextarea
                    value={componentsParams[type]?.[index + 1]}
                    onChange={V => onVariableChange(V, type, index + 1)}
                    type="border"
                    styleWrapper={fieldStyle}
                    placeholderValue="Enter variable"
                    readOnly={readOnly}
                  />
                </div>
              ))
            }
          </>
        ))
      }
    </div>
  );
};

export default WhatsAppTemplateCard;
