import React from 'react';
import BroadcastsScreen from 'mkit-front-ds/dist/screens/BroadcastsScreen';

const CampaignsScreen = ({ history }) => (
  <div
    className="broadcasts-container"
    style={{
      display: 'flex', flexDirection: 'column', minHeight: '100%', width: '100%',
    }}
  >
    <BroadcastsScreen history={history} />
  </div>
);

export default CampaignsScreen;
