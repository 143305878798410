import React from 'react';
import { isString } from 'lodash';

import { IconButton } from 'components/buttons';

import './styles.scss';

const ButtonCellRenderer = ({
  buttons,
  data,
  node,
}) => (
  <div className="mkit-buttons-cell-renderer">
    {
      buttons && buttons.map((button) => {
        const isHidden = button?.hidden?.(data) || false;
        return (
          <IconButton
            key={button.id}
            icon={isString(button.icon) ? button.icon : button.icon(data)}
            filterType={button.filterType}
            onClick={() => {
              button.onClick(data);
              // hack, stop propagation
              node.gridOptionsWrapper.gridOptions.isPropagationStopped = true;
              setTimeout(() => {
                node.gridOptionsWrapper.gridOptions.isPropagationStopped = false;
              });
            }}
            style={{ ...button.style, visibility: isHidden ? 'hidden' : 'visible' }}
            iconStyle={button.iconStyle}
            isDisabled={button.disabled}
            title={button.title}
          />
        );
      })
    }
  </div>
);

export default ButtonCellRenderer;
