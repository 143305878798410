import { toast, Slide } from 'react-toastify';

const defaultPositions = 'bottom-center';

export const error = (text, { position = defaultPositions, toastId } = {}) =>
  toast.error(text, { position, toastId, transition: Slide });

export const success = text =>
  toast.success(text, { position: defaultPositions, transition: Slide });

export const warning = text =>
  toast.warn(text, { position: defaultPositions, transition: Slide });
