import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { uikit } from 'components';
import { BasicButton } from 'components/buttons';
import {
  RcsBlock, SmsBlock, WhatsAppBlock, WeChatBlock,
} from 'components/blocks';
import { useBoolean } from 'hooks';
import {
  getTransformedRcs,
  getTransformedSms,
  getTransformedWhatsApp,
  getTransformedViber,
  getTransformedWeChat,
} from 'helpers/transforms';
import { CHANNELS } from 'const/channelsNew';
import phoneIcon from 'assets/icons/smartphone.svg';
import checkIcon from 'assets/icons/check-green.svg';
import crossIcon from 'assets/icons/red-cross.svg';

import './style.scss';

const ConstructorField = ({
  input,
  readOnly,
  label,
  initialParams,
  canChooseType,
  styles = {},
  currentFlow,
  reportFields,
  isFirstNode,
}) => {
  const { t } = useTranslation();
  const [
    isOpenConstructorDrawer,
    openConstructorDrawer,
    closeConstructor,
  ] = useBoolean(false);

  const stylesBasicButton = {
    container: { marginRight: 20 },
  };

  const handleChange = (value) => {
    input.onChange(value.contentPattern);
    closeConstructor();
  };

  const isFilled = (currentFlow?.channel === CHANNELS.RCS && input.value?.RCSMessage)
      || (currentFlow?.channel === CHANNELS.SMS && input.value)
      || (currentFlow?.channel === CHANNELS.WHATSAPP && input.value)
      || (currentFlow?.channel === CHANNELS.VIBER && input.value)
      || (currentFlow?.channel === CHANNELS.WECHAT && input.value);
  const channel = useMemo(() => input.value
  && getTransformedRcs({ contentPattern: input.value }), [input.value]);

  return (
    <div className="constructor-field" style={styles.container}>
      <span className="constructor-field__label">
        {label}
      </span>
      <div className="constructor-field__content">
        <BasicButton
          text={t('SCENARIOS_CONSTRUCTOR.CONTROLS.OPEN_CONSTRUCTOR')}
          type={BasicButton.types.ACCENT}
          icon={phoneIcon}
          onClick={openConstructorDrawer}
          styles={stylesBasicButton}
        />
        {
          isFilled
            ? (
              <>
                {t('SCENARIOS_CONSTRUCTOR.INSTANCES.FILLED')}
                <img src={checkIcon} alt="" className="constructor-field__content__icon" />
              </>
            )
            : (
              <>
                {t('SCENARIOS_CONSTRUCTOR.INSTANCES.EMPTY')}
                <img src={crossIcon} alt="" className="constructor-field__content__icon" />
              </>
            )
        }
      </div>
      <uikit.Drawer
        visible={isOpenConstructorDrawer}
        width={533}
        closable={false}
        destroyOnClose
      >
        {
          currentFlow?.channel === CHANNELS.SMS && (
            <SmsBlock
              channel={input.value && getTransformedSms({ contentPattern: input.value })}
              onCancel={closeConstructor}
              onChange={handleChange}
              withTools={false}
              withTemplates
              readOnly={readOnly}
              isNewDesign
            />
          )
        }
        {
          currentFlow?.channel === CHANNELS.RCS && (
            <RcsBlock
              channel={channel}
              onCancel={closeConstructor}
              onChange={handleChange}
              withTools={false}
              withTemplates
              readOnly={readOnly}
              initialParams={initialParams}
              canChooseType={canChooseType}
              reportFields={reportFields}
              channelNumber={currentFlow.channel}
              isNewDesign
            />
          )
        }
        {
          currentFlow?.channel === CHANNELS.VIBER && (
            <RcsBlock
              channel={input.value && getTransformedViber({ contentPattern: input.value })}
              onCancel={closeConstructor}
              onChange={handleChange}
              withTools={false}
              withTemplates
              readOnly={readOnly}
              initialParams={initialParams}
              canChooseType={canChooseType}
              reportFields={reportFields}
              channelNumber={currentFlow.channel}
              isViber
              isNewDesign
            />
          )
        }
        {
          currentFlow?.channel === CHANNELS.WHATSAPP && (
            <WhatsAppBlock
              channel={input.value && getTransformedWhatsApp({ contentPattern: input.value })}
              onCancel={closeConstructor}
              onChange={handleChange}
              initialParams={initialParams}
              withTools={false}
              readOnly={readOnly}
              constructorType={isFirstNode ? 'template' : 'freeMessage'}
              channelNumber={currentFlow.channel}
              isNewDesign
            />
          )
        }
        {
          currentFlow?.channel === CHANNELS.WECHAT && (
            <WeChatBlock
              channel={input.value && getTransformedWeChat({ contentPattern: input.value })}
              onCancel={closeConstructor}
              onChange={handleChange}
              readOnly={readOnly}
              channelNumber={currentFlow.channel}
            />
          )
        }
      </uikit.Drawer>
    </div>
  );
};

export default ConstructorField;
