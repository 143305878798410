import React from 'react';
import {
  Field,
} from 'react-final-form';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import uikit from 'components/UIKit';
import { requiredString } from 'helpers';

import './CampaignEditGeneral.scss';

const {
  FormLayout,
  FormItem,
  Input,
  TextArea,
  CardTitle,
  Card,
  FormControl,
} = uikit;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
  labelAlign: 'left',
};

const CampaignEditGeneral = ({
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <Card className="campaign-create-general campaign-create__card">
      <div>
        <CardTitle>
          <Row type="flex" justify="space-between">
            <Col>
              {t('INSTANCES.CAMPAIGNS.GENERAL')}
            </Col>
          </Row>
        </CardTitle>
        <FormLayout {...formItemLayout}>
          <FormItem label={t('INSTANCES.CAMPAIGNS.BROADCAST_NAME')}>
            <Field autocomplete="off" name="name" placeholder={t('INSTANCES.CAMPAIGNS.BROADCAST_NAME')} validate={value => requiredString(value, t)}>
              {({
                input,
                ...props
              }) => (
                <FormControl meta={props.meta}>
                  <Input {...input} {...props} disabled={disabled} />
                </FormControl>
              )}
            </Field>
          </FormItem>
          <FormItem label={t('INSTANCES.DESCRIPTION')}>
            <Field name="description" placeholder={t('INSTANCES.CAMPAIGNS.ENTER_VALUE')}>
              {({ input, ...props }) => (
                <FormControl meta={props.meta}>
                  <TextArea {...input} {...props} rows={12} disabled={disabled} />
                </FormControl>
              )}
            </Field>
          </FormItem>
        </FormLayout>
      </div>
    </Card>
  );
};

export default CampaignEditGeneral;
